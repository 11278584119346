import React from 'react';
import {FormMessage} from "../../components/form";

interface ErrorMessageListProps {
  errors: string[] | string | undefined;
}

const ErrorMessageList: React.FC<ErrorMessageListProps> = ({ errors }) => {
  if (!errors) {
    return null;
  }

  const errorList = Array.isArray(errors) ? errors : [errors];

  return (
    <>
      {errorList.map((error, index) => (
        <p key={index} className={"text-sm text-destructive"}>{error}</p>
      ))}
    </>
  );
};

export default ErrorMessageList;
