import {UserCheckInCheckOut} from "./components/user-check-in-check-out";
import {UserOverview} from "./components/user-overview";
import {useDashboardData} from "../../Controllers/User/dashboard-controller";
import React, {Dispatch, SetStateAction, useEffect} from "react";

type UserAttendanceProps = {
    reloadDashboard: boolean
    setReloadDashboard: Dispatch<SetStateAction<boolean>>
}

export const UserAttendance: React.FC<UserAttendanceProps> = (
{
    reloadDashboard,
    setReloadDashboard
}
) => {
    const {
        dashboardData,
        loadingDashboardData,
        setReloadDashboardData
    } = useDashboardData(reloadDashboard);

    return (
        <>
            <UserCheckInCheckOut
                dashboardData={dashboardData}
                setReloadDashboardData={setReloadDashboardData}
                loadingDashboardData={loadingDashboardData}
                setReloadDashboard={setReloadDashboard}
            />
            <UserOverview
                dashboardData={dashboardData}
                loadingDashboardData={loadingDashboardData}
                setReloadDashboardData={setReloadDashboardData}
                reloadDashboard={reloadDashboard}
                setReloadDashboard={setReloadDashboard}
            />
        </>
    )
}