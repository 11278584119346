import React, {ReactNode, useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import statusVariants, {status} from "../../Core/functions/status-variants";
import {Action} from "../../Core/Table/data-table-actions";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import moment from "moment/moment";
import {Badge} from "../../components/badge";
import {nullOrUndefined} from "../../Core/constants/variables";
import {toast} from "../../components/use-toast";
import {useIntl} from "react-intl";

export const timeCorrectionsUrl: string = 'attendance-management/time-corrections/';

export type TimeCorrection = {
    pk: string,
    userFullName: string
    status: status,
    timeStamp: {
        pk: string,
        checkInDatetime: string,
        checkOutDatetime: string,
        checkInLocation: {
            name: string,
            uuid: string
        },
        checkOutLocation: {
            name: string,
            uuid: string
        }
    },
    correctionCheckInDate: string,
    correctionCheckInTime: string,
    correctionCheckOutDate: string,
    correctionCheckOutTime: string,
    duration: string,
    correctionCheckInLocation: {
        name: string,
        uuid: string
    },
    correctionCheckOutLocation: {
        name: string,
        uuid: string
    },
    beforeCorrectionCheckInDate: string,
    beforeCorrectionCheckInTime: string,
    beforeCorrectionCheckOutDate: string,
    beforeCorrectionCheckOutTime: string,
    beforeDuration: string,
    beforeCorrectionCheckInLocation: {
        name: string,
        uuid: string
    },
    beforeCorrectionCheckOutLocation: {
        name: string,
        uuid: string
    }
    userIsActive?: boolean
}

export type TimeCorrectionsPayload = {
    start: number
    length: number
    search?: string
    ordering?: string
    "filter.status"?: string
    "filter.startDate"?: string
    "filter.endDate"?: string
}

export type TimeCorrectionsRequest = {
    recordsTotal: number
    recordsFiltered: number
    next: string
    previous: string
    data: TimeCorrection[]
}

export const useFetchTimeCorrectionsDataTable = (payload: TimeCorrectionsPayload, sideFunctions: (() => void)[]) => {
    const intl = useIntl()
    const [shouldReFetch, reFetchTimeCorrections] = useState(false)
    const [response, setResponse] = useState<TimeCorrectionsRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    });
    const [formattedData, setFormattedData] = useState<TimeCorrectionFormatted[]>([])
    const [selectedRecord, setSelectedRecord] = useState<string>()
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const deleteTimeCorrection = (id: string) => {
        axiosInstance.delete(timeCorrectionsUrl + `${id}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                reFetchTimeCorrections(true)
                sideFunctions.forEach(func => func())
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    const fetchTimeCorrections = (signal: AbortSignal) => {
        setLoading(true);
        axiosInstance.get(timeCorrectionsUrl,{
            params: payload,
            signal
        })
            .then((res) => {
                let data = res.data
                setResponse(data);

                const formatData: TimeCorrectionFormatted[] = data.data.map((timeCorrection: TimeCorrection) => {
                    return {
                        ...timeCorrection,
                        rowID: `${timeCorrection.pk}_${timeCorrection.userFullName}`,
                        correctionCheckInDate: moment(timeCorrection.correctionCheckInDate).format("ddd DD MMM YYYY"),
                        beforeCorrectionCheckInDate: moment(timeCorrection.beforeCorrectionCheckInDate).format("ddd DD MMM YYYY"),
                        statusBadge:
                            <Badge variant={statusVariants(timeCorrection?.status)}>{timeCorrection?.status ? intl.formatMessage({id: timeCorrection.status.toLowerCase(), defaultMessage: timeCorrection.status}) : nullOrUndefined}</Badge>,
                        actions: [
                            {type: "delete", handleAction: () => deleteTimeCorrection(timeCorrection.pk)}
                        ] as Action[]
                    };
                })
                setFormattedData(formatData);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                reFetchTimeCorrections(false);
            })
    }

    useEffect( () => {
        const abortController = new AbortController()
        const signal = abortController.signal

        if (
            payload.hasOwnProperty("filter.startDate") &&
            payload.hasOwnProperty("filter.endDate")
        ) fetchTimeCorrections(signal);

        return () => {
            abortController.abort()
        }
    }, [payload, shouldReFetch, permissionContext.isAdmin])

    return { response, loading, error, reFetchTimeCorrections, formattedData, selectedRecord, setSelectedRecord };
}

export interface TimeCorrectionFormatted extends TimeCorrection {
    rowID: string
    actions: Action[]
    statusBadge: ReactNode
}