import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {PermissionProvider} from "./PermissionProvider";

export type AuthType = {
    userFullName: string | null
    token: string | null
    userUUID: string | null
    accountRole: string | null
    isAdminView: boolean
}

const getAuthCredentials = () : AuthType => {
    const userFullName = localStorage.getItem("userFullName");
    const token = localStorage.getItem("token");
    const userUUID = localStorage.getItem("userUUID");
    const isAdminView = localStorage.getItem("isAdminView") === "true";
    const accountRole = localStorage.getItem("accountRole");
    const location = window.location.href.includes("login");

    if (!userFullName && !token && location) {
        alert("Local storage is not accessible. Please check browser settings.")
    }
    return {userFullName, token, userUUID, accountRole, isAdminView};
};

export const AuthMiddleware = ({ children }: any) => {
    const auth = getAuthCredentials();
    const navigate = useNavigate();

    useEffect(() => {
        if (!auth.token || !auth.userFullName || !auth.userUUID) {
            localStorage.clear();
            navigate("/account-management/login");
        }
    }, [auth, navigate]);

    return auth.token ? (
        <PermissionProvider auth={auth}>
            {children}
        </PermissionProvider>
    ) : null;
};
