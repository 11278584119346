import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "../../components/form";
import React, {useRef, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {toast} from "../../components/use-toast";
import {DialogClose, DialogFooter} from "../../components/dialog";
import {Button} from "../../components/button";
import {AttendanceBreaktime, attendancesUrl, CheckInActivityType} from "../Controllers/attendances-controller";
import {FormattedMessage, useIntl} from "react-intl";
import {ServerErrorsType, useServerErrors} from "../../Core/functions/use-server-errors";
import ErrorMessageList from "../../Core/components/ErrorMessageList";
import {TimePicker} from "../../components/date-time-picker/time-picker";

const createAttendanceBreaktimeSchema = z.object({
    startTime: z.any(),
    endTime: z.any(),
})

type CreateAttendanceBreaktimeFormProps = {
    attendance: CheckInActivityType | null
    userUUID: string
    reloadData: (value: boolean) => void
}

export const CreateAttendanceBreaktimeForm: React.FC<CreateAttendanceBreaktimeFormProps> = (
    {
        attendance,
        userUUID,
        reloadData
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof createAttendanceBreaktimeSchema>>({
        resolver: zodResolver(createAttendanceBreaktimeSchema)
    })
    const closeModal = useRef<HTMLButtonElement>(null);
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({})
    const errors = useServerErrors(apiErrors, form);


    const onSubmit = (values: z.infer<typeof createAttendanceBreaktimeSchema>) => {
        if (!attendance) return;
        const startHour = values.startTime ? String(values.startTime?.hour) : null;
        const startMinute = values.startTime ? String(values.startTime?.minute) : null;
        const endHour = values.endTime ? String(values.endTime?.hour) : null;
        const endMinute = values.endTime ? String(values.endTime?.minute) : null;

        const data = {
            ...values,
            ...((startHour && startMinute) && {
                startTime: `${startHour.length > 1 ? startHour : "0" + startHour}:${startMinute.length > 1 ? startMinute : "0" + startMinute}`
            }),
            ...((endHour && endMinute) && {
                endTime: `${endHour.length > 1 ? endHour : "0" + endHour}:${endMinute.length > 1 ? endMinute : "0" + endMinute}`
            }),
        }

        axiosInstance.post(attendancesUrl + `${userUUID}/attendances/${attendance?.pk}/break-times/`, data)
            .then((res) => {
                setApiErrors({});
                reloadData(true);
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response.data);
            })
    }

    return (
        <Form {...form}>
            <form method="post" onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4 max-w-[550px]">
                <div className="flex flex-row gap-2">
                    <div className={"w-1/2"}>
                        <FormField
                            control={form.control}
                            name="startTime"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>
                                        <FormattedMessage
                                            id={"attendance.attendances.startTime"}
                                            defaultMessage={"Start Time"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <TimePicker value={field.value} onChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className={"w-1/2"}>
                        <FormField
                            control={form.control}
                            name="endTime"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>
                                        <FormattedMessage
                                            id={"attendance.attendances.endTime"}
                                            defaultMessage={"End Time"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <TimePicker value={field.value} onChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                    </div>
                </div>

                <ErrorMessageList errors={errors.nonFieldErrors} />
                <ErrorMessageList errors={errors.detailErrors} />

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal} asChild>
                        <Button type="button" variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}





const editAttendanceServicesSchema = z.object({
    startTime: z.object({
        hour: z.number(),
        minute: z.number(),
    }).optional(),
    endTime: z.object({
        hour: z.number(),
        minute: z.number(),
    }).optional(),
})

type EditAttendanceBreaktimeFormProps = {
    attendance: CheckInActivityType | null
    userUUID: string
    reloadData: (value: boolean) => void
    breaktime: AttendanceBreaktime
}

export const EditAttendanceBreaktimeForm: React.FC<EditAttendanceBreaktimeFormProps> = (
    {
        breaktime,
        attendance,
        userUUID,
        reloadData,
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof editAttendanceServicesSchema>>({
        resolver: zodResolver(editAttendanceServicesSchema),
        defaultValues: {
            startTime: (() => {
                const [hour, minute] = breaktime.startTime.split(":");
                return {
                    hour: parseInt(hour),
                    minute: parseInt(minute)
                }
            })(),
            endTime: (() => {
                const [hour, minute] = breaktime.endTime.split(":");
                return {
                    hour: parseInt(hour),
                    minute: parseInt(minute)
                }
            })()
        }
    })
    const closeModal = useRef<HTMLButtonElement>(null);
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({})
    const errors = useServerErrors(apiErrors, form);

    const onSubmit = (values: z.infer<typeof editAttendanceServicesSchema>) => {
        if (!attendance && !breaktime) return;
        const startHour = String(values.startTime?.hour);
        const startMinute = String(values.startTime?.minute);
        const endHour = String(values.endTime?.hour);
        const endMinute = String(values.endTime?.minute);

        const data = {
            ...values,
            startTime: `${startHour.length > 1 ? startHour : "0" + startHour}:${startMinute.length > 1 ? startMinute : "0" + startMinute}`,
            endTime: `${endHour.length > 1 ? endHour : "0" + endHour}:${endMinute.length > 1 ? endMinute : "0" + endMinute}`
        }

        axiosInstance.put(attendancesUrl + `${userUUID}/attendances/${attendance?.pk}/break-times/${breaktime.pk}/`, data)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.updatedSuccessfully", defaultMessage: "The update was completed successfully."})
                });
                setApiErrors({});
                reloadData(true);
            })
            .catch((err) => {
                setApiErrors(err.response.data)
            })
    }

    return (
        <Form {...form}>
            <form method="post" onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <div className="flex flex-row gap-2">
                    <div className={"w-1/2"}>
                        <FormField
                            control={form.control}
                            name="startTime"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>
                                        <FormattedMessage
                                            id={"attendance.breaktimeStartTime"}
                                            defaultMessage={"Start Time"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <TimePicker value={field.value as any} onChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className={"w-1/2"}>
                        <FormField
                            control={form.control}
                            name="endTime"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>
                                        <FormattedMessage
                                            id={"attendance.breaktimeEndTime"}
                                            defaultMessage={"End Time"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <TimePicker value={field.value as any} onChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                    </div>
                </div>

                <ErrorMessageList errors={errors.nonFieldErrors} />
                <ErrorMessageList errors={errors.detailErrors} />

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}