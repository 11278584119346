import DataTableOld, {ColumnOld} from "../../Core/Table/data-table-old";
import React, {ReactNode, SetStateAction, useEffect, useState} from "react";
import DataTableTools from "../../Core/Table/data-table-tools";
import DataTablePagination from "../../Core/Table/data-table-pagination";
import {
    TimesheetEmployeeDetail, TimesheetEmployeeDetailsRequest,
    TimesheetEmployeesPayload,
    timesheetManagementUrl
} from "../Controllers/timesheet-controller";
import moment from "moment/moment";
import {useIntl} from "react-intl";
import statusVariants from "../../Core/functions/status-variants";
import {Badge} from "../../components/badge";

type TimesheetEmployeeDetailsDataTableProps = {
    timesheetEmployeesRequest: TimesheetEmployeeDetailsRequest
    payload: TimesheetEmployeesPayload
    setPayload: React.Dispatch<SetStateAction<TimesheetEmployeesPayload>>
    loading: boolean
    month: number
    year: number
    isLoading?: boolean
}

interface TTimesheetEmployeeDetailFromatted extends TimesheetEmployeeDetail {
    badge?: ReactNode
}


const TimesheetEmployeesDataTable = (
    {
        timesheetEmployeesRequest,
        payload,
        setPayload,
        loading,
        year,
        month,
        isLoading
    }: TimesheetEmployeeDetailsDataTableProps) => {
    const intl = useIntl();
    const [formattedData, setFormattedData] = useState<TTimesheetEmployeeDetailFromatted[]>([])

    const timesheetEmployeesColumns: ColumnOld[] = [
        {
            backendKey: "fullName",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.employeeName", defaultMessage: "Employee Name"}),
            sortable: true,
            sortingValue: "full_name"
        },
        {
            backendKey: "date",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.date", defaultMessage: "Date"}),
            sortable: true,
        },
        {
            backendKey: "startTime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.startTime", defaultMessage: "Start Time"}),
            sortable: true,
            sortingValue: "start_time"
        },
        {
            backendKey: "endTime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.endTime", defaultMessage: "End Time"}),
            sortable: true,
            sortingValue: "end_time"
        },
        {
            backendKey: "duration",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.duration", defaultMessage: "Duration"}),
            sortable: true
        },
        {
            backendKey: "badge",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.type", defaultMessage: "type"}),
            sortable: true,
            sortingValue: "type"
        }
    ]

    const DETAIL_TYPES = [
        {
            key: "sickLeave",
            value: intl.formatMessage({id: "timesheetManagement.sickLeave", defaultMessage: "Sick Leave"})
        },
        {
            key: "vacation",
            value: intl.formatMessage({id: "timesheetManagement.vacation", defaultMessage: "Vacation"})
        },
        {
            key: "attendance",
            value: intl.formatMessage({id: "timesheetManagement.attendance", defaultMessage: "Attendance"})
        },
        {
            key: "breakTime",
            value: intl.formatMessage({id: "timesheetManagement.breakTime", defaultMessage: "Break Time"})
        },
    ]

    const types = [
      {
        value: "Attendance",
        label: intl.formatMessage({id: "timesheetManagement.attendance", defaultMessage: "Attendance"})
      },
      {
        value: "breakTime",
        label: intl.formatMessage({id: "timesheetManagement.breakTime", defaultMessage: "Break Time"})
      },
      {
        value: "SickLeave",
        label: intl.formatMessage({id: "timesheetManagement.sickLeave", defaultMessage: "Sick Leave"})
      },
      {
        value: "vacation",
        label: intl.formatMessage({id: "timesheetManagement.vacation", defaultMessage: "Vacation"}),
      }
    ]

    useEffect(() => {
        const formatData: TTimesheetEmployeeDetailFromatted[] = timesheetEmployeesRequest?.data.map((employee: TimesheetEmployeeDetail) => {
            let type = DETAIL_TYPES.find(elem => elem.key === employee.type)

            return {
                ...employee,
                ...(employee.date && {date: moment(employee.date).format("ddd DD MMM YYYY")}),
                ...(employee.type && {badge: <Badge variant={statusVariants(employee.type)}>{type?.value}</Badge>})
            }
        })

        setFormattedData(formatData)
    }, [timesheetEmployeesRequest.data]);

    return(
        <div className="space-y-4 min-h-[40rem] flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools
                    payload={payload}
                    setPayload={setPayload}
                    filters={[
                        {
                            type: "simple",
                            title: intl.formatMessage({id: "data.table.filter.type", defaultMessage: "Type"}),
                            filterBy: 'filter.type',
                            options: types ?? []
                        }
                    ]}
                    exportTable={{
                        fileName: `${year}_${month}_${moment().valueOf()}_employees-details`,
                        apiURL: timesheetManagementUrl + `${year}/${month}/employees/details/export/`,
                        payload: payload
                    }}
                />
                <DataTableOld
                    data={formattedData}
                    detailData={timesheetEmployeesRequest.data}
                    loading={isLoading || loading}
                    setPayload={setPayload}
                    columns={timesheetEmployeesColumns}
                    payload={payload}
                    showLoadingState={true}
                />
            </div>
            <DataTablePagination request={timesheetEmployeesRequest} payload={payload} setPayload={setPayload}/>
        </div>
    )
}

export default TimesheetEmployeesDataTable;