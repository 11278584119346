"use client"

import React, {SetStateAction, useEffect, useState} from "react";
import { TimeValue } from "react-aria";
import {Label} from "../label";
import {TimePickerInput} from "./time-picker-input";
import {Clock} from "@phosphor-icons/react";
import {cn} from "../../lib/utils";
import { Period } from "./time-picker-utils";
import {TimePeriodSelect} from "./period-select";
import {FormattedMessage} from "react-intl";
import moment from "moment";

/*const TimePicker = React.forwardRef<
  HTMLDivElement,
  Omit<TimeFieldStateOptions<TimeValue>, "locale">
>((props, forwardedRef) => {
  return <TimeField {...props} />;
});*/


interface TimePickerDemoProps {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
  disabled?: boolean
  hideSeconds?: boolean
  handleResetProps?: Function
}

export function TimePickerDemo({ date, setDate, disabled, handleResetProps, hideSeconds }: TimePickerDemoProps) {
  const minuteRef = React.useRef<HTMLInputElement>(null);
  const hourRef = React.useRef<HTMLInputElement>(null);
  const secondRef = React.useRef<HTMLInputElement>(null);

  return (
    <div data-testid={"time-picker"} className="flex items-end gap-2">
      <div className="grid gap-1 text-center">
        <Label htmlFor="hours" className="text-xs">
          <FormattedMessage id={"hours"} defaultMessage={"Hours"}/>
        </Label>
        <TimePickerInput
          picker="hours"
          date={date}
          setDate={setDate}
          ref={hourRef}
          onRightFocus={() => minuteRef.current?.focus()}
          disabled={disabled}
          handleResetProps={handleResetProps}
        />
      </div>
      <div className="grid gap-1 text-center">
        <Label htmlFor="minutes" className="text-xs">
          <FormattedMessage id={"minutes"} defaultMessage={"Minutes"}/>
        </Label>
        <TimePickerInput
          picker="minutes"
          date={date}
          setDate={setDate}
          ref={minuteRef}
          onLeftFocus={() => hourRef.current?.focus()}
          onRightFocus={() => secondRef.current?.focus()}
          disabled={disabled}
          handleResetProps={handleResetProps}
        />
      </div>
      <div className={cn(
          "grid gap-1 text-center",
          hideSeconds && "hidden"
      )}>
        <Label htmlFor="seconds" className="text-xs">
          Seconds
        </Label>
        <TimePickerInput
          picker="seconds"
          date={date}
          setDate={setDate}
          ref={secondRef}
          onLeftFocus={() => minuteRef.current?.focus()}
        />
      </div>
      {/*<div className="flex h-10 items-center">
        <Clock className="ml-2 h-4 w-4" />
      </div>*/}
    </div>
  );
}

export function TimePicker12Demo({ date, setDate }: TimePickerDemoProps) {
  const [period, setPeriod] = React.useState<Period>("PM");

  const minuteRef = React.useRef<HTMLInputElement>(null);
  const hourRef = React.useRef<HTMLInputElement>(null);
  const secondRef = React.useRef<HTMLInputElement>(null);
  const periodRef = React.useRef<HTMLButtonElement>(null);

  return (
    <div className="flex items-end gap-2">
      <div className="grid gap-1 text-center">
        <Label htmlFor="hours" className="text-xs">
          Hours
        </Label>
        <TimePickerInput
          picker="12hours"
          period={period}
          date={date}
          setDate={setDate}
          ref={hourRef}
          onRightFocus={() => minuteRef.current?.focus()}
        />
      </div>
      <div className="grid gap-1 text-center">
        <Label htmlFor="minutes" className="text-xs">
          Minutes
        </Label>
        <TimePickerInput
          picker="minutes"
          id="minutes12"
          date={date}
          setDate={setDate}
          ref={minuteRef}
          onLeftFocus={() => hourRef.current?.focus()}
          onRightFocus={() => secondRef.current?.focus()}
        />
      </div>
      <div className="grid gap-1 text-center">
        <Label htmlFor="seconds" className="text-xs">
          Seconds
        </Label>
        <TimePickerInput
          picker="seconds"
          id="seconds12"
          date={date}
          setDate={setDate}
          ref={secondRef}
          onLeftFocus={() => minuteRef.current?.focus()}
          onRightFocus={() => periodRef.current?.focus()}
        />
      </div>
      <div className="grid gap-1 text-center">
        <Label htmlFor="period" className="text-xs">
          Period
        </Label>
        <TimePeriodSelect
          period={period}
          setPeriod={setPeriod}
          date={date}
          setDate={setDate}
          ref={periodRef}
          onLeftFocus={() => secondRef.current?.focus()}
        />
      </div>
    </div>
  );
}

export type TaimTimePickerValue = {
    hour: number,
    minute: number,
    seconds?: number,
}

interface TimeValueProps {
  value?: TimeValue | string | null;
  onChange: (value: TimeValue | undefined | null) => void;
  label?: string
  isDisabled?: boolean
}

const TimePicker = React.forwardRef<
  HTMLDivElement,
  TimeValueProps
>((props, forwardedRef) => {
    const [date, setDate] = useState<Date | undefined>()

    const isSameTime = (t1: {hour: number, minute: number,},t2: {hour: number, minute: number,}): boolean => {
        return (t1.hour === t2.hour && t1.minute === t2.minute)
    }

    const handleResetProps = () => {
        props.onChange(undefined)
        setDate(undefined)
    }

    useEffect(() => {
        let propsValue: { hour: number; minute: number; } | undefined = undefined;

        switch (typeof props.value) {
            case "string": {
                let h = moment(props.value, 'HH:mm:ss').get("hours")
                let m = moment(props.value, 'HH:mm:ss').get("minutes")
                propsValue = {
                    hour: h,
                    minute: m
                }
                break;
            }
            case "object": {
                propsValue = {
                    hour: props.value?.hour ?? 0,
                    minute: props.value?.minute ?? 0,
                }
                break;
            }
            default:
                break;
        }

        let dateValue = {
            hour: date?.getHours() ?? 0,
            minute: date?.getMinutes() ?? 0,
        }

        if (propsValue) {
            if (isSameTime(propsValue, dateValue)) return;

            if (propsValue) {
                let {hour, minute} = propsValue

                setDate(prevState => {
                    let newState = new Date()
                    if (newState) {
                        newState.setHours(hour, minute, 0)
                    }
                    return newState
                })
            }
        }
    }, [props.value])

    useEffect(() => {
        let onChange = props.onChange
        if (onChange) {
            if (date) {
                let hour = date.getHours()
                let minute = date.getMinutes()

                onChange({hour: hour, minute: minute} as TimeValue)
            } else {
                onChange(undefined)
            }
        }
    }, [date]);

    return <TimePickerDemo
        date={date}
        setDate={setDate}
        disabled={props.isDisabled}
        handleResetProps={handleResetProps}
        hideSeconds
    />;
});


TimePicker.displayName = "TimePicker";
TimePicker.defaultProps = {
    value: undefined
}

export { TimePicker };
