import React from "react";
import DetailViewEmployeeAvatar from "Core/components/detail-view-employee-avatar";
import {Badge} from "components/badge";
import DetailViewCard from "Core/components/detail-view-card";
import statusVariants from "Core/functions/status-variants";
import {nullOrUndefined} from "Core/constants/variables";
import {AttendanceFormatted, AttendanceService} from "AttendanceManagement/Controllers/attendances-controller";
import {Action} from "Core/Table/data-table-actions";
import {Service} from "ProjectManagement/Controller/services-controller";
import {FormattedMessage} from "react-intl";
import {ArrowCircleUpRight} from "@phosphor-icons/react";
import {BreakActivity, CheckInActivity, CheckOutActivity, ServiceActivity} from "./activities";
import {cn} from "lib/utils";
import moment from "moment";

type AttendanceDetailViewProps = {
    employeeAttendance: AttendanceFormatted | null
    services: Service[]
    loading?: boolean
    rePopulateRecord: (userUUID: string) => void
}

export interface AttendanceServiceFormatted extends AttendanceService {
    startDatetimeFormatted: string
    endDatetimeFormatted: string
    checkout?: string | null
    actions: Action[]
}

export const AttendanceDetailView: React.FC<AttendanceDetailViewProps> = (
    {
        employeeAttendance,
        services,
        // loading,
        rePopulateRecord
    }
) => {

    return <>
        {employeeAttendance ? (
            <>
                <DetailViewEmployeeAvatar
                    username={employeeAttendance?.userFullName ?? nullOrUndefined}
                    status={<Badge variant={statusVariants(employeeAttendance?.status)}>{employeeAttendance?.status}</Badge>}
                    isActive={employeeAttendance?.isActive}
                />
                <DetailViewCard
                    sizeFit={true}
                    rows={[
                        {jsxElement: (
                            <div className="flex flex-col">
                                <div className="flex flex-row justify-between">
                                    <span className="font-semibold">
                                        {employeeAttendance?.checkInDate ? moment(employeeAttendance?.checkInDate).format("ddd DD MMM YYYY") : nullOrUndefined}
                                    </span>
                                    <div className="flex flex-row gap-2 items-center justify-center">
                                        <span className={cn(
                                            "px-2.5 py-0.5 rounded-xl",
                                            employeeAttendance?.overTime?.charAt(0) === "-" ? "bg-red-100 text-red-600" : "bg-green-100 text-green-600"
                                        )}>
                                            {employeeAttendance?.overTime ?? nullOrUndefined}
                                        </span>
                                        <span>{employeeAttendance?.totalDuration ?? nullOrUndefined}</span>
                                    </div>
                                </div>
                                {employeeAttendance?.activities.length ? (
                                    employeeAttendance.activities.map((activity, index) => {
                                        switch (activity.type) {
                                            case "checkIn":
                                                return (
                                                    <>
                                                        {index >= 1 && (
                                                            <div className="w-full flex justify-center">
                                                                <div className="w-1/3 border-t-2"></div>
                                                            </div>
                                                        )}
                                                        <CheckInActivity
                                                            user={{
                                                                userUUID: employeeAttendance.userUUID,
                                                                userName: employeeAttendance.userFullName
                                                            }}
                                                            services={services}
                                                            rePopulateRecord={rePopulateRecord}
                                                            activities={employeeAttendance.activities}
                                                            {...activity}
                                                        />
                                                    </>
                                                )
                                            case "checkOut":
                                                return (
                                                    <CheckOutActivity {...activity}/>
                                                )
                                            case "breakTime":
                                                return (
                                                    <BreakActivity {...activity}/>
                                                )
                                            case "service":
                                                return (
                                                    <ServiceActivity {...activity}/>
                                                )
                                            default:
                                                break;
                                        }
                                    })
                                ) : null}
                                {employeeAttendance?.status === "Missing" && (
                                    <div className="flex flex-row justify-between text-red-600">
                                        <div className="flex flex-row items-center gap-2">
                                            <ArrowCircleUpRight size={28} color={"red"}/>
                                            <div className="tewt-sm flex flex-col">
                                                <span className="text-gray-500">Check Out</span>
                                                <span className="font-semibold">Missing</span>
                                            </div>
                                        </div>
                                        <span>Missing</span>
                                    </div>
                                )}
                            </div>
                        )}
                    ]}
                />
            </>
        ) : (
            <FormattedMessage
                id={"pick_a_record"}
                defaultMessage={"Select a record to view details."}
            />
        )}
    </>
}
