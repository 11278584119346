import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "../../components/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/form"
import { Input } from "../../components/input"
import {DialogClose, DialogFooter} from "../../components/dialog";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Label} from "../../components/label";
import {FormattedMessage, useIntl} from "react-intl";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {employmentTypeUrl} from "../Controllers/employment-type-controller";
import {toast} from "../../components/use-toast";
import {ServerErrorsType, useServerErrors} from "../../Core/functions/use-server-errors";
import ErrorMessageList from "../../Core/components/ErrorMessageList";
import {companyGuidelinesUrl, TVacationRule} from "../Controllers/company-guidelines-controller";

const createFormSchema = z.object({
    startingYear: z.any(),
    expiryDay: z.any(),
    expiryMonth: z.any()
})

type CreateVacationRuleProps = {
    reFetch: Dispatch<SetStateAction<boolean>>
}

export const CreateVacationRule: React.FC<CreateVacationRuleProps> = ({reFetch}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof createFormSchema>>({
        resolver: zodResolver(createFormSchema),
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>(null);

    async function onSubmit(values: z.infer<typeof createFormSchema>) {
        axiosInstance.post(companyGuidelinesUrl, values)
            .then((res) => {
                setApiErrors({});
                closeModal.current?.click();
                reFetch(true);
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => setApiErrors(err.response.data))
    }

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <FormField
                    control={form.control}
                    name="startingYear"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"companyGuidelines.startingYear"} defaultMessage={"Starting Year"}/>
                            </FormLabel>
                            <FormControl>
                                <Input
                                    type="number"
                                    placeholder={intl.formatMessage({id: "companyGuidelines.startingYearPlaceholder", defaultMessage: "Enter Starting Year"})}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <div className="flex flex-row space-x-4">
                    <FormField
                        control={form.control}
                        name="expiryDay"
                        render={({ field }) => (
                            <FormItem className="grow">
                                <FormLabel>
                                    <FormattedMessage id={"companyGuidelines.expiryDay"} defaultMessage={"Expiry Day"}/>
                                </FormLabel>
                                <FormControl>
                                    <Input
                                        type="number"
                                        placeholder={intl.formatMessage({id: "companyGuidelines.expiryDayPlaceholder", defaultMessage: "Enter Expiry Day"})}
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="expiryMonth"
                        render={({ field }) => (
                            <FormItem className="grow">
                                <FormLabel>
                                    <FormattedMessage id={"companyGuidelines.expiryMonth"} defaultMessage={"Expiry Month"}/>
                                </FormLabel>
                                <FormControl>
                                    <Input
                                        type="number"
                                        placeholder={intl.formatMessage({id: "companyGuidelines.expiryMonthPlaceholder", defaultMessage: "Enter Expiry Month"})}
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />
                </div>

                <ErrorMessageList errors={errors.nonFieldErrors}/>
                <ErrorMessageList errors={errors.detailErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}


const editFormSchema = z.object({
    startingYear: z.any(),
    expiryDay: z.any(),
    expiryMonth: z.any()
})

type EditVacationRuleProps = {
    vacationRule: TVacationRule
    reFetch: Dispatch<SetStateAction<boolean>>
}

export const EditVacationRule: React.FC<EditVacationRuleProps> = ({vacationRule,reFetch}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof editFormSchema>>({
        resolver: zodResolver(editFormSchema),
        defaultValues: {
            ...(vacationRule.startingYear && {startingYear: vacationRule.startingYear}),
            ...(vacationRule.expiryMonth && {expiryMonth: vacationRule.expiryMonth}),
            ...(vacationRule.expiryDay && {expiryDay: vacationRule.expiryDay}),
        }
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>(null);

    async function onSubmit(values: z.infer<typeof editFormSchema>) {
        axiosInstance.put(companyGuidelinesUrl + `${vacationRule.pk}/`, values)
            .then((res) => {
                setApiErrors({});
                closeModal.current?.click();
                reFetch(true);
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => setApiErrors(err.response.data))
    }

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <FormField
                    control={form.control}
                    name="startingYear"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"companyGuidelines.startingYear"} defaultMessage={"Starting Year"}/>
                            </FormLabel>
                            <FormControl>
                                <Input
                                    type="number"
                                    placeholder={intl.formatMessage({id: "companyGuidelines.startingYearPlaceholder", defaultMessage: "Enter Starting Year"})}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <div className="flex flex-row space-x-4">
                    <FormField
                        control={form.control}
                        name="expiryDay"
                        render={({ field }) => (
                            <FormItem className="grow">
                                <FormLabel>
                                    <FormattedMessage id={"companyGuidelines.expiryDay"} defaultMessage={"Expiry Day"}/>
                                </FormLabel>
                                <FormControl>
                                    <Input
                                        type="number"
                                        placeholder={intl.formatMessage({id: "companyGuidelines.expiryDayPlaceholder", defaultMessage: "Enter Expiry Day"})}
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="expiryMonth"
                        render={({ field }) => (
                            <FormItem className="grow">
                                <FormLabel>
                                    <FormattedMessage id={"companyGuidelines.expiryMonth"} defaultMessage={"Expiry Month"}/>
                                </FormLabel>
                                <FormControl>
                                    <Input
                                        type="number"
                                        placeholder={intl.formatMessage({id: "companyGuidelines.expiryMonthPlaceholder", defaultMessage: "Enter Expiry Month"})}
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />
                </div>

                <ErrorMessageList errors={errors.nonFieldErrors}/>
                <ErrorMessageList errors={errors.detailErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}
