export const firstLetterFromEachWord = (value: string, length: number = 2) => {
    if (value && value.trim() === '' || !value) {
        return 'TT';
    }

    const words = value.split(/\s+/).filter(word => word.trim() !== '');

    if (words.length === 0) {
        return 'TT';
    }

    if (words.length === 1) {
        return value.charAt(0).toUpperCase();
    }

    const firstLetters = words.map(word => word.charAt(0).toUpperCase());
    return firstLetters.slice(0, length).join('');
}
