import {ReactNode, useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {Action} from "../../Core/Table/data-table-actions";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import {DataTableRequest} from "../../Core/Table/interfaces";

export const attendancesUrl: string = 'attendance-management/attendances-tab/employees/';

export type ServiceActivityType = {
    type: "service"
    pk: string
    startDate: string
    startTime: string
    endTime: string
    datetime: string
    startDatetime: string
    endDatetime: string
    duration: string
    attendanceID: string
    name: string
}

export type BreaktimeActivityType = {
    type: "breakTime"
    pk: string
    startDate: string
    time: string
    startTime: string
    endDate: string
    endTime: string
    duration: string
    datetime: string
    startDatetime: string
    endDatetime: string
}

export type CheckInActivityType = {
    type: "checkIn"
    pk: string
    date: string
    time: string
    location: string
    datetime: string
}

export type CheckOutActivityType = {
    type: "checkOut"
    pk: string
    date: string
    time: string
    location: string
    datetime: string
}

export type Activity = BreaktimeActivityType | CheckInActivityType | CheckOutActivityType | ServiceActivityType

export type EmployeeAttendance = {
    pk: string
    userUUID: string
    userFullName: string
    totalDuration: string
    checkInTime: string
    checkOutTime: string
    checkInDate: string
    status: "Check In" | "Check Out" | "Missing"
    activities: Activity[]
    isActive?: boolean
    overTime?: string
}

export type AttendancesPayload = {
    start: number
    length: number
    search?: string
    ordering?: string
    "filter.checkInDate"?: string
    "filter.status"?: string
}

export type AttendancesRequest = {
    recordsTotal: number
    recordsFiltered: number
    next: string
    previous: string
    data: EmployeeAttendance[]
}

export const useFetchAttendancesDataTable = (payload: AttendancesPayload) => {
    const [shouldReFetch, reFetchAttendances] = useState(false)
    const [request, setData] = useState<AttendancesRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchAttendancesData = (signal: AbortSignal) => {
        setLoading(true);
        axiosInstance.get(attendancesUrl,{
            params: payload,
            signal
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                reFetchAttendances(false);
            })
    }

    useEffect( () => {
        const abortController = new AbortController()
        const signal = abortController.signal

        if (payload.hasOwnProperty("filter.checkInDate")) fetchAttendancesData(signal);

        return () => {
            abortController.abort()
        }
    }, [payload, shouldReFetch, permissionContext.isAdmin])

    return { request, loading, error, reFetchAttendances };
}


export type AttendanceBreaktime = {
    pk: string
    startDate: string
    startTime: string
    endTime: string
    attendanceID: string
}

type AttendanceBreaktimesProps = {
    userUUID: string
    attendanceID: string
}

export type AttendanceBreaktimesRequest = AttendanceBreaktime[]

export const useFetchAttendanceBreaktimesDataTable = (
    {userUUID, attendanceID}: AttendanceBreaktimesProps
) => {
    const [shouldReFetch, shouldReloadAttendanceBreaktimes] = useState(false)
    const [attendanceBreaktimesRequest, setData] = useState<AttendanceBreaktimesRequest>([]);
    const [attendanceBreaktimesLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchAttendancesData = (signal: AbortSignal) => {
        setLoading(true);
        axiosInstance.get(attendancesUrl + `${userUUID}/attendances/${attendanceID}/break-times/`, {signal})
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                shouldReloadAttendanceBreaktimes(false);
            })
    }

    useEffect( () => {
        const abortController = new AbortController()
        const signal = abortController.signal

        fetchAttendancesData(signal);

        return () => {
            abortController.abort()
        }
    }, [userUUID, attendanceID, shouldReFetch, permissionContext.isAdmin])

    return { attendanceBreaktimesRequest, attendanceBreaktimesLoading, error, shouldReloadAttendanceBreaktimes };
}

export interface AttendanceService {
    pk: string,
    startDatetime: string,
    endDatetime: string | null,
    attendanceID: string,
    serviceID: string,
    serviceName: string
    projectID: string
    projectName: string
}

export interface AttendanceServicesRequest extends DataTableRequest {
    data: AttendanceService[]
}

type AttendanceServicesPayload = {
    userUUID?: string
    attendanceID?: string
}

const attendanceServicesRequestInitialState: AttendanceServicesRequest = {
    next: "",
    previous: "",
    recordsTotal: 0,
    recordsFiltered: 0,
    data: []
}

export const useFetchAttendanceServices = ({userUUID, attendanceID}: AttendanceServicesPayload) => {
    const [
        attendanceServicesRequest,
        setData
    ] = useState<AttendanceServicesRequest>(attendanceServicesRequestInitialState);
    const [attendanceServicesLoading, setLoading] = useState(false);
    const [reloadAttendanceServices, shouldReloadAttendanceServices] = useState(false);
    const [attendanceServicesErrors, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchAttendanceServices = (signal: AbortSignal) => {
        if (!userUUID && !attendanceID) return;

        setLoading(true);
        axiosInstance.get(attendancesUrl + `${userUUID}/attendances/${attendanceID}/services/`,{
            params: {
                length: 10,
                start: 0,
            },
            signal
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                shouldReloadAttendanceServices(false);
            })
    }

    useEffect( () => {
        const abortController = new AbortController()
        const signal = abortController.signal

        fetchAttendanceServices(signal);

        return () => {
            abortController.abort()
        }
    }, [userUUID, attendanceID, reloadAttendanceServices, permissionContext.isAdmin])

    return { attendanceServicesRequest, attendanceServicesLoading, attendanceServicesErrors, shouldReloadAttendanceServices };
}

export interface AttendanceFormatted extends EmployeeAttendance {
    rowID: string
    actions?: Action[]
    statusBadge: ReactNode
}