import DataTableOld, {ColumnOld} from "../../Core/Table/data-table-old";
import React, {SetStateAction, useEffect, useState} from "react";
import DataTableTools from "../../Core/Table/data-table-tools";
import DataTablePagination from "../../Core/Table/data-table-pagination";
import {Action} from "../../Core/Table/data-table-actions";
import {employmentTypeUrl} from "../Controllers/employment-type-controller";
import {useIntl} from "react-intl";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {toast} from "../../components/use-toast";
import {DataTablePayload} from "../../Core/Table/interfaces";
import {
    CompanyGuidelinesRequest,
    companyGuidelinesUrl,
    TVacationRule
} from "../Controllers/company-guidelines-controller";
import {EditVacationRule} from "../Forms/company-guideline-forms";


type FormattedVacationRules = {
    actions: Action[]
} & TVacationRule

type CompanyGuidelinesDataTableProps = {
    request: CompanyGuidelinesRequest
    payload: DataTablePayload
    setPayload: React.Dispatch<SetStateAction<DataTablePayload>>
    loading: boolean
    reFetch: React.Dispatch<React.SetStateAction<boolean>>
}


const CompanyGuidelinesDataTable = (
    {
        request,
        payload,
        setPayload,
        loading,
        reFetch
    }: CompanyGuidelinesDataTableProps) => {
    const intl = useIntl()
    const axiosInstance = useAxiosInstance()
    const [formattedData, setFormattedData] = useState<FormattedVacationRules[]>([])
    const columns: ColumnOld[] = [
        {
            backendKey: "startingYear",
            frontendKey: intl.formatMessage({
                id: "companyGuidelines.startingYear",
                defaultMessage: "Starting Year"
            }),
            sortable: true
        },
        {
            backendKey: "expiryDay",
            frontendKey: intl.formatMessage({
                id: "companyGuidelines.expiryDay",
                defaultMessage: "Expiry Day"
            }),
        },
        {
            backendKey: "expiryMonth",
            frontendKey: intl.formatMessage({
                id: "companyGuidelines.expiryMonth",
                defaultMessage: "Expiry Month"
            }),
            sortable: true
        },
        {
            backendKey: "actions",
            frontendKey: "actions"
        },
    ]

    const deleteVacationRule = (pk: string) => {
        axiosInstance.delete(companyGuidelinesUrl + `${pk}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                reFetch(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    const rePopulateRecord = (pk: string) => {
        axiosInstance.get(employmentTypeUrl + `${pk}/`)
            .then((res) => {
                setFormattedData(prevState => prevState.map(type => {
                    if (type.pk === pk) {
                        return {
                            ...type,
                            ...res.data
                        }
                    }
                    return type;
                }))
            })
    }


    useEffect(() => {

        let formatData: FormattedVacationRules[] = request?.data.map((vacationRule) => {
            return {
                ...vacationRule,
                actions: [
                    {
                        type: "edit",
                        content: <EditVacationRule vacationRule={vacationRule} reFetch={reFetch}/>
                    },
                    {type: "delete", handleAction: () => deleteVacationRule(vacationRule.pk)},
                ] as Action[]
            };
        })

        setFormattedData(formatData);
    }, [request.data]);

    return(
        <div className="space-y-4 h-full flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools payload={payload} setPayload={setPayload}/>
                <DataTableOld
                    data={formattedData}
                    loading={loading}
                    detailData={formattedData}
                    payload={payload}
                    setPayload={setPayload}
                    columns={columns}
                />
            </div>
            <DataTablePagination request={request} payload={payload} setPayload={setPayload}/>
        </div>
    )
}

export default CompanyGuidelinesDataTable;