import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {PermissionContext} from "../../../Core/utilities/PermissionProvider";

const employeesAbsencesUrl = "dashboard/employees/absences/";

export type Absence = {
    fullName: string,
    type: string,
    message: string | null,
}

export const useFetchEmployeesAbsences = (
    reloadDashboard: boolean
) => {
    const [loadingAbsences, setLoading] = useState<boolean>(false);
    const [shouldReFetch, reFetchAbsences] = useState(false)
    const [absences, setData] = useState<Absence[]>([]);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchData = () => {
        setLoading(true);
        axiosInstance.get(employeesAbsencesUrl)
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false)
                reFetchAbsences(false)
            })
    }

    useEffect( () => {
        fetchData();
    }, [shouldReFetch, permissionContext.isAdmin, reloadDashboard])

    return { absences, loadingAbsences, error, reFetchAbsences };
}