import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import DetailViewEmployeeAvatar from "../../Core/components/detail-view-employee-avatar";
import {Badge} from "../../components/badge";
import DetailViewCard from "../../Core/components/detail-view-card";
import statusVariants from "../../Core/functions/status-variants";
import {
    useVacationRecords,
    vacation,
    VacationRecord,
    VacationRecordsPayload,
    vacationsUrl
} from "../Controllers/vactions-controller";
import {nullOrUndefined} from "../../Core/constants/variables";
import {FormattedMessage, useIntl} from "react-intl";
import DataTableOld, {ColumnOld} from "../../Core/Table/data-table-old";
import {Action} from "../../Core/Table/data-table-actions";
import moment from "moment";
import DataTablePagination from "../../Core/Table/data-table-pagination";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {toast} from "../../components/use-toast";
import {EditVacationRecord} from "../Forms/absences";
import {portionTypes, vacationTypes} from "../Forms/absences/constants";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";

type VacationsDetailViewProps = {
    vacation?: vacation
    loading?: boolean
    shouldReloadVacations: Dispatch<SetStateAction<boolean>>
    refetchTrigger: boolean
}

type FomrattedVacationRecord = {
    day: string
    substituteFullName?: string
    actions: Action[]
    portionDisplay?: string
    typeDisplay?: string
} & VacationRecord


const AbsencesDetailView: React.FC<VacationsDetailViewProps> = (
    {vacation, loading, shouldReloadVacations, refetchTrigger}
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);
    const [payload, setPayload] = useState<VacationRecordsPayload>({
        length: 5,
        start: 0
    })
    const {
        vacationRecordsRequest,
        loadingVacationRecords,
        reFetchVacationRecords
    } = useVacationRecords(payload, refetchTrigger, vacation?.pk);
    const [formattedRecords, setFormatted] = useState<FomrattedVacationRecord[]>([])
    const vactionRecordColumns: ColumnOld[] = [
        {
            backendKey: "day",
            frontendKey: intl.formatMessage({id: "attendance.vacations.detail.dayName", defaultMessage: "Day Name"})
        },
        {
            backendKey: "date",
            frontendKey: intl.formatMessage({id: "attendance.vacations.detail.date", defaultMessage: "Date"})
        },
        {
            backendKey: "typeDisplay",
            frontendKey: intl.formatMessage({id: "attendance.vacations.detail.type", defaultMessage: "Type"})
        },
        {
            backendKey: "portionDisplay",
            frontendKey: intl.formatMessage({id: "attendance.vacations.detail.portion", defaultMessage: "portion"})
        },
        {
            backendKey: "substituteFullName",
            frontendKey: intl.formatMessage({id: "attendance.vacations.detail.substitute", defaultMessage: "Substitute"})
        },
        {
            backendKey: "actions",
            frontendKey: "actions",
            isPublic: true
        }
    ]

    const deleteVacationRecord = (recordID: string) => {
        if (!vacation) return
        axiosInstance.delete(vacationsUrl + `${vacation?.pk}/records/${recordID}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                reFetchVacationRecords(true);
                shouldReloadVacations(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }


    useEffect(() => {
        const formatted: FomrattedVacationRecord[] = vacationRecordsRequest.data.map((record) => ({
            ...record,
            day: moment(record.date).format("dddd"),
            date: moment(record.date).format("DD MMM YYYY"),
            substituteFullName: record.substitute?.fullName,
            ...(record.portion && {
                portionDisplay: intl.formatMessage({
                    id: `attendance.vacations.${portionTypes.find(e => e.value === record.portion)?.value}`,
                    defaultMessage: portionTypes.find(e => e.value === record.portion)?.key
                })
            }),
            ...(record.type && {
                typeDisplay: intl.formatMessage({
                    id: `attendance.vacations.${vacationTypes.find(e => e.value === record.type)?.value}`,
                    defaultMessage: vacationTypes.find(e => e.value === record.type)?.key
                })
            }),
            actions: [
                {type: "edit", content: (
                    <EditVacationRecord
                        vacationID={vacation?.pk ?? ""}
                        recordID={record.pk}
                        record={record}
                        reFetchVacationRecords={reFetchVacationRecords}
                        shouldReloadVacations={shouldReloadVacations}
                    />
                )},
                {type: "delete", handleAction: () => deleteVacationRecord(record.pk)}
            ]
        }))

        setFormatted(formatted)
    }, [vacationRecordsRequest.data]);

    return(<>
        {vacation ? (
            <>
                <DetailViewEmployeeAvatar
                    username={vacation?.userFullName ?? nullOrUndefined}
                    status={<Badge variant={statusVariants(vacation?.status)}>{vacation?.status}</Badge>}
                    isActive={vacation.user?.isActive}
                />
                <DetailViewCard
                    header={intl.formatMessage({id: "attendance.vacations.detail.currentTime", defaultMessage: "Current Time"})}
                    rows={[
                        {
                            name: intl.formatMessage({id: "attendance.vacations.table.startDate", defaultMessage: "Start Date"}),
                            value: vacation?.dateFrom ? moment(vacation.dateFrom).format("ddd DD MMM YYYY") : nullOrUndefined
                        },
                        {
                            name: intl.formatMessage({id: "attendance.vacations.table.endDate", defaultMessage: "End Date"}),
                            value: vacation?.dateTo ? moment(vacation.dateTo).format("ddd DD MMM YYYY") : nullOrUndefined
                        },
                        {
                            name: intl.formatMessage({id: "attendance.vacations.table.workingDays", defaultMessage: "Working Days"}),
                            value: vacation?.workingDays ?? nullOrUndefined
                        },
                        ((permissionContext.isAdmin && vacation?.records.find(elem => elem.type === "sickLeave")?.pk) ?{
                            name: intl.formatMessage({id: "attendance.vacations.detail.sickNoteSubmitted", defaultMessage: "Sick Note Submitted"}),
                            value: vacation?.sickNoteSubmitted
                                ? intl.formatMessage({id: "yes", defaultMessage: "Yes"}).split('').map((c, i) => i === 0 ? c.toUpperCase() : c).join('')
                                : intl.formatMessage({id: "no", defaultMessage: "No"}).split('').map((c, i) => i === 0 ? c.toUpperCase() : c).join('')
                        } :  {}),
                    ]}
                />
                <DetailViewCard
                    header={intl.formatMessage({id: "attendance.vacations.detail.vacationDays", defaultMessage: "Absence Days"})}
                    sizeFit={true}
                    rows={[
                        {jsxElement: vacationRecordsRequest.data ? (
                            <div>
                                <DataTableOld
                                    columns={vactionRecordColumns}
                                    data={formattedRecords}
                                    loading={loadingVacationRecords}
                                />
                                <DataTablePagination
                                    request={vacationRecordsRequest}
                                    payload={payload}
                                    setPayload={setPayload}
                                />
                            </div>
                        ) : (
                            <FormattedMessage id={"no_results"} defaultMessage={"No results found."}/>
                        )},
                    ]}
                />
            </>
        ) : (
            <FormattedMessage
                id={"pick_a_record"}
                defaultMessage={"Select a record to view details."}
            />
        )}
    </>)
}


export default AbsencesDetailView;