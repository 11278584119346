import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTrigger,
} from "../../components/dialog";
import {CardTitle} from "../../components/card";
import React, {ReactEventHandler, ReactNode, useEffect} from "react";
import {cn} from "../../lib/utils";

type Action = {
    name: string
    handleEvent: ReactEventHandler
}

export type ModalProps = {
    button?: ReactNode
    name?: string
    header?: string
    content: ReactNode[] | ReactNode
    action?: Action
    contentWidth?: string
}

const Modal: React.FC<ModalProps> = (
    {
        header,
        content,
        name,
        button,
        contentWidth
    }
) => {

    return(
        <Dialog>
            <DialogTrigger className={!button ? "w-full flex flex-row justify-start" : ""}>
                {button ? button : name}
            </DialogTrigger>
            <DialogContent className="min-w-fit w-[550px]" style={{ width: contentWidth }}>
                <DialogHeader>
                    <CardTitle className="pb-6">
                        {header ? header : "Modal"}
                    </CardTitle>
                </DialogHeader>
                <div className="flex flex-col gap-4 max-h-[80vh] overflow-y-auto no-scrollbar p-2">
                    {Array.isArray(content) ? (
                        content.map((elem, index) => <React.Fragment key={index}>{elem}</React.Fragment>)
                    ) : content}
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default Modal;