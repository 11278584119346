import React from "react";
import ManagerEntry from "./Pages/manager";
import {useIntl} from "react-intl";
import {PageShape, PageShapeProps} from "../Core/layout/page-shape";

const SHIFT_MANAGER_TAB = "shift-manager";

const ShiftManagement = () => {
    const intl = useIntl();

    const shiftManagerName = intl.formatMessage({id: "shift_management.shift_manager", defaultMessage: "Shift Manager"});
    const sections = [
        {label: shiftManagerName, value: SHIFT_MANAGER_TAB},
    ]

    const PageContent: PageShapeProps = {
        header: intl.formatMessage({id: "shift_management.header", defaultMessage: "Shift Management"}),
        sections,
        pageEntries: [
            props => <ManagerEntry name={SHIFT_MANAGER_TAB} pagesState={props.pagesState}/>,
        ],
        defaultSection: sections[0]
    }

    return <PageShape {...PageContent}/>
}

export default ShiftManagement;