import {useForm} from "react-hook-form";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "../../components/form";
import * as z from "zod";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {ServerErrorsType, useServerErrors} from "../../Core/functions/use-server-errors";
import {toast} from "../../components/use-toast";
import {FormattedMessage, useIntl} from "react-intl";
import ErrorMessageList from "../../Core/components/ErrorMessageList";
import {DialogClose, DialogFooter} from "../../components/dialog";
import {Button} from "../../components/button";
import {Project} from "../Controllers/User/project-controller";
import SearchInput from "../../Core/components/search-input";
import {zodResolver} from "@hookform/resolvers/zod";

const formSchema = z.object({
    serviceID: z.any()
})

type SwitchProjectProps = {
    userUUID: string
    project: Project
    setReloadProjects: Dispatch<SetStateAction<boolean>>
}

const bc = new BroadcastChannel("stateChange");

export const SwitchProject: React.FC<SwitchProjectProps> = ({
    userUUID,
    project,
    setReloadProjects
}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            serviceID: ""
        }
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLButtonElement>(null);
    const services = project.services.map((service) => ({
        key: service.name, value: service.pk
    })) ?? []

    const onSubmit = (values: z.infer<typeof formSchema>) => {
        let data = {
            defaultServiceID: values.serviceID,
            defaultProjectID: project.pk,
        }
        axiosInstance.put(`project-management/employees/${userUUID}/`, data)
            .then(() => {
                setApiErrors({});
                closeModal.current?.click();
                setReloadProjects(true);
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response?.data);
            })
            .finally(() => {
                bc.postMessage("State changed!");
            })
    }

    useEffect(() => {
        bc.addEventListener("message", e => {
            setReloadProjects(true);
        })
    }, []);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">

                <FormField
                    name={"serviceID"}
                    control={form.control}
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"dashboard.service"} defaultMessage={"Service"}/>
                            </FormLabel>
                            <FormControl>
                                <SearchInput
                                    title={intl.formatMessage({id: "dashboard.pickService", defaultMessage: "Pick a service"})}
                                    values={services}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}

                />

                <ErrorMessageList errors={errors.nonFieldErrors}/>
                <ErrorMessageList errors={errors.detailErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal} asChild>
                        <Button type="button" variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}