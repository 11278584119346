import {useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";

export const locationsUrl: string = 'attendance-management/locations/';

export type location = {
    uuid: string
    name: string
    fullAddress: string
}

export type locationsPayload = {
    start: number
    length: number
    search?: string
    ordering?: string
}

export type locationsRequest = {
    recordsTotal: number
    recordsFiltered: number
    next: string
    previous: string
    data: location[]
}

export const useFetchLocationsDataTable = (payload: locationsPayload) => {
    const [shouldReFetch, reFetch] = useState(false)
    const [request, setData] = useState<locationsRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchLocationsData = (signal: AbortSignal) => {
        setLoading(true);
        axiosInstance.get(locationsUrl,{
            params: payload,
            signal
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                reFetch(false);
            })
    }

    useEffect( () => {
        const abortController = new AbortController()
        const signal = abortController.signal

        fetchLocationsData(signal);

        return () => {
            abortController.abort()
        }
    }, [payload, shouldReFetch, permissionContext.isAdmin])

    return { request, loading, error, reFetch };
}

