import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useFetchDashboardDailyTrack} from "../../Controllers/Admin/daily-track-controller";
import {UserProjects} from "./user-projects";
import {Button} from "../../../components/button";
import {FormattedMessage} from "react-intl";
import DailyTrack from "../../Components/daily-track";
import {UserAttendance} from "./user-attendance";
import moment from "moment";


type UserDashboardProps = {
    lastLoaded: moment.Moment
    reloadDashboard: boolean
    setReloadDashboard: Dispatch<SetStateAction<boolean>>
}

const UserDashboard: React.FC<UserDashboardProps> = ({reloadDashboard, setReloadDashboard, lastLoaded}) => {
    const [payload, setPayload] = useState<any>({});
    const {
        dailyTrack,
        loadingDailyTrack
    } = useFetchDashboardDailyTrack(payload, reloadDashboard);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between items-center">
                <span className="text-[#1b2436] text-[2rem] font-semibold leading-2">Dashboard</span>
                <div className="flex flex-col items-end space-y-1">
                    <Button variant={"success"} className={"w-fit"} onClick={() => setReloadDashboard(true)}>
                        <FormattedMessage id={"dashboard.reload"} defaultMessage={"Reload"}/>
                    </Button>
                    <span className="text-gray-500 text-xs">
                        <FormattedMessage id={"dashboard.reloadSentence"} defaultMessage={"Last updated on {time}"} values={{
                            time: lastLoaded.format('HH:mm')
                        }}/>
                    </span>
                </div>
            </div>
            <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-7">
                <div className="lg:col-span-2 h-fit xl:col-span-5 flex flex-col gap-4">
                    <UserProjects/>
                    <DailyTrack
                        payload={payload}
                        setPayload={setPayload}
                        request={dailyTrack}
                        loading={loadingDailyTrack}
                    />
                </div>
                <div className="lg:col-span-1 h-fit xl:col-span-2 flex flex-col gap-4">
                    <UserAttendance
                        setReloadDashboard={setReloadDashboard}
                        reloadDashboard={reloadDashboard}
                    />
                </div>
            </div>
        </div>
    )
}

export default UserDashboard