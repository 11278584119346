import {useEffect, useState} from "react";
import {openDB} from "idb";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import moment from "moment/moment";

export const BASE_FILE_FIELDS = [
    "Personalnummer",
    "Nachname",
    "Vorname",
    "Eintritt",
    "Übergangsbereich",
    "Mehrfachbeschäftigt",
    "Manuelle BMG",
    "Identifikationsnummer",
    "Krankenkasse",
    "SV-Nummer",
    "LGO aktiv",
    "LOHN DRUCKSERVICE",
    "Austritt",
    "Ersteintritt",
    "Fehler/Hinweis",
    "ELStAM angewendet",
    "Abrechnungskreis-Nr.",
    "Abrechnungskreis-Bez.",
    "Tarifgruppe"
];

export const ADDRESS_FILE_FIELDS = [
    "Nummer",
    "Name",
    "Name_1",
    "Vorname",
    "Straße",
    "Nummer_1",
    "PLZ",
    "Ort",
    "Telefon1",
    "Telefon2",
    "Fax",
    "E-Mail",
    "Abteilung",
    "Land"
];

export type TArbeitnehmerAdresse = {
    "Nummer": number
    "Name": string
    "Name_1": string
    "Vorname": string
    "Straße": string
    "Nummer_1": number
    "PLZ": number
    "Ort": string
    "Telefon1": string
    "Telefon2": string
    "Fax": string
    "E-Mail": string
}

export type TPersonalstammdaten = {
    "Personalnummer": number
    "Nachname": string
    "Vorname": string
    "Eintritt": string
    "Übergangsbereich": string
    "Mehrfachbeschäftigt": string
    "Manuelle BMG": string
    "Identifikationsnummer": number
    "Krankenkasse": string
    "SV-Nummer": number
    "LGO aktiv": string
    "LOHN DRUCKSERVICE": string
    // waste of time
    "Austritt": string
    "Ersteintritt": string
    "Fehler/Hinweis": string
    "ELStAM angewendet": string
    "Abrechnungskreis-Nr.": string
    "Abrechnungskreis-Bez.": string
    "Tarifgruppe": string
}

export type EmployeeImported = TPersonalstammdaten & TArbeitnehmerAdresse

export type EmployeeImportedRefined = {
    id?: string;
    employeeNumber?: number | null; // Personalnummer
    lastName?: string; // Nachname
    firstName?: string; // Vorname
    entryDate?: string | Date; // Eintritt
    transitionalArea?: string; // Übergangsbereich
    multipleEmployment?: string; // Mehrfachbeschäftigt
    manualBMG?: string; // Manuelle BMG
    identificationNumber?: number | null; // Identifikationsnummer
    healthInsurance?: string; // Krankenkasse
    socialSecurityNumber?: number | null; // SV-Nummer
    activeLGO?: string; // LGO aktiv
    payrollPrintService?: string; // LOHN DRUCKSERVICE
    phoneNumber1?: string; // Telefon1
    phoneNumber2?: string; // Telefon2
    fax?: string; // Fax
    email?: string; // E-Mail
    number?: number | null; // Nummer
    street?: string; // Straße
    number1?: number | null; // Nummer_1
    postalCode?: number | null; // PLZ
    stateCode?: string; // State_Code
    city?: string; // Ort
    name?: string; // Name
    additionalName?: string; // Name_1
    exitDate?: string | Date; // Austritt
    firstEntryDate?: string | Date; // Ersteintritt
    errorHint?: string; // Fehler/Hinweis
    elstamApplied?: string; // ELStAM angewendet
    payrollAreaNumber?: string; // Abrechnungskreis-Nr.
    payrollAreaDescription?: string; // Abrechnungskreis-Bez.
    tariffGroup?: string; // Tarifgruppe
    status?: 'Pending' | 'Saved'
    [key: string]: any;
};


export const refineAgendaDataToJson = (obj: EmployeeImportedRefined) => {
    Object.keys(obj).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
            let value = obj[key];
            if (typeof value === 'string') {
                obj[key] = value.trim();
            }
        }
    });

    return JSON.stringify({
        personalnummer: obj.employeeNumber || "",
        nachname: obj.lastName || "",
        vorname: obj.firstName || "",
        eintritt: obj.entryDate || "",
        "Übergangsbereich": obj.transitionalArea || "",
        "Mehrfachbeschäftigt": obj.multipleEmployment || "",
        "manuelle_BMG": obj.manualBMG || "",
        identifikations_nummer: obj.identificationNumber || "",
        Krankenkasse: obj.healthInsurance || "",
        "SV_nummer": obj.socialSecurityNumber || "",
        "LGO_aktiv": obj.activeLGO || "",
        "LOHN_DRUCKSERVICE": obj.payrollPrintService || "",
        telefon1: obj.phoneNumber1 || "",
        telefon2: obj.phoneNumber2 || "",
        Fax: obj.fax || "",
        "E-Mail": obj.email || "",
        nummer: obj.number || "",
        straße: obj.street || "",
        nummer_1: obj.number1 || "",
        PLZ: obj.postalCode || "",
        ort: obj.city || "",
        name: obj.name || "",
        name_1: obj.additionalName || "",
        austritt: obj.exitDate || "",
        ersteintritt: obj.firstEntryDate || "",
        "Fehler_Hinweis": obj.errorHint || "",
        "ELStAM_angewendet": obj.elstamApplied || "",
        "Abrechnungskreis_Nr": obj.payrollAreaNumber || "",
        "Abrechnungskreis_Bez": obj.payrollAreaDescription || "",
        Tarifgruppe: obj.tariffGroup || "",
    });
}

export const refineImportedEmployee = async (obj: EmployeeImported, existingPersonalNumbers?: Promise<string[]>): Promise<EmployeeImportedRefined> => {
    let employeeExists = await existingPersonalNumbers?.then((data) => data.includes(String(obj?.Personalnummer)));

    return {
        employeeNumber: obj.Personalnummer ?? obj.Nummer ?? null,
        lastName: obj.Nachname || "",
        firstName: obj.Vorname || "",
        entryDate: obj.Eintritt || "",
        transitionalArea: obj["Übergangsbereich"] || "",
        multipleEmployment: obj["Mehrfachbeschäftigt"] || "",
        manualBMG: obj["Manuelle BMG"] || "",
        identificationNumber: obj.Identifikationsnummer || null,
        healthInsurance: obj.Krankenkasse || "",
        socialSecurityNumber: obj["SV-Nummer"] || null,
        activeLGO: obj["LGO aktiv"] || "",
        payrollPrintService: obj["LOHN DRUCKSERVICE"] || "",
        phoneNumber1: obj.Telefon1 || "",
        phoneNumber2: obj.Telefon2 || "",
        fax: obj.Fax || "",
        email: obj["E-Mail"] || "",
        number: obj.Nummer || null,
        street: obj.Straße || "",
        number1: obj.Nummer_1 || null,
        postalCode: obj.PLZ || null,
        stateCode: "",
        city: obj.Ort || "",
        name: obj.Name || "",
        additionalName: obj.Name_1 || "",
        exitDate: obj.Austritt || "",
        firstEntryDate: obj.Ersteintritt || "",
        errorHint: obj["Fehler/Hinweis"] || "",
        elstamApplied: obj["ELStAM angewendet"] || "",
        payrollAreaNumber: obj["Abrechnungskreis-Nr."] || "",
        payrollAreaDescription: obj["Abrechnungskreis-Bez."] || "",
        tariffGroup: obj.Tarifgruppe || "",
        status: employeeExists ? "Saved" : "Pending",
    };

}


const dbName = 'importDB';
const storeName = 'employees';

const initDB = async () => {
    return await openDB(dbName, 1, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(storeName)) {
                db.createObjectStore(storeName, { keyPath: 'id', autoIncrement: true });
            }
        },
    });
};

const addEmployees = async (employees: EmployeeImportedRefined[]) => {
    const db = await initDB();
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);
    await Promise.all(employees.map(employee => store.add(employee)));
    await tx.done;
};

const updateEmployee = async (key: string, item: EmployeeImportedRefined) => {
    const db = await initDB();
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);
    const request = store.get(key)
    request.then((res) => {
        const updateRequest = store.put(item)

        updateRequest.then(() => console.log('employee updated'))
    })
    await tx.done;
};

const getEmployees = async () => {
    const db = await initDB();
    const tx = db.transaction(storeName, 'readonly');
    const store = tx.objectStore(storeName);
    const allEmployees: EmployeeImportedRefined[] = await store.getAll();
    await tx.done;
    return allEmployees;
};

const deleteEmployee = async (key: number) => {
    const db = await initDB();
    const tx = db.transaction(storeName, 'readwrite');
    tx.objectStore(storeName).delete(key);
    await tx.done;
}

const clearAll = async () => {
    const db = await initDB();
    const tx = db.transaction(storeName, 'readwrite');
    const objectStore = tx.objectStore(storeName);
    objectStore.clear();

    await tx.done;
}


export const useImportEmployees = () => {
    const [employees, setEmployees] = useState<EmployeeImportedRefined[]>([])
    const [triggerActions, setTriggerAction] = useState(false)
    const axiosInstance = useAxiosInstance();

    const checkPersonnalNumbers = async (data: any): Promise<string[]>  => {
        let existingPersonalNumbers: string[] = []

        await axiosInstance.post('employee-management/check-personal-numbers/', {
            personalNumbers: data
        })
            .then((res) => {
                existingPersonalNumbers = res.data.existingPersonalNumbers
            })
            .catch((err) => console.log(err))

        return existingPersonalNumbers
    }

    const storeData = async (employees: EmployeeImported[]) => {
        clearData();
        let personalNumbers = checkPersonnalNumbers(employees.map(e => e.Personalnummer));
        const refinedImportedData = await Promise.all(employees.map(async (employee) => {
            return await refineImportedEmployee(employee, personalNumbers);
        }));

        const formattedData = await Promise.all(refinedImportedData.map(async (employee) => {
            let updateEmployee = { ...employee };
            let postalCode = employee.postalCode;


            function getJsDateFromExcel(excelDate: number){
                const SECONDS_IN_DAY = 24 * 60 * 60;
                const MISSING_LEAP_YEAR_DAY = SECONDS_IN_DAY * 1000;
                const MAGIC_NUMBER_OF_DAYS = (25567 + 2);
                if (!Number(excelDate)) {
                    alert('wrong input format')
                }

                const delta = excelDate - MAGIC_NUMBER_OF_DAYS;
                const parsed = delta * MISSING_LEAP_YEAR_DAY;
                return new Date(parsed)
            }

            if (employee.entryDate) {
                let excelDate = Number(employee.entryDate)
                updateEmployee.entryDate = getJsDateFromExcel(excelDate);
            }

            try {
                const res = await axiosInstance.post('employee-management/find-users-federal-state/', { postalCode });
                updateEmployee.stateCode = res.data.stateCode;
                console.log(res.data.stateCode)
            } catch (err) {
                console.log(err);
            }

            return updateEmployee;
        }));


        return await addEmployees(formattedData)
            .then(() => {
                setTriggerAction(true)
            })
    }

    const update = async (key: string, item: EmployeeImportedRefined) => {
        return await updateEmployee(key, item)
            .then(() => {
                console.log('employee updated')
                setTriggerAction(true)
            })
            .catch((err) => console.log(err))
    }

    const clearData = () => {
        clearAll()
            .then(() => {
                console.log('all data is cleared')
                setTriggerAction(true)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        const fetchEmployees = async () => {
            const storedEmployees = await getEmployees();
            setEmployees(storedEmployees)
        };

        fetchEmployees().finally(() => setTriggerAction(false))
    }, [triggerActions]);

    return {employees, storeData, clearData, update}
}