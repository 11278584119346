import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "../../components/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/form"
import { Input } from "../../components/input"
import {DialogClose, DialogFooter} from "../../components/dialog";
import React, {useEffect, useRef, useState} from "react";
import {EmploymentType} from "../Controllers/employee-controller";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {toast} from "../../components/use-toast";
import {employmentTypeUrl} from "../Controllers/employment-type-controller";
import {Label} from "../../components/label";
import {FormattedMessage, useIntl} from "react-intl";
import {ServerErrorsType, useServerErrors} from "../../Core/functions/use-server-errors";
import ErrorMessageList from "../../Core/components/ErrorMessageList";

const formSchema = z.object({
    type: z.any(),
    monthlyWorkHours: z.any()
})

type FormEmploymentTypeEditProps = {
    employmentType: EmploymentType
    rePopulateRecord: (pk: string) => void
}

export const FormEmploymentTypeEdit: React.FC<FormEmploymentTypeEditProps> = ({employmentType, rePopulateRecord}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            type: employmentType?.type,
            monthlyWorkHours: employmentType?.monthlyWorkHours.toString()
        }
    });
    const [weeklyWorkingHours, setHours] = useState(0);
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();

    function onSubmit(values: z.infer<typeof formSchema>) {
        axiosInstance.put(employmentTypeUrl + `${employmentType.pk}/`, values)
            .then((res) => {
                setApiErrors({});
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.updatedSuccessfully", defaultMessage: "The update was completed successfully."})
                });
                rePopulateRecord(employmentType.pk);
            })
            .catch((err) => {
                setApiErrors(err.response.data);
            });
    }

    useEffect(() => {
        setHours(Number(form.getValues('monthlyWorkHours')) / 4)
    }, [form.watch()]);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <FormField
                    control={form.control}
                    name="type"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employmentType.table.type"} defaultMessage={"Type"}/>
                            </FormLabel>
                            <FormControl>
                                <Input placeholder={intl.formatMessage({id: "employmentType.table.type", defaultMessage: "Type"})} {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <div className="flex flex-col gap-2">
                    <Label className="text-slate-500">
                        <FormattedMessage id={"employmentType.detail.workingHoursPerWeek"} defaultMessage={"Working Hours Per Week"}/>
                    </Label>
                    <Input
                        value={weeklyWorkingHours}
                        type="number"
                        disabled
                    />
                </div>
                <FormField
                    control={form.control}
                    name="monthlyWorkHours"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employmentType.detail.workingHoursPerMonth"} defaultMessage={"Working Hours Per Month"}/>
                            </FormLabel>
                            <FormControl>
                                <Input placeholder="0" type="number" {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <ErrorMessageList errors={errors.nonFieldErrors}/>
                <ErrorMessageList errors={errors.detailErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline"><FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/></Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}
