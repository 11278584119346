import {DataTablePayload, DataTableRequest} from "../../../../Core/Table/interfaces";
import {useAxiosInstance} from "../../../../Core/utilities/AxiosInstance";
import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {PermissionContext} from "../../../../Core/utilities/PermissionProvider";
import {employeesUrl} from "../../../Controllers/employee-controller";

export type TManualVacationCarryOver = {
    pk: string
    year: number
    days: number
}

export interface TAnnualVacationRequest extends DataTableRequest {
    data: TManualVacationCarryOver[]
}

export const useManualVacationCarryOvers = (payload: DataTablePayload, userUUID: string, reloadVacationPlanTab: boolean) => {
    const axiosInstance = useAxiosInstance();
    const [manualVacationCarryOvers, setData] = useState<TAnnualVacationRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    })
    const [shouldRefetch, reFetchManualVacationCarryOvers] = useState(false)
    const [loadingManualVacationCarryOvers, setLoading] = useState(false);
    const permission = useContext(PermissionContext);

    const fetch = (signal: AbortSignal) => {
        setLoading(true)
        axiosInstance.get(employeesUrl + `${userUUID}/manual-vacation-carry-overs/`,{
            params: payload,
            signal
        })
            .then((res) => {
                setData(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
                reFetchManualVacationCarryOvers(false)
            })
    }

    useEffect(() => {
        const abortController = new AbortController()
        const signal = abortController.signal

        fetch(signal)

        return () => {
            abortController.abort()
        }
    }, [payload, userUUID, shouldRefetch, permission.isAdmin, reloadVacationPlanTab]);

    return {manualVacationCarryOvers, loadingManualVacationCarryOvers, reFetchManualVacationCarryOvers}
}