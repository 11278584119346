export enum SHIFT_TYPES {
    EARLY_SHIFT = "Early Shift",
    Late_SHIFT = "Late Shift",
    NIGHT_SHIFT = "Night Shift",
    SWING_SHIFT = "Swing Shift",
}

type ThemeColors = {
    [key: string]: {
        text: string;
        background: string;
    };
};

export const THEME_COLORS: ThemeColors = {
    primary: {
        text: "#1c1c1c",    // Dark Grey (for text)
        background: "#ffb327" // Light Grey (for background)
    },
    secondary: {
        text: "#006530",    // Dark Green (for text)
        background: "#a4f5bf" // Light Green (for background)
    },
    accent: {
        text: "#ffffff",    // Dark Red (for text)
        background: "#ef7266" // Light Red (for background)
    },
    default: {
        text: "#ffffff",    // Dark Blue (for text)
        background: "#3498db" // Light Blue (for background)
    }
};