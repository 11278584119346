import * as React from "react"
import { cn } from "../../lib/utils"
import { Button } from "../../components/button"
import { Input } from "../../components/input"
import {Spinner} from "@phosphor-icons/react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {useForm} from "react-hook-form";
import {useContext, useEffect, useState} from "react";
import {ServerErrorsType, useServerErrors} from "../../Core/functions/use-server-errors";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {Form, FormField, FormItem, FormLabel, FormMessage} from "../../components/form";
import ErrorMessageList from "../../Core/components/ErrorMessageList";
import {useNavigate} from "react-router-dom";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/select";
import {nullOrUndefined} from "../../Core/constants/variables";
import {Context} from "../../Core/utilities/Wrapper";
import {Label} from "../../components/label";

interface UserLogInFormProps extends React.HTMLAttributes<HTMLDivElement> {}

type TSystem = {
    repr: string
    value: string
}

const loginFormSchema = z.object({
  emailOrUsername: z.string().optional(),
  password: z.string().optional()
})

export function UserLogInForm({ className, ...props }: UserLogInFormProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const form = useForm<z.infer<typeof loginFormSchema>>({
    resolver: zodResolver(loginFormSchema)
  });
  const navigate = useNavigate();
  const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
  const errors = useServerErrors(apiErrors, form);
  const axiosInstance = useAxiosInstance();
  const context = useContext(Context);
  const axios = useAxiosInstance();
  const [systems, setSystems] = useState<TSystem[]>([]);


  async function onSubmit(values: z.infer<typeof loginFormSchema>) {
    setIsLoading(true);

    if (context.currSystem) {
        localStorage.setItem("currSystem", context.currSystem?.value);
        localStorage.setItem("currSystemRepr", context.currSystem?.repr);
    } else {
        localStorage.removeItem("currSystem");
        localStorage.removeItem("currSystemRepr");
    }

    axiosInstance.post("account-management/login/", values)
        .then((res) => {
          let {userFullName, token, userUUID, isAdminView, accountRole} = res.data;
          localStorage.setItem("userFullName", userFullName);
          localStorage.setItem("token", token);
          localStorage.setItem("userUUID", userUUID);
          localStorage.setItem("isAdminView", isAdminView);
          localStorage.setItem("accountRole", accountRole);
          setApiErrors({});
          navigate("/")
        })
        .catch((err) => {
          setApiErrors(err.response.data);
        })
        .finally(() => {
          setIsLoading(false);
        })
  }

    const changeSystem = (value: string) => {
        if (context.changeCurrSystem) {
            let system = systems.find(elem => elem.value === value)
            let inLocalStorge = localStorage.getItem("currSystem") === value
            if (
                inLocalStorge &&
                localStorage.getItem("currSystemRepr") &&
                localStorage.getItem("currSystem")
            ) {
                context.changeCurrSystem({
                    value: localStorage.getItem("currSystem") as string,
                    repr: localStorage.getItem("currSystemRepr") as string
                })
            } else if (system) {
                context.changeCurrSystem(system)
            } else {
                context.changeCurrSystem(undefined)
            }
        }
    }

    const fetchSystems = () => {
        axios.get('system-management/available-systems')
            .then((res) => setSystems(res.data))
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchSystems()
    }, []);

  useEffect(() => {
        if (
            !context.currSystem?.value &&
            context.changeCurrSystem &&
            localStorage.getItem('currSystem') &&
            localStorage.getItem('currSystemRepr')
        ) {
            context.changeCurrSystem({
                value: localStorage.getItem('currSystem') as string,
                repr: localStorage.getItem('currSystemRepr') as string
            })
        }
    }, []);

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid gap-4">
            <FormField
                control={form.control}
                name={"emailOrUsername"}
                render={({field}) => (
                    <FormItem>
                      <FormLabel>
                        Email or Username
                      </FormLabel>
                      <Input
                        placeholder="username or email"
                        disabled={isLoading}
                        {...field}
                      />
                      <FormMessage/>
                    </FormItem>
                )}
            />
            <FormField
                control={form.control}
                name={"password"}
                render={({field}) => (
                    <FormItem>
                      <FormLabel>
                        Password
                      </FormLabel>
                      <Input
                        placeholder="Password"
                        disabled={isLoading}
                        type={"password"}
                        {...field}
                      />
                      <FormMessage/>
                    </FormItem>
                )}
            />
            {context.environment !== process.env.PRODUCTION_ENVIRONMENT ? (
                <div className={"flex flex-col gap-2"}>
                    <Label>System</Label>
                    <Select
                        onValueChange={changeSystem}
                        value={context.currSystem?.value ?? "null"}
                        defaultValue={"null"}
                    >
                        <SelectTrigger>
                            <SelectValue placeholder="-/-"/>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value={"null"}>{nullOrUndefined}</SelectItem>
                            {(
                                localStorage.getItem('currSystem') &&
                                !systems.find(elem => elem.value === localStorage.getItem('currSystem'))
                            ) && (() => {
                                let systemValue = localStorage.getItem('currSystem')
                                let systemRepr = localStorage.getItem('currSystemRepr')

                                return systemValue && systemRepr ? <SelectItem value={systemValue}>{systemRepr}</SelectItem> : null
                            })()}
                            {systems.map((system) => (
                                <SelectItem value={system.value}>{system.repr}</SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
            ) : <div></div>}
            <Button type="submit" disabled={isLoading} variant="taimDefault">
              {isLoading && (
                <Spinner className="mr-2 h-4 w-4 animate-spin" />
              )}
              Login
            </Button>
            <ErrorMessageList errors={errors.nonFieldErrors}/>
            <ErrorMessageList errors={errors.detailErrors}/>
            {/*<div className="text-right">
              <Button type="button" className="p-0" variant="link">Forgot password</Button>
            </div>*/}
          </div>
        </form>
      </Form>
    </div>
  )
}