import DataTableOld, {ColumnOld, DataTableI} from "Core/Table/data-table-old";
import React, {useEffect, useState} from "react";
import DataTableTools from "Core/Table/data-table-tools";
import DataTablePagination from "Core/Table/data-table-pagination";
import {useIntl} from "react-intl";
import {System} from "../Controller/systems-controller";
import {Action} from "Core/Table/data-table-actions";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {DataTableRequest} from "../../Core/Table/interfaces";
import {EditServiceForm} from "../../ProjectManagement/Forms/service-forms";
import {EditSystem} from "../Forms/system-forms";
import {servicesUrl} from "../../ProjectManagement/Controller/services-controller";
import {toast} from "../../components/use-toast";
import {systemsUrl} from "../index";

export interface SystemFormatted extends System {
    actions: Action[]
}
interface SystemsTable extends DataTableI<System> {
    request: DataTableRequest
    reloadData: (value: boolean) => void
}


export const SystemsTable: React.FC<SystemsTable> = (
    {
        payload,
        setPayload,
        loading,
        request,
        reloadData
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const [formattedData, setFormattedData] = useState<SystemFormatted[]>([]);
    const employeeColumns: ColumnOld[] = [
        {
            backendKey: "name",
            frontendKey: intl.formatMessage({id: "sd.systems.name", defaultMessage: "Name"}),
            sortable: true,
        },
        {
            backendKey: "subdomainName",
            frontendKey: intl.formatMessage({id: "sd.systems.subdomainName", defaultMessage: "Sub-Domain Name"}),
            sortable: true,
        },
        {
            backendKey: "coupled",
            frontendKey: intl.formatMessage({id: "sd.systems.coupled", defaultMessage: "Coupled"}),
            sortable: true,
        },
        {backendKey: "actions", frontendKey: "actions"},
    ];

    const deleteSystem = (pk: string) => {
        axiosInstance.delete(systemsUrl + `${pk}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                reloadData(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    useEffect(() => {
        const formatData: SystemFormatted[] = request?.data.map((system) => {
            return {
                ...system,
                coupled: <>{system.coupled ? "true" : "false"}</>,
                actions: [
                    {
                        type: "edit",
                        content: <EditSystem system={system} reFetch={reloadData}/>
                    },
                    {
                        type: "delete",
                        handleAction: () => deleteSystem(system.pk)
                    }
                ]
            }
        });

        setFormattedData(formatData);
    }, [request.data]);

    return (
        <div className="space-y-4 h-full flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools payload={payload} setPayload={setPayload}/>
                <DataTableOld
                    data={formattedData}
                    payload={payload}
                    setPayload={setPayload}
                    columns={employeeColumns}
                    loading={loading}
                />
            </div>
            <DataTablePagination request={request} payload={payload} setPayload={setPayload}/>
        </div>
    )
}

