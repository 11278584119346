import React from "react";
import {Service} from "../../Controller/services-controller";
import {ArrowRight, Wrench} from "@phosphor-icons/react";
import DetailViewCard from "../../../Core/components/detail-view-card";
import {FormattedMessage, useIntl} from "react-intl";
import {Avatar, AvatarFallback} from "../../../components/avatar";
import {TaimModal} from "../../../Core/components/taim-modal";
import {DialogClose, DialogFooter} from "../../../components/dialog";
import {Button} from "../../../components/button";

type ServiceDetailProps = {
    service: Service
}

export const ServiceDetail: React.FC<ServiceDetailProps> = ({service}) => {
    const intl = useIntl();

    return (
        <TaimModal
            button={<ArrowRight className={"cursor-pointer"}/>}
            header={intl.formatMessage({id: "project.service.detail", defaultMessage: "Service Details"})}
        >
            <div className="flex flex-col gap-4">
                <div className={`flex flex-row gap-4 items-center text-black`}>
                    <Avatar className="h-12 w-12 text-xl font-bold">
                        <AvatarFallback>
                            <Wrench size={"24"}/>
                        </AvatarFallback>
                    </Avatar>
                    <div className="text-lg font-medium leading-none">{service.name}</div>
                </div>
                <DetailViewCard rows={[
                    {
                        name: intl.formatMessage({id: "project.employees", defaultMessage: "Employees"}),
                        value: service.employeesCount
                    },
                    {
                        name: intl.formatMessage({id: "project.timeSpent", defaultMessage: "Time Spent"}),
                        value: service.employeesSpentTime
                    }
                ]}/>
                <DetailViewCard
                    header={intl.formatMessage({id: "project.projects.employees", defaultMessage: "Employees"})}
                    rows={
                        service.employees?.length ? service.employees?.map(employee => {
                            return {
                                jsxElement: (
                                    <div className="p-2 border-b flex justify-between items-end">
                                        <div className={"flex flex-col gap-1"}>
                                            <div>{employee.fullName}</div>
                                            <div className="text-gray-400">{employee.roleName}</div>
                                        </div>
                                        <div>{employee.totalDuration}</div>
                                    </div>
                                )
                            }
                        }) : [{jsxElement: <FormattedMessage id="no_results" defaultMessage={"No results found."}/>}]
                    }
                />
                <DialogFooter className="flex flex-row justify-between">
                    <DialogClose asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.leave"} defaultMessage={"Leave"}/>
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </div>
        </TaimModal>
    )
}