import {Button} from "../../components/button";
import {Trash, Users} from "@phosphor-icons/react";
import {FormattedMessage, useIntl} from "react-intl";
import {Table, TableBody, TableCell, TableHead, TableRow} from "../../components/table";
import ErrorMessageList from "../../Core/components/ErrorMessageList";
import {DialogClose, DialogFooter} from "../../components/dialog";
import {TaimModal} from "../../Core/components/taim-modal";
import React, {useContext, useEffect, useState} from "react";
import {SHIFTS_URL, TShiftFormatted, useEmployeesOnShift} from "../Controller/shifts_controller";
import {ServerErrorsType} from "../../Core/functions/use-server-errors";
import {toast} from "../../components/use-toast";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {Employee} from "../../EmployeeManagement/Controllers/employee-controller";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import {MultiSelect} from "../../Core/components/multi-select";
import {getRandomInt} from "../../Core/functions/random";
import {Skeleton} from "../../components/skeleton";

type ManageShiftEmployeesProps = {
    employees: Employee[]
    triggerButton: React.ReactNode
    shift?: TShiftFormatted
    setShift: React.Dispatch<React.SetStateAction<TShiftFormatted | undefined>>
    setRefetchShifts: (pk?: number) => void
}

export const ManageShiftEmployees: React.FC<ManageShiftEmployeesProps> = (
    {shift, setShift, setRefetchShifts, triggerButton, employees}
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const [lazyLoader, setLazyLoader] = useState(false)
    const [selectedEmployees, setSelectedEmployees] = useState<string[]>([])
    const [employeesList, setEmployeesList] = useState<{
        employee: {
            userUUID: string
            fullName: string
        }
        value: string
        label: string
        icon?: React.ComponentType<{ className?: string }>
    }[]>([])
    const employeesOnShift = useEmployeesOnShift(lazyLoader, () => null, shift);
    const [employeesAssignApiErrors, setEmployeesAssignApiErrors] = useState<ServerErrorsType>({})
    const permissionContext = useContext(PermissionContext);

    const addEmployee = async (userUUID: string) => {
        if (!shift) {
            return;
        }

        return axiosInstance.post(SHIFTS_URL + `${shift.weeklyShift}/shifts/${shift.pk}/employees/`, {
            shift: shift.pk,
            userUUID
        })
            .then(res => {

            })
            .catch((err) => {
                throw err
            })
    }

    const addMultipleEmployeesOnce = (promises: Promise<undefined | void>[]) => {
        Promise.all(promises)
            .then(() => {
                setSelectedEmployees([])
                setEmployeesAssignApiErrors({})
                employeesOnShift.setReFetch(true)
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."}),
                })
            })
            .catch((err) => {
                setEmployeesAssignApiErrors(prevState => {
                    let {detail, non_field_errors} = err?.response?.data
                    return {
                        ...prevState,
                        ...(non_field_errors && {non_field_errors}),
                        ...(detail && {detail})
                    }
                })
            })
            .finally(() => {})
    }

    const deleteEmployee = (pk: number) => {
        if (!shift) return;

        axiosInstance.delete(SHIFTS_URL + `${shift.weeklyShift}/shifts/${shift.pk}/employees/${pk}/`)
            .then(res => {
                setSelectedEmployees([])
                employeesOnShift.setReFetch(true)
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."}),
                })
            })
            .catch(err => console.log(err))
    }

    const setEmployeesOnShift = () => {
        setEmployeesList(prevState => {
            return employees.filter((elem: Employee) => {
                    let employeesOnShiftArrayOfStrings = employeesOnShift.data.map(employeeOnShift => employeeOnShift.userUUID);
                    return !employeesOnShiftArrayOfStrings.includes(elem.userUUID);
                }).map((elem: Employee) => ({
                    employee: elem,
                    value: elem.userUUID,
                    label: elem.fullName
                }))
        })
    }

    useEffect(() => {
        setEmployeesOnShift();
    }, [employeesOnShift.data, permissionContext.isAdmin]);

    return <>
        <TaimModal
            header={intl.formatMessage({id: "shift_management.manage_employees", defaultMessage: "Manage Emloyees"})}
            button={(
                <Button
                    type="button"
                    variant="link"
                    className="p-0 flex flex-row gap-2 w-fit"
                    style={{
                        ...(shift?.webFontColor && {color: shift.webFontColor})
                    }}
                >
                    {triggerButton}
                </Button>
            )}
            onOpenChange={(open) => {
                if (!open) {
                    setSelectedEmployees([])
                    setEmployeesAssignApiErrors({})
                    setShift(undefined)
                    setLazyLoader(false)
                } else {
                    setShift(shift)
                    setLazyLoader(true)
                }
                setRefetchShifts(shift?.pk)
            }}
            dialogContentClassName={"max-w-[450px]"}
        >

            <div className="flex flex-row items-center gap-2">
                <MultiSelect
                    options={employeesList}
                    onValueChange={setSelectedEmployees}
                    defaultValue={selectedEmployees}
                    placeholder={intl.formatMessage({id: "shift_management.select_employees_placeholder", defaultMessage: "Select employees"})}
                    variant="inverted"
                    maxCount={3}
                    modalPopover={true}
                    hideOptions={true}
                />
                <Button
                    variant="taimDefault2"
                    onClick={(event) => {
                        if (selectedEmployees.length) {
                            let promises = selectedEmployees.map(userUUID => addEmployee(userUUID))
                            if (promises.length) {
                                addMultipleEmployeesOnce(promises)
                            }
                        } else {
                            toast({
                                title: intl.formatMessage({id: "toast.informational", defaultMessage: "Info!"}),
                                description: intl.formatMessage({id: "toast.informational.input_data_missing", defaultMessage: "Check again the input data you are trying to send."}),
                                variant: "informational"
                            })
                        }
                    }}
                ><FormattedMessage id={"add"} defaultMessage={"Add"} /></Button>
            </div>

            <Table className="border">
                <TableRow>
                    <TableHead>
                        <FormattedMessage id={"shift_management.employee"} defaultMessage={"Employee"}/>
                    </TableHead>
                    <TableHead>Action</TableHead>
                </TableRow>
                <TableBody>
                    {(employeesOnShift.data.length) ? (
                        employeesOnShift.data.map((e) => (
                            <TableRow>
                                <TableCell>{e.employee.firstName + ' ' + e.employee.lastName}</TableCell>
                                <TableCell>
                                    <Trash size={20} className={"text-red-600 cursor-pointer"} onClick={() => deleteEmployee(e.pk)}/>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : employeesOnShift.loading ? (
                        <>
                            {Array.from({length: 3}).map((_) => (
                                <TableRow>
                                    <TableCell className="text-center">
                                        <Skeleton className={`w-1/${getRandomInt(2, 4)} h-[15px]`}/>
                                    </TableCell>
                                    <TableCell className="text-center">
                                        <Skeleton className={`w-1/${getRandomInt(2, 4)} h-[15px]`}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    ) : (
                        <TableRow>
                            <TableCell colSpan={2} className="h-24 text-center">
                                <FormattedMessage id={"no_results"} defaultMessage={""}/>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            <ErrorMessageList errors={employeesAssignApiErrors?.non_field_errors}/>

            <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                <DialogClose asChild>
                    <Button variant="outline">
                        <FormattedMessage id={"button.leave"} defaultMessage={"Leave"}/>
                    </Button>
                </DialogClose>
            </DialogFooter>
        </TaimModal>
    </>
}