import {z} from "zod";
import {Service} from "ProjectManagement/Controller/services-controller";
import React, {useEffect, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {ServerErrorsType, useServerErrors} from "Core/functions/use-server-errors";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "components/form";
import {Employee} from "ProjectManagement/Controller/employees-controller";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {toast} from "components/use-toast";
import SearchInput, {SearchInputValue} from "Core/components/search-input";
import ErrorMessageList from "Core/components/ErrorMessageList";
import {DialogClose, DialogFooter} from "components/dialog";
import {Button} from "components/button";
import {projectManagementUrl} from "ProjectManagement";
import {Project} from "ProjectManagement/Controller/projects-controller";

const changeDefaultServiceSchema = z.object({
    defaultServiceID: z.any()
})

type ChangeDefaultServiceFormProps = {
    employee: Employee
    rePopulateRecord: (userUUID: string) => void
}

export const ChangeDefaultServiceForm: React.FC<ChangeDefaultServiceFormProps> = (
    {
        employee,
        rePopulateRecord
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof changeDefaultServiceSchema>>({
        resolver: zodResolver(changeDefaultServiceSchema),
        defaultValues: {
            defaultServiceID: employee.defaultServiceID
        }
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();
    const [userProjects, setUserProjects] = useState<SearchInputValue[]>([]);
    const [projectServices, setProjectServices] = useState<SearchInputValue[]>([]);
    const [userProject, setUserProject] = useState<string | null>();
    const [userService, setUserService] = useState<string | null>();

    const fetchProjects = () => {
        axiosInstance.get(projectManagementUrl + `employees/${employee.userUUID}/projects/`)
            .then(res => {
                let formattedProjects = res.data.map((elem: Project) => {
                    return {
                        key: elem.name,
                        value: elem.pk
                    }
                });
                setUserProjects(formattedProjects);
            })
            .catch(err => console.log(err));
    }

    const fetchServices = (projectID: string) => {
        axiosInstance.get(projectManagementUrl + `employees/${employee.userUUID}/projects/${projectID}/services/`)
            .then(res => {
                let formattedServices: SearchInputValue[] = res.data.map((elem: Service) => {
                    return {
                        key: elem.name,
                        value: elem.pk
                    }
                });
                setProjectServices(formattedServices);

                if (formattedServices.length) {
                    setUserService(formattedServices[0].value);
                } else {
                    setUserService(undefined);
                }
            })
            .catch(err => console.log(err));
    }

    const onSubmit = (values: z.infer<typeof changeDefaultServiceSchema>) => {
        let data: Employee = {
            ...employee
        }

        data.defaultProjectID = userProject;
        data.defaultServiceID = userService;

        axiosInstance.put(projectManagementUrl + `employees/${data.userUUID}/`, data)
            .then(() => {
                setApiErrors({});
                closeModal.current?.click();
                rePopulateRecord(employee.userUUID);
                toast({
                    title: "Great !",
                    description: "Default service has been changed successfully"
                });
            })
            .catch((err) => {
                setApiErrors(err.response.data);
            })
    }

    useEffect(() => {
        fetchProjects();
    }, [employee]);

    useEffect(() => {
        if (userProject) {
            fetchServices(userProject);
        }
    }, [userProject]);

    useEffect(() => {
        setUserProject(employee.defaultProjectID)
        setUserService(employee.defaultServiceID)
    }, []);

    return (
        <Form {...form}>
            <form method="post" onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                    <FormLabel>
                        <FormattedMessage
                            id={"project.project"}
                            defaultMessage={"Project"}
                        />
                    </FormLabel>
                    <SearchInput
                        title={intl.formatMessage({id: "project.employee.projectPlaceholder", defaultMessage: "Pick a project"})}
                        values={userProjects}
                        value={userProject}
                        onChange={setUserProject}
                    />
                </div>
                <FormField
                    control={form.control}
                    name={"defaultServiceID"}
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage
                                    id={"project.employee.defaultService"}
                                    defaultMessage={"Default Service"}
                                />
                            </FormLabel>
                            <FormControl>
                                <SearchInput
                                    title={intl.formatMessage({id: "project.employee.defaultServicePlaceholder", defaultMessage: "Pick a service"})}
                                    values={projectServices}
                                    value={userService}
                                    onChange={setUserService}
                                />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <ErrorMessageList errors={errors.detailErrors}/>
                <ErrorMessageList errors={errors.nonFieldErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>

            </form>
        </Form>
    )
}