import * as React from "react"
import { CheckIcon, PlusCircledIcon } from "@radix-ui/react-icons"

import { cn } from "../../lib/utils"
import { Badge } from "../../components/badge"
import { Button } from "../../components/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "../../components/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/popover"
import { Separator } from "../../components/seperator"
import {SetStateAction, useEffect, useState} from "react";
import {Option} from "./data-table-tools";
import {DataTablePayload} from "./interfaces";
import {useIntl} from "react-intl";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue
} from "../../components/select";



interface DataTableSingleFilterProps {
    title?: string
    filterBy: string
    payload: DataTablePayload
    setPayload: React.Dispatch<SetStateAction<DataTablePayload>>
    options: Option[]
}

export function DataTableSingleFilter({
    title,
    filterBy,
    payload,
    setPayload,
    options,
}: DataTableSingleFilterProps) {
    const handleSelect = (value: string) => {
        setPayload(prevState => ({
            ...prevState,
            [filterBy]: value
        }))
    }

    return (
        <Select onValueChange={handleSelect}>
            <SelectTrigger className="w-[180px]">
                <SelectValue placeholder={title} />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {options.map(option => (
                        <SelectItem value={option.value}>{option.label}</SelectItem>
                    ))}
                </SelectGroup>
            </SelectContent>
        </Select>
    )
}