import {useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {DataTablePayload, DataTableRequest} from "Core/Table/interfaces";
import {projectManagementUrl} from "../index";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";


export type Employee = {
    fullName: string
    userUUID: string
    defaultService?: string
    defaultServiceName?: string
    defaultServiceID?: string | null
    defaultProject?: string
    defaultProjectName?: string
    defaultProjectID?: string | null
    roleName?: string
}

export interface ProjectEmployeesPayload extends DataTablePayload {

}

export interface ProjectEmployeesRequest extends DataTableRequest {}


const employeesInitialState: ProjectEmployeesRequest = {
    recordsTotal: 0,
    recordsFiltered: 0,
    next: "",
    previous: "",
    data: []
}

export const useFetchProjectEmployees = (payload: ProjectEmployeesPayload) => {
    const [
        employeesRequest,
        setData
    ] = useState<ProjectEmployeesRequest>(employeesInitialState);
    const [loadingEmployees, setLoading] = useState(false);
    const [refetchEmployees, shouldRefetchEmployees] = useState(false);
    const [employeesErrors, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchEmployees = () => {
        setLoading(true);
        axiosInstance.get(projectManagementUrl + 'employees/',{
            params: payload
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                shouldRefetchEmployees(false);
            })
    }

    useEffect( () => {
        fetchEmployees();
    }, [payload, refetchEmployees, permissionContext.isAdmin])

    return {employeesRequest, loadingEmployees, employeesErrors, shouldRefetchEmployees}
}
