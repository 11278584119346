import React, {useContext, useEffect, useState} from "react";
import {Button} from "../../components/button";
import LocationsDataTable from "../DataTables/locations-data-table";
import {
    locationsPayload,
    useFetchLocationsDataTable
} from "../Controllers/locations-controller";
import {CreateLocationForm} from "../Forms/location-forms";
import {FormattedMessage, useIntl} from "react-intl";
import {MainTab, PageActions, PageEntrySchema} from "../../Core/layout/page-shape";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";

const initialPayload = {
    start: 0,
    length:10,
    search: "",
    ordering: "",
}

const LocationsEntry: React.FC<PageEntrySchema> = ({name, pagesState}) => {
    const intl = useIntl();
    const [payload, setPayload] = useState<locationsPayload>(initialPayload);
    const { request, loading, reFetch } = useFetchLocationsDataTable(payload);
    const permissionContext = useContext(PermissionContext);


    useEffect(() => {
        let currPage = pagesState?.find(page => page.page.value === name);
        if (currPage && currPage.reFetch) {
            // setPayload(initialPayload);
            reFetch(true);
        }
    }, [pagesState]);

    return (
        <MainTab
            type="mainOnly"
            name={name}
            main={{
                title: intl.formatMessage({id: "table.list", defaultMessage: "List"}),
                content: <LocationsDataTable
                    locationsRequest={request}
                    loading={loading}
                    payload={payload}
                    setPayload={setPayload}
                    reFetch={reFetch}
                />
            }}
            actions={[
                {
                    type: "modal",
                    button: (
                        <Button variant="taimDefault">
                            <FormattedMessage
                                id={"attendance.locations.create"}
                                defaultMessage={"Create Location"}
                            />
                        </Button>
                    ),
                    header: intl.formatMessage({id: "attendance.locations.create", defaultMessage: "Create Location"}),
                    children: <CreateLocationForm reFetch={reFetch}/>
                }
            ].filter((elem) => {
                if (permissionContext.isAdmin) return elem
            }) as PageActions[]}
        />
    )
}

export default LocationsEntry;