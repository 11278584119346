import React, {HTMLAttributes, RefObject, SetStateAction, useEffect, useRef, useState} from "react";
import moment from "moment";
import {CaretLeft, CaretRight} from "@phosphor-icons/react";
import {FormattedMessage, useIntl} from "react-intl";
import {isCurrentMonth} from "../functions/is-current-month";
import {cn} from "../../lib/utils";

interface DaysTimeline {
    month: number
    year: number
    day: number
    setDay?: React.Dispatch<SetStateAction<number | null>>
}

interface DayComponentProps extends HTMLAttributes<HTMLDivElement> {
  day: Day;
}

type Day = {
    dayNumber: number
    dayName: string
}

function generateDaysOfMonth (year: number, month: number) {
    const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
    const daysArray: Day[] = [];

    for (let day = 1; day <= daysInMonth; day++) {
        const date = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
        daysArray.push({
            dayNumber: date.date(),
            dayName: date.format("dddd")
        });
    }

    return daysArray;
}


const DayComponent: React.FC<DayComponentProps & {
    isSlected: boolean
    isFuture: boolean
}> = ({day, isSlected, isFuture, ...props}) => {
    const {dayNumber, dayName} = day;
    const formatDay = String(dayNumber).length === 1 ? `0${dayNumber}` : dayNumber;
    const formatDayName = moment(dayName, 'dddd').format('ddd');

    return (
        <div
            {...props}
            className={cn(
                "min-h-[30px] min-w-[30px] xl:min-h-[40px] xl:min-w-[40px] 2xl:min-h-[50px] py-1 2xl:min-w-[50px] flex flex-col bg-gray-50/50 rounded items-center justify-center border cursor-pointer hover:scale-110 text-gray-500",
                isFuture && "cursor-not-allowed bg-gray-100 text-gray-400 hover:scale-100 border-gray-200",
                isSlected && "bg-blue-900 text-white"
            )}
        >
            <span className="text-[12px] 2xl:text-sm">{formatDay}</span>
            <span className="text-[10px] 2xl:text-xs">{formatDayName}</span>
        </div>
    )
}


export const DaysTimeline: React.FC<DaysTimeline> = ({day, month, year, setDay}) => {
    const today = new Date().getDate();
    const [daysOfMonth, setDaysOfMonth] = useState<Day[]>();
    const daysContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setDaysOfMonth(generateDaysOfMonth(year, month));
    }, [year, month]);


    useEffect(() => {
        const handleClick = (event: KeyboardEvent) => {
            if (event.key == "ArrowRight") {
                handleScrollToRight();
            } else if (event.key == "ArrowLeft") {
                handleScrollToLeft();
            }
        };

        window.addEventListener('keydown', handleClick);

        return () => {
          window.removeEventListener('keydown', handleClick);
        };
    }, [day]);

    useEffect(() => {
        if (isCurrentMonth(month, year)) {
            if (daysOfMonth) {
                let onePartLength = Math.round(daysOfMonth.length / 3)
                let firstPart = onePartLength;
                let secondPart = onePartLength * 2;

                if (day >= firstPart && day < secondPart) {
                    handleScrollToRight(day - 6);
                } else if (day >= secondPart) {
                    handleScrollToRight(secondPart)
                }
            }
        } else {
            handleScrollToLeft(30)
        }
    }, [daysOfMonth]);

    const handleScrollToRight = (shift?: number) => {
        if (daysContainer.current) {
            daysContainer.current.scrollLeft += shift ? shift * 100 : 100
        }
    };
    const handleScrollToLeft = (shift?: number) => {
        if (daysContainer.current) {
            daysContainer.current.scrollLeft -= shift ? shift * 100 : 100
        }
    };

    return (
        <div className="flex flex-row gap-4 w-full items-center select-none">
            <div
                className="border p-2 rounded cursor-pointer hover:scale-110"
                onClick={() => {
                    handleScrollToLeft();
                }}
            ><CaretLeft size={16}/></div>
            <div
                ref={daysContainer}
                className={cn(
                    "grow flex flex-row gap-2 xl:gap-3 2xl:gap-4 overflow-x-scroll scroll-smooth no-scrollbar p-1"
                )}
            >
                {daysOfMonth && daysOfMonth.map((currDay, index) => {
                    let isSelected = currDay.dayNumber === day;
                    let isFuture = isCurrentMonth(month, year) && currDay.dayNumber > today;

                    return <DayComponent
                        day={currDay}
                        isSlected={isSelected}
                        isFuture={isFuture}
                        onClick={() => {
                            if (setDay && !isSelected && !isFuture) setDay(currDay.dayNumber);
                        }}
                    />
                })}
            </div>
            <div
                className="border p-2 rounded cursor-pointer hover:scale-110"
                onClick={() => {
                    handleScrollToRight();
                }}
            ><CaretRight size={16}/></div>
        </div>
    )
}

