import {useIntl} from "react-intl";
import {Projects} from "./Pages/Projects";
import {PageShape, PageShapeProps} from "../Core/layout/page-shape";
import {Employees} from "./Pages/Employees";
import {Services} from "./Pages/Services";

const PROJECTS_TAB = "projects";
const SERVICES_TAB = "services";
const EMPLOYEES_TAB = "employees";


export const projectManagementUrl = 'project-management/';

export const ProjectManagement = () => {
    const intl = useIntl();
    const employeeName = intl.formatMessage({id: "project.employee", defaultMessage: "Employee"});
    const projectsName = intl.formatMessage({id: "project.projects", defaultMessage: "Projects"});
    const servicesName = intl.formatMessage({id: "project.services", defaultMessage: "Services"});
    const sections = [
        {label: projectsName, value: PROJECTS_TAB},
        {label: servicesName, value: SERVICES_TAB},
        {label: employeeName, value: EMPLOYEES_TAB},
    ]


    const pageContent: PageShapeProps = {
        header: intl.formatMessage({id: "project.header", defaultMessage: "Project Management"}),
        pageEntries: [
            props => <Projects name={PROJECTS_TAB} pagesState={props.pagesState}/>,
            props => <Employees name={EMPLOYEES_TAB} pagesState={props.pagesState}/>,
            props => <Services name={SERVICES_TAB} pagesState={props.pagesState}/>,
        ],
        sections,
        defaultSection: sections[0]
    }

    return <PageShape {...pageContent}/>
}