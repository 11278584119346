import React, {Context, createContext, ReactNode, useEffect, useReducer, useState} from "react";
import {useAxiosInstance} from "./AxiosInstance";
import {AuthType} from "./Authenticator";

export type PermissionContextType = {
    role: string
    isAdmin: boolean
    changeState: Function
    isChanchingState: boolean
}

const defaultPermission: PermissionContextType = {
    role: "user",
    isAdmin: false,
    changeState: () => null,
    isChanchingState: false
}

export const PermissionContext: Context<PermissionContextType> = createContext(defaultPermission);

type PermissionProviderType = {
    children: ReactNode
    auth: AuthType
}

const bc = new BroadcastChannel("stateChange");

export const PermissionProvider: React.FC<PermissionProviderType> = (
    {children, auth}
) => {
    const axiosInstance = useAxiosInstance();
    const userUUID = localStorage.getItem("userUUID");
    const role = localStorage.getItem("accountRole") ?? "user";
    const [isAdmin, setIsAdmin] = useState<boolean>(auth.isAdminView);
    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState(0)

    // serves as an updater for the whole tree down this component. it has many downsides (it's avoided for the moment)
    const handleKeyIncrement = () => {
        setKey(k => k + 1)
    }

    const changeState = (value?: "user" | "admin", locally?: boolean) => {
        if (role === "user") {
            return;
        }

        setLoading(true);
        let isAdminView = value !== "user";

        if (!locally) {
            axiosInstance.post(`/employee-management/employees/${userUUID}/switch-admin-state/`, {
                isAdminView
            })
                .then((res) => {
                    setIsAdmin((prevState) => {
                        localStorage.setItem("isAdminView", String(isAdminView));
                        return isAdminView;
                    });
                })
                .catch((err) => console.log(err.message))
                .finally(() => {
                    setTimeout(() => {
                        setLoading(false);
                        // handleKeyIncrement();

                        bc.postMessage("State changed!");
                    }, 1000);
                });
        } else {
            setIsAdmin((prevState) => {
                const newAdminState = (value && value === "admin") ?? !prevState;
                localStorage.setItem("isAdminView", String(newAdminState));

                setTimeout(() => {
                    setLoading(false);
                    // handleKeyIncrement();

                    bc.postMessage("State changed!");
                }, 1000);

                return newAdminState;
            });
        }
    }

    useEffect(() => {
        bc.addEventListener("message", e => {
            setIsAdmin((prevState) => {
                return String(localStorage.getItem("isAdminView")) === "true";
            });
        })
    }, []);

    return (
        <PermissionContext.Provider value={{
            role,
            isAdmin,
            changeState,
            isChanchingState: loading
        }} key={key}>
            {children}
        </PermissionContext.Provider>
    )
}