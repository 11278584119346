import React, {ReactNode, useContext, useEffect, useRef, useState} from "react";
import {
    GlobeSimple,
    Timer,
    Users,
    Calendar,
    Question,
    PhoneCall,
    Power,
    Cardholder,
    Bug,
    ChatDots,
    RocketLaunch,
    Key,
    DotsThreeVertical,
    Devices,
    Download,
    ClockUser,
    User,
    CalendarBlank,
    CalendarDots
} from "@phosphor-icons/react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import taim from "../../assets/images/taim_new_web_app.png";
import {useAxiosInstance} from "../utilities/AxiosInstance";
import {TaimModal} from "../components/taim-modal";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent, AlertDialogDescription, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "../../components/alert-dialog";
import {FormattedMessage, useIntl} from "react-intl";
import {Textarea} from "../../components/textarea";
import {Button} from "../../components/button";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/select";
import {Label} from "../../components/label";
import {PermissionContext} from "../utilities/PermissionProvider";

const SMALL_WINDOW = "small"
const MEDIUM_WINDOW = "medium"
const LARGE_WINDOW = "large"

type TWindowScale = "small" | "medium" | "large"

type NavBarEntry = {
    name: string
    link: string
    icon: React.ComponentType<any>
}

const navBarEntries: NavBarEntry[] = [
    {
        name: 'Dashboard',
        link: '/',
        icon: GlobeSimple
    },
    {
        name: 'Employee Management',
        link: '/employee-management',
        icon: Users
    },
    {
        name: 'Project Management',
        link: '/project-management',
        icon: Cardholder
    },
    {
        name: 'Attendance Management',
        link: '/attendance-management',
        icon: Timer
    },
    {
        name: 'Shift Management',
        link: '/shift-management',
        icon: () => (
            <div className="relative w-8 h-8">
                <CalendarDots size={16} className="absolute top-0 right-0" />
                <User size={20} className="absolute bottom-0 left-0"/>
            </div>
        )
    },
    {
        name: 'Import Management',
        link: '/import-management',
        icon: Download
    },
    {
        name: 'Systems and Devices Management',
        link: '/systems-management',
        icon: Devices
    },
    {
        name: 'Timesheet Management',
        link: '/timesheet-management',
        icon: Calendar
    },
]

const navBarSettingEntries: NavBarEntry[] = [
    {
        name: 'Help Center',
        link: '/help-center',
        icon: Question
    }
]

const ThreeDotsCard: React.FC<{
    children?: React.ReactNode
}> = ({children}) => {
    const [open, setOpen] = useState(false)
    const divRef = useRef<HTMLDivElement>(null)
    const handleClickOutside = (event: MouseEvent) => {
        if (divRef.current && !divRef.current.contains(event.target as Node)) {
            setOpen(false)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return <div ref={divRef}>
        <div
            title={"more"}
            onClick={() => setOpen(!open)}
            onMouseEnter={() => setOpen(true)}
            className={`hover:bg-slate-800 hover:scale-110 p-3 rounded cursor-pointer`}
        >
            <DotsThreeVertical color="white" width={28} height={28}/>
        </div>
        {open && (
            <div
                className="absolute select-none bg-slate-900 flex flex-row space-x-4 w-fit p-3 border border-white rounded-lg"
            >{children}</div>
        )}
    </div>
}

const getWindowViewPort = () => {
    let vw = window.innerWidth
    let vh = window.innerHeight
    return {vw, vh}
}

const NavBarComponent = () => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const location = useLocation();
    const permissionContext = useContext(PermissionContext)
    const [selectedPage, setSelectedPage] = useState<string>();
    const [pages, setPages] = useState<NavBarEntry[]>(navBarEntries);
    const navigate = useNavigate();
    const [windowScale, setWindowScale] = useState<TWindowScale>((() => {
        let {vh} = getWindowViewPort()
        return vh < 820 ? SMALL_WINDOW : vh < 1000 ? MEDIUM_WINDOW : LARGE_WINDOW
    })())


    useEffect(() => {
        const updateViewPort = () => {
            let {vh} = getWindowViewPort()

            if (vh < 820) {
                setWindowScale(SMALL_WINDOW)
            } else if (vh < 1000) {
                setWindowScale(MEDIUM_WINDOW)
            } else {
                setWindowScale(LARGE_WINDOW)
            }
        }

        window.addEventListener("resize", updateViewPort);
        return () => window.removeEventListener("resize", updateViewPort);
    }, []);

    const handleLogout = () => {
        axiosInstance.post("account-management/logout/")
            .then((res) => {
                // can't perform actions here because they go wrong from the server side
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                for (let localStorageKey in localStorage) {
                    if (
                        localStorageKey !== "currSystem" &&
                        localStorageKey !== "currSystemRepr" &&
                        localStorageKey !== "environment"
                    ) {
                        localStorage.removeItem(localStorageKey)
                    }
                }
                navigate("/account-management/login");
            })
    }

    useEffect(() => {
        navBarEntries.concat(navBarSettingEntries).forEach((elem) => {
            if (window.location.pathname.includes(elem.link)) {
                setSelectedPage(elem.name);
            }
        })
    }, [location]);

    useEffect(() => {
        if (!permissionContext.isAdmin) {
            setPages(navBarEntries.filter(p => {
                return (
                    p.link !== '/import-management' &&
                    p.link !== '/systems-management'
                )
            }))
        } else {
            setPages(navBarEntries)
        }
    }, [permissionContext.isAdmin]);

    return (
        <div className="flex flex-col justify-between flex-shrink-0 items-center self-stretch p-5 bg-slate-900 w-full">
            <div className="flex flex-col w-14 gap-8 2xl:gap-12 3xl:gap-16 pt-4 pb-4 border-b border-[rgba(255,255,255,0.1)]">
                <div className="flex flex-col items-center cursor-pointer">
                    <Link to="/" onClick={() => setSelectedPage('Dashboard')}>
                        <img className="w-12 h-12" alt="" src={taim}/>
                    </Link>
                </div>
                <div className="flex flex-col self-stretch gap-4 2xl:gap-6 3xl:gap-8 text-white items-center max-h-[50vh] overflow-y-scroll no-scrollbar">
                    {pages && (windowScale === SMALL_WINDOW ? (
                        <>
                            {pages.slice(0, 3).map((navBarEntry, index) => (
                                <Link key={index} to={navBarEntry.link}
                                      onClick={() => setSelectedPage(navBarEntry.name)}>
                                    <div
                                        title={navBarEntry.name}
                                        className={`hover:bg-slate-800 hover:scale-110 p-3 rounded cursor-pointer ${(selectedPage === navBarEntry.name) ? "bg-slate-800" : ""}`}
                                    >
                                        <navBarEntry.icon color="white" width={28} height={28}/>
                                    </div>
                                </Link>
                            ))}
                            <ThreeDotsCard>
                                {pages.slice(3,).map((navBarEntry, index) => (
                                    <Link key={index} to={navBarEntry.link}
                                          onClick={() => setSelectedPage(navBarEntry.name)}>
                                        <div
                                            title={navBarEntry.name}
                                            className={`hover:bg-slate-800 hover:scale-110 p-3 rounded cursor-pointer ${(selectedPage === navBarEntry.name) ? "bg-slate-800" : ""}`}
                                        >
                                            <navBarEntry.icon color="white" width={28} height={28}/>
                                        </div>
                                    </Link>
                                ))}
                            </ThreeDotsCard>
                        </>
                        ) : windowScale === MEDIUM_WINDOW ? (
                            <>
                                {pages.slice(0, 5).map((navBarEntry, index) => (
                                    <Link key={index} to={navBarEntry.link}
                                          onClick={() => setSelectedPage(navBarEntry.name)}>
                                        <div
                                            title={navBarEntry.name}
                                            className={`hover:bg-slate-800 hover:scale-110 p-3 rounded cursor-pointer ${(selectedPage === navBarEntry.name) ? "bg-slate-800" : ""}`}
                                        >
                                            <navBarEntry.icon color="white" width={28} height={28}/>
                                        </div>
                                    </Link>
                                ))}
                                <ThreeDotsCard>
                                    {pages.slice(5,).map((navBarEntry, index) => (
                                        <Link key={index} to={navBarEntry.link}
                                              onClick={() => setSelectedPage(navBarEntry.name)}>
                                            <div
                                                title={navBarEntry.name}
                                                className={`hover:bg-slate-800 hover:scale-110 p-3 rounded cursor-pointer ${(selectedPage === navBarEntry.name) ? "bg-slate-800" : ""}`}
                                            >
                                                <navBarEntry.icon color="white" width={28} height={28}/>
                                            </div>
                                        </Link>
                                    ))}
                                </ThreeDotsCard>
                            </>
                        ) : (
                            pages.map((navBarEntry, index) => (
                                <Link key={index} to={navBarEntry.link} onClick={() => setSelectedPage(navBarEntry.name)}>
                                    <div
                                        title={navBarEntry.name}
                                        className={`hover:bg-slate-800 hover:scale-110 p-3 rounded cursor-pointer ${(selectedPage === navBarEntry.name) ? "bg-slate-800" : ""}`}
                                    >
                                        <navBarEntry.icon color="white" width={28} height={28} />
                                    </div>
                                </Link>
                            ))
                        )
                    )}
                </div>
            </div>
            <div className="flex flex-col gap-8 2xl:gap-12 3xl:gap-16 pt-4 border-t border-[rgba(255,255,255,0.1)]">
                <div className="flex flex-col w-14 self-stretch gap-4 2xl:gap-6 3xl:gap-8 text-white items-center max-h-[30vh] overflow-y-scroll no-scrollbar">
                    {navBarSettingEntries && navBarSettingEntries.map((navBarEntry, index) => (
                        <Link
                            key={index}
                            to={navBarEntry.link}
                            onClick={() => setSelectedPage(navBarEntry.name)}
                        >
                            <div
                                title={navBarEntry.name}
                                className={`hover:bg-slate-800 hover:scale-110 p-3 rounded cursor-pointer ${(selectedPage === navBarEntry.name) ? "bg-slate-800" : ""}`}
                            >
                                <navBarEntry.icon color="white" width={28} height={28} />
                            </div>
                        </Link>
                    ))}

                    <TaimModal
                        header={"Contact"}
                        button={
                            <div
                                title={"Contact"}
                                className={`hover:bg-slate-800 hover:scale-110 p-3 rounded cursor-pointer`}
                            >
                                <PhoneCall color="white" width={28} height={28} />
                            </div>
                        }
                    >
                        <div className="grid w-full gap-4">
                            <div className="flex flex-col gap-2">
                                <Label><FormattedMessage id={"contact.type"} defaultMessage="Type"/></Label>
                                <Select>
                                    <SelectTrigger>
                                        <SelectValue
                                            placeholder={intl.formatMessage({id: "contact.typePlaceHolder", defaultMessage: "Pick a message type"})}
                                        />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value={"1"}>
                                            <div className={"flex flex-row gap-1 items-center"}>
                                                <Bug/>
                                                <span>Bug Report</span>
                                            </div>
                                        </SelectItem>
                                        <SelectItem value={"2"}>
                                            <div className={"flex flex-row gap-1 items-center"}>
                                                <RocketLaunch/>
                                                <span>Feature Request</span>
                                            </div>
                                        </SelectItem>
                                        <SelectItem value={"3"}>
                                            <div className={"flex flex-row gap-1 items-center"}>
                                                <ChatDots/>
                                                <span>Feedback</span>
                                            </div>
                                        </SelectItem>
                                        <SelectItem value={"4"}>
                                            <div className={"flex flex-row gap-1 items-center"}>
                                                <Key/>
                                                <span>Privacy Concerns</span>
                                            </div>
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                            <div className="flex flex-col gap-2">
                                <Label><FormattedMessage id={"contact.content"} defaultMessage="Contact"/></Label>
                                <Textarea placeholder={intl.formatMessage({id: "contact.contentPlaceHolder", defaultMessage: "Enter your message here. It will be sent to info@taim.de"})} />
                            </div>
                            <Button variant={"taimDefault"}>
                                <FormattedMessage id={"contact.button"} defaultMessage={"Send message"}/>
                            </Button>
                        </div>
                    </TaimModal>

                    <AlertDialog>
                        <AlertDialogTrigger className="flex-1 flex flex-row justify-start w-full">
                            <div
                                title={"Logout"}
                                className={`hover:bg-slate-800 hover:scale-110 p-3 rounded cursor-pointer`}
                            >
                                <Power color="white" width={28} height={28} />
                            </div>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>
                                    <FormattedMessage id={"logout"} defaultMessage={"Logout"}/>
                                </AlertDialogTitle>
                                <AlertDialogDescription>
                                    <FormattedMessage id={"logout.sentence"} defaultMessage={"Are you sure you want to log out? This action will sign you out of your account."}/>
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel><FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/></AlertDialogCancel>
                                <AlertDialogAction
                                    variant="destructive"
                                    onClick={handleLogout}
                                >
                                    <FormattedMessage id={"continue"} defaultMessage={"Continue"}/>
                                </AlertDialogAction>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>

                </div>
            </div>
        </div>
    )
}

export default NavBarComponent;