import React, {useEffect, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {role, rolesUrl} from "../Controllers/role-controller";
import {Card} from "../../components/card";
import RegularNameAvatar from "../../Core/components/regular-name-avatar";
import DetailViewCard from "../../Core/components/detail-view-card";
import {DialogClose, DialogFooter} from "../../components/dialog";
import {Button} from "../../components/button";
import FormTitle from "../../Core/components/form-title";
import {nullOrUndefined} from "../../Core/constants/variables";
import {Skeleton} from "../../components/skeleton";
import {FormattedMessage, useIntl} from "react-intl";

type RolesDetailViewProps = {
    role: role
}

const RolesDetailView: React.FC<RolesDetailViewProps> = ({role}) => {
    const intl = useIntl()
    const axiosInstance = useAxiosInstance()
    const [roleDetails, setRoleDetails] = useState<role>()
    const [loading, setLoading] = useState<boolean>()
    const fetchRoleDetails = () => {
        setLoading(true);
        axiosInstance.get(rolesUrl + `${role.pk}/`)
            .then((res) => setRoleDetails(res.data))
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchRoleDetails();
    }, [role]);
    return (
        <>
            <DetailViewCard rows={[
                {
                    name: intl.formatMessage({id: "roles.detail.name", defaultMessage: "Name"}),
                    value: role.name ?? nullOrUndefined
                },
                {
                    name: intl.formatMessage({id: "roles.detail.numberOfEmployees", defaultMessage: "Number Of Employees"}),
                    value: role.totalEmployees ?? nullOrUndefined
                }
            ]}/>
            <FormTitle title={intl.formatMessage({id: "roles.detail.employees", defaultMessage: "Employees"})}/>
            <Card className="p-4 max-h-[16rem] overflow-auto">
                {(!loading && roleDetails?.employees?.length) ? (
                    roleDetails.employees.map((employee, index) => (
                        <div className="py-2">
                            <RegularNameAvatar username={employee.fullName} key={index}></RegularNameAvatar>
                        </div>
                    ))
                ) : (loading) ? (
                    <div className="flex flex-row gap-4 items-center">
                        <Skeleton className="w-[20px] h-[20px] rounded-full"/>
                        <Skeleton className="w-1/2 h-[10px] rounded-full" />
                    </div>
                ) : (
                    <FormattedMessage id={"no_results"} defaultMessage={"No results found."}/>
                )}
            </Card>
            <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                <DialogClose asChild>
                    <Button variant="outline">
                        <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                    </Button>
                </DialogClose>
            </DialogFooter>
        </>
    )
}


export default RolesDetailView;