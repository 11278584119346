import {useIntl} from "react-intl";
import {PageShape, PageShapeProps} from "../Core/layout/page-shape";
import {Devices} from "./Pages/Devices";
import {Systems} from "./Pages/Systems";
import {useContext, useEffect} from "react";
import {PermissionContext} from "../Core/utilities/PermissionProvider";
import {useNavigate} from "react-router-dom";

export const systemsUrl = 'system-management/customer-systems/';
export const devicesUrl = 'system-management/devices/';

const SYSTEMS_TAB = "systems";
const DEVICES_NAME = "devices";

export const SystemsManagement = () => {
    const intl = useIntl();
    const permissionContext = useContext(PermissionContext);
    const navigate = useNavigate();
    const systemsName = intl.formatMessage({id: "sd.systems", defaultMessage: "Systems"});
    const devicesName = intl.formatMessage({id: "sd.devices", defaultMessage: "Devices"});
    const sections = [
        {label: systemsName, value: SYSTEMS_TAB},
        {label: devicesName, value: DEVICES_NAME},
    ]


    const pageContent: PageShapeProps = {
        header: intl.formatMessage({id: "sd.header", defaultMessage: "Systems Management"}),
        pageEntries: [
            props => <Systems name={SYSTEMS_TAB} pagesState={props.pagesState}/>,
            props => <Devices name={DEVICES_NAME} pagesState={props.pagesState}/>
        ],
        sections,
        defaultSection: sections[0]
    }

    useEffect(() => {
        if (!permissionContext.isAdmin) {
            navigate('/')
        }
    }, []);

    return <PageShape {...pageContent}/>
}