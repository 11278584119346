import { Card, CardContent, CardHeader, CardTitle } from "components/card";
import React from "react";
import { FC } from "react";
import {Skeleton} from "components/skeleton";
import {IconProps} from "@phosphor-icons/react";
import {cn} from "lib/utils";
import {Employee} from "Dashboard/Controllers/Admin/daily-track-controller";
import {Avatar, AvatarFallback} from "components/avatar";
import {firstLetterFromEachWord} from "Core/functions/first-letter-from-each-word";
import Modal from "Core/components/modal";
import RegularNameAvatar from "Core/components/regular-name-avatar";
import {FormattedMessage, useIntl} from "react-intl";
import {HoverCard, HoverCardContent, HoverCardTrigger} from "components/hover-card";

type HighlightComponentProps = {
    name: string
    Icon: React.ForwardRefExoticComponent<IconProps>
    iconColor?: string
    bgColor?: string
    users?: Employee[]
    loading?: boolean
};

type UsersListProps = {
    name: string
    users?: Employee[]
}

const UsersList: React.FC<UsersListProps> = (
    {users, name}
) => {
    const intl = useIntl();

    return (
        <div className="flex flex-row justify-between items-center">
            {users?.length ? (
                <div className="flex items-center -space-x-2.5 cursor-pointer">
                    {users.map((user, index) => {
                        if (index <= 4) {
                            return (
                                <HoverCard>
                                    <HoverCardTrigger>
                                        <Avatar key={index} className={cn("h-8 w-8 hover:z-10 focus:z-10 border-2 border-white")}>
                                            <AvatarFallback className="bg-slate-200 text-[10px] font-semibold">
                                                {(index === 4 || index === users.length) ? `+${users.length - index}` : firstLetterFromEachWord(user.fullName)}
                                            </AvatarFallback>
                                        </Avatar>
                                    </HoverCardTrigger>
                                    <HoverCardContent className={"w-fit h-fit text-xs p-2 capitalize"}>
                                        {(index === 4 || index === users.length) ? intl.formatMessage({
                                            id: "dashboard.overview.more",
                                            defaultMessage: `+{number} more`,
                                            }, {number: users.length - index}
                                        ) : user.fullName}
                                    </HoverCardContent>
                                </HoverCard>
                            )
                        }
                    })}
                </div>
            ) : <div>...</div>}
            <Modal
                button={<div className="underline cursor-pointer hover:text-slate-700">
                    <FormattedMessage
                        id={"dashboard.viewAll"}
                        defaultMessage={"View All"}
                    />
                </div>}
                header={intl.formatMessage({id: `dashboard.listOfEmployees.${name}`, defaultMessage: "List Of Employees"})}
                content={(
                    <Card className="p-4 max-h-[28rem] overflow-auto">
                        {users?.length ? users?.map((user, index) => {
                            return (
                                <div className="py-2">
                                    <RegularNameAvatar username={user.fullName} key={index}></RegularNameAvatar>
                                </div>
                            )
                        }) : (
                            <div className="py-2">
                                <FormattedMessage id={"no_results"} defaultMessage={"No results found."}/>
                            </div>
                        ) }
                    </Card>
                )}
            />
        </div>
    )
}

const backgroundColors = [
    "bg-purple-50",
    "bg-orange-50",
    "bg-blue-50",
    "bg-gray-100"
]

const HighlightComponent: FC<HighlightComponentProps> = ({
    name,
    Icon,
    iconColor,
    bgColor,
    users,
    loading
}) => {

    return (
        <div className="col-span-1 grow">
            {loading && !users ? (
                <div className={"p-4 flex flex-col space-y-4 justify-between h-full border rounded-lg"}>
                    <div className="flex flex-row items-center justify-between">
                        <div className="text-sm font-medium flex flex-col space-y-2">
                            <Skeleton className="w-16 h-4"/>
                            <Skeleton className="w-8 h-4"/>
                        </div>
                        <Skeleton className="w-8 h-8"/>
                    </div>
                    <div className="text-2xl font-bold flex flex-row justify-between items-end">
                        <Skeleton className="w-1/3 h-6"/>
                        <Skeleton className="w-16 h-4"/>
                    </div>
                </div>
            ) : (
                <div className="p-4 flex flex-col justify-between h-full border rounded-lg">
                    <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-col gap-2">
                            <div className="text-sm font-medium text-slate-500">
                                <FormattedMessage id={`dashboard.${name}`} defaultMessage={"Overview"}/>
                            </div>
                            <div className="text-3xl font-bold">{users?.length ?? 0}</div>
                        </div>
                        <div className={cn(
                        "flex items-center justify-center p-2 rounded",
                            (() => {
                                switch (bgColor) {
                                    case "blue":
                                        return backgroundColors[2];
                                    case "orange":
                                        return backgroundColors[1];
                                    case "purple":
                                        return backgroundColors[0];
                                    default:
                                        return backgroundColors[3];
                                }
                            })()
                        )}>
                            <Icon height={26} width={24} color={iconColor}/>
                        </div>
                    </div>
                    {users?.length ? <UsersList users={users} name={name}/> : null}
                </div>
            )}
        </div>
    );
};

export default HighlightComponent;
