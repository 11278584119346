import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuItem,
    DropdownMenuTrigger
} from "../../../components/dropdown-menu";
import {Button} from "../../../components/button";
import {MoreHorizontal} from "lucide-react";
import ActionsAlertDialog from "../../components/action-alert-dialog";
import React, {ReactNode, useState} from "react";
import {toast} from "../../../components/use-toast";
import ActionVerifyDialog from "../../components/action-verify-dialog";
import {useIntl} from "react-intl";
import {TaimModal} from "../taim-modal";
import {cn} from "../../../lib/utils";

export type EditAction = {
    type: "edit"
    title?: string
    content?: ReactNode
    handleAction?: Function
    dialogContentClassName?: string
}

export type ViewAction = {
    type: "view"
    title?: string
    content?: ReactNode
    dialogContentClassName?: string
}

export type DeleteAction = {
    type: "delete"
    title?: string
    content?: string
    handleAction?: Function
}

export type CustomAction = {
    type:"custom"
    isModal: boolean
    name?: string
    title?: string
    isDisabled?: boolean
    content?: string | ReactNode
    handleAction?: Function
    dialogContentClassName?: string
}

export type Action = EditAction | ViewAction | DeleteAction | CustomAction

type DataTableActionsProps = {
    actions?: Action[] | ReactNode
}

export const DataTableActions = ({actions}: DataTableActionsProps) => {
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const viewName = intl.formatMessage({id: "action.name.view", defaultMessage: "View"});
    const editName = intl.formatMessage({id: "action.name.edit", defaultMessage: "Edit"});
    const deleteName = intl.formatMessage({id: "action.name.delete", defaultMessage: "Delete"});

    const handleOpen = () => {
        setOpen(!open);
    }

    if (!Array.isArray(actions)) {
        return <>{actions}</>
    }

    return (
        <div onClick={e => e.stopPropagation()}>
            <DropdownMenu open={open} onOpenChange={handleOpen} modal={false}>
                <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="h-8 w-8 p-0">
                        <span className="sr-only">Open menu</span>
                        <MoreHorizontal className="h-4 w-4" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                    {actions && actions.map((action, index) => {
                        if (action.type) {
                            switch (action.type) {
                                case "view":
                                    return (
                                        <TaimModal
                                            header={action.title ?? viewName}
                                            button={(
                                                <DropdownMenuItem className={"cursor-pointer"} onSelect={(e) => e.preventDefault()}>{action.title ?? viewName}</DropdownMenuItem>
                                            )}
                                            dialogTriggerClassName={"w-full"}
                                            dialogContentClassName={action.dialogContentClassName}
                                        >
                                            {action?.content ? action.content : []}
                                        </TaimModal>
                                    )
                                case "edit":
                                    return (
                                        <TaimModal
                                            header={action.title ?? editName}
                                            button={(
                                                <DropdownMenuItem className={"cursor-pointer"} onSelect={(e) => e.preventDefault()}>{action.title ?? editName}</DropdownMenuItem>
                                            )}
                                            dialogTriggerClassName={"w-full"}
                                            dialogContentClassName={action.dialogContentClassName}
                                        >
                                            {action?.content ? action.content : []}
                                        </TaimModal>
                                    )
                                case "delete":
                                    return (
                                        <DropdownMenuItem onSelect={(e) => e.preventDefault()} key={index}>
                                            <ActionsAlertDialog
                                                name={deleteName}
                                                title={action?.title ?? intl.formatMessage({id: "toast.areYouSure", defaultMessage: "Are you sure ?"})}
                                                content={action?.content ?? intl.formatMessage({id: "toast.areYouSure.description", defaultMessage: "This action can not be undone."})}
                                                action={{
                                                    name: intl.formatMessage({id: "button.continue",defaultMessage: "Continue"}),
                                                    handleEvent: () => {
                                                        if (action.handleAction) {
                                                            action.handleAction()
                                                        } else toast({
                                                            title: "action Done",
                                                            description: "This action cannot be undone."
                                                        })
                                                        setOpen(false);
                                                    }
                                                }}
                                            />
                                        </DropdownMenuItem>
                                    )
                                case "custom":
                                    return action.isModal ? (
                                        action?.isDisabled ? (
                                            <DropdownMenuItem
                                                onSelect={(e) => e.preventDefault()}
                                                disabled={action.isDisabled}
                                            >{action.title ?? "action title"}</DropdownMenuItem>
                                            ) : (
                                            <TaimModal
                                                header={action?.title ?? "action title"}
                                                button={(
                                                    <DropdownMenuItem
                                                        className={cn("cursor-pointer")}
                                                        onSelect={(e) => e.preventDefault()}
                                                    >{action.title ?? "action title"}</DropdownMenuItem>
                                                )}
                                                dialogTriggerClassName={"w-full"}
                                                dialogContentClassName={action.dialogContentClassName}
                                            >
                                                {action?.content ? action.content : []}
                                            </TaimModal>
                                        )
                                    ) : (
                                        <DropdownMenuItem onSelect={(e) => e.preventDefault()} key={index}>
                                            <ActionVerifyDialog
                                                name={action?.name ?? "action name"}
                                                title={action?.title ?? intl.formatMessage({id: "toast.areYouSure", defaultMessage: "Are you sure ?"})}
                                                content={(typeof action.content === "string" && action?.content) ? action.content : intl.formatMessage({id: "toast.areYouSure.description", defaultMessage: "This action can not be undone."})}
                                                action={{
                                                    name: intl.formatMessage({id: "button.continue",defaultMessage: "Continue"}),
                                                    handleEvent: () => {
                                                        if (action.handleAction) action.handleAction()
                                                        else toast({
                                                            title: "action Done",
                                                            description: "This action cannot be undone."
                                                        })
                                                        setOpen(false);
                                                    }
                                                }}
                                            />
                                        </DropdownMenuItem>
                                    )
                                default:
                                    return null
                            }
                        }
                        return null
                    })}
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    )
}