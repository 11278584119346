import moment from "moment";


const zeroPad = (v: number = 0): string => v.toString().padStart(2,'0')

export const transformDuration = (value: string, format: 'HH:mm' | "HH" | "mm" = "HH:mm", padStart: boolean = false): { value: string, sign: "+" | "-" } => {
    let output: { value: string, sign: "+" | "-" } = {
        value: "",
        sign: "+"
    }
    let hours, minutes;
    let duration: moment.Duration;
    let isNegative = value[0] === "-"

    if (isNegative) {
        let stringWithoutSign = value.slice(1)
        let durationA = moment.duration(stringWithoutSign.split(' ')[0], 'days')
        let durationB = moment.duration(stringWithoutSign.split(' ')[1])
        duration = durationA.subtract(durationB);
        output.sign = "-"
    } else {
        duration = moment.duration(value);
    }

    hours = Math.floor(duration.asHours());
    minutes = Math.round((duration.asHours() - hours) * 60);

    hours = padStart ? zeroPad(hours) : String(hours)
    minutes = padStart ? zeroPad(minutes) : String(minutes)

    switch (format) {
        case "HH":
            output.value = isNegative ? "-" + hours : hours
            break;
        case "mm":
            output.value = minutes
            break;
        case "HH:mm":
        default:
            output.value = isNegative ? "-" + `${hours}:${minutes}` : `${hours}:${minutes}`
            break;
    }

    return output
}

export const transformHoursAndMinutesToDuration = (values: {hours: number, minutes: number}, format: "HH:mm:ss" | "HH:mm" = "HH:mm:ss"): string => {
    let hours = zeroPad(values.hours)
    let minutes = zeroPad(values.minutes)

    return `${hours}:${minutes}:00`
}

