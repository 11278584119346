import DataTableOld, {ColumnOld} from "Core/Table/data-table-old";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import DataTableTools, {Option} from "Core/Table/data-table-tools";
import DataTablePagination from "Core/Table/data-table-pagination";
import {Action} from "Core/Table/data-table-actions";
import {Badge} from "components/badge";
import statusVariants from "Core/functions/status-variants";
import {vacation, vacationsRequest, vacationsUrl} from "AttendanceManagement/Controllers/vactions-controller";
import {VacationFormatted} from "AttendanceManagement/Controllers/vactions-controller";
import moment from "moment";
import {useIntl} from "react-intl";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {toast} from "components/use-toast";
import {DataTablePayload} from "../../Core/Table/interfaces";
import {EditVacation} from "../Forms/absences";
import {cn} from "../../lib/utils";
import {vacationTypes} from "../Forms/absences/constants";
import {nullOrUndefined} from "../../Core/constants/variables";


export const statuses: Option[] = [
  {
    value: "accepted",
    label: "Accepted",
      translationName: "accepted"
  },
  {
    value: "rejected",
    label: "Rejected",
      translationName: "rejected"
  },
  {
    value: "pending",
    label: "Pending",
      translationName: "pending"
  },
]

type VacationsDataTableProps = {
    vacationsRequest: vacationsRequest
    selectedVacation: string | null
    setSelectedVacation: Dispatch<SetStateAction<string | null>>
    payload: DataTablePayload
    setPayload: React.Dispatch<SetStateAction<DataTablePayload>>
    loading: boolean
    reFetch: Dispatch<SetStateAction<boolean>>
}


const AbsencesDataTable = (
    {
        vacationsRequest,
        selectedVacation,
        setSelectedVacation,
        payload,
        setPayload,
        loading,
        reFetch
    }
: VacationsDataTableProps) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const [formattedData, setFormattedData] = useState<VacationFormatted[]>([]);
    const [detailData, setDetailData] = useState<VacationFormatted | null>(null);
    const vacationColumns: ColumnOld[] = [
        {
            backendKey: "userFullName",
            frontendKey: intl.formatMessage({id: "attendance.vacations.table.employee", defaultMessage: "Employee"}),
            sortable: true,
            isSorted: "asc"
        },
        {
            backendKey: "dateFrom",
            frontendKey: intl.formatMessage({id: "attendance.vacations.table.startDate", defaultMessage: "Start Date"}),
            sortable: true,
            isSorted: "desc"
        },
        {
            backendKey: "dateTo",
            frontendKey: intl.formatMessage({id: "attendance.vacations.table.endDate", defaultMessage: "End Date"}),
            sortable: true
        },
        {
            backendKey: "workingDays",
            frontendKey: intl.formatMessage({id: "attendance.vacations.table.workingDays", defaultMessage: "Working Days"}),
            sortable: true
        },
        {
            backendKey: "requestType",
            frontendKey: intl.formatMessage({id: "attendance.vacations.table.type", defaultMessage: "Type"}),
            sortable: true
        },
        {
            backendKey: "statusBadge",
            frontendKey: intl.formatMessage({id: "attendance.vacations.table.status", defaultMessage: "Status"}),
            sortable: true
        },
        {backendKey: "actions", frontendKey: "actions", isPublic: true},
    ]


    const handleSetDetailData = (input: VacationFormatted) => {
        if (!input) return;
        setSelectedVacation(input?.pk)
        setDetailData(input)
    }


    const deleteVacation = (id: string) => {
        axiosInstance.delete(vacationsUrl + `${id}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                setDetailData(null);
                reFetch(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    useEffect(() => {
        const formatData: VacationFormatted[] = vacationsRequest?.data.map((vacation: vacation) => {
            return {
                ...vacation,
                ...(vacation.requestType && {requestType: intl.formatMessage({id: `attendance.vacations.${vacation.requestType}`, defaultMessage: vacation.requestType})}),
                rowID: `${vacation.pk}_${vacation.dateFrom}`,
                dateFrom: moment(vacation.dateFrom).format("ddd DD MMM YYYY"),
                dateTo: moment(vacation.dateTo).format("ddd DD MMM YYYY"),
                statusBadge:
                    <Badge variant={statusVariants(vacation.status)}>{vacation.status ? intl.formatMessage({id: vacation.status.toLowerCase(), defaultMessage: vacation.status}) : nullOrUndefined}</Badge>,
                actions: [
                    {
                        type: "edit",
                        content: <EditVacation
                            vacationID={vacation.pk}
                            userUUID={vacation.user.userUUID}
                            reFetch={reFetch}
                        />,
                        dialogContentClassName: cn('min-w-[600px]')
                    },
                    {type: "delete", handleAction: () => deleteVacation(vacation.pk)}
                ] as Action[]
            };
        })

        setFormattedData(formatData);
    }, [vacationsRequest.data]);

    useEffect(() => {
        const getVacationData = () => {
            let v = formattedData.find(elem => elem.pk === selectedVacation)
            if (v) setDetailData(v)
        }

        getVacationData();
    }, [formattedData]);

    return(
        <div className="space-y-4 h-full flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools
                    payload={payload}
                    setPayload={setPayload}
                    filters={[
                        {
                            type: "simple",
                            title: intl.formatMessage({id: "data.table.filter.status", defaultMessage: "Status"}),
                            filterBy: 'filter.status',
                            options: statuses ?? [],
                        },
                        {
                            type: "simple",
                            title: intl.formatMessage({id: "data.table.filter.type", defaultMessage: "Type"}),
                            filterBy: 'filter.requestType',
                            options: vacationTypes.map(elem => ({
                                value: elem.value,
                                label: intl.formatMessage({id: `attendance.vacations.${elem.value}`})
                            })) ?? [],
                        },
                        {
                            type: "dateFromTo",
                            from: "filter.startDate",
                            to: "filter.endDate",
                        }
                    ]}
                    exportTable={{
                        fileName: `${moment().valueOf()}_absences`,
                        apiURL: vacationsUrl + 'export/',
                        payload: payload
                    }}
                />
                <DataTableOld
                    data={formattedData}
                    loading={loading}
                    setDetailData={handleSetDetailData}
                    detailData={detailData}
                    setPayload={setPayload}
                    columns={vacationColumns}
                    payload={payload}
                />
            </div>
            <DataTablePagination request={vacationsRequest} payload={payload} setPayload={setPayload}/>
        </div>
    )
}

export default AbsencesDataTable;