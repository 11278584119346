import {Activity} from "Dashboard/Controllers/Admin/daily-track-controller";
import moment from "moment";

export type FormattedActivity = {
    shift: number,
    width: number
} & Activity

const resetTimeToStartOfDay = (dateTimeString: string | null) => {
    if (!dateTimeString) return moment().startOf("day").toISOString();
    const datePart = dateTimeString.split('T')[0];
    return  datePart + 'T00:00:00';
}

const limitToCurrentTime = (activities: Activity[]) => {
    return activities.map((activity) => {
        const startDay = moment(activity.startDatetime).date();
        const endDay = moment(activity.endDatetime).date();
        if (startDay !== endDay) {
            return {
                ...activity,
                startDatetime: resetTimeToStartOfDay(activity.endDatetime)
            }
        }
        return activity
    }).map((activity) => {
        if (moment(activity.endDatetime) > moment()) {
            return {
                ...activity,
                endDatetime: moment().format('YYYY-MM-DDTHH:mm:ss')
            }
        }
        return activity
    });
}


export const transformActivities = (activities: Activity[]) : FormattedActivity[] => {
    let startOfDay = moment().startOf('day');
    let endOfDay = moment().endOf('day');

    const preventNullActivities: Activity[] = activities.filter(activity => {
        return activity.endDatetime && activity.startDatetime
    })

    const activitiesToCurrentTime = limitToCurrentTime(preventNullActivities);

    activitiesToCurrentTime.push(
        {
            type: "empty",
            startDatetime: startOfDay.format("YYYY-MM-DDTHH:mm:ss"),
            endDatetime: endOfDay.format("YYYY-MM-DDTHH:mm:ss")
        }
    )

    return activitiesToCurrentTime.map((activity) => {
        const startDatetime = moment(activity.startDatetime);
        const endDatetime = moment(activity.endDatetime);

        const shift = startDatetime.diff(startOfDay, 'minutes');
        const width = endDatetime.diff(startDatetime, 'minutes');

        return {
            ...activity,
            shift: shift,
            width: width
        }
    })
}