import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle} from "../../components/dialog";
import {WifiHigh, WifiSlash} from "@phosphor-icons/react";

const NetworkNotice = () => {
    const location = useLocation()
    const [online, setOnline] = useState<boolean>(window.navigator.onLine)
    const [open, setOpen] = useState<boolean>(false)

    const handleOnline = () => {
        setOnline(true)
        handleOpen()
        setTimeout(() => {
            setOpen(false)
        }, 3000)
    };
    const handleOffline = () => {
        setOnline(false)
        handleOpen()
    };

    const handleOpen = () => setOpen(!open)

    useEffect(() => {
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    useEffect(() => {
        if (!online) handleOffline()
    }, [location]);

    return (
        <Dialog open={open} onOpenChange={handleOpen}>
          <DialogContent onInteractOutside={(e) => e.preventDefault()}>
            <DialogHeader>
              <DialogTitle>{online ? "Network Restored" : "Network Disconnected"}</DialogTitle>
              <DialogDescription className="flex flex-row items-center p-6">
                <div className="mr-4">
                  {online ? <WifiHigh size={72}/> : <WifiSlash size={72}/>}
                </div>
                <div className="">
                  {online ?
                    <p className="text-lg">Your connection is back.</p> :
                    <p className="text-lg">Oops! Looks like you're offline.</p>
                  }
                  {!online &&
                    <p className="text-sm text-gray-600 mt-2">Please check your network settings and try again.</p>
                  }
                </div>
              </DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>
    )
}

export default NetworkNotice;