import DetailViewCard from "../../../Core/components/detail-view-card";
import React from "react";
import {useIntl} from "react-intl";
import {BalanceBoundariesTable} from "./balance_boundaries";
import {ManualCarryoverCorrectionsTable} from "./manual_carryover_corrections";
import {PaidOvertimesTable} from "./paid_overtimes";
import {WorkingTimeAccountStatusesTable} from "./working_time_account_statuses";
import {MonthSummariesTable} from "./month_summaries";

export const WORKING_TIME_ACCOUNT_URL = 'working-time-account/employees/'

const WorkingTimeAccount: React.FC<{userUUID: string}> = ({userUUID}) => {
    const intl = useIntl();
    return (
        <>
            <DetailViewCard
                header={intl.formatMessage({id: "employees.detail.working_time_account.month_summaries", defaultMessage: "Month Summaries"})}
                rows={[
                    {jsxElement: (
                        <MonthSummariesTable userUUID={userUUID}/>
                    )},
                ]}
                sizeFit={true}
            />
            <DetailViewCard
                header={intl.formatMessage({id: "employees.detail.working_time_account.working_time_account_statuses", defaultMessage: "Working Time Account Status"})}
                rows={[
                    {jsxElement: (
                        <WorkingTimeAccountStatusesTable userUUID={userUUID}/>
                    )},
                ]}
                sizeFit={true}
            />
            <DetailViewCard
                header={intl.formatMessage({id: "employees.detail.working_time_account.balance_boundaries", defaultMessage: "Balance Boundaries"})}
                rows={[
                    {jsxElement: (
                        <BalanceBoundariesTable userUUID={userUUID}/>
                    )},
                ]}
                sizeFit={true}
            />
            <DetailViewCard
                header={intl.formatMessage({id: "employees.detail.working_time_account.paid_overtimes", defaultMessage: "Paid Overtimes"})}
                rows={[
                    {jsxElement: (
                        <PaidOvertimesTable userUUID={userUUID}/>
                    )},
                ]}
                sizeFit={true}
            />
            <DetailViewCard
                header={intl.formatMessage({id: "employees.detail.working_time_account.manual_carryover_corrections", defaultMessage: "Manual Carryover Corrections"})}
                rows={[
                    {jsxElement: (
                        <ManualCarryoverCorrectionsTable userUUID={userUUID}/>
                    )},
                ]}
                sizeFit={true}
            />
        </>
    )
}

export default WorkingTimeAccount;