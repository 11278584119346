import {Table, TableBody, TableHead, TableHeader, TableRow} from "../../components/table";
import {FormattedMessage} from "react-intl";
import React, {ReactNode} from "react";

type WorkingPlanBodyProps = {
    children: ReactNode,
    additionalFields?: string[]
}

export const WorkingPlanBody: React.FC<WorkingPlanBodyProps> = ({children, additionalFields}) => {

    return (
        <div className="border rounded-md">
            <Table className="border-none">
                <TableHeader>
                    <TableRow>
                        <TableHead>
                            <FormattedMessage
                                id={"employees.forms.workingPlan.day"}
                                defaultMessage={"Day"}
                            />
                        </TableHead>
                        <TableHead>
                            <FormattedMessage
                                id={"employees.forms.workingPlan.startTime"}
                                defaultMessage={"Start Time"}
                            />
                        </TableHead>
                        <TableHead>
                            <FormattedMessage
                                id={"employees.forms.workingPlan.endTime"}
                                defaultMessage={"End Time"}
                            />
                        </TableHead>
                        {additionalFields?.length ? (
                            additionalFields.map((field) => (
                                <TableHead>
                                    <FormattedMessage
                                        id={`employees.forms.workingPlan.${field}`}
                                        defaultMessage={field}
                                    />
                                </TableHead>
                            ))
                        ) : (
                            <TableHead>
                                <FormattedMessage
                                    id={"employees.forms.workingPlan.duration"}
                                    defaultMessage={"Duration"}
                                />
                            </TableHead>
                        )}
                        <TableHead>
                            <FormattedMessage
                                id={"employees.forms.workingPlan.action"}
                                defaultMessage={"Action"}
                            />
                        </TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {children}
                </TableBody>
            </Table>
        </div>
    )
}