import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "../../components/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/form"
import { Input } from "../../components/input"
import {DialogClose, DialogFooter} from "../../components/dialog";
import React, {Dispatch, SetStateAction, useRef, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {rolesUrl} from "../Controllers/role-controller";
import {toast} from "../../components/use-toast";
import {FormattedMessage, useIntl} from "react-intl";
import ErrorMessageList from "../../Core/components/ErrorMessageList";
import {ServerErrorsType, useServerErrors} from "../../Core/functions/use-server-errors";

const formSchema = z.object({
    name: z.string({
        required_error: 'The Name field is required',
    }),
})

type FormRoleCreateProps = {
    reFetch: Dispatch<SetStateAction<boolean>>
}

export const FormRoleCreate: React.FC<FormRoleCreateProps> = ({reFetch}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();

    async function onSubmit(values: z.infer<typeof formSchema>) {
        axiosInstance.post(rolesUrl, values)
            .then((res) => {
                setApiErrors({});
                closeModal.current?.click();
                reFetch(true);
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => setApiErrors(err.response.data))
    }

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"role.form.name"} defaultMessage={"Role Name"}/>
                            </FormLabel>
                            <FormControl>
                                <Input placeholder={intl.formatMessage({id: "role.form.namePlaceHolder", defaultMessage: "Enter role name"})} {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <ErrorMessageList errors={errors.nonFieldErrors}/>
                <ErrorMessageList errors={errors.detailErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline"><FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/></Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}
