import moment from "moment";
import {TimeValue} from "react-aria";

export const parseStringTimeToObject = (time: string): TimeValue => {
    const hour = moment(time, "HH:mm").get("h")
    const minute = moment(time, "HH:mm").get("m")

    return {hour, minute} as TimeValue
}

export const parseObjectTimeToString = (time: TimeValue | {hour: number, minute: number}): string => {
    return moment({h: time.hour, m: time.minute}).format("HH:mm")
}