import {FormattedMessage, useIntl} from "react-intl";
import {UserDashboardData} from "../../../Controllers/User/dashboard-controller";
import {Button} from "../../../../components/button";
import {nullOrUndefined} from "../../../../Core/constants/variables";
import {useAxiosInstance} from "../../../../Core/utilities/AxiosInstance";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Skeleton} from "../../../../components/skeleton";
import {TaimModal} from "../../../../Core/components/taim-modal";
import SearchInput from "../../../../Core/components/search-input";
import {DialogClose, DialogFooter} from "../../../../components/dialog";
import {Label} from "../../../../components/label";
import {toast} from "../../../../components/use-toast";
import {isMobile} from "../../../../Core/functions/is-mobile";

type Location = {
    uuid: string
    name: string
    fullAddress: string
}

type UserCheckInCheckOutProps = {
    dashboardData?: UserDashboardData
    loadingDashboardData: boolean
    setReloadDashboardData: Dispatch<SetStateAction<boolean>>
    setReloadDashboard: Dispatch<SetStateAction<boolean>>
}

const bc = new BroadcastChannel("stateChange");

export const UserCheckInCheckOut: React.FC<UserCheckInCheckOutProps> = (
{
    dashboardData,
    loadingDashboardData,
    setReloadDashboardData,
    setReloadDashboard
}
) => {
    const intl = useIntl()
    const axiosInstance = useAxiosInstance()
    const [attendanceLoading, setAttendanceLoading] = useState<boolean>(false)
    const [locations, setLocations] = useState<Location[]>([])
    const [location, setLocation] = useState<string | null>()
    const closeModal = useRef<HTMLButtonElement>(null)
    const userUUID = localStorage.getItem('userUUID')

    const fetchLocations = () => {
        axiosInstance.get("attendance-management/locations/")
            .then((res) => setLocations(res.data))
            .catch((err) => console.log(err))
    }

    const handleAttendance = () => {
        if (isMobile()) {
            toast({
                title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                description: intl.formatMessage({id: "toast.error.mobileNotAllowed", defaultMessage: "A check-in via mobile phone is not possible. Please use alternative methods."}),
                variant: 'destructive'
            })
            return;
        }

        setAttendanceLoading(true);
        axiosInstance.post(`dashboard/employees/${userUUID}/check-in-or-check-out/`, {
                locationUUID: location
            })
            .then((res) => {
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
                setReloadDashboard(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.errorOccured", defaultMessage: err.response?.detail ?? "An error occurred. Please try again later."})
                });
            })
            .finally(() => {
                setAttendanceLoading(false)
                bc.postMessage("State changed!")
            })
    }

    useEffect(() => {
        fetchLocations()
    }, [])

    useEffect(() => {
        bc.addEventListener("message", e => {
            setReloadDashboardData(true)
        })
    }, [])

    return (
        <div className="col-span-2 flex flex-col gap-2 h-52">
            <span className="font-semibold text-xl">
                <FormattedMessage id={"dashboard.attendance"} defaultMessage={"Attendance"}/>
            </span>
            <div className={"grow flex flex-col items-center justify-between p-4 border rounded-lg"}>
                <span className={'text-gray-500 text-lg'}>
                    <FormattedMessage id={"dashboard.balance"} defaultMessage={"Balance"}/>
                </span>
                <span className={'text-xl font-bold'}>
                    {loadingDashboardData && !dashboardData?.overtime ? (
                        <div className={"flex flex-row space-x-4"}>
                            <Skeleton className="h-6 w-12"/>
                            <Skeleton className="h-6 w-16"/>
                        </div>
                    ) : dashboardData ? dashboardData.overtime : nullOrUndefined}
                </span>
                {dashboardData && !loadingDashboardData && !attendanceLoading ? (
                    <TaimModal
                        header={intl.formatMessage({id: "dashboard.attendance", defaultMessage: "Attendance"})}
                        button={(
                            <Button
                                className={"w-full"}
                                variant={dashboardData.checkInState ? "destructive" : !dashboardData.checkInState ? "success" : "secondary"}
                            >{dashboardData.checkInState ? "Check Out" : !dashboardData.checkInState ? "Check In" : null}</Button>
                        )}
                        dialogTriggerClassName={"w-full"}
                    >
                        <div className="flex flex-col gap-4">
                            <Label><FormattedMessage id={"dashboard.location"} defaultMessage={"Location"}/></Label>
                            <SearchInput
                                title={intl.formatMessage({id: "dashboard.location.pickLocation", defaultMessage: "Pick a location"})}
                                value={location}
                                values={locations.map((location) => ({
                                    key: location.name,
                                    value: location.uuid
                                })) ?? []}
                                onChange={setLocation}
                            />
                            <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                                <DialogClose ref={closeModal} asChild>
                                    <Button type="button" variant="outline">
                                        <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                                    </Button>
                                </DialogClose>
                                <Button
                                    variant="taimDefault"
                                    type="submit"
                                    onClick={handleAttendance}
                                ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                            </DialogFooter>
                        </div>
                    </TaimModal>
                ) : (
                    <Button variant={"secondary"} className={"w-full"} disabled={true} />
                )}
            </div>
        </div>
    )
}