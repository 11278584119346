import React from "react";
import {FormattedMessage, FormattedPlural, useIntl} from "react-intl";
import {ProjectFormatted} from "../../DataTables/projects-table";
import {Avatar, AvatarFallback, AvatarImage} from "../../../components/avatar";
import {firstLetterFromEachWord} from "../../../Core/functions/first-letter-from-each-word";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../../../components/tab";
import DetailViewCard from "../../../Core/components/detail-view-card";
import RegularNameAvatar from "../../../Core/components/regular-name-avatar";
import {nullOrUndefined} from "../../../Core/constants/variables";
import {ServiceDetail} from "./service-details";
import {ActivityDetails} from "./activity-details";

type ProjectDetailProps = {
    project: ProjectFormatted | null
    loading: boolean
}
export const ProjectDetail: React.FC<ProjectDetailProps> = (
    {project, loading}
) => {
    const intl = useIntl();

    const Content = () => (
        <>
            {project ? (
                <div className="flex flex-col gap-6">
                    <div className="flex items-center justify-between space-x-4">
                        <div className="flex items-center space-x-4">
                            <Avatar>
                                <AvatarImage src="/avatars/01.png"/>
                                <AvatarFallback>{firstLetterFromEachWord(project.name)}</AvatarFallback>
                            </Avatar>
                            <div>
                                <div className="capitalize font-semibold">{project.name}</div>
                                {project.statusBadge}
                            </div>
                        </div>
                    </div>
                    <Tabs defaultValue={"Information"} className="w-full">
                        <TabsList>
                            <TabsTrigger value={"Information"}>
                                <FormattedMessage id={"project.projects.information"}/>
                            </TabsTrigger>
                            <TabsTrigger value={"Services"}>
                                <FormattedMessage id={"project.projects.services"}/>
                            </TabsTrigger>
                            <TabsTrigger value={"Activities"}>
                                <FormattedMessage id={"project.projects.activities"} defaultMessage={"Activities"}/>
                            </TabsTrigger>
                            <TabsTrigger value={"Employees"}>
                                <FormattedMessage id={"project.projects.employees"}/>
                            </TabsTrigger>
                        </TabsList>
                        <TabsContent value={"Information"}>
                            <DetailViewCard rows={[
                                {
                                    name: intl.formatMessage({id: "project.projects.startDate", defaultMessage: "Start Date"}),
                                    value: project.startDate ? project.startDate : nullOrUndefined
                                },
                                {
                                    name: intl.formatMessage({id: "project.projects.endDate", defaultMessage: "End Date"}),
                                    value: project.endDate ? project.endDate : nullOrUndefined
                                },
                                {
                                    name: intl.formatMessage({id: "project.projects.employees", defaultMessage: "Employees"}),
                                    value: project.employeesCount
                                },
                            ]}/>
                        </TabsContent>
                        <TabsContent value={"Services"}>
                            <DetailViewCard
                                sizeFit={true}
                                rows={
                                    project.services.length ? (
                                        project.services.map((service) => {
                                            return {
                                                jsxElement: (
                                                    <div className="flex justify-between items-center py-2 border-b">
                                                        <div className="flex flex-col gap-2">
                                                            <div>{service.name}</div>
                                                            <div className="text-gray-500 capitalize">
                                                                {service.employeesCount ? (<>
                                                                    {service.employeesCount + " "}
                                                                    <FormattedPlural
                                                                        value={service.employeesCount}
                                                                        one={intl.formatMessage({id: "project.employee", defaultMessage: "Employee"})}
                                                                        other={intl.formatMessage({id: "project.employees", defaultMessage: "Employees"})}
                                                                    />
                                                                </>) : <FormattedMessage id="no_results" defaultMessage={"No results found."}/>}
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col gap-2 items-end">
                                                            <ServiceDetail service={service}/>
                                                            <div className="text-gray-500 capitalize">{service.employeesSpentTime}</div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    ) : (
                                        [{jsxElement: <FormattedMessage id={"no_results"} defaultMessage={"No results"}/>}]
                                    )
                                }
                                isScrollable={true}
                            />
                        </TabsContent>
                        <TabsContent value={"Activities"}>
                            <ActivityDetails id={project.pk}/>
                        </TabsContent>
                        <TabsContent value={"Employees"}>
                            <DetailViewCard
                                rows={
                                    project.employees.length ? (
                                        project.employees.map((employee) => {
                                            return {
                                                jsxElement: (
                                                    <div className="flex justify-between items-center">
                                                        <RegularNameAvatar username={employee.fullName}/>
                                                        <div className="text-gray-500 capitalize w-1/2">{employee.roleName || nullOrUndefined}</div>
                                                    </div>
                                                )
                                            }
                                        })
                                    ) : (
                                        [{jsxElement: <FormattedMessage id={"no_results"} defaultMessage={"No results"}/>}]
                                    )
                                }
                                isScrollable={true}
                            />
                        </TabsContent>
                    </Tabs>
                </div>
            ) : (
                <FormattedMessage
                    id={"pick_a_record"}
                    defaultMessage={"Select a record to view details."}
                />
            )}
        </>
    )


    return <Content/>
}