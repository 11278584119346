import DataTableOld, {ColumnOld} from "../../Core/Table/data-table-old";
import React, {SetStateAction, useEffect, useState} from "react";
import DataTableTools from "../../Core/Table/data-table-tools";
import DataTablePagination from "../../Core/Table/data-table-pagination";
import {Action} from "../../Core/Table/data-table-actions";
import {employmentTypesRequest, employmentTypeUrl} from "../Controllers/employment-type-controller";
import {employmentType} from "../Controllers/employment-type-controller";
import {FormEmploymentTypeEdit} from "../Forms/form-employment-type-edit";
import EmploymentTypesDetailView from "../DetailViews/employment-types-detail-view";
import {useIntl} from "react-intl";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {toast} from "../../components/use-toast";
import {DataTablePayload} from "../../Core/Table/interfaces";
import moment from "moment";


type FormattedEmploymentType = {
    actions: Action[]
} & employmentType

type employmentTypesDataTableProps = {
    employmentTypesRequest: employmentTypesRequest
    payload: DataTablePayload
    setPayload: React.Dispatch<SetStateAction<DataTablePayload>>
    loading: boolean
    reFetch: React.Dispatch<React.SetStateAction<boolean>>
}


const EmploymentTypesDataTable = (
    {
        employmentTypesRequest,
        payload,
        setPayload,
        loading,
        reFetch
    }: employmentTypesDataTableProps) => {
    const intl = useIntl()
    const axiosInstance = useAxiosInstance()
    const [formattedData, setFormattedData] = useState<FormattedEmploymentType[]>([])
    const Columns: ColumnOld[] = [
        {
            backendKey: "type",
            frontendKey: intl.formatMessage({
                id: "employmentType.table.type",
                defaultMessage: "Type"
            }),
            sortable: true
        },
        {
            backendKey: "weeklyWorkHoursString",
            frontendKey: intl.formatMessage({
                id: "employmentType.table.weeklyWorkHours",
                defaultMessage: "Work Hours Per Week"
            }),
        },
        {
            backendKey: "monthlyWorkHoursString",
            frontendKey: intl.formatMessage({
                id: "employmentType.table.monthlyWorkHours",
                defaultMessage: "Work Hours Per Month"
            }),
            sortingValue: "monthlyWorkHours",
            sortable: true
        },
        {
            backendKey: "totalEmployees",
            frontendKey: intl.formatMessage({
                id: "employmentType.table.totalEmployees",
                defaultMessage: "Total Employees"
            }),
            sortable: true
        },
        {
            backendKey: "actions",
            frontendKey: "actions"
        },
    ]

    const deleteEmploymentType = (employmentType: employmentType) => {
        axiosInstance.delete(employmentTypeUrl + `${employmentType.pk}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                reFetch(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    const rePopulateRecord = (pk: string) => {
        axiosInstance.get(employmentTypeUrl + `${pk}/`)
            .then((res) => {
                setFormattedData(prevState => prevState.map(type => {
                    if (type.pk === pk) {
                        return {
                            ...type,
                            ...res.data
                        }
                    }
                    return type;
                }))
            })
    }


    useEffect(() => {

        let formatData: FormattedEmploymentType[] = employmentTypesRequest?.data.map((employmentType: employmentType) => {
            return {
                ...employmentType,
                weeklyWorkHoursString: (employmentType.weeklyWorkHours.toString() +
                    " " +
                    (employmentType.weeklyWorkHours === 1 ?
                        intl.formatMessage({id:"hour", defaultMessage: "Hour"}) :
                        intl.formatMessage({id:"hours", defaultMessage: "Hours"})
                    )
                ),
                monthlyWorkHoursString: (employmentType.monthlyWorkHours.toString() +
                    " " +
                    (employmentType.monthlyWorkHours === 1 ?
                        intl.formatMessage({id:"hour", defaultMessage: "Hour"}) :
                        intl.formatMessage({id:"hours", defaultMessage: "Hours"})
                    )
                ),
                actions: [
                    {
                        type: "edit",
                        content: <FormEmploymentTypeEdit employmentType={employmentType} rePopulateRecord={rePopulateRecord}/>
                    },
                    {
                        type: "view",
                        content: <EmploymentTypesDetailView employmentType={employmentType}/>
                    },
                    {type: "delete", handleAction: () => deleteEmploymentType(employmentType)},
                ] as Action[]
            };
        })

        setFormattedData(formatData);
    }, [employmentTypesRequest.data]);

    return(
        <div className="space-y-4 h-full flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools payload={payload} setPayload={setPayload}/>
                <DataTableOld
                    data={formattedData}
                    loading={loading}
                    detailData={formattedData}
                    payload={payload}
                    setPayload={setPayload}
                    columns={Columns}
                />
            </div>
            <DataTablePagination request={employmentTypesRequest} payload={payload} setPayload={setPayload}/>
        </div>
    )
}

export default EmploymentTypesDataTable;