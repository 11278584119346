import {useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import {DataTablePayload, DataTableRequest} from "../../Core/Table/interfaces";
export const companyGuidelinesUrl: string = 'employee-management/vacation-rules/';

export type TVacationRule = {
    pk: string
    startingYear: string
    expiryDay: string
    expiryMonth: string
}

export interface CompanyGuidelinesRequest extends DataTableRequest{
    data: TVacationRule[]
}

export const useFetchCompanyGuidelines = (payload: DataTablePayload) => {
    const axiosInstance = useAxiosInstance();
    const [shouldReFetch, reFetch] = useState(false)
    const [dataRequest, setData] = useState<CompanyGuidelinesRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const permissionContext = useContext(PermissionContext);

    const fetchEmployeesData = () => {
        setLoading(true);
        axiosInstance.get(companyGuidelinesUrl,{
            params: payload
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                reFetch(false);
            })
    }

    useEffect( () => {
        fetchEmployeesData();
    }, [payload, shouldReFetch, permissionContext.isAdmin])

    return { dataRequest, loading, error, reFetch };
}