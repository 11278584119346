import TimesheetEmployeeDetailsDataTable from "../../DataTables/timesheet-employee-details-data-table";
import TimesheetDailyTrackDetailsDataTable from "../../DataTables/timesheet-daily-track-details-data-table";
import React, {SetStateAction} from "react";
import {
    TimesheetEmployeesPayload, useFetchTimesheetDailyTrackDetailsDataTable,
    useFetchTimesheetEmployeeDetailsDataTable
} from "../../Controllers/timesheet-controller";
import {useIntl} from "react-intl";

type DetailedProps = {
    viewType: string
    year: number
    month: number
    day: number | null
    setDay: React.Dispatch<SetStateAction<number | null>>
    employeesDetailsPayload: TimesheetEmployeesPayload
    setEmployeesDetailsPayload: React.Dispatch<React.SetStateAction<TimesheetEmployeesPayload>>
    dailyTracksDetailsPayload: TimesheetEmployeesPayload
    setDailyTracksDetailsPayload: React.Dispatch<React.SetStateAction<TimesheetEmployeesPayload>>
    monthsLoading: boolean
}

export const Detailed: React.FC<DetailedProps> = (
{
    viewType,
    year,
    month,
    day,
    setDay,
    employeesDetailsPayload,
    setEmployeesDetailsPayload,
    dailyTracksDetailsPayload,
    setDailyTracksDetailsPayload,
    monthsLoading
}) => {
    const intl = useIntl();
    const MONTHLY_TRACK = intl.formatMessage({id: "timesheetManagement.master.monthlyTrack", defaultMessage: "Month View"});
     const {
        timesheetEmployeeDetailsRequest,
        timesheetEmployeeDetailsLoading
    } = useFetchTimesheetEmployeeDetailsDataTable(employeesDetailsPayload, month, year);
    const {
        timesheetDailyTrackDetailsRequest,
        timesheetDailyTrackDetailsLoading,
    } = useFetchTimesheetDailyTrackDetailsDataTable(dailyTracksDetailsPayload, month, year, day);

    return (
        <>
            {viewType === MONTHLY_TRACK ? (
                <TimesheetEmployeeDetailsDataTable
                    timesheetEmployeesRequest={timesheetEmployeeDetailsRequest}
                    payload={employeesDetailsPayload}
                    setPayload={setEmployeesDetailsPayload}
                    loading={timesheetEmployeeDetailsLoading}
                    year={year}
                    month={month}
                    isLoading={monthsLoading}
                />
            ) : (
                <TimesheetDailyTrackDetailsDataTable
                    timesheetDailyTracksRequest={timesheetDailyTrackDetailsRequest}
                    payload={dailyTracksDetailsPayload}
                    year={year}
                    month={month}
                    setDay={setDay}
                    day={day}
                    isLoading={monthsLoading}
                    setPayload={setDailyTracksDetailsPayload}
                    loading={timesheetDailyTrackDetailsLoading}
                />
            )}
        </>
    )
}