import * as React from "react"
import {SetStateAction, useEffect} from "react";
import {DatePickerWithRange} from "../../components/date-picker-with-range";
import {DateRange} from "react-day-picker";
import formatDate from "../functions/format-date";
import {Button} from "../../components/button";
import {Cross2Icon} from "@radix-ui/react-icons";
import {DateFilter, DateFromToFilter} from "./data-table-tools";
import {DatePicker} from "../../components/date-picker";
import {FormattedMessage} from "react-intl";
import {DataTablePayload} from "./interfaces";


interface DataTableDateFilterProps {
    filter: DateFilter | DateFromToFilter
    payload: DataTablePayload
    setPayload: React.Dispatch<SetStateAction<DataTablePayload>>
}

export function DataTableDateFilter({
    filter,
    payload,
    setPayload
}: DataTableDateFilterProps) {
    const [dateRange, setDateRange] = React.useState<DateRange | undefined>();
    const [date, setDate] = React.useState<Date | undefined>();

    useEffect(() => {
        if (setPayload) {
            if (dateRange && filter.type === "dateFromTo") {
                setPayload((prevState: any) => {
                    return {
                        ...prevState,
                        start: 0,
                        [filter.from]: dateRange?.from ? formatDate(dateRange?.from) : "",
                        [filter.to]: dateRange?.to ? formatDate(dateRange?.to) : "",
                    }
                })
            } else if (dateRange === undefined && filter.type === "dateFromTo") {
                setPayload((prevState: any) => {
                    return {
                        ...prevState,
                        start: 0,
                        [filter.from]: "",
                        [filter.to]: "",
                    }
                })
            }
        }
    }, [dateRange]);

    useEffect(() => {
        if (setPayload && filter.type === "date") {
            if (date) {
                setPayload((prevState: any) => {
                    return {
                        ...prevState,
                        start: 0,
                        [filter.date]: formatDate(date as Date),
                    }
                })
            }
            else if (date === undefined && !filter.defaultValue) {
                setPayload((prevState: any) => {
                    return {
                        ...prevState,
                        start: 0,
                        [filter.date]: filter?.defaultValue ? formatDate(filter?.defaultValue) : "",
                    }
                })
            }
        }
    }, [date]);

    useEffect(() => {
        if (filter.type === "date") {
            const payloadValue = payload[filter.date]

            if (payloadValue) {
                setDate(new Date(payloadValue))
            } else if (filter.defaultValue) {
                setDate(filter.defaultValue)
            }
        } else if (filter.type === "dateFromTo") {
            const payloadValueFrom = payload[filter.from]
            const payloadValueTo = payload[filter.to]

            if (payloadValueFrom && payloadValueTo) {
                setDateRange({
                    from: new Date(payloadValueFrom),
                    to: new Date(payloadValueTo)
                })
            }
        }
    }, []);

    return (
        <>
            {(filter && filter.type === "dateFromTo") ? (
                <>
                    <DatePickerWithRange date={dateRange} setDate={setDateRange} disabled={filter.disabled}/>
                    {dateRange && (
                        <Button
                            variant="ghost"
                            onClick={() => setDateRange((prevState) => undefined)}
                            className="h-8 text-xs px-2 lg:px-3"
                        >
                            <FormattedMessage id={"reset"} defaultMessage={"Reset"}/>
                            <Cross2Icon className="ml-2 h-4 w-4" />
                        </Button>
                    )}
                </>
            ) : (
                <>
                    <DatePicker date={date} setDate={setDate} disabled={filter.disabled}/>
                    {(date && filter?.defaultValue && formatDate(date) !== formatDate(filter?.defaultValue as Date)) ? (
                        <Button
                            variant="ghost"
                            onClick={() => setDate(filter?.defaultValue)}
                            className="h-8 text-xs px-2 lg:px-3"
                        >
                            <FormattedMessage id={"reset"} defaultMessage={"Reset"}/>
                            <Cross2Icon className="ml-2 h-4 w-4" />
                        </Button>
                    ) : (date && !filter?.defaultValue) ? (
                        <Button
                            variant="ghost"
                            onClick={() => setDate((prevState) => undefined)}
                            className="h-8 text-xs px-2 lg:px-3"
                        >
                            <FormattedMessage id={"reset"} defaultMessage={"Reset"}/>
                            <Cross2Icon className="ml-2 h-4 w-4" />
                        </Button>
                    ) : null}
                </>
            )}
        </>
    )
}


