import {Card, CardContent, CardHeader, CardTitle} from "components/card";
import DailyTrackDataTable from "Dashboard/DataTables/daily-track-data-table";
import {DailyTrackPayload, DailyTrackRequest} from "Dashboard/Controllers/Admin/daily-track-controller";
import React, {Dispatch, SetStateAction} from "react";
import {FormattedMessage} from "react-intl";

type DailyTrackProps = {
    payload: DailyTrackPayload,
    setPayload: Dispatch<SetStateAction<DailyTrackPayload>>,
    request: DailyTrackRequest
    loading: boolean
}

const DailyTrack: React.FC<DailyTrackProps> = (
    {
        payload,
        setPayload,
        request,
        loading
    }
) => {

    return(
        <Card className="lg:col-span-2 xl:col-span-5 h-fit">
            <CardHeader>
               <CardTitle className="capitalize 2xl:text-[24px] xl:text-[20px]">
                   <FormattedMessage id="dashboard.dailyTrack.title" defaultMessage={"Daily Track"}/>
               </CardTitle>
            </CardHeader>
            <CardContent className="px-4">
                <DailyTrackDataTable
                    request={request}
                    payload={payload}
                    setPayload={setPayload}
                    loading={loading}
                />
            </CardContent>
        </Card>
    )
}

export default DailyTrack;