import Overview from "./overview";
import AbsencesSection from "./absences";
import {useFetchEmployeesAbsences} from "../../Controllers/Admin/employees-absences-controller";
import React, {Dispatch, SetStateAction, useState} from "react";
import {DailyTrackPayload, useFetchDashboardDailyTrack} from "../../Controllers/Admin/daily-track-controller";
import DailyTrack from "../../Components/daily-track";
import ProjectSection from "./project";
import {Button} from "../../../components/button";
import moment from "moment";
import {FormattedMessage} from "react-intl";

type AdminDashboardProps = {
    reloadDashboard: boolean
    setReloadDashboard: Dispatch<SetStateAction<boolean>>
    lastLoaded: moment.Moment
}

const AdminDashboard: React.FC<AdminDashboardProps> = ({lastLoaded, reloadDashboard, setReloadDashboard}) => {
    const {
        absences,
        loadingAbsences,
    } = useFetchEmployeesAbsences(reloadDashboard);
    const [payload, setPayload] = useState<DailyTrackPayload>({
        start: 0,
        length: 10,
        search: "",
        ordering: "",
        "filter.status": ""
    });
    const {
        dailyTrack,
        loadingDailyTrack,
    } = useFetchDashboardDailyTrack(payload, reloadDashboard);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between items-center">
                <span className="text-[#1b2436] text-[2rem] font-semibold leading-2">Dashboard</span>
                <div className="flex flex-col items-end space-y-1">
                    <Button variant={"success"} className={"w-fit"} onClick={() => setReloadDashboard(true)}>
                        <FormattedMessage id={"dashboard.reload"} defaultMessage={"Reload"}/>
                    </Button>
                    <span className="text-gray-500 text-xs">
                        <FormattedMessage id={"dashboard.reloadSentence"} defaultMessage={"Last updated on {time}"} values={{
                            time: lastLoaded.format('HH:mm')
                        }}/>
                    </span>
                </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 divide-y-2 lg:divide-x-2 lg:divide-y-0 gap-2 h-fit">
                <Overview
                    reloadDashboard={reloadDashboard}
                    setReloadDashboard={setReloadDashboard}
                />
                <ProjectSection reloadDashboard={reloadDashboard} setReloadDashboard={setReloadDashboard}/>
            </div>
            <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-7">
                <DailyTrack
                    payload={payload}
                    setPayload={setPayload}
                    request={dailyTrack}
                    loading={loadingDailyTrack}
                />
                <AbsencesSection absences={absences} loading={loadingAbsences}/>
            </div>
        </div>
    )
}

export default AdminDashboard