import {useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {DataTablePayload, DataTableRequest} from "Core/Table/interfaces";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import {devicesUrl} from "../index";


export type Device = {
    pk: string
    name: string
    deviceType: string
    ipv4Address: string
    ipv4LastUpdated: string
    ipv6Address: string
    ipv6LastUpdated: string
}

const DevicesInitialState: DataTableRequest = {
    recordsTotal: 0,
    recordsFiltered: 0,
    next: "",
    previous: "",
    data: []
}

export const useFetchDevices = (payload: DataTablePayload) => {
    const [
        devicesData,
        setData
    ] = useState<DataTableRequest>(DevicesInitialState);
    const [loadingDevices, setLoading] = useState(false);
    const [refetch, shouldRefetchDevices] = useState(false);
    const [employeesErrors, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchData = () => {
        setLoading(true);
        axiosInstance.get(devicesUrl,{
            params: payload
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                shouldRefetchDevices(false);
            })
    }

    useEffect( () => {
        fetchData();
    }, [payload, refetch, permissionContext.isAdmin])

    return {devicesData, loadingDevices, employeesErrors, shouldRefetchDevices}
}
