import moment from "moment";
import {WorkingPlanDurationSchemaType, WorkingPlanSchemaType} from "./interfaces";

const INITIAL_DURATION_STRING = "00h 00m"

export const WORKING_PLAN_SCHEMA: WorkingPlanSchemaType[] = [
    {
        day: 1,
        dayName: "Monday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 2,
        dayName: "Tuesday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 3,
        dayName: "Wednesday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 4,
        dayName: "Thursday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 5,
        dayName: "Friday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 6,
        dayName: "Saturday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 7,
        dayName: "Sunday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    }
]

export const workingPlanDurationSchema: WorkingPlanDurationSchemaType[] = [
    {
        day: 1,
        duration: moment.duration(0),
        formattedDuration: "0",
        totalDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            breakDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            workingDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
    },
    {
        day: 2,
        duration: moment.duration(0),
        formattedDuration: "0",
        totalDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            breakDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            workingDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
    },
    {
        day: 3,
        duration: moment.duration(0),
        formattedDuration: "0",
        totalDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            breakDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            workingDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
    },
    {
        day: 4,
        duration: moment.duration(0),
        formattedDuration: "0",
        totalDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            breakDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            workingDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
    },
    {
        day: 5,
        duration: moment.duration(0),
        formattedDuration: "0",
        totalDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            breakDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            workingDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
    },
    {
        day: 6,
        duration: moment.duration(0),
        formattedDuration: "0",
        totalDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            breakDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            workingDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
    },
    {
        day: 7,
        duration: moment.duration(0),
        formattedDuration: "0",
        totalDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            breakDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            workingDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
    }
]