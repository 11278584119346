import {Link, useNavigate} from "react-router-dom";
import {UserLogInForm} from "./Forms/user-log-in-form";
import taim from "../assets/images/taim_login_logo_white_2.png";
import React, {useEffect} from "react";

export const metadata: any = {
  title: "Authentication",
  description: "Authentication",
}

export default function AuthenticationPage() {
    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            navigate("/");
        }
    }, [token]);

  return (
    <>

    <div className="container relative h-[100vh] flex-col items-center justify-center grid max-w-none lg:grid-cols-2 lg:px-0 sm:grid-cols-1 xs:hidden">
        <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
          <div className="absolute inset-0 bg-slate-900 flex justify-center items-center">
            <img className="w-1/3" alt="" src={taim}/>
          </div>
          <div className="relative z-20 mt-auto">
            <blockquote className="space-y-2">
              <p className="text-lg">
                &ldquo;Say goodbye to inefficient time tracking methods. Our app provides you with a reliable and accurate solution for your time tracking needs.&rdquo;
              </p>
            </blockquote>
          </div>
        </div>
        <div className="flex flex-col h-screen">
          <div className="lg:p-8 flex-grow flex items-center">
            <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
              <div className="flex flex-col space-y-2 text-center">
                <h1 className="text-2xl font-semibold tracking-tight">
                  Login To Taim
                </h1>
              </div>
              <UserLogInForm/>
            </div>
          </div>
          <div className="py-4 px-8 text-sm text-muted-foreground flex flex-row gap-4 justify-center">
            <Link
              to="/terms"
              className="underline underline-offset-4 hover:text-primary"
            >
              Imprint
            </Link>
            <Link
              to="/privacy"
              className="underline underline-offset-4 hover:text-primary"
            >
              AGB & Privacy
            </Link>
            <Link
              to="/privacy"
              className="underline underline-offset-4 hover:text-primary"
            >
              Support
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}