import React, {useContext, useEffect, useReducer, useState} from "react";
import moment from "moment";
import {shiftReducer, TShiftPlan} from "../HelperFunctions/shift_reducer";
import {Button} from "../../components/button";
import {cn} from "../../lib/utils";
import {
    SHIFTS_URL,
    TShiftFormatted,
    useShifts,
    useWeeklyShifts
} from "../Controller/shifts_controller";
import {CreateShift, EditShift} from "../Forms/shift_forms";
import {THEME_COLORS} from "../../Core/constants/enum";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "../../components/dropdown-menu";
import {MoreHorizontal} from "lucide-react";
import {
    Dialog, DialogClose,
    DialogContent,
    DialogDescription, DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from "../../components/dialog";
import {FormattedMessage, useIntl} from "react-intl";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {toast} from "../../components/use-toast";
import {TaimModal} from "../../Core/components/taim-modal";
import {UsersList} from "../Components/users_list";
import {Trash, Users} from "@phosphor-icons/react";
import {Employee, employeesUrl} from "../../EmployeeManagement/Controllers/employee-controller";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/select";
import {ManageShiftEmployees} from "../Forms/manage_employees_form";

const weeksInYear = (year: number) => moment({year}).weeksInYear()

const shiftsInitialState: TShiftPlan = {
    year: moment().year(),
    week: moment().week(),
    open: false,
    columnsLength: 5
}

type ShiftsTableProps = {}

export const ShiftsTable: React.FC<ShiftsTableProps> = (
    {
    }) => {
    const axiosInstance = useAxiosInstance();
    const intl = useIntl();
    const permissionContext = useContext(PermissionContext)
    const [shiftPlan, dispatch] = useReducer(shiftReducer, shiftsInitialState)
    const {
        weeklyShift,
        startWeeklyShift,
        publishWeeklyShift,
    } = useWeeklyShifts(shiftPlan.year, shiftPlan.week)
    const {
        shifts,
        handleRefetchShifts
    } = useShifts(weeklyShift[0]?.pk)
    const weekDays = moment.weekdays(true);
    const [editOpen, setEditOpen] = useState(false);
    const [shift, setShift] = useState<TShiftFormatted>()
    const [employees, setEmployees] = useState<Employee[]>([])

    const handleEditOpen = () => {
        if (editOpen) {
            handleRefetchShifts();
        }
        setEditOpen(!editOpen)
    }

    const handleDelete = (pk: number) => {
        axiosInstance.delete(SHIFTS_URL + `${shiftPlan.weeklyShift?.pk}/shifts/${pk}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                handleRefetchShifts();
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    const fetchEmployees = () => {
        axiosInstance.get(employeesUrl)
            .then((res) => {
                setEmployees(res.data)
            })
            .catch((err) => err)
    }

    useEffect(() => {
        fetchEmployees()
    }, []);

    useEffect(() => {
        if (weeklyShift.length) {
            dispatch({type: "START_STOP", payload: true})
            dispatch({type: "SET_WEEKLY_SHIFT", payload: weeklyShift[0].pk})
        } else {
            dispatch({type: "START_STOP", payload: false})
            dispatch({type: "REMOVE_WEEKLY_SHIFTS"})
        }
    }, [weeklyShift]);

    useEffect(() => {
        dispatch({type: "SET_SHIFTS", payload: shifts})
    }, [shifts]);

    return (
        <div className="flex flex-col space-y-4 mt-6">
            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row space-x-4">
                    <div className="flex flex-row space-x-2 items-center w-32">
                        <div>
                            <FormattedMessage id={"year"} defaultMessage={"Year"}/>
                        </div>
                        <Select
                            value={String(shiftPlan.year)}
                            onValueChange={(v) => dispatch({type: 'SET_YEAR', payload: Number(v)})}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder={"pick a week"}/>
                            </SelectTrigger>
                            <SelectContent>
                                {Array.from({length: 5}).map((_, index) => {
                                    let year = moment().add(1, 'y').year() - index
                                    return <SelectItem value={String(year)}>{year}</SelectItem>
                                })}
                            </SelectContent>
                        </Select>
                    </div>
                    <div className="flex flex-row space-x-2 items-center w-72">
                        <div>
                            <FormattedMessage id={"week"} defaultMessage={"Week"}/>
                        </div>
                        <Select
                            value={String(shiftPlan.week)}
                            onValueChange={(v) => dispatch({type: 'SET_WEEK', payload: Number(v)})}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder={"pick a week"}/>
                            </SelectTrigger>
                            <SelectContent className="w-[300px]">
                                {Array.from({length: weeksInYear(shiftPlan.year)}).map((_,index) => (
                                    <SelectItem value={String(index + 1)}>{`${intl.formatMessage({id: 'week', defaultMessage: 'Week'})} ${index + 1} : ${moment().set({
                                        year: shiftPlan.year,
                                        week: index + 1
                                    }).startOf('week').format('DD')} - ${moment().set({
                                        year: shiftPlan.year,
                                        week: index + 1
                                    }).endOf('week').format('DD')} ${moment().set({
                                        year: shiftPlan.year,
                                        week: index + 1
                                    }).format('MMMM')}`}</SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                {permissionContext.isAdmin && (
                    <div className="flex flex-row space-x-4">
                        <Button
                            variant={(shiftPlan.open ? "success" : "taimDefault")}
                            onClick={() => (shiftPlan.open ? publishWeeklyShift(shiftPlan.weeklyShift?.pk) : startWeeklyShift())}
                        >{(shiftPlan.open ?
                            intl.formatMessage({id: "shift_management.publish", defaultMessage: "Publish"}) :
                            intl.formatMessage({id: "shift_management.start_shift_plan", defaultMessage: "Start Shift Plan"})
                        )}</Button>
                    </div>
                )}
            </div>
            {(shiftPlan.weeklyShift?.pk && permissionContext.isAdmin) && (
                <div className="w-full">
                    <TaimModal
                        header={intl.formatMessage({id: "shift_management.create_shift", defaultMessage: "Create Shift"})}
                        button={<Button variant="taimDefault">
                            <FormattedMessage id="shift_management.create_shift" defaultMessage="Create Shift"/>
                        </Button>}
                    >
                        <CreateShift
                            shiftPlan={shiftPlan}
                            weekDays={weekDays}
                            sideFunction={handleRefetchShifts}
                        />
                    </TaimModal>
                </div>
            )}
            <div>
                <div className="grid grid-cols-7">
                    {weekDays?.map((day, index) => {
                        const currentDay = moment().set({
                            year: shiftPlan.year,
                            week: shiftPlan.week,
                            day: index + 1
                        }).startOf('day')
                        const today = moment().startOf('day')
                        const dayIsLess = today.isAfter(currentDay)

                        return (
                            <div
                                key={index}
                                className={cn(
                                    "border border-b-0 border-r-0 flex flex-col space-y-2 p-2 text-left text-gray-500",
                                    index === 0 && "rounded-tl-lg",
                                    index === weekDays.length - 1 && "rounded-tr-lg border-r",
                                    (today.format('DD MMM YYYY') === currentDay.format('DD MMM YYYY')) && "bg-sky-100 text-sky-700",
                                    dayIsLess && "bg-gray-100"
                                )}
                            >
                                <div className="text-3xl">{moment().set({
                                    year: shiftPlan.year,
                                    week: shiftPlan.week,
                                    day: index + 1
                                }).format('DD')}</div>
                                <div>{moment().day(day).format('ddd')}</div>
                            </div>
                        )
                    })}
                </div>
                {Array.from({length: shiftPlan.columnsLength}).map((row, rowIndex) => {
                    if (!shiftPlan.open) {
                        return (
                            <div className="grid grid-cols-7">
                                {weekDays?.map((day, index) => (
                                    <div
                                        key={index}
                                        className={cn(
                                            "border border-b-0 border-r-0 bg-gray-50 h-28 text-center cursor-not-allowed",
                                            index === weekDays.length - 1 && "border-r",
                                            rowIndex === (shiftPlan.columnsLength - 1) && "border-b",
                                            (rowIndex === (shiftPlan.columnsLength - 1) && index === 0) && "rounded-bl-lg",
                                            (rowIndex === (shiftPlan.columnsLength - 1) && index === weekDays.length - 1) && "rounded-br-lg",
                                        )}
                                    ></div>
                                ))}
                            </div>
                        )
                    }

                    return (
                        <div className="grid grid-cols-7">
                            {weekDays?.map((day, index) => {
                                let currShift: TShiftFormatted | undefined = undefined;

                                if (shiftPlan.weeklyShift?.shifts) {
                                    currShift = shiftPlan.weeklyShift.shifts[index][rowIndex]
                                }

                                return (currShift) ? (
                                    <div
                                        key={index}
                                        className={cn(
                                            "border border-b-0 border-r-0 bg-white h-28 text-left p-2 pb-0",
                                            index === weekDays.length - 1 && "border-r",
                                            rowIndex === (shiftPlan.columnsLength - 1) && "border-b",
                                            (rowIndex === (shiftPlan.columnsLength - 1) && index === 0) && "rounded-bl-lg",
                                            (rowIndex === (shiftPlan.columnsLength - 1) && index === weekDays.length - 1) && "rounded-br-lg",
                                            (currShift) && "bg-purple-100 text-purple-700"
                                        )}
                                        style={{
                                            backgroundColor: currShift?.webBackgroundColor ?? THEME_COLORS["default"].background,
                                            color: currShift?.webFontColor ?? THEME_COLORS["default"].text,
                                        }}
                                    >{(currShift) ? (
                                        <div className="flex flex-col justify-between space-y-2 h-full text-xs">
                                            <div className="flex flex-row justify-between">
                                                <div>{`${currShift.startDatetime.hoursAndMinutesString} - ${currShift.endDatetime.hoursAndMinutesString}`}</div>
                                                <div>{currShift.employees.length}/{currShift.capacity}</div>
                                            </div>
                                            {!permissionContext.isAdmin ? (
                                                <UsersList
                                                    users={currShift.employees.map(e => ({
                                                            fullName: e.firstName + ' ' + e.lastName,
                                                            username: e.username
                                                        })
                                                    )}
                                                    avatarThemes={{
                                                        background: currShift.webBackgroundColor,
                                                        text: currShift.webFontColor
                                                    }}
                                                ></UsersList>
                                            ) : currShift.employees?.length ? (
                                                <UsersList
                                                    users={currShift.employees.map(e => ({
                                                            fullName: e.firstName + ' ' + e.lastName,
                                                            username: e.username
                                                        })
                                                    )}
                                                    avatarThemes={{
                                                        background: currShift.webBackgroundColor,
                                                        text: currShift.webFontColor
                                                    }}
                                                >
                                                    <ManageShiftEmployees
                                                        employees={employees}
                                                        triggerButton={(
                                                            <>
                                                                <div className="underline cursor-pointer hover:text-slate-700">
                                                                    <FormattedMessage
                                                                        id={"dashboard.viewAll"}
                                                                        defaultMessage={"View All"}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                        shift={{
                                                            ...shift,
                                                            ...currShift
                                                        }}
                                                        setShift={setShift}
                                                        setRefetchShifts={handleRefetchShifts}
                                                    />
                                                </UsersList>
                                            ) : (
                                                <ManageShiftEmployees
                                                    employees={employees}
                                                    triggerButton={(
                                                        <>
                                                            <Users size={18}/>
                                                            <FormattedMessage id={"shift_management.assign_employees"} defaultMessage={"Assign Employees"}/>
                                                        </>
                                                    )}
                                                    shift={{
                                                        ...shift,
                                                        ...currShift
                                                    }}
                                                    setShift={setShift}
                                                    setRefetchShifts={handleRefetchShifts}
                                                />
                                            )}
                                            <div className="flex flex-row justify-between items-center">
                                                <div className="truncate w-32">
                                                    {currShift ? intl.formatMessage({id: currShift.name, defaultMessage: currShift.name}) : null}
                                                    {currShift.workingSection ? ` - ${currShift.workingSection}` : null}
                                                </div>
                                                {permissionContext.isAdmin && (
                                                    <DropdownMenu modal={false}>
                                                        <DropdownMenuTrigger asChild>
                                                            <Button variant="link" className="h-8 w-8 p-0">
                                                                <span className="sr-only">Open menu</span>
                                                                <MoreHorizontal
                                                                    className="h-4 w-4"
                                                                    style={{
                                                                        color: currShift.webFontColor
                                                                    }}
                                                                />
                                                            </Button>
                                                        </DropdownMenuTrigger>
                                                        <DropdownMenuContent align="end">
                                                            <TaimModal
                                                                header={intl.formatMessage({id: "edit", defaultMessage: "Edit"})}
                                                                button={(
                                                                    <DialogTrigger className="w-full">
                                                                        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                                                                            <FormattedMessage id={"edit"} defaultMessage={"Edit"}/>
                                                                        </DropdownMenuItem>
                                                                    </DialogTrigger>
                                                                )}
                                                                open={editOpen}
                                                                onOpenChange={handleEditOpen}
                                                                dialogTriggerClassName={"w-full"}
                                                            >
                                                                <EditShift
                                                                    shift={currShift}
                                                                    shiftPlan={shiftPlan}
                                                                    dispatch={dispatch}
                                                                    setReFetchShifts={handleRefetchShifts}
                                                                />
                                                            </TaimModal>
                                                            <Dialog>
                                                                <DialogTrigger className="w-full">
                                                                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                                                                        <FormattedMessage id={"action.name.delete"} defaultMessage={"Delete"}/>
                                                                    </DropdownMenuItem>
                                                                </DialogTrigger>
                                                                <DialogContent>
                                                                    <DialogHeader className={"gap-4"}>
                                                                        <DialogTitle className={"text-2xl"}>
                                                                            <FormattedMessage id={"toast.areYouSure"} defaultMessage={"Are you sure ?"}/>
                                                                        </DialogTitle>
                                                                        <DialogDescription>
                                                                            <FormattedMessage id={"toast.areYouSure.description"} defaultMessage={"This action can not be undone."}/>
                                                                        </DialogDescription>
                                                                    </DialogHeader>
                                                                    <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                                                                        <DialogClose asChild>
                                                                            <Button variant="outline">
                                                                                <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                                                                            </Button>
                                                                        </DialogClose>
                                                                        <DialogClose asChild>
                                                                            <Button
                                                                                variant="destructive"
                                                                                type="submit"
                                                                                onClick={() => {
                                                                                    if (currShift) handleDelete(currShift.pk)
                                                                                }}
                                                                            ><FormattedMessage id={"action.name.delete"} defaultMessage={"Delete"}/></Button>
                                                                        </DialogClose>
                                                                    </DialogFooter>
                                                                </DialogContent>
                                                            </Dialog>
                                                        </DropdownMenuContent>
                                                    </DropdownMenu>
                                                )}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            ) : (
                                <div
                                    key={index}
                                    className={cn(
                                        "border border-b-0 border-r-0 bg-white h-28 text-left p-2",
                                        index === weekDays.length - 1 && "border-r",
                                        rowIndex === (shiftPlan.columnsLength - 1) && "border-b",
                                        (rowIndex === (shiftPlan.columnsLength - 1) && index === 0) && "rounded-bl-lg",
                                        (rowIndex === (shiftPlan.columnsLength - 1) && index === weekDays.length - 1) && "rounded-br-lg"
                                    )}
                                ></div>
                            )
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    );
};