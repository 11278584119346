import moment from "moment";
import {Activity} from "../../Controllers/attendances-controller";

export type DTFormattedActivity = DTBreaktimeActivity | DTWorkingtimeActivity | DTServiceActivity | DTEmptyActivity

type DTActivityCredentials = {
    pk?: string | number
    endDatetime: string | null
    startDatetime: string
    width?: number
    shift?: number
    duration?: string
}

export type DTBreaktimeActivity = {
    type: "breakTime"
} & DTActivityCredentials

export type DTWorkingtimeActivity = {
    type: "workingTime"
} & DTActivityCredentials

export type DTServiceActivity = {
    type: "service"
    name: string
    duration: string
} & DTActivityCredentials

export type DTEmptyActivity = {
    type: "empty"
} & DTActivityCredentials


const resetTimeToStartOfDay = (dateTimeString: string | null) => {
    if (!dateTimeString) return moment().startOf("day").toISOString();
    const datePart = dateTimeString.split('T')[0];
    return  datePart + 'T00:00:00';
}

const limitToCurrentTime = (activities: DTFormattedActivity[]) => {
    return activities.map((activity) => {
        const startDay = moment(activity.startDatetime).date();
        const endDay = moment(activity.endDatetime).date();
        if (startDay !== endDay) {
            return {
                ...activity,
                startDatetime: resetTimeToStartOfDay(activity.endDatetime)
            }
        }
        return activity
    }).map((activity) => {
        if (moment(activity.endDatetime) > moment()) {
            return {
                ...activity,
                endDatetime: moment().format('YYYY-MM-DDTHH:mm:ss')
            }
        }
        return activity
    });
}

function formatDuration(activityDuration: string): moment.Duration {
    const durationArray = activityDuration.split(' ');
    let duration = moment.duration();

    durationArray.forEach(unit => {
        const value = parseInt(unit) || 0;
        if (unit.includes('h')) {
            duration.add(value, 'hours');
        } else if (unit.includes('m')) {
            duration.add(value, 'minutes');
        }
    });

    return duration;
}


const trimDayFunc = (activities: DTFormattedActivity[], startOfDay: moment.Moment, endOfDay: moment.Moment) : DTFormattedActivity[] => {
    const isInRange = (datetime: moment.Moment): "between" | "dayBefore" | "dayAfter" => {
        let state: "between" | "dayBefore" | "dayAfter" = "between";
        if (datetime.isBefore(startOfDay) && datetime.isBefore(endOfDay)) {
            state = "dayBefore";
        } else if (datetime.isAfter(startOfDay) && datetime.isAfter(endOfDay)) {
            state = "dayAfter"
        }
        return state;
    };

    return activities.map((activity) => {
        let startDatetime = moment(activity.startDatetime);
        let endDatetime = moment(activity.endDatetime);

        return {
            ...activity,
            startDatetime: isInRange(startDatetime) === "between" ? activity.startDatetime : startOfDay.toISOString(),
            endDatetime: isInRange(endDatetime) === "between" ? activity.endDatetime : endOfDay.toISOString()
        }
    })
}

const mergeCheckInAndCheckOutsFunc = (
    activities: Activity[],
    isCurr: boolean,
    endOfDay: moment.Moment
) : DTFormattedActivity[] => {
    let output : DTFormattedActivity[] = [];
    activities.forEach((activity, index, array) => {
        switch (activity.type) {
            case "breakTime":
                let breaktime: DTBreaktimeActivity = {
                    type: activity.type,
                    startDatetime: activity.startDatetime,
                    endDatetime: activity.endDatetime,
                    duration: activity.duration
                };
                output.push(breaktime);
                break;
            case "service":
                let service: DTServiceActivity = {
                    type: activity.type,
                    name: activity.name,
                    startDatetime: moment(activity.startDatetime).toISOString(),
                    endDatetime: moment(activity.endDatetime).toISOString(),
                    duration: activity.duration
                }
                output.push(service);
                break;
            case "checkIn":
                let workingtime: DTWorkingtimeActivity = {
                    type: "workingTime",
                    startDatetime: activity.datetime,
                    endDatetime: null
                }
                let hasCheckout = false;
                array.forEach((nestedActivity, nestedIndex) => {
                    if (nestedActivity.type === "checkOut" && nestedIndex > index && workingtime.endDatetime === null) {
                        workingtime.endDatetime = nestedActivity.datetime;
                        hasCheckout = true;
                    }
                })
                if (!hasCheckout) {
                    workingtime.endDatetime = isCurr ? moment().format('YYYY-MM-DDTHH:mm:ss') : endOfDay.toISOString();
                }
                output.push(workingtime);
                break;
            case "checkOut":
                break;
        }
    })
    return output;
}

export const transformActivities = (activities: Activity[], isCurr: boolean, currDay?: string) : DTFormattedActivity[] => {
    let startOfDay = moment(currDay).startOf('day');
    let endOfDay = moment(currDay).endOf('day');

    const clearedNullishServices = activities.filter((activity) => !(activity.type === "service" && !activity.endTime));

    const mergedCheckInAndCheckOuts: DTFormattedActivity[] = mergeCheckInAndCheckOutsFunc(clearedNullishServices, isCurr, endOfDay);

    const trimedDay = trimDayFunc(mergedCheckInAndCheckOuts, startOfDay, endOfDay);

    trimedDay.push(
        {
            type: "empty",
            startDatetime: startOfDay.format("YYYY-MM-DDTHH:mm:ss"),
            endDatetime: endOfDay.format("YYYY-MM-DDTHH:mm:ss")
        }
    )

    return trimedDay.map((activity) => {
        const startDatetime = moment(activity.startDatetime);
        const endDatetime = moment(activity.endDatetime);
        const shift = startDatetime.diff(startOfDay, 'minutes');
        const width = endDatetime.diff(startDatetime, 'minutes');


        return {
            ...activity,
            shift: shift,
            width: width
        }
    }).filter((_) => _.endDatetime !== null)
}