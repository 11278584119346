export function getHourAndMinute(input: string, unit: 'h' | 'm') {
    const [hours, minutes] = input?.split(':').map(Number);

    if (unit === 'h') {
        return hours;
    } else if (unit === 'm') {
        return minutes;
    } else {
        return undefined;
    }
}