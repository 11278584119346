import React, {useEffect, useState} from "react";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {projectsUrl} from "ProjectManagement/Controller/projects-controller";
import {DatePickerWithRange} from "components/date-picker-with-range";
import {FormattedMessage} from "react-intl";
import {Cross2Icon} from "@radix-ui/react-icons";
import {Button} from "components/button";
import moment from "moment";
import {nullOrUndefined} from "Core/constants/variables";
import {Circle} from "@phosphor-icons/react";
import {Skeleton} from "components/skeleton";
import {DateRange} from "react-day-picker";

type Activity = {
     pk: string
     startDate: string
     startTime: string
     endDate: string
     endTime: string
     duration: string
     serviceID: string
     serviceName: string
     projectID: string
     projectName: string
     employeeName: string
     employeeUUID: string
     employeeRoleName: string
}

export const ActivityDetails: React.FC<{id: string}> = ({id}) => {
    const axiosInstance = useAxiosInstance();
    const [activities, setActivities] = useState<{startDate: string, records: Activity[]}[]>([]);
    const [activitiesloading, setActivitiesLoading] = useState<boolean>(false);
    const [dateRange, setDateRange] = React.useState<DateRange | undefined>()

    const fetchActivities = () => {
        setActivitiesLoading(true);
        axiosInstance.get(projectsUrl + `${id}/activities/`, {
            params: {
                "filter.startDate": dateRange ? moment(dateRange?.from).format("YYYY-MM-DD") : null,
                "filter.endDate": dateRange ? moment(dateRange?.to).format("YYYY-MM-DD") : null
            }
        })
            .then((res) => {
                setActivities(res.data);
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setActivitiesLoading(false);
            });
    }

    useEffect(() => {
        fetchActivities();
    }, [dateRange]);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex gap-2 items-center">
                <DatePickerWithRange setDate={setDateRange} date={dateRange}/>
                {dateRange ? (
                    <Button
                        variant="ghost"
                        onClick={() => setDateRange(undefined)}
                        className="h-8 px-2 lg:px-3"
                    >
                        <FormattedMessage id={"reset"} defaultMessage={"Reset"}/>
                        <Cross2Icon className="ml-2 h-4 w-4" />
                    </Button>
                ) : null}
            </div>
            <div className="flex flex-col gap-4 max-h-[40rem] overflow-y-scroll pr-2 scrollbar">
                {activities.length && !activitiesloading ? activities.map((activity) => {
                    return (
                        <div className="flex flex-col gap-4">
                            <div className="border rounded-lg p-4 flex flex-col gap-4">
                                <div className="font-bold text-lg mb-2">{moment(activity.startDate).format("ddd DD MMM YYYY")}</div>
                                {activity.records.map((activity) => (
                                    <div className="flex justify-between items-end border-b">
                                        <div className="flex flex-col">
                                            <div className="flex gap-2 items-center p-1">
                                                <span>{activity.employeeName ?? nullOrUndefined}</span>
                                                <Circle weight={"fill"} size={6}/>
                                                <span>{activity.serviceName ?? nullOrUndefined}</span>
                                            </div>
                                            <div className="text-gray-400 p-1">{activity.startTime} - {activity.endTime}</div>
                                        </div>
                                        <div className="p-1">{activity.duration}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                }) : !activities.length && activitiesloading ? (
                    Array.from({length: 3}).map((elem) => (
                        <div className="border rounded-lg p-4">
                            <div className="font-bold text-lg mb-4"><Skeleton className={`w-1/6 h-[15px]`}/></div>
                            <div className="flex justify-between items-end border-b pb-2">
                                <div className="flex flex-col w-full gap-2">
                                    <Skeleton className={`w-1/2 h-[15px]`}/>
                                    <Skeleton className={`w-1/3 h-[15px]`}/>
                                </div>
                                <Skeleton className={`w-1/6 h-[15px]`}/>
                            </div>
                        </div>
                    ))
                ) : <FormattedMessage id={"no_results"} defaultMessage={"No results found."}/>}
            </div>
        </div>
    )
}