import React from 'react';
import ReactDOM from 'react-dom/client';
import './globals.css';
import App from './App';
import {Wrapper} from "./Core/utilities/Wrapper";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <Wrapper>
        <App/>
    </Wrapper>
);