import DetailViewCard from "../../../Core/components/detail-view-card";
import React, {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {VacationCredits} from "./VacationCredits";
import {AnnualVacation} from "./AnnualVacation";
import {TaimInfo} from "../../../Core/components/taim-info";
import {ManualVacationCarryOvers} from "./ManualVacationCarryOvers";
import {VacationExceptionRules} from "./VacationExceptionRules";


const VacationPlan: React.FC<{userUUID: string}> = ({userUUID}) => {
    const intl = useIntl();
    const [reloadVacationPlanTab, setReloadVacationPlanTab] = useState(false)

    return (
        <>
            <DetailViewCard
                header={intl.formatMessage({id: "employees.detail.vacationCredits", defaultMessage: "Vacation Credits"})}
                rows={[
                    {jsxElement: (
                        <VacationCredits
                            userUUID={userUUID}
                            reloadVacationPlanTab={reloadVacationPlanTab}
                            setReloadVacationPlanTab={setReloadVacationPlanTab}
                        />
                    )},
                ]}
                sizeFit={true}
            />
            <DetailViewCard
                header={intl.formatMessage({id: "employees.detail.annualVacation", defaultMessage: "Annual Vacation"})}
                rows={[
                    {jsxElement: (
                        <AnnualVacation
                            userUUID={userUUID}
                            reloadVacationPlanTab={reloadVacationPlanTab}
                            setReloadVacationPlanTab={setReloadVacationPlanTab}
                        />
                    )},
                ]}
                sizeFit={true}
            />
            <DetailViewCard
                header={
                    <div className={"flex flex-row gap-2 items-center"}>
                        <FormattedMessage id={"employees.detail.manualVacationCarryOvers"} defaultMessage={"Manual Vacation Carry Overs"}/>
                        <TaimInfo
                            size={"18"}
                            text={intl.formatMessage({id: "employees.detail.manualVacationCarryOversInfo", defaultMessage: "The manual transfer of vacation days is used for the subsequent transfer of remaining vacation days from the previous year when using it for the first time."})}
                        />
                    </div>
                }
                rows={[
                    {jsxElement: (
                        <ManualVacationCarryOvers
                            userUUID={userUUID}
                            reloadVacationPlanTab={reloadVacationPlanTab}
                            setReloadVacationPlanTab={setReloadVacationPlanTab}
                        />
                    )},
                ]}
                sizeFit={true}
            />
            <DetailViewCard
                header={
                    <div className={"flex flex-row gap-2 items-center"}>
                        <FormattedMessage id={"employees.detail.vacationExceptionRules"} defaultMessage={"Vacation Exception Rules"}/>
                        <TaimInfo
                            size={"18"}
                            text={intl.formatMessage({id: "employees.detail.vacationExceptionRulesInfo", defaultMessage: "The exception rule for vacation carryovers applies only to the entered year to extend the expiration period."})}
                        />
                    </div>
                }
                rows={[
                    {jsxElement: (
                        <VacationExceptionRules
                            userUUID={userUUID}
                            reloadVacationPlanTab={reloadVacationPlanTab}
                            setReloadVacationPlanTab={setReloadVacationPlanTab}
                        />
                    )},
                ]}
                sizeFit={true}
            />
        </>
    )
}

export default VacationPlan;