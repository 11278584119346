import DataTableOld, {ColumnOld} from "../../Core/Table/data-table-old";
import React, {ReactNode, SetStateAction, useEffect, useState} from "react";
import DataTableTools from "../../Core/Table/data-table-tools";
import DataTablePagination from "../../Core/Table/data-table-pagination";
import {Action} from "../../Core/Table/data-table-actions";
import {
    TimesheetDailyTrack, TimesheetDailyTrackDetail, TimesheetDailyTrackDetailsRequest,
    TimesheetDailyTracksPayload,
    TimesheetDailyTracksRequest, timesheetManagementUrl,
} from "../Controllers/timesheet-controller";
import {DaysTimeline} from "../../Core/components/days-timeline";
import moment from "moment";
import {useIntl} from "react-intl";
import {cn} from "../../lib/utils";
import {Badge} from "../../components/badge";
import statusVariants from "../../Core/functions/status-variants";


type TimesheetDailyTrackDetailsDataTableProps = {
    timesheetDailyTracksRequest: TimesheetDailyTrackDetailsRequest
    payload: TimesheetDailyTracksPayload
    setPayload: React.Dispatch<SetStateAction<TimesheetDailyTracksPayload>>
    loading: boolean
    year?: number
    month?: number
    day: number | null
    setDay: React.Dispatch<SetStateAction<number | null>>
    isLoading?: boolean
}

interface IFormattedTimesheetDailyTrackDetail extends TimesheetDailyTrackDetail {
    badge?: ReactNode
}

const TimesheetDailyTrackDetailsDataTable = (
    {
        timesheetDailyTracksRequest,
        payload,
        setPayload,
        loading,
        month,
        year,
        setDay,
        day,
        isLoading
    }: TimesheetDailyTrackDetailsDataTableProps) => {
    const intl = useIntl();
    const [formattedData, setFormattedDate] = useState<IFormattedTimesheetDailyTrackDetail[]>([])

    const timesheetDailyTracksColumns: ColumnOld[] = [
        {
            backendKey: "fullName",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.dailyTrack.employeeName", defaultMessage: "Employee Name"}),
            sortable: true,
            sortingValue: "full_name"
        },
        {
            backendKey: "date",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.dailyTrack.date", defaultMessage: "Date"}),
            sortable: true,
        },
        {
            backendKey: "startTime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.dailyTrack.startTime", defaultMessage: "Start Time"}),
            sortable: true,
            sortingValue: "start_time"
        },
        {
            backendKey: "endTime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.dailyTrack.endTime", defaultMessage: "End Time"}),
            sortable: true,
            sortingValue: "end_time"
        },
        {
            backendKey: "duration",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.dailyTrack.duration", defaultMessage: "Duration"}),
            sortable: true,
        },
        {
            backendKey: "badge",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.dailyTrack.type", defaultMessage: "Type"}),
            sortable: true,
            sortingValue: "type"
        },
    ]

    const DETAIL_TYPES = [
        {
            key: "sickLeave",
            value: intl.formatMessage({id: "timesheetManagement.sickLeave", defaultMessage: "Sick Leave"})
        },
        {
            key: "vacation",
            value: intl.formatMessage({id: "timesheetManagement.vacation", defaultMessage: "Vacation"})
        },
        {
            key: "attendance",
            value: intl.formatMessage({id: "timesheetManagement.attendance", defaultMessage: "Attendance"})
        },
        {
            key: "breakTime",
            value: intl.formatMessage({id: "timesheetManagement.breakTime", defaultMessage: "Break Time"})
        },
    ]

    const types = [
      {
        value: "Attendance",
        label: intl.formatMessage({id: "timesheetManagement.attendance", defaultMessage: "Attendance"})
      },
      {
        value: "breakTime",
        label: intl.formatMessage({id: "timesheetManagement.breakTime", defaultMessage: "Break Time"})
      },
      {
        value: "SickLeave",
        label: intl.formatMessage({id: "timesheetManagement.sickLeave", defaultMessage: "Sick Leave"})
      },
      {
        value: "vacation",
        label: intl.formatMessage({id: "timesheetManagement.vacation", defaultMessage: "Vacation"}),
      }
    ]


    useEffect(() => {
        const formattedDate = timesheetDailyTracksRequest.data.map((dailyTrackDetail) => {
            let type = DETAIL_TYPES.find(elem => elem.key === dailyTrackDetail.type)

            return {
                ...dailyTrackDetail,
                ...(dailyTrackDetail.date && {date: moment(dailyTrackDetail.date).format("ddd DD MMM YYYY")}),
                ...(dailyTrackDetail.type && {badge: <Badge variant={statusVariants(dailyTrackDetail.type)}>{type?.value}</Badge>})
            }
        })

        setFormattedDate(formattedDate)
    }, [timesheetDailyTracksRequest]);

    if (!day) return <></>

    return(
        <div className="space-y-4 min-h-[40rem] flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools
                    payload={payload}
                    setPayload={setPayload}
                    exportTable={{
                        fileName: `${year}_${month}_${day}_${moment().valueOf()}_daily-tracks-details`,
                        apiURL: timesheetManagementUrl + `${year}/${month}/${day}/daily-tracks/details/export/`,
                        payload: payload
                    }}
                    filters={[
                        {
                            type: "simple",
                            title: intl.formatMessage({id: "data.table.filter.type", defaultMessage: "Type"}),
                            filterBy: 'filter.type',
                            options: types ?? []
                        }
                    ]}
                />
                <DaysTimeline
                    day={day}
                    setDay={setDay}
                    year={year || new Date().getFullYear()}
                    month={month || new Date().getMonth() + 1}
                />
                <DataTableOld
                    data={formattedData}
                    loading={isLoading || loading}
                    setPayload={setPayload}
                    columns={timesheetDailyTracksColumns}
                    payload={payload}
                    showLoadingState={true}
                />
            </div>
            <DataTablePagination request={timesheetDailyTracksRequest} payload={payload} setPayload={setPayload}/>
        </div>
    )
}

export default TimesheetDailyTrackDetailsDataTable;