import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "../../components/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/form"
import { Input } from "../../components/input"
import {DialogClose, DialogFooter} from "../../components/dialog";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Label} from "../../components/label";
import {FormattedMessage, useIntl} from "react-intl";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {employmentTypeUrl} from "../Controllers/employment-type-controller";
import {toast} from "../../components/use-toast";
import {ServerErrorsType, useServerErrors} from "../../Core/functions/use-server-errors";
import ErrorMessageList from "../../Core/components/ErrorMessageList";

const formSchema = z.object({
    type: z.any(),
    monthlyWorkHours: z.any()
})

type FormEmploymentTypeCreateProps = {
    reFetch: Dispatch<SetStateAction<boolean>>
}

export const FormEmploymentTypeCreate: React.FC<FormEmploymentTypeCreateProps> = ({reFetch}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
    });
    const [weeklyWorkingHours, setHours] = useState(0);
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>(null);


    async function onSubmit(values: z.infer<typeof formSchema>) {
        axiosInstance.post(employmentTypeUrl, values)
            .then((res) => {
                setApiErrors({});
                closeModal.current?.click();
                reFetch(true);
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => setApiErrors(err.response.data))
    }


    useEffect(() => {
        if (form.getValues('monthlyWorkHours')) setHours(form.getValues('monthlyWorkHours') / 4)
    }, [form.watch()]);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <FormField
                    control={form.control}
                    name="type"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employmentType.table.type"} defaultMessage={"Type"}/>
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={intl.formatMessage({id: "employmentType.table.typePlaceholder", defaultMessage: "Enter employment type"})}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="monthlyWorkHours"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employmentType.detail.workingHoursPerMonth"} defaultMessage={"Working Hours Per Month"}/>
                            </FormLabel>
                            <FormControl>
                                <Input placeholder="0" type="number" {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <div className="flex flex-col gap-3">
                    <Label className="text-slate-500">
                        <FormattedMessage id={"employmentType.detail.workingHoursPerWeek"} defaultMessage={"Working Hours Per Week"}/>
                    </Label>
                    <Input
                        value={weeklyWorkingHours}
                        type="number"
                        disabled
                    />
                </div>

                <ErrorMessageList errors={errors.nonFieldErrors}/>
                <ErrorMessageList errors={errors.detailErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}
