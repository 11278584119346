import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "../../components/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/form"
import { Input } from "../../components/input"
import {DialogClose, DialogFooter} from "../../components/dialog";
import React, {useRef, useState} from "react";
import FormTitle from "../../Core/components/form-title";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {locationsUrl} from "../Controllers/locations-controller";
import {toast} from "../../components/use-toast";
import {FormattedMessage, useIntl} from "react-intl";
import {ServerErrorsType, useServerErrors} from "../../Core/functions/use-server-errors";
import ErrorMessageList from "../../Core/components/ErrorMessageList";

const formSchema = z.object({
    name: z.string().optional(),
    address: z.object({
        street: z.string().optional(),
        houseNumber: z.string().optional(),
        city: z.string().optional(),
        postalCode: z.string().optional(),
    })
})

type CreatLocationFormProps = {
    reFetch: any
}

export const CreateLocationForm: React.FC<CreatLocationFormProps> = ({reFetch}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();

    async function onSubmit(values: z.infer<typeof formSchema>) {
        axiosInstance.post(locationsUrl, values)
        .then((res) => {
            setApiErrors({});
            reFetch(true);
            closeModal.current?.click();
            toast({
                title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
            });
        })
        .catch((err) => {
            setApiErrors(err.response.data);
        })
    }

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.locations.name"}
                                    defaultMessage={"Location Name"}
                                />
                            </FormLabel>
                            <FormControl>
                                <Input placeholder={intl.formatMessage({
                                    id: "attendance.locations.namePlaceHolder",
                                    defaultMessage: "Enter a name"
                                })} {...field} />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <FormTitle
                    title={intl.formatMessage({
                        id: "attendance.locations.address",
                        defaultMessage: "Address"
                    })}
                    isOptional={true}
                />
                <FormField
                    control={form.control}
                    name="address.street"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.locations.street"}
                                    defaultMessage={"Street"}
                                />
                            </FormLabel>
                            <FormControl>
                                <Input placeholder={intl.formatMessage({
                                    id: "attendance.locations.streetPlaceHolder",
                                    defaultMessage: "Enter street name"
                                })} {...field} />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="address.houseNumber"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.locations.houseNumber"}
                                    defaultMessage={"House Number"}
                                />
                            </FormLabel>
                            <FormControl>
                                <Input placeholder={intl.formatMessage({
                                    id: "attendance.locations.housNumberPlaceHolder",
                                    defaultMessage: "Enter house number"
                                })} {...field} />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="address.postalCode"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.locations.postalCode"}
                                    defaultMessage={"Postal Code"}
                                />
                            </FormLabel>
                            <FormControl>
                                <Input placeholder={intl.formatMessage({
                                    id: "attendance.locations.postalCodePlaceHolder",
                                    defaultMessage: "Enter postal code"
                                })} {...field} />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="address.city"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.locations.city"}
                                    defaultMessage={"City"}
                                />
                            </FormLabel>
                            <FormControl>
                                <Input placeholder={intl.formatMessage({
                                    id: "attendance.locations.cityPlaceHolder",
                                    defaultMessage: "Enter city name"
                                })} {...field} />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <ErrorMessageList errors={errors.detailErrors}/>
                <ErrorMessageList errors={errors.nonFieldErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}
