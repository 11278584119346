import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import DetailViewEmployeeAvatar from "../../Core/components/detail-view-employee-avatar";
import DetailViewCard from "../../Core/components/detail-view-card";
import {Progress} from "../../components/progress";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../../components/tab";
import {
    Employee,
    employeesUrl,
    EmploymentType, useEmployeeWorkingPlans,
    useEmployeeWorkingTimeProgress,
    WorkingTimeProgress
} from "../Controllers/employee-controller";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {nullOrUndefined} from "../../Core/constants/variables";
import {WorkingPlan} from "./WorkingPlan";
import {FormattedMessage, useIntl} from "react-intl";
import {EmploymentHistory} from "./EmploymentHistory";
import {TaimModal} from "../../Core/components/taim-modal";
import {Button} from "../../components/button";
import {toast} from "../../components/use-toast";
import moment from "moment";
import WorkingTimeAccount from "./WorkingTimeAccount";
import {employeeManagementUrl} from "../index";
import {useSearchParams} from "react-router-dom";
import VacationPlan from "./VacationPlan";

type EmployeesDetailViewProps = {
    employee: Employee
    loading?: boolean
    rePopulateRecord: (employeeID: string) => void
    detailTab: string
    setDetailTab: React.Dispatch<SetStateAction<string>>
}

type ContentComponentProps = {
    employee: Employee
    workingTimeProgress: WorkingTimeProgress
    reLoadWorkingTimeProgress: Dispatch<SetStateAction<boolean>>
    employmentType?: EmploymentType
    loading?: boolean
    rePopulateRecord: (employeeID: string) => void
    detailTab: string
    setDetailTab: React.Dispatch<SetStateAction<string>>
}

const ContentComponent: React.FC<ContentComponentProps> = ({
    employee,
    workingTimeProgress,
    reLoadWorkingTimeProgress,
    employmentType,
    rePopulateRecord,
    detailTab,
    setDetailTab
}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const [percentageNumber,setPercentageNumber] = useState<number>(0)
    const informationTab = intl.formatMessage({
        id: "employees.detail.information",
        defaultMessage: "Information"
    })
    const workingPlanTab = intl.formatMessage({
        id: "employees.detail.workingPlan",
        defaultMessage: "Working Plan"
    })
    const vacationPlanTab = intl.formatMessage({
        id: "employees.detail.vacationPlan",
        defaultMessage: "Vacation Plan"
    })
    const workingTimeAccountPlanTab = intl.formatMessage({
        id: "employees.detail.working_time_account",
        defaultMessage: "Working Time Account"
    })
    const workingPlans = useEmployeeWorkingPlans(employee?.userUUID);
    const [searchParams, setSearchParams] = useSearchParams();

    const onTabChange = (value: string) => {
        setDetailTab(value);
        setSearchParams(prev => {
            prev.set('detail-tab',value)
            return prev
        })
    }

    const generateAuthCode = () => {
        axiosInstance.post(employeesUrl + `${employee?.userUUID}/generate-auth-token/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.requestProcessed", defaultMessage: "Your request has been processed successfully."})
                })
                rePopulateRecord(employee.userUUID)
            })
            .catch((err) => {
                toast({
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: intl.formatMessage({id: "toast.error.unableToProcess", defaultMessage: "Unable to process your request at the moment."}),
                    variant: "destructive"
                })
            })
    }

    useEffect(() => {
        if (String(workingTimeProgress.percentage).length > 0) {
            setPercentageNumber(Number(workingTimeProgress.percentage));
        }

    }, [workingTimeProgress]);

    useEffect(() => {
        let detailTabParam = searchParams.get('detail-tab');
        if (detailTabParam) {
            setDetailTab(detailTabParam)
        }
    }, []);

    return(<>
        {employee ? (
            <>
                <div className="flex flex-row justify-between items-center">
                    <DetailViewEmployeeAvatar
                        username={employee?.fullName}
                        isActive={employee?.isActive}
                    />
                    <TaimModal
                        header={"QR Code"}
                        button={<img className="object-cover h-16 w-16" src={employee.qrCode} alt=""/>}
                    >
                        <div className="flex flex-col items-center gap-3 text-slate-500">
                            <img alt="" src={employee.qrCode} className="object-cover h-44 w-44"/>
                            <span>QR Code URL</span>
                            <a className="underline text-blue-800 cursor-pointer" href={employee?.qrCode}>URL</a>
                            {employee?.isTestUser ? (
                                <>
                                    <span>Authentication Code</span>
                                    <span className="font-bold">{employee?.authCode ?? nullOrUndefined}</span>
                                    <div className="w-full text-right" onClick={generateAuthCode}>
                                        <Button variant="taimDefault2">Generate Auth Code</Button>
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </TaimModal>
                </div>
                <div className="flex flex-col gap-2">
                    <Progress className="bg-blue-800" value={percentageNumber}/>
                    <div className="flex justify-between">
                        <span>{workingTimeProgress?.actualHours}</span>
                        <span className="text-slate-600">{workingTimeProgress?.plannedHours}</span>
                    </div>
                </div>
                <Tabs value={detailTab} onValueChange={onTabChange} className="w-full">
                    <TabsList className="flex flex-row justify-start overflow-auto w-fit">
                        <TabsTrigger value={'information'}>{informationTab}</TabsTrigger>
                        <TabsTrigger value={'working-plan'}>{workingPlanTab}</TabsTrigger>
                        <TabsTrigger value={'vacation-plan'}>{vacationPlanTab}</TabsTrigger>
                        <TabsTrigger value={'working-time-account'}>{workingTimeAccountPlanTab}</TabsTrigger>
                    </TabsList>
                    <TabsContent value={'information'}>
                        <DetailViewCard rows={[
                            {
                                name: intl.formatMessage({id: "employees.detail.information.personalNumber", defaultMessage: "Personal Number"}),
                                value: employee?.personalNumber ?? nullOrUndefined
                            },
                            {
                                name: intl.formatMessage({id: "employees.detail.information.role", defaultMessage: "Role"}),
                                value: employee?.role ?? nullOrUndefined
                            },
                            {
                                name: intl.formatMessage({id: "employees.detail.information.email", defaultMessage: "Email"}),
                                value: employee?.email ?? nullOrUndefined
                            },
                            {
                                name: intl.formatMessage({id: "employees.detail.information.nfcUUID", defaultMessage: "NFC UUID"}),
                                value: employee?.nfcUUID ?? nullOrUndefined},
                            {
                                name: intl.formatMessage({id: "employees.detail.information.vacationDays", defaultMessage: "Vacation Days"}),
                                value: employee?.totalVacationDays ?? nullOrUndefined
                            },
                            {
                                name: intl.formatMessage({id: "employees.detail.information.entranceDate", defaultMessage: "Entrance Date"}),
                                value: employee?.entranceDate ? moment(employee?.entranceDate).format("ddd DD MMM YYYY") : nullOrUndefined
                            },
                        ]}/>
                    </TabsContent>
                    <TabsContent value={'working-plan'} className={"space-y-4"}>
                        <DetailViewCard
                            header={intl.formatMessage({id: "employees.detail.workingPlans", defaultMessage: "Working Plans"})}
                            rows={[
                                {jsxElement: (
                                    <WorkingPlan
                                        userUUID={employee.userUUID}
                                        employmentType={employmentType}
                                        rePopulateRecord={rePopulateRecord}
                                        reLoadWorkingTimeProgress={reLoadWorkingTimeProgress}
                                        workingPlans={workingPlans.data}
                                        loadingWorkingPlans={workingPlans.loading}
                                        reFetchWorkingPlans={workingPlans.setRefetchWorkingPlans}
                                    />
                                )},
                            ]}
                            sizeFit={true}
                        />
                        <DetailViewCard
                            header={intl.formatMessage({id: "employees.detail.employmentHistory", defaultMessage: "Employment History"})}
                            rows={[
                                {jsxElement: (
                                    <EmploymentHistory
                                        userUUID={employee.userUUID}
                                        rePopulateRecord={rePopulateRecord}
                                        reLoadWorkingTimeProgress={reLoadWorkingTimeProgress}
                                        reFetchWorkingPlans={workingPlans.setRefetchWorkingPlans}
                                    />
                                )},
                            ]}
                            sizeFit={true}
                        />
                    </TabsContent>
                    <TabsContent value={'vacation-plan'} className={"space-y-4"}>
                        <VacationPlan userUUID={employee.userUUID}/>
                    </TabsContent>
                    <TabsContent value={'working-time-account'} className={"space-y-4"}>
                        <WorkingTimeAccount userUUID={employee.userUUID}/>
                    </TabsContent>
                </Tabs>
            </>
        ) : (
            <FormattedMessage
                id={"pick_a_record"}
                defaultMessage={"Select a record to view details."}
            />
        )}
    </>)
}




const EmployeesDetailView: React.FC<EmployeesDetailViewProps> = (
    {
        employee,
        rePopulateRecord,
        loading,
        detailTab,
        setDetailTab
    }
) => {
    const axiosInstance = useAxiosInstance();
    const {
        workingTimeProgress,
        reLoadWorkingTimeProgress
    } = useEmployeeWorkingTimeProgress(employee?.userUUID)
    const [employmentTypes, setEmploymentTypes] = useState<EmploymentType[]>([])
    const [employmentType, setEmploymentType] = useState<EmploymentType>()



    const fetchEmployeeTypes = () => {
        axiosInstance.get(employeeManagementUrl + 'employment-types/')
            .then((res) => {
                setEmploymentTypes(res.data)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        if (employee && employmentTypes) setEmploymentType(employmentTypes.find((employmentType) => {
            return employmentType.type === employee.employmentType
        }))
    }, [employee, employmentTypes]);

    useEffect(() => {
        fetchEmployeeTypes()
    }, []);

    return <ContentComponent
        employee={employee}
        workingTimeProgress={workingTimeProgress}
        reLoadWorkingTimeProgress={reLoadWorkingTimeProgress}
        employmentType={employmentType}
        rePopulateRecord={rePopulateRecord}
        loading={loading}
        detailTab={detailTab}
        setDetailTab={setDetailTab}
    />
}


export default EmployeesDetailView;