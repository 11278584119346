import {useEffect, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import moment from "moment/moment";
import {toast} from "../../components/use-toast";
import {useIntl} from "react-intl";

export const SHIFTS_URL = 'weekly-shift-plan-management/'

type TEmployee = {
    pk: number
    userUUID: string
    firstName: string
    lastName: string
    username: string
    email: string
}

export type TWeeklyShift = {
    pk: number
    week: number
    year: number
}[]

export interface TShift {
    pk: number
    name: string
    startDatetime: string
    endDatetime: string
    weeklyShift: number
    employees: TEmployee[]
    capacity: number | null
    webFontColor?: string
    webBackgroundColor?: string
    workingSection: string | null
    shiftBreakTimeRecords: {
        pk?: number
        start_datetime: string
        end_datetime: string
    }[]
}

export interface TShiftFormatted extends Omit<TShift, 'startDatetime' | 'endDatetime'> {
    startDatetime: {
        string: string;
        hoursAndMinutesString: string;
        date: moment.Moment;
    };
    endDatetime: {
        string: string;
        hoursAndMinutesString: string;
        date: moment.Moment;
    };
}

export const useWeeklyShifts = (year: number, week: number) => {
    const [weeklyShift, setData] = useState<TWeeklyShift>([]);
    const [loadingWeeklyShift, setLoading] = useState(false)
    const [reFetchWeeklyShift, setReFetchWeeklyShift] = useState(false)
    const axiosInstance = useAxiosInstance();
    const intl = useIntl();

    const startWeeklyShift = () => {
        axiosInstance.post(SHIFTS_URL + `${year}/${week}/weekly-shifts/`, {
            calendarWeek: week,
            year
        })
            .then((res) => {
                setReFetchWeeklyShift(true)
            })
            .catch((err) => console.log(err))
    }

    const publishWeeklyShift = (weeklyShiftId?: number) => {
        if (!weeklyShiftId) return

        axiosInstance.post(SHIFTS_URL + `${weeklyShiftId}/shifts/release-weekly-shift/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: 'toast.success', defaultMessage: 'Great!'}),
                    description: intl.formatMessage({id: 'toast.success.requestProcessed', defaultMessage: 'Your request has been processed successfully.'})
                })
            })
            .catch((err) => {
                toast({
                    variant: 'destructive',
                    title: intl.formatMessage({id: 'toast.error', defaultMessage: 'Error!'}),
                    description: err?.response?.data?.detail ?? intl.formatMessage({id: 'toast.error.unableToProcess', defaultMessage: 'Unable to process your request at the moment.'})
                })
            })
    }

    const fetchData = () => {
        setLoading(true);

        axiosInstance.get(SHIFTS_URL + `${year}/${week}/weekly-shifts/`)
            .then((res) => setData(res.data))
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false)
                setReFetchWeeklyShift(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [week, year, reFetchWeeklyShift])

    return {weeklyShift, loadingWeeklyShift, setReFetchWeeklyShift, startWeeklyShift, publishWeeklyShift}
}


export const useShifts = (weeklyShiftId?: number) => {
    const [shifts, setShifts] = useState<TShift[]>([]);
    const [loadingShifts, setLoading] = useState(false);
    const [reFetchShifts, setReFetchShifts] = useState(false);
    const axiosInstance = useAxiosInstance();

    const handleRefetchShifts = async (pk?: number) => {
        if (pk) {
            await fetchSingleShift(pk)
                .then((res) => {
                    let newShift: TShift = res
                    setShifts(prevState => {
                        return prevState.map((shift) => {
                            if (shift?.pk === pk) {
                                return {
                                    ...shift,
                                    ...newShift
                                }
                            }
                            return shift
                        })
                    })
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            setReFetchShifts(true)
        }
    }

    const fetchSingleShift = async (pk: number) => {
        return axiosInstance.get(SHIFTS_URL + `${weeklyShiftId}/shifts/${pk}/`)
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                throw err
            })
    }

    const fetchData = () => {
        setLoading(true)

        axiosInstance.get(SHIFTS_URL + `${weeklyShiftId}/shifts/`)
            .then((res) => {
                setShifts(res.data);
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
                setReFetchShifts(false);
            })
    }

    useEffect(() => {
        if (weeklyShiftId) {
            fetchData();
        } else {
            setShifts([]);
        }
    }, [weeklyShiftId, reFetchShifts]);

    return {shifts, loadingShifts, handleRefetchShifts}
}


export const useEmployeesOnShift = (lazyLoader: boolean, handleLazyLoader: () => void, shift?: TShiftFormatted) => {
    const [data, setData] = useState<{
        employee: TEmployee
        pk: number
        shift: number
        userUUID: string
    }[]>([]);
    const [loading, setLoading] = useState(false);
    const [reFetch, setReFetch] = useState(false);
    const axiosInstance = useAxiosInstance();

    const fetchData = () => {
        if (!shift) return

        setLoading(true)

        axiosInstance.get(SHIFTS_URL + `${shift.weeklyShift}/shifts/${shift.pk}/employees/`)
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
                setReFetch(false);
                handleLazyLoader();
            })
    }

    useEffect(() => {
        if (lazyLoader) fetchData()
    }, [reFetch, lazyLoader]);

    return {data, loading, setReFetch}
}