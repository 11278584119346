import {Avatar, AvatarFallback, AvatarImage} from "../../components/avatar";
import {CheckCircle, WarningCircle, XCircle} from "@phosphor-icons/react";
import React, {ReactNode} from "react";
import {firstLetterFromEachWord} from "../functions/first-letter-from-each-word";
import Modal from "./modal";
import {DialogClose, DialogFooter} from "../../components/dialog";
import {Button} from "../../components/button";

type DetailViewEmployeeAvatarProps = {
    username: string
    status?: ReactNode
    isActive?: boolean
}

const DetailViewEmployeeAvatar = (props: DetailViewEmployeeAvatarProps) => {
    return(
        <div className="flex items-center justify-between space-x-4">
            <div className="flex items-center space-x-4">
                <Avatar>
                    <AvatarImage src="/avatars/01.png"/>
                    <AvatarFallback>{firstLetterFromEachWord(props.username)}</AvatarFallback>
                </Avatar>
                <div className="flex flex-col gap-2">
                    <p className="text-lg font-bold leading-none capitalize">{props.username}</p>
                    <div className="flex flex-row gap-2">
                        {props?.status}
                        {props.hasOwnProperty("isActive") && props.isActive ? (
                            <div
                                className="flex flex-row items-center gap-1 border bg-green-500 text-white rounded-lg px-2 cursor-pointer">
                                <CheckCircle weight="fill" color="white"/>
                                <div>taim</div>
                            </div>
                        ) : props.hasOwnProperty("isActive") && !props.isActive ?(
                            <div
                                className="flex flex-row items-center gap-1 border bg-red-500 text-white rounded-lg px-2 cursor-pointer">
                                <XCircle weight="fill" color="white"/>
                                <div>taim</div>
                            </div>
                        ) : (
                            <div
                                className="flex flex-row items-center gap-1 border bg-gray-400 text-white rounded-lg px-2 cursor-pointer">
                                <WarningCircle weight="fill" color="white"/>
                                <div>taim</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailViewEmployeeAvatar;