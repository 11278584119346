import HighlightComponent from "Dashboard/Components/HighlightComponent";
import {AirplaneTilt, DesktopTower, FirstAidKit} from "@phosphor-icons/react";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Employee, useFetchDashboardDailyTrack} from "Dashboard/Controllers/Admin/daily-track-controller";

type EmployeesCountsProps = {
    reloadDashboard: boolean,
    setReloadDashboard: Dispatch<SetStateAction<boolean>>
}

const Overview: React.FC<EmployeesCountsProps> = (
    {
        reloadDashboard,
        setReloadDashboard
    }
) => {
    const intl = useIntl();
    const [payload, setPayload] = useState<any>({
        search: "",
        ordering: "",
        "filter.status": ""
    });
    const {
        dailyTrack,
        loadingDailyTrack
    } = useFetchDashboardDailyTrack(payload, reloadDashboard);
    const [working, setWorking] = useState<Employee[]>([]);
    const [dayOffAndVacation, setDayOffAndVacation] = useState<Employee[]>([]);
    const [sickLeave, setSickLeave] = useState<Employee[]>([]);

    useEffect(() => {
        if (dailyTrack.data.length) {
            setWorking(dailyTrack.data.filter((employee) => employee.status === "Check In"));
            setSickLeave(dailyTrack.data.filter((employee) => employee.status === "Sick Leave"));
            setDayOffAndVacation(dailyTrack.data.filter((employee) => employee.status === "Day Off" || employee.status === "Vacation"));
        }
    },[dailyTrack.data])
    return (
        <div className="col-span-1 flex flex-col gap-2 grow">
            <span className="font-semibold text-xl">
                <FormattedMessage
                    id={"dashboard.overview"}
                    defaultMessage={"Overview"}
                />
            </span>
            <div className="grow min-h-96 2xl:h-44 grid grid-cols-1 2xl:grid-cols-3 gap-4">
                <HighlightComponent
                    name={"working"}
                    Icon={DesktopTower}
                    iconColor={"#233B99"}
                    bgColor={"gray"}
                    users={working}
                    loading={loadingDailyTrack}
                />
                <HighlightComponent
                    name={"dayOff/vacation"}
                    Icon={AirplaneTilt}
                    iconColor={"#233B99"}
                    bgColor={"gray"}
                    users={dayOffAndVacation}
                    loading={loadingDailyTrack}
                />
                <HighlightComponent
                    name={"sickLeave"}
                    Icon={FirstAidKit}
                    iconColor={"#233B99"}
                    bgColor={"gray"}
                    users={sickLeave}
                    loading={loadingDailyTrack}
                />
            </div>
        </div>
    )
}

export default Overview;