import React, {useEffect, useLayoutEffect, useRef} from "react";
import {Badge} from "components/badge";
import {cn} from "lib/utils";
import {Cursor} from "@phosphor-icons/react";
import {transformActivities} from "./transform-activities";
import {timelineHours} from "./constants";
import {Usertrack} from "./usertrack";
import moment from "moment";
import {AttendanceFormatted} from "AttendanceManagement/Controllers/attendances-controller";

type CalendarProps = {
    employees: AttendanceFormatted[]
    date?: string
    setDetailData: React.Dispatch<AttendanceFormatted | null>
}


const Calendar: React.FC<CalendarProps> = ({
    employees,
    date,
    setDetailData
}) => {
    const currentDate = moment();
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        let currentHour = moment().get("hour");
        const scrollElement = document.querySelector(".scrollable");

        if (scrollElement) {
            const totalScrollWidth = scrollElement.scrollWidth;
            const intervalWidth = Math.floor(totalScrollWidth / 24);

            scrollElement.scrollLeft = intervalWidth * (currentHour > 0 ? currentHour - 1 : currentHour);
        }
    };


    useLayoutEffect(() => {
        if (document.querySelector(".scrollable")) handleScroll();
    }, [employees]);

    useEffect(() => {
        const handleClick = (event: KeyboardEvent) => {
            if (event.key == "ArrowRight" && scrollContainerRef.current) {
                scrollContainerRef.current.scrollLeft += 150
            } else if (event.key == "ArrowLeft" && scrollContainerRef.current) {
                scrollContainerRef.current.scrollLeft -= 150
            }
        };

        window.addEventListener('keydown', handleClick);

        return () => {
          window.removeEventListener('keydown', handleClick);
        };
      }, []);

    return (
        <div className="flex flex-col">
            <div className="grid grid-cols-11 gap-3 m-4 overflow-y-hidden">
                <div className="col-span-2">
                    <div className="h-[30px]"></div>
                    {employees?.length && employees.map((employee,index) =>
                        <div className="flex flex-col justify-center items-start h-24 relative overflow-auto no-scrollbar" key={index}>
                            <div className="text-black font-bold leading-6 capitalize cursor-pointer" onClick={() => setDetailData(employee)}>{employee.userFullName}</div>
                        </div>
                    )}
                </div>
                <div className="col-span-8 overflow-x-auto scroll-smooth scrollable no-scrollbar" ref={scrollContainerRef}>
                    <div className="h-[30px] flex flex-row">
                        {timelineHours.map((timelineHour, index) => (
                            <div key={index} className="flex justify-center items-center min-w-[60px]">
                                <div className="text-xs text-gray-400">{timelineHour}</div>
                            </div>
                        ))}
                    </div>
                    {employees?.length && employees.map((employee,index) => {
                        let isCurr = currentDate.format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY")
                        return (
                            <Usertrack
                                key={index}
                                activities={transformActivities(employee.activities, isCurr, date)}
                                isCurrentDate={isCurr}
                            />
                        )
                    })}
                </div>
                <div className="col-span-1">
                    <div className="h-[30px]"></div>
                    {employees.length && employees.map((employee,index) =>
                        <div className="h-24 flex items-center" key={index}>
                            <Badge
                                className={cn(
                                    "w-full py-3 justify-center uppercase text-center",
                                    employee?.status !== "Check In" && "border-gray-100 bg-gray-50 text-gray-500",
                                )}
                                variant={"secondary2"}
                            >{employee?.status}</Badge>
                        </div>
                    )}
                </div>
            </div>
            <div className="w-full text-center text-xs text-gray-500/50 flex items-center justify-center gap-2">
                <span>click on username to see more details</span>
                (<Cursor/>)
            </div>
        </div>
    );
}

export default Calendar;