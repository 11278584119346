import React, {useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {Button} from "components/button";
import {
    PublicHolidaysPayload,
    useFetchPublicHolidaysDataTable
} from "../Controllers/public-holidays-controller";
import PublicHolidaysDataTable from "AttendanceManagement/DataTables/public-holidays-data-table";
import PublicHolidayDetailView from "AttendanceManagement/DetailViews/public-holiday-detail-view";
import {CreatePublicHolidayForm} from "AttendanceManagement/Forms/public-holiday-forms";
import {employeeManagementUrl} from "EmployeeManagement";
import {federalState} from "EmployeeManagement/Controllers/employee-controller";
import {PublicHolidayFormatted} from "AttendanceManagement/Controllers/public-holidays-controller";
import {FormattedMessage, useIntl} from "react-intl";
import {MainTab, PageActions, PageEntrySchema} from "Core/layout/page-shape";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";

const initialPayload = {
    start: 0,
    length:10,
    search: "",
    ordering: "-date,name,stateCode",
}

const PublicHolidaysEntry: React.FC<PageEntrySchema> = ({name, pagesState}) => {
    const intl = useIntl();
    const [payload, setPayload] = useState<PublicHolidaysPayload>(initialPayload);
    const {
        request,
        loading,
        reFetch
    } = useFetchPublicHolidaysDataTable(payload);
    const [publicHoliday, setPublicHoliday] = useState<PublicHolidayFormatted | null>(null);
    const [federalStates, setFederalStates] = useState<federalState[]>([]);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchFederalStates = () => {
        axiosInstance.get(employeeManagementUrl + 'states/')
            .then((res) => {
                setFederalStates(res.data)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchFederalStates();
    },[permissionContext.isAdmin]);

    useEffect(() => {
        let currPage = pagesState?.find(page => page.page.value === name);
        if (currPage && currPage.reFetch) {
            reFetch(true);
        }
    }, [pagesState]);

    return (
        <MainTab
            type="mainAndDetail"
            name={name}
            main={{
                title: intl.formatMessage({id: "table.list", defaultMessage: "List"}),
                content: <PublicHolidaysDataTable
                    publicHolidaysRequest={request}
                    payload={payload}
                    setPayload={setPayload}
                    detailData={publicHoliday}
                    setDetailData={setPublicHoliday}
                    loading={loading}
                    reFetch={reFetch}
                />
            }}
            detail={{
                title: intl.formatMessage({id: "attendance.publicHolidays.detail", defaultMessage: "Public Holiday Detail"}),
                content: <PublicHolidayDetailView publicHoliday={publicHoliday} loading={loading}/>
            }}
            actions={[
                {
                    type: "modal",
                    button: (
                        <Button variant="taimDefault">
                            <FormattedMessage
                                id={"attendance.publicHolidays.create"}
                                defaultMessage={"Create Public Holiday"}
                            />
                        </Button>
                    ),
                    header: intl.formatMessage({id: "attendance.publicHolidays.create", defaultMessage: "Create Public Holiday"}),
                    children: (<CreatePublicHolidayForm
                        federalStates={federalStates}
                        reFetch={reFetch}
                    />)
                }
            ].filter((elem) => {
                if (permissionContext.isAdmin) return elem
            }) as PageActions[]}
        />
    )
}

export default PublicHolidaysEntry;