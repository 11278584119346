import * as React from "react"
import { CheckIcon, PlusCircledIcon } from "@radix-ui/react-icons"

import { cn } from "../../lib/utils"
import { Badge } from "../../components/badge"
import { Button } from "../../components/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "../../components/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/popover"
import { Separator } from "../../components/seperator"
import {SetStateAction, useEffect, useState} from "react";
import {Option} from "./data-table-tools";
import {DataTablePayload} from "./interfaces";
import {FormattedMessage, useIntl} from "react-intl";



interface DataTableFacetedFilterProps {
    title?: string
    filterBy: string
    payload: DataTablePayload
    setPayload: React.Dispatch<SetStateAction<DataTablePayload>>
    options: Option[]
}

export function DataTableMultipleFilter({
    title,
    filterBy,
    payload,
    setPayload,
    options,
}: DataTableFacetedFilterProps) {
    const intl = useIntl()
    const [selectedValues, setSelectedValues] = useState<Set<string>>(new Set())
    const [optionsSet, setOptionsSet] = useState<Set<string>>(
        selectedValues.size ? new Set() : new Set(options.map((opt) => opt.value))
    )

    const handleSelect = (value: string) => {
        if (selectedValues.has(value)) {
            setSelectedValues((prevState) => {
                if (value === '') return new Set()

                if (setPayload) setPayload((nestedPrevState: any) => ({
                    ...nestedPrevState,
                    start: 0,
                    [filterBy]: [...prevState].filter(elem => elem !== value).join(',')
                }))
                return new Set(
                    [...prevState].filter(elem => elem !== value)
                );
            })
        } else {
            setSelectedValues((prevState) => {
                if (value === '') return new Set(options.map((opt) => opt.value))

                if (setPayload) setPayload((nestedPrevState: any) => ({
                    ...nestedPrevState,
                    start: 0,
                    [filterBy]: [...prevState, value].join(',')
                }))
                return new Set([...prevState, value]);
            })
        }
    }

    const selectAll = () => {
        setSelectedValues(() => {
            let newFilters = ''

            if (selectedValues.size < optionsSet.size) [...optionsSet].forEach(option => newFilters += newFilters.length ? `,${option}` : option)

            if (setPayload) setPayload((payloadPrevState: any) => ({
                ...payloadPrevState,
                start: 0,
                [filterBy]: newFilters
            }))
            return (selectedValues.size < optionsSet.size) ? optionsSet : new Set()
        })
    }

    const equalSets = (set1: Set<any>, set2: Set<any>) => {
        return (set1.size === set2.size) && [...set1].every(x => set2.has(x))
    }

    useEffect(() => {
        setSelectedValues(prevState => {
            if (payload.hasOwnProperty(filterBy) && payload[filterBy]) {
                return new Set([
                    ...prevState, ...(String(payload[filterBy])?.split(','))
                ])
            }
            return prevState;
        })
    }, []);

    useEffect(() => {
        if (options.length && !optionsSet.size) {
            setOptionsSet(new Set(options.map((opt) => opt.value)))
        }
    }, [options]);

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button variant="outline" size="sm" className="h-8 border-dashed">
                    <PlusCircledIcon className="mr-2 h-4 w-4" />
                    {title}
                    {selectedValues?.size > 0 && (
                    <>
                        <Separator orientation="vertical" className="mx-2 h-4" />
                            <Badge
                                variant="secondary"
                                className="rounded-sm px-1 font-normal lg:hidden"
                            >
                                {selectedValues.size}
                            </Badge>
                            <div className="hidden space-x-1 lg:flex">
                            {selectedValues.size > 2 ? (
                                <Badge
                                    variant="secondary"
                                    className="rounded-sm px-1 font-normal truncate"
                                >
                                    {selectedValues.has('') ? selectedValues.size -1 : selectedValues.size } selected
                                </Badge>
                            ) : (
                                options
                                    .filter((option) => selectedValues.has(option.value))
                                    .map((option) => (
                                        <Badge
                                            variant="secondary"
                                            key={option.value}
                                            className="rounded-sm px-1 font-normal"
                                        >
                                            {
                                                option?.translationName
                                                    ? intl.formatMessage({id: option.translationName, defaultMessage: option.label})
                                                    : option.label
                                            }
                                        </Badge>
                                    ))
                            )}
                        </div>
                    </>
                )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0" align="start">
                <Command>
                    <CommandInput placeholder={title} />
                    <CommandList>
                        <CommandEmpty>No results found.</CommandEmpty>
                        <CommandGroup>
                            <CommandItem
                                onSelect={selectAll}
                            >
                            <div
                                className={cn(
                                    "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                                    equalSets(optionsSet, selectedValues)
                                    ? "bg-primary text-primary-foreground"
                                    : "opacity-50 [&_svg]:invisible"
                                )}
                            >
                                <CheckIcon className={cn("h-4 w-4")} />
                            </div>
                            <span>
                                <FormattedMessage id={"multi_select.select_all"} defaultMessage={"(Select All)"} />
                            </span>
                        </CommandItem>
                            {options.map((option) => {
                                return (
                                    <CommandItem
                                            key={option.value}
                                            onSelect={() => handleSelect(option.value)}
                                        >
                                        <div
                                            className={cn(
                                                "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                                                selectedValues.has(option.value)
                                                ? "bg-primary text-primary-foreground"
                                                : "opacity-50 [&_svg]:invisible"
                                            )}
                                        >
                                            <CheckIcon className={cn("h-4 w-4")} />
                                        </div>
                                        {option.icon && (
                                            <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                                        )}
                                        <span>
                                        {
                                            option?.translationName
                                                ? intl.formatMessage({id: option.translationName, defaultMessage: option.label})
                                                : option.label
                                        }
                                        </span>
                                    </CommandItem>
                                )
                            })}
                        </CommandGroup>
                        {selectedValues.size > 0 && (
                            <>
                                <CommandSeparator />
                                <CommandGroup>
                                    <CommandItem
                                        onSelect={() => {
                                            setSelectedValues(new Set())
                                            setPayload((prevState: any) => {
                                                return {
                                                    ...prevState,
                                                    [filterBy]: ''
                                                }
                                            })
                                        }}
                                        className="justify-center text-center"
                                    >
                                        <FormattedMessage id={"clear"} defaultMessage={"Clear"} />
                                    </CommandItem>
                                </CommandGroup>
                            </>
                        )}
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}