import {ServicePayload, useFetchServices} from "ProjectManagement/Controller/services-controller";
import React, {useContext, useEffect, useState} from "react";
import {ServiceFormatted, ServicesTable} from "../DataTables/services-table";
import {CreateServiceForm} from "../Forms/service-forms";
import {Button} from "../../components/button";
import {FormattedMessage, useIntl} from "react-intl";
import {MainTab, PageActions, PageState} from "../../Core/layout/page-shape";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";

const servicesPayloadInitialState: ServicePayload = {
    start: 0,
    length: 10,
    search: "",
    ordering: "",
}

type ServicesProps = {
    name: string
    pagesState?: PageState[]
}

export const Services: React.FC<ServicesProps> = ({name, pagesState}) => {
    const intl = useIntl();
    const [
        servicesPayload,
        setServicesPayload
    ] = useState<ServicePayload>(servicesPayloadInitialState);
    const {
        servicesRequest,
        loadingServices,
        shouldRefetchServices
    } = useFetchServices(servicesPayload);
    const [
        servicesDetail,
        setServiceDetail
    ] = useState<ServiceFormatted | null>(null);
    const permissionContext = useContext(PermissionContext);


    useEffect(() => {
        let currPage = pagesState?.find(page => page.page.value === name);
        if (currPage && currPage.reFetch) {
            // setServicesPayload(servicesPayloadInitialState);
            shouldRefetchServices(true);
        }
    }, [pagesState]);

    return <MainTab
        type={"mainOnly"}
        name={name}
        main={{
            title: intl.formatMessage({id: "table.list", defaultMessage: "List"}),
            content: (<ServicesTable
                request={servicesRequest}
                payload={servicesPayload}
                setPayload={setServicesPayload}
                loading={loadingServices}
                detailData={servicesDetail}
                setDetailData={setServiceDetail}
                reloadData={shouldRefetchServices}
            />)
        }}
        actions={[
            {
                type: "modal",
                button: (
                    <Button variant={"taimDefault"}>
                        <FormattedMessage id={"project.services.createButton"} defaultMessage={"Create Service"}/>
                    </Button>
                ),
                header: intl.formatMessage({id: "project.services.createButton", defaultMessage: "Create Service"}),
                children: (<CreateServiceForm reloadData={shouldRefetchServices}/>)
            }
        ].filter((elem) => {
            if (permissionContext.isAdmin) return elem
        }) as PageActions[]}
    />
}