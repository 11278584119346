import DataTableOld, {ColumnOld} from "../../Core/Table/data-table-old";
import React, {Dispatch, SetStateAction} from "react";
import DataTableTools from "../../Core/Table/data-table-tools";
import DataTablePagination from "../../Core/Table/data-table-pagination";
import {Action} from "../../Core/Table/data-table-actions";
import {location, locationsRequest, locationsUrl} from "../Controllers/locations-controller";
import {useIntl} from "react-intl";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {toast} from "../../components/use-toast";
import {DataTablePayload} from "../../Core/Table/interfaces";

type DataTableProps = {
    locationsRequest: locationsRequest
    payload: DataTablePayload
    setPayload: React.Dispatch<SetStateAction<DataTablePayload>>
    loading: boolean
    reFetch: Dispatch<SetStateAction<boolean>>
}

const LocationsDataTable = ({locationsRequest, payload, setPayload, loading, reFetch}: DataTableProps) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const locationColumns: ColumnOld[] = [
        {
            backendKey: "name",
            frontendKey: intl.formatMessage({id: "attendance.locations.table.location", defaultMessage: "Location"}),
            sortable: true
        },
        {
            backendKey: "fullAddress",
            frontendKey: intl.formatMessage({id: "attendance.locations.table.address", defaultMessage: "Address"}),
            sortable: true
        },
        {backendKey: "actions", frontendKey: "actions"},
    ];


    const deleteLocation = (id: string) => {
        axiosInstance.delete(locationsUrl + `${id}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                reFetch(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    const formatData = locationsRequest?.data?.map((location: location) => {
        return {
            ...location,
            actions: [
                {type: "delete", handleAction: () => deleteLocation(location.uuid)}
            ] as Action[]
        };
    })

    return(
        <div className="space-y-4 h-full flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools payload={payload} setPayload={setPayload}/>
                <DataTableOld
                    data={formatData}
                    loading={loading}
                    detailData={locationsRequest.data}
                    setPayload={setPayload}
                    columns={locationColumns}
                    payload={payload}
                />
            </div>
            <DataTablePagination request={locationsRequest} payload={payload} setPayload={setPayload}/>
        </div>
    )
}

export default LocationsDataTable;