import React, {useContext, useEffect, useRef, useState} from "react";
import {PermissionContext} from "../../../Core/utilities/PermissionProvider";
import {useAxiosInstance} from "../../../Core/utilities/AxiosInstance";
import {WORKING_TIME_ACCOUNT_URL} from "./index";
import DataTableOld, {ColumnOld} from "../../../Core/Table/data-table-old";
import DataTablePagination from "../../../Core/Table/data-table-pagination";
import {DataTablePayload, DataTableRequest} from "../../../Core/Table/interfaces";
import {Action} from "../../../Core/Table/data-table-actions";
import {TaimModal} from "../../../Core/components/taim-modal";
import {Button} from "../../../components/button";
import {FormattedMessage, useIntl} from "react-intl";
import {useForm} from "react-hook-form";
import * as z from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {ServerErrorsType, useServerErrors} from "../../../Core/functions/use-server-errors";
import moment from "moment/moment";
import {toast} from "../../../components/use-toast";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "../../../components/form";
import {Input} from "../../../components/input";
import ErrorMessageList from "../../../Core/components/ErrorMessageList";
import {DialogClose, DialogFooter} from "../../../components/dialog";
import {Select, SelectContent, SelectItem, SelectNull, SelectTrigger, SelectValue} from "../../../components/select";
import {getYearsArray} from "../../../Core/constants/variables";
import {transformDuration, transformHoursAndMinutesToDuration} from "../../../Core/functions/transform-duration";

const DROPDOWN_YEARS = getYearsArray()

type PaidOvertimes = {
    pk: number
    user: number
    paidDuration: string
    paidDurationInHours: string
    monthDate: string
}

type FormattedPaidOvertimes = PaidOvertimes & {
    actions: Action[]
    monthDateFormatted: string
}

export interface PaidOvertimesResponse extends DataTableRequest {
    data: PaidOvertimes[]
}

const paidOvertimesSchema = z.object({
    paidDurationHours: z.any(),
    paidDurationMinutes: z.any(),
    year: z.any(),
    month: z.any(),
});

const CreateForm: React.FC<{userUUID: string, handleRefetch: (v: boolean) => void}> = ({userUUID, handleRefetch}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof paidOvertimesSchema>>({
        resolver: zodResolver(paidOvertimesSchema),
        defaultValues: {
            paidDurationHours: 0,
            paidDurationMinutes: 0,
        }
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();
    const months = moment.months();

    const onSubmit = (values: z.infer<typeof paidOvertimesSchema>) => {
        let payload = {
            ...((values.paidDurationHours || values.paidDurationMinutes) && {
                paidDuration: transformHoursAndMinutesToDuration({
                    hours: values.paidDurationHours,
                    minutes: values.paidDurationMinutes
                })
            }),
            monthDate: values.year && values.month ? moment({
                year: values.year,
                month: values.month
            }).format('YYYY-MM-DD') : null,
        }

        axiosInstance.post(WORKING_TIME_ACCOUNT_URL + `${userUUID}/paid-overtimes/`, payload)
            .then((res) => {
                setApiErrors({});
                closeModal.current?.click();
                handleRefetch(true);
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response?.data)
            })
    };

    useEffect(() => {
        let year = form.getValues('year')
        let month = form.getValues('month')

        if (errors.fieldErrors['monthDate']) {
            if (!year) form.setError('year', {message: errors.printErrorFieldMessage('monthDate')})
            if (!month) form.setError('month', {message: errors.printErrorFieldMessage('monthDate')})
        }

        if (errors.fieldErrors["paidDuration"]) {
            form.setError('paidDurationHours', {message: errors.printErrorFieldMessage('paidDuration')})
            form.setError('paidDurationMinutes', {message: errors.printErrorFieldMessage('paidDuration')})
        }
    }, [errors.fieldErrors]);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <div className="flex flex-col space-y-1">
                    <div>
                        <FormattedMessage
                            id={"paid.duration"}
                            defaultMessage={"Paid Duration"}
                        />
                    </div>
                    <div className="flex space-x-2">
                        <FormField
                            control={form.control}
                            name="paidDurationHours"
                            render={({ field }) => (
                                <FormItem className="w-1/2">
                                    <FormLabel className="text-gray-500">
                                        <FormattedMessage
                                            id={"hours"}
                                            defaultMessage={"Hours"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder={intl.formatMessage({id: "set.hours", defaultMessage: "Set hours"})}
                                            min={0}
                                            type={"number"}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="paidDurationMinutes"
                            render={({ field }) => (
                                <FormItem className="w-1/2">
                                    <FormLabel className="text-gray-500">
                                        <FormattedMessage
                                            id={"minutes"}
                                            defaultMessage={"Minutes"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder={intl.formatMessage({id: "set.hours", defaultMessage: "Set hours"})}
                                            min={0}
                                            max={59}
                                            type={"number"}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>

                <div className="flex space-x-2">
                    <FormField
                        control={form.control}
                        name="year"
                        render={({ field }) => (
                            <FormItem className="w-1/2">
                                <FormLabel>
                                    <FormattedMessage
                                        id={"year"}
                                        defaultMessage={"Year"}
                                    />
                                </FormLabel>
                                <FormControl>
                                    <Select onValueChange={field.onChange} value={field.value}>
                                        <SelectTrigger className="select-none">
                                            <SelectValue placeholder={intl.formatMessage({id: "pick.year", defaultMessage: "Pick Year"})}/>
                                        </SelectTrigger>
                                        <SelectContent className="max-h-[50vh]">
                                            <SelectNull field={field}/>
                                            {DROPDOWN_YEARS.map((year) => (
                                                <SelectItem value={(year).toString()}>{year}</SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="month"
                        render={({ field }) => (
                            <FormItem className="w-1/2">
                                <FormLabel>
                                    <FormattedMessage
                                        id={"month"}
                                        defaultMessage={"Month"}
                                    />
                                </FormLabel>
                                <FormControl>
                                    <Select onValueChange={field.onChange} value={field.value}>
                                        <SelectTrigger className="select-none">
                                            <SelectValue placeholder={intl.formatMessage({id: "pick.month", defaultMessage: "Pick Month"})}/>
                                        </SelectTrigger>
                                        <SelectContent className="max-h-[50vh]">
                                            <SelectNull field={field}/>
                                            {months.map((month, index) => (
                                                <SelectItem value={index.toString()}>{month}</SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <ErrorMessageList errors={errors.detailErrors}/>
                <ErrorMessageList errors={errors.nonFieldErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}

const EditForm: React.FC<{record: PaidOvertimes, userUUID: string, handleRefetch: (v: boolean) => void}> = ({record, userUUID, handleRefetch}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof paidOvertimesSchema>>({
        resolver: zodResolver(paidOvertimesSchema),
        defaultValues: {
            ...(record.paidDuration && {
                paidDurationHours: transformDuration(record.paidDuration, "HH").value,
                paidDurationMinutes: transformDuration(record.paidDuration, "mm").value,
            }),
            ...(record.monthDate && {
                month: moment(record.monthDate, 'YYYY-MM-DD').get('month').toString(),
                year: moment(record.monthDate, 'YYYY-MM-DD').get('year').toString(),
            })
        }
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();
    const months = moment.months();

    const onSubmit = (values: z.infer<typeof paidOvertimesSchema>) => {
        let payload = {
            ...((values.paidDurationHours || values.paidDurationMinutes) && {
                paidDuration: transformHoursAndMinutesToDuration({
                    hours: values.paidDurationHours,
                    minutes: values.paidDurationMinutes
                })
            }),
            monthDate: values.year && values.month ? moment({
                year: values.year,
                month: values.month
            }).format('YYYY-MM-DD') : null,
        }

        axiosInstance.put(WORKING_TIME_ACCOUNT_URL + `${userUUID}/paid-overtimes/${record.pk}/`, payload)
            .then((res) => {
                setApiErrors({});
                closeModal.current?.click();
                handleRefetch(true);
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response?.data)
            })
    };

    useEffect(() => {
        let year = form.getValues('year')
        let month = form.getValues('month')

        if (errors.fieldErrors['monthDate']) {
            if (!year) form.setError('year', {message: errors.printErrorFieldMessage('monthDate')})
            if (!month) form.setError('month', {message: errors.printErrorFieldMessage('monthDate')})
        }

        if (errors.fieldErrors["paidDuration"]) {
            form.setError('paidDurationHours', {message: errors.printErrorFieldMessage('paidDuration')})
            form.setError('paidDurationMinutes', {message: errors.printErrorFieldMessage('paidDuration')})
        }
    }, [errors.fieldErrors]);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <div className="flex flex-col space-y-1">
                    <div>
                        <FormattedMessage
                            id={"paid.duration"}
                            defaultMessage={"Paid Duration"}
                        />
                    </div>
                    <div className="flex space-x-2">
                        <FormField
                            control={form.control}
                            name="paidDurationHours"
                            render={({ field }) => (
                                <FormItem className="w-1/2">
                                    <FormLabel className="text-gray-500">
                                        <FormattedMessage
                                            id={"hours"}
                                            defaultMessage={"Hours"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder={intl.formatMessage({id: "set.hours", defaultMessage: "Set hours"})}
                                            min={0}
                                            type={"number"}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="paidDurationMinutes"
                            render={({ field }) => (
                                <FormItem className="w-1/2">
                                    <FormLabel className="text-gray-500">
                                        <FormattedMessage
                                            id={"minutes"}
                                            defaultMessage={"Minutes"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder={intl.formatMessage({id: "set.hours", defaultMessage: "Set hours"})}
                                            min={0}
                                            max={59}
                                            type={"number"}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>

                <div className="flex space-x-2">
                    <FormField
                        control={form.control}
                        name="year"
                        render={({ field }) => (
                            <FormItem className="w-1/2">
                                <FormLabel>
                                    <FormattedMessage
                                        id={"year"}
                                        defaultMessage={"Year"}
                                    />
                                </FormLabel>
                                <FormControl>
                                    <Select onValueChange={field.onChange} value={field.value}>
                                        <SelectTrigger className="select-none">
                                            <SelectValue placeholder={intl.formatMessage({id: "pick.year", defaultMessage: "Pick Year"})}/>
                                        </SelectTrigger>
                                        <SelectContent className="max-h-[50vh]">
                                            <SelectNull field={field}/>
                                            {DROPDOWN_YEARS.map((year) => (
                                                <SelectItem value={(year).toString()}>{year}</SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="month"
                        render={({ field }) => (
                            <FormItem className="w-1/2">
                                <FormLabel>
                                    <FormattedMessage
                                        id={"month"}
                                        defaultMessage={"Month"}
                                    />
                                </FormLabel>
                                <FormControl>
                                    <Select onValueChange={field.onChange} value={field.value}>
                                        <SelectTrigger className="select-none">
                                            <SelectValue placeholder={intl.formatMessage({id: "pick.month", defaultMessage: "Pick Month"})}/>
                                        </SelectTrigger>
                                        <SelectContent className="max-h-[50vh]">
                                            <SelectNull field={field}/>
                                            {months.map((month, index) => (
                                                <SelectItem value={index.toString()}>{month}</SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <ErrorMessageList errors={errors.detailErrors}/>
                <ErrorMessageList errors={errors.nonFieldErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}


export const usePaidOvertimes = (payload: DataTablePayload, userUUID: string, refetch: boolean, handleRefetch: (v: boolean) => void) => {
    const intl = useIntl();
    const [response, setResponse] = useState<PaidOvertimesResponse>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    })
    const [formattedData, setFormattedData] = useState<FormattedPaidOvertimes[]>([])
    const [loading, setLoading] = useState(false)
    const permissionContext = useContext(PermissionContext)
    const axiosInstance = useAxiosInstance()

    const fetchData = () => {
        setLoading(true);

        axiosInstance.get(WORKING_TIME_ACCOUNT_URL + `${userUUID}/paid-overtimes/`, {
            params: payload
        })
            .then(res => {
                let data: FormattedPaidOvertimes[] = res.data?.data?.map((rec: PaidOvertimes) => ({
                    ...rec,
                    ...(rec.monthDate && {monthDateFormatted: moment(rec.monthDate, 'YYYY-MM-DD').format('MMM YYYY')}),
                    actions: [
                        {
                            type: 'edit',
                            content: <EditForm record={rec} userUUID={userUUID} handleRefetch={handleRefetch}/>
                        },
                        {
                            type: 'delete',
                            handleAction: () => deleteRecord(rec.pk)
                        },
                    ]
                } as FormattedPaidOvertimes))
                setResponse(res.data)
                setFormattedData(data)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setLoading(false)
                handleRefetch(false)
            })
    }

    const deleteRecord = (pk: number) => {
        axiosInstance.delete(WORKING_TIME_ACCOUNT_URL + `${userUUID}/paid-overtimes/${pk}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                handleRefetch(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }


    useEffect(() => {
        fetchData();
    }, [payload, userUUID, refetch, permissionContext.isAdmin]);

    return {response, formattedData, loading}
}

export const PaidOvertimesTable: React.FC<{userUUID: string}> = ({userUUID}) => {
    const intl = useIntl()
    const permissionContext = useContext(PermissionContext)
    const [refetch, setRefetch] = useState(false)
    const handleRefetch = (v: boolean) => setRefetch(v)
    const [payload, setPayload] = useState<DataTablePayload>({
        start: 0,
        length: 5
    })
    const {
        response,
        formattedData,
        loading
    } = usePaidOvertimes(payload, userUUID, refetch, handleRefetch)

    const columns: ColumnOld[] = [
        {
            frontendKey: intl.formatMessage({id: "date", defaultMessage: "Date"}),
            backendKey: 'monthDateFormatted'
        },
        {
            frontendKey: intl.formatMessage({id: "duration", defaultMessage: "Duration"}),
            backendKey: 'paidDurationInHours'
        },
        {
            frontendKey: 'actions',
            backendKey: 'actions'
        }
    ]



    return (
        <div className="flex flex-col space-y-4">
            <DataTableOld
                columns={columns}
                data={formattedData}
                loading={loading}
                payload={payload}
                setPayload={setPayload}
            />
            <DataTablePagination request={response} payload={payload} setPayload={setPayload}/>
            {permissionContext.isAdmin && (
                <TaimModal
                    button={(
                        <Button variant="taimDefault2">
                            <FormattedMessage id="employees.detail.working_time_account.paid_overtimes.create" defaultMessage="Add Paid Overtime"/>
                        </Button>
                    )}
                    header={intl.formatMessage({id: "employees.detail.working_time_account.paid_overtimes.create", defaultMessage: "Add Paid Overtime"})}
                    // dialogContentClassName={"min-w-[600px]"}
                >
                    <CreateForm userUUID={userUUID} handleRefetch={handleRefetch}/>
                </TaimModal>
            )}
        </div>
    )
}