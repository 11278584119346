import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "components/button"
import {
    Form, FormControl, FormField, FormItem, FormLabel,
    FormMessage,
} from "components/form"
import {DialogClose, DialogFooter} from "components/dialog";
import React, {useRef, useState} from "react";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {toast} from "components/use-toast";
import {Input} from "components/input";
import {FormattedMessage, useIntl} from "react-intl";
import {ServerErrorsType, useServerErrors} from "Core/functions/use-server-errors";
import ErrorMessageList from "Core/components/ErrorMessageList";
import {devicesUrl} from "../index";
import {Device} from "../Controller/devices-controller";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/select";
import {nullOrUndefined} from "../../Core/constants/variables";

const DEVICE_TYPES = [
    {key: 'Terminal', value: "terminal"}
]

const DEVICE_DEFAULT_VALUES = {
    name: "",
    deviceType: ""
}

const addDeviceFormSchema = z.object({
    name: z.any(),
    deviceType: z.any()
});

type AddDeviceProps = {
    reFetch?: any
}

export const AddDevice: React.FC<AddDeviceProps>  = ({reFetch}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof addDeviceFormSchema>>({
        resolver: zodResolver(addDeviceFormSchema),
        defaultValues: DEVICE_DEFAULT_VALUES
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();

    const onSubmit = (values: z.infer<typeof addDeviceFormSchema>) => {
        axiosInstance.post(devicesUrl, {
            name: values.name,
            ...((values.deviceType && values.deviceType !== "null") && {deviceType: values.deviceType})
        })
            .then((res) => {
                setApiErrors({});
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
                reFetch(true);
            })
            .catch((err) => {
                setApiErrors(err.response?.data)
            })
    };


    return (
    <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>
                            <FormattedMessage
                                id={"sd.devices.name"}
                                defaultMessage={"Name"}
                            />
                        </FormLabel>
                        <FormControl>
                            <Input
                                placeholder={intl.formatMessage({
                                    id: "sd.devices.namePlaceholder",
                                    defaultMessage: "Enter a name"
                                })}
                                {...field}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <FormField
                control={form.control}
                name="deviceType"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>
                            <FormattedMessage
                                id={"sd.devices.deviceType"}
                                defaultMessage={"Type"}
                            />
                        </FormLabel>
                        <FormControl>
                            <Select onValueChange={field.onChange} value={field.value}>
                                <SelectTrigger>
                                    <SelectValue placeholder={intl.formatMessage({id: "sd.devices.deviceTypePlaceholder", defaultMessage: "Select a type"})}/>
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value={"null"}>{nullOrUndefined}</SelectItem>
                                    {DEVICE_TYPES.map(type => (
                                        <SelectItem value={type.value}>{type.key}</SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <ErrorMessageList errors={errors.detailErrors}/>
            <ErrorMessageList errors={errors.nonFieldErrors}/>

            <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                <DialogClose ref={closeModal as any} asChild>
                    <Button variant="outline">
                        <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                    </Button>
                </DialogClose>
                <Button
                    variant="taimDefault"
                    type="submit"
                ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
            </DialogFooter>
        </form>
    </Form>
    )
}



const editDevicesFormSchema = z.object({
    name: z.any(),
    deviceType: z.any()
});

type EditDeviceProps = {
    device: Device
    reFetch?: any
}

export const EditDevice: React.FC<EditDeviceProps>  = ({
    device,
    reFetch,
}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof editDevicesFormSchema>>({
        resolver: zodResolver(editDevicesFormSchema),
        defaultValues: device
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();

    const onSubmit = (values: z.infer<typeof editDevicesFormSchema>) => {
        axiosInstance.put(devicesUrl + `${device.pk}/`, {
            name: values.name,
            ...((values.deviceType && values.deviceType !== "null") && {deviceType: values.deviceType})
        })
            .then((res) => {
                setApiErrors({});
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
                reFetch(true);
            })
            .catch((err) => {
                setApiErrors(err.response?.data)
            })
    };

    return (
    <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>
                            <FormattedMessage
                                id={"sd.devices.name"}
                                defaultMessage={"Name"}
                            />
                        </FormLabel>
                        <FormControl>
                            <Input
                                placeholder={intl.formatMessage({
                                    id: "sd.devices.namePlaceholder",
                                    defaultMessage: "Enter a name"
                                })}
                                {...field}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <FormField
                control={form.control}
                name="deviceType"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>
                            <FormattedMessage
                                id={"sd.devices.deviceType"}
                                defaultMessage={"Type"}
                            />
                        </FormLabel>
                        <FormControl>
                            <Select onValueChange={field.onChange} value={field.value}>
                                <SelectTrigger>
                                    <SelectValue placeholder={intl.formatMessage({id: "sd.devices.deviceTypePlaceholder", defaultMessage: "Select a type"})}/>
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value={"null"}>{nullOrUndefined}</SelectItem>
                                    {DEVICE_TYPES.map(type => (
                                        <SelectItem value={type.value}>{type.key}</SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <ErrorMessageList errors={errors.detailErrors}/>
            <ErrorMessageList errors={errors.nonFieldErrors}/>

            <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                <DialogClose ref={closeModal as any} asChild>
                    <Button variant="outline">
                        <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                    </Button>
                </DialogClose>
                <Button
                    variant="taimDefault"
                    type="submit"
                ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
            </DialogFooter>
        </form>
    </Form>
    )
}