import {useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";

export const rolesUrl: string = 'employee-management/roles/';

export type rolesRequest = {
    recordsTotal: number
    recordsFiltered: number
    next: string
    previous: string
    data: role[]
}

export type rolesPayload = {
    start: number
    length: number
    search?: string
    ordering?: string
}

export type role = {
    pk: string
    name: string,
    totalEmployees: string
    employees?: {
        firstName:string
        lastName: string
        fullName: string
    }[]
}


export const useFetchRolesDataTable = (payload: rolesPayload) => {
    const axiosInstance = useAxiosInstance();
    const [shouldReFetch, reFetch] = useState(false)
    const [dataRequest, setData] = useState<rolesRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const permissionContext = useContext(PermissionContext);

    const fetchEmployeesData = () => {
        setLoading(true);
        axiosInstance.get(rolesUrl,{
            params: payload
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                reFetch(false);
            })
    }

    useEffect( () => {
        fetchEmployeesData();
    }, [payload, shouldReFetch, permissionContext.isAdmin])

    return { dataRequest, loading, error, reFetch };
}