import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "components/form";
import React, {useEffect, useRef, useState} from "react";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {toast} from "components/use-toast";
import {DialogClose, DialogFooter} from "components/dialog";
import {Button} from "components/button";
import {attendancesUrl, CheckInActivityType} from "../Controllers/attendances-controller";
import {Service} from "ProjectManagement/Controller/services-controller";
import {FormattedMessage, useIntl} from "react-intl";
import SearchInput, {SearchInputValue} from "Core/components/search-input";
import {ServerErrorsType, useServerErrors} from "Core/functions/use-server-errors";
import ErrorMessageList from "Core/components/ErrorMessageList";
import {TimePicker} from "components/date-time-picker/time-picker";
import moment from "moment";
import {formatValuesToDateString} from "Core/functions/format-values-to-date";
import {AttendanceServiceFormatted} from "AttendanceManagement/DetailViews/AttendanceDetails";
import {Project} from "ProjectManagement/Controller/projects-controller";
import {projectManagementUrl} from "ProjectManagement";

const createAttendanceServicesSchema = z.object({
    projectID: z.any(),
    serviceID: z.any(),
    startDatetime: z.object({
        hour: z.number(),
        minute: z.number(),
    }).optional().nullable(),
    endDatetime: z.object({
        hour: z.number(),
        minute: z.number(),
    }).optional().nullable(),
})

type CreateAttendanceServicesFormProps = {
    attendance: CheckInActivityType | null
    userUUID: string
    reloadData: (value: boolean) => void
}

export const CreateAttendanceServicesForm: React.FC<CreateAttendanceServicesFormProps> = (
    {
        attendance,
        userUUID,
        reloadData
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof createAttendanceServicesSchema>>({
        resolver: zodResolver(createAttendanceServicesSchema)
    })
    const closeModal = useRef<HTMLButtonElement>(null);
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({})
    const errors = useServerErrors(apiErrors, form);
    const [userProjects, setUserProjects] = useState<SearchInputValue[]>([]);
    const [projectServices, setProjectServices] = useState<SearchInputValue[]>([]);
    const [userProject, setUserProject] = useState<string | null>();
    const [userService, setUserService] = useState<string | null>();

    const fetchProjects = () => {
        axiosInstance.get(projectManagementUrl + `employees/${userUUID}/projects/`)
            .then(res => {
                let formattedProjects = res.data.map((elem: Project) => {
                    return {
                        key: elem.name,
                        value: elem.pk
                    }
                });
                setUserProjects(formattedProjects);
            })
            .catch(err => console.log(err));
    }

    const fetchServices = (projectID: string) => {
        axiosInstance.get(projectManagementUrl + `employees/${userUUID}/projects/${projectID}/services/`)
            .then(res => {
                let formattedServices: SearchInputValue[] = res.data.map((elem: Service) => {
                    return {
                        key: elem.name,
                        value: elem.pk
                    }
                });
                setProjectServices(formattedServices);

                if (formattedServices.length) {
                    setUserService(formattedServices[0].value);
                } else {
                    setUserService(undefined);
                }
            })
            .catch(err => console.log(err));
    }

    const onSubmit = (values: z.infer<typeof createAttendanceServicesSchema>) => {
        if (!attendance) return;
        const startDatetime = values.startDatetime;
        const endDatetime = values.endDatetime;

        const data = {
            ...values,
            projectID: userProject,
            serviceID: userService,
            startDatetime: (() => {
                let values = {
                    year: moment(attendance.date).get("year"),
                    month: moment(attendance.date).get("month") + 1,
                    day: moment(attendance.date).get("date"),
                    hour: startDatetime?.hour || 0,
                    minute: startDatetime?.minute || 0
                }
                return startDatetime ? formatValuesToDateString(values) : null
            })(),
            endDatetime: (() => {
                let values = {
                    year: moment(attendance.date).get("year"),
                    month: moment(attendance.date).get("month") + 1,
                    day: moment(attendance.date).get("date") + ((endDatetime && startDatetime && startDatetime?.hour > endDatetime?.hour) ? 1 : 0),
                    hour: endDatetime?.hour || 0,
                    minute: endDatetime?.minute || 0
                }
                return endDatetime ? formatValuesToDateString(values) : null
            })()
        }

        axiosInstance.post(attendancesUrl + `${userUUID}/attendances/${attendance?.pk}/services/`, data)
            .then((res) => {
                setApiErrors({});
                reloadData(true);
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response.data);
            })
    }


    useEffect(() => {
        fetchProjects();
    }, [userUUID]);

    useEffect(() => {
        if (userProject) {
            fetchServices(userProject);
        }
    }, [userProject]);

    return (
        <Form {...form}>
            <form method="post" onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <FormField
                    control={form.control}
                    name={"projectID"}
                    render={({field}) => (
                        <div className="flex flex-col gap-2">
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.project"}
                                    defaultMessage={"Project"}
                                />
                            </FormLabel>
                            <SearchInput
                                title={intl.formatMessage({
                                    id: "attendance.pickProject",
                                    defaultMessage: "Pick a project"
                                })}
                                values={userProjects}
                                value={userProject}
                                onChange={setUserProject}
                            />
                            <FormMessage/>
                        </div>
                    )}
                />
                <FormField
                    control={form.control}
                    name={"serviceID"}
                    render={({field}) => (
                        <div className="flex flex-col gap-2">
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.service"}
                                    defaultMessage={"Service"}
                                />
                            </FormLabel>
                            <SearchInput
                                title={intl.formatMessage({
                                    id: "attendance.pickService",
                                    defaultMessage: "Pick a service"
                                })}
                                values={projectServices}
                                value={userService}
                                onChange={setUserService}
                            />
                            <FormMessage/>
                        </div>
                    )}
                />
                <div className="flex flex-row gap-2">
                    <div className={"grow"}>
                        <FormField
                            control={form.control}
                            name="startDatetime"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>
                                        <FormattedMessage
                                            id={"attendance.attendances.startTime"}
                                            defaultMessage={"Start Time"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <TimePicker value={field.value as any} onChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className={"grow"}>
                        <FormField
                            control={form.control}
                            name="endDatetime"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>
                                        <FormattedMessage
                                            id={"attendance.attendances.endTime"}
                                            defaultMessage={"End Time"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <TimePicker value={field.value as any} onChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                    </div>
                </div>

                <ErrorMessageList errors={errors.nonFieldErrors} />
                <ErrorMessageList errors={errors.detailErrors} />

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal} asChild>
                        <Button type="button" variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}





const editAttendanceServicesSchema = z.object({
    projectID: z.any(),
    serviceID: z.any(),
    startDatetime: z.any(),
    endDatetime: z.any(),
})

type EditAttendanceServicesFormProps = {
    attendance: CheckInActivityType | null
    userUUID: string
    service: AttendanceServiceFormatted
    services: Service[]
    reloadData: (value: boolean) => void
}

export const EditAttendanceServiceForm: React.FC<EditAttendanceServicesFormProps> = (
    {
        attendance,
        userUUID,
        service,
        reloadData,
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof editAttendanceServicesSchema>>({
        resolver: zodResolver(editAttendanceServicesSchema),
        defaultValues: {
            projectID: service.projectID,
            serviceID: service.serviceID,
            startDatetime: (() => {
                const hour = moment(service.startDatetime).get("hour");
                const minute = moment(service.startDatetime).get("minute");
                return {
                    hour: hour,
                    minute: minute
                }
            })() || null,
            endDatetime: (() => {
                if (service.endDatetime) {
                    const hour = moment(service.endDatetime).get("hour");
                    const minute = moment(service.endDatetime).get("minute");
                    return {
                        hour: hour,
                        minute: minute
                    }
                }
                return undefined
            })(),
        }
    })
    const closeModal = useRef<HTMLButtonElement>(null);
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({})
    const errors = useServerErrors(apiErrors, form);
    const [userProjects, setUserProjects] = useState<SearchInputValue[]>([]);
    const [projectServices, setProjectServices] = useState<SearchInputValue[]>([]);
    const [userProject, setUserProject] = useState<string | undefined | null>(service.projectID);
    const [userService, setUserService] = useState<string | undefined | null>(service.serviceID);

    const fetchProjects = () => {
        axiosInstance.get(projectManagementUrl + `employees/${userUUID}/projects/`)
            .then(res => {
                let formattedProjects = res.data.map((elem: Project) => {
                    return {
                        key: elem.name,
                        value: elem.pk
                    }
                });
                setUserProjects(formattedProjects);
            })
            .catch(err => console.log(err));
    }

    const fetchServices = (projectID: string) => {
        axiosInstance.get(projectManagementUrl + `employees/${userUUID}/projects/${projectID}/services/`)
            .then(res => {
                let formattedServices: SearchInputValue[] = res.data.map((elem: Service) => {
                    return {
                        key: elem.name,
                        value: elem.pk
                    }
                });
                setProjectServices(formattedServices);

                if (formattedServices.length) {
                    const isCurrentProject = projectID === service.projectID;

                    if (isCurrentProject) {
                        let serviceID = formattedServices.find(elem => elem.value === service.serviceID)?.value;
                        setUserService(serviceID);
                    } else {
                        setUserService(formattedServices[0].value);
                    }
                } else {
                    setUserService(undefined);
                }
            })
            .catch(err => console.log(err));
    }

    const onSubmit = (values: z.infer<typeof editAttendanceServicesSchema>) => {
        if (!attendance && !service) return;
        const startDatetime = values.startDatetime;
        const endDatetime = values.endDatetime;

        const data = {
            ...values,
            projectID: userProject,
            serviceID: userService,
            startDatetime: (() => {
                let values = {
                    year: moment(service.startDatetime).get("year"),
                    month: moment(service.startDatetime).get("month") + 1,
                    day: moment(service.startDatetime).get("date"),
                    hour: startDatetime?.hour || 0,
                    minute: startDatetime?.minute || 0
                }
                return startDatetime ? formatValuesToDateString(values) : null
            })(),
            endDatetime: (() => {
                let values = {
                    year: service.endDatetime ? moment(service.endDatetime).get("year") : moment(service.startDatetime).get("year"),
                    month: service.endDatetime ? moment(service.endDatetime).get("month") + 1 : moment(service.startDatetime).get("month") + 1 ,
                    day: (service.endDatetime ? moment(service.endDatetime).get("date") : moment(service.startDatetime).get("date")) + ((endDatetime && startDatetime && startDatetime?.hour > endDatetime?.hour) ? 1 : 0),
                    hour: endDatetime?.hour || 0,
                    minute: endDatetime?.minute || 0
                }
                return endDatetime ? formatValuesToDateString(values) : null
            })()
        }

        axiosInstance.put(attendancesUrl + `${userUUID}/attendances/${attendance?.pk}/services/${service.pk}/`, data)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.updatedSuccessfully", defaultMessage: "The update was completed successfully."})
                });
                setApiErrors({});
                reloadData(true);
            })
            .catch((err) => {
                setApiErrors(err.response.data)
            })
    }

    useEffect(() => {
        fetchProjects();
    }, [userUUID]);

    useEffect(() => {
        if (userProject) {
            fetchServices(userProject);
        }
    }, [userProject]);

    return (
        <Form {...form}>
            <form method="post" onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <FormField
                    control={form.control}
                    name={"projectID"}
                    render={({field}) => (
                        <div className="flex flex-col gap-2">
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.project"}
                                    defaultMessage={"Project"}
                                />
                            </FormLabel>
                            <SearchInput
                                title={intl.formatMessage({
                                    id: "attendance.pickProject",
                                    defaultMessage: "Pick a project"
                                })}
                                values={userProjects}
                                value={userProject}
                                onChange={setUserProject}
                            />
                            <FormMessage/>
                        </div>
                    )}
                />
                <FormField
                    control={form.control}
                    name={"serviceID"}
                    render={({field}) => (
                        <div className="flex flex-col gap-2">
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.service"}
                                    defaultMessage={"Service"}
                                />
                            </FormLabel>
                            <SearchInput
                                title={intl.formatMessage({
                                    id: "attendance.pickService",
                                    defaultMessage: "Pick a service"
                                })}
                                values={projectServices}
                                value={userService}
                                onChange={setUserService}
                            />
                            <FormMessage/>
                        </div>
                    )}
                />
                <div className="flex flex-row gap-2">
                    <div className={"grow"}>
                        <FormField
                            control={form.control}
                            name="startDatetime"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>
                                        <FormattedMessage
                                            id={"attendance.attendances.startTime"}
                                            defaultMessage={"Start Time"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <TimePicker value={field.value as any} onChange={field.onChange}  isDisabled={service.checkout === null}/>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className={"grow"}>
                        <FormField
                            control={form.control}
                            name="endDatetime"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>
                                        <FormattedMessage
                                            id={"attendance.attendances.endTime"}
                                            defaultMessage={"End Time"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <TimePicker value={field.value as any} onChange={field.onChange}  isDisabled={service.checkout === null}/>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                    </div>
                </div>

                <ErrorMessageList errors={errors.nonFieldErrors} />
                <ErrorMessageList errors={errors.detailErrors} />

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}