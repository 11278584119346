import React, {useContext, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Button} from "components/button";
import {MainTab, PageActions, PageState} from "Core/layout/page-shape";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import {DataTablePayload} from "../../Core/Table/interfaces";
import {DevicesTable} from "../DataTables/devices-table";
import {useFetchDevices} from "../Controller/devices-controller";
import {AddDevice} from "../Forms/device-forms";


const devicesPayloadInitialState: DataTablePayload = {
    length: 10,
    start: 0,
    ordering: "",
    "filter.startDate": "",
    "filter.endDate": ""
};

type DevicesProps = {
    name: string
    pagesState?: PageState[]
}


export const Devices: React.FC<DevicesProps> = ({name, pagesState}
) => {
    const intl = useIntl()
    const permissionContext = useContext(PermissionContext)
    const [payload,setPayload] = useState<DataTablePayload>({
        start: 0,
        length: 10,
    })
    const {
        devicesData,
        shouldRefetchDevices,
        loadingDevices
    } = useFetchDevices(payload)

    return <MainTab
        type={"mainOnly"}
        name={name}
        main={{
            title: intl.formatMessage({id: "table.list", defaultMessage: "List"}),
            content: <DevicesTable
                payload={payload}
                setPayload={setPayload}
                reloadData={shouldRefetchDevices}
                request={devicesData}
            />
        }}
        actions={[
            {
                type: "modal",
                button: (
                    <Button variant={"taimDefault"}>
                        <FormattedMessage id={"sd.devices.createButton"} defaultMessage={"Create Device"}/>
                    </Button>
                ),
                header: intl.formatMessage({id: "sd.devices.createButton", defaultMessage: "Create Device"}),
                children: <AddDevice reFetch={shouldRefetchDevices}/>
            }
        ].filter((elem) => {
            if (permissionContext.isAdmin) return elem
        }) as PageActions[]}
    />
}