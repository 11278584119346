import React, {useContext, useEffect, useState} from "react";
import RolesDataTable from "../DataTables/roles-data-table";
import {rolesPayload, useFetchRolesDataTable} from "../Controllers/role-controller";
import {Button} from "../../components/button";
import {FormRoleCreate} from "../Forms/form-role-create";
import {FormattedMessage, useIntl} from "react-intl";
import {MainTab, PageActions, PageEntrySchema} from "Core/layout/page-shape";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import {useSearchParams} from "react-router-dom";

const initialPayload = {
    start: 0,
    length:10,
    search: "",
    ordering: "",
}

const RolesEntry : React.FC<PageEntrySchema> = ({name, pagesState}) => {
    const intl = useIntl();
    const [payload, setPayload] = useState<rolesPayload>(initialPayload);
    const { dataRequest, loading, reFetch } = useFetchRolesDataTable(payload);
    const permissionContext = useContext(PermissionContext);
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        let currPage = pagesState?.find(page => page.page.value === name);
        if (currPage && currPage.reFetch) {
            reFetch(true);
        }
    }, [pagesState]);

    return (
        <MainTab
            type="mainOnly"
            name={name}
            main={{
                title: intl.formatMessage({
                    id: "table.list",
                    defaultMessage: "List"
                }),
                content: <RolesDataTable
                    payload={payload}
                    setPayload={setPayload}
                    rolesRequest={dataRequest}
                    loading={loading}
                    reFetch={reFetch}
                />
            }}
            actions={[
                {
                    type: "modal",
                    button: <Button variant="taimDefault">
                        <FormattedMessage id="role.createButton" defaultMessage="Create Role"/>
                    </Button>,
                    header: intl.formatMessage({id: "role.createButton", defaultMessage: "Create Role"}),
                    children: <FormRoleCreate reFetch={reFetch}/>
                }
            ].filter((elem) => {
                if (permissionContext.isAdmin) return elem
            }) as PageActions[]}
        />)
}

export default RolesEntry;