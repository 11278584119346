"use client"

import * as React from "react"
import { ChevronLeft, ChevronRight } from "lucide-react"
import {DayPicker, useDayPicker} from "react-day-picker"

import { cn } from "../lib/utils"
import { buttonVariants } from "./button"
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "./select";
import moment from "moment";
import {ReactNode} from "react";


export type CalendarProps = React.ComponentProps<typeof DayPicker>

const SelectDropdown: React.FC<{
    name: string,
    value?: string | number | readonly string[] | undefined,
    selectItems: {label: string, value: string}[],
    currentMonth: Date,
    goToMonth: (month: Date) => void
}> = ({name, value, selectItems, currentMonth, goToMonth}) => {

    return (
        <Select
            onValueChange={(v) => {
                function getLocalDateWithoutTimezone (i?: Date) {
                    return moment(i).local().format('YYYY-MM-DDT00:00:00.000')
                }
                let newDate = currentMonth
                if (name === "months") {
                    newDate.setMonth(parseInt(v))
                } else if (name === "years") {
                    newDate.setFullYear(parseInt(v))
                }
                goToMonth(new Date(getLocalDateWithoutTimezone(newDate)))
            }}
            defaultValue={value?.toString()}
            value={value?.toString()}
        >
            <SelectTrigger className="select-none">
                <SelectValue placeholder={"pick a value"}/>
            </SelectTrigger>
            <SelectContent className="max-h-[50vh]">
                {selectItems.map((selectItem) => (
                    <SelectItem value={selectItem.value}>{selectItem.label}</SelectItem>
                ))}
            </SelectContent>
        </Select>
    )
}

const NavigationButton: React.FC<React.ComponentProps<"div"> & {
    children: ReactNode
}> = ({...props}) => {
    return <div
        className={cn(
            "border p-1 hover:bg-slate-50 cursor-pointer rounded",
            props.className
        )}
        {...props}
    >{props.children}</div>
}

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 select-none",
        month: "space-y-4",
        month_caption: "flex justify-center pt-1 relative items-center",
        caption_label: cn(
            "text-sm font-medium",
            (props.captionLayout && props.startMonth && props.endMonth) && "hidden"
        ),
        nav: cn(
            "space-x-1 flex items-center h-fit",
            (props.captionLayout && props.startMonth && props.endMonth) && "hidden"
        ),
        button_previous: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 absolute left-5 top-5 z-50"
        ),
        button_next: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 absolute right-5 top-5 z-50"
        ),
        month_grid: "w-full border-collapse space-y-1",
        weekdays: "flex",
        weekday: "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
        week: "flex w-full mt-2",
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-9 w-9 p-0 font-normal aria-selected:opacity-100"
        ),
        day_button: "w-full h-full",
        range_end: "day-range-end",
        selected: "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
        today: "bg-accent text-accent-foreground",
        outside: "text-muted-foreground opacity-50",
        disabled: "text-muted-foreground opacity-50",
        range_middle: "aria-selected:bg-accent aria-selected:text-accent-foreground rounded-none",
        hidden: "invisible",
        dropdowns: "flex space-x-4",
        root: `shadow-lg p-4`,
        ...classNames,
      }}
      components={{
          Nav: ({...props}) => {
              return <div className={props.className} hidden>
                  {props.children}
              </div>
          },
          Chevron: ({...props}) => {
              if (props.orientation === "left") {
                  return <ChevronLeft className="h-4 w-4" {...props} />
              }
              return <ChevronRight className="h-4 w-4" {...props} />
          },
          Dropdown: ({...props}) => {
              const dayPicker = useDayPicker()
              let currentMonth = dayPicker.months[0].date
              let name: "months" | "years" = props["aria-label"]?.toLowerCase().includes("month") ? "months" : "years"
              let value = props.value
              let selectItems = props.options?.map((option) => {
                return {
                  value: option.value.toString(),
                  label: moment(name === 'months' ? {month: option.value} : {year: option.value}).format(name === 'months' ? "MMM" : "YYYY")
                }
              }) ?? []

              return <SelectDropdown
                name={name}
                goToMonth={dayPicker.goToMonth}
                selectItems={selectItems}
                currentMonth={currentMonth}
                value={value}
              />
          },
          DropdownNav: ({...props}) => {
              const {
                  goToMonth,
                  previousMonth,
                  nextMonth
              } = useDayPicker()

              return <div className={"flex justify-between items-center grow"}>
                  <NavigationButton
                    onClick={() => {
                        if (previousMonth) goToMonth(previousMonth)
                    }}
                  ><ChevronLeft className="h-4 w-4 text-slate-500" /></NavigationButton>
                  <div className={"flex space-x-2"}>{props.children}</div>
                  <NavigationButton
                    onClick={() => {
                        if (nextMonth) goToMonth(nextMonth)
                    }}
                  ><ChevronRight className="h-4 w-4 text-slate-500" /></NavigationButton>
              </div>
          },
      }}
      {...props}
    />
  )
}
Calendar.displayName = "Calendar"

export { Calendar }
