import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "../lib/utils"

const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        secondary2:
          "border border-gray-200 bg-gray-100 text-secondary-foreground p-2 rounded-lg",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
        outline: "text-foreground",
        green:
          "border-transparent bg-green-100 text-[#00CE6B]",
        green2:
          "border-transparent bg-green-50 border border-green-500 p-2 rounded-lg  text-[#00CE6B]",
        red:
          "border-transparent bg-red-100 text-[#FF2854]",
        red2:
          "border-transparent bg-red-50 border border-red-500 p-2 rounded-lg text-[#FF2854]",
        yellow:
          "border-transparent bg-yellow-100 text-yellow-500",
        yellow2:
          "border-transparent bg-yellow-50 border border-yellow-500 p-2 rounded-lg text-yellow-500",
        blue:
          "border-transparent bg-blue-100 text-blue-500",
        blue2:
          "border-transparent bg-blue-50 border border-blue-500 p-2 rounded-lg text-blue-500",
        purple:
          "border-transparent bg-purple-100 text-purple-500",
        purple2:
          "border-transparent bg-purple-50 border border-purple-500 p-2 rounded-lg text-purple-500"
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants }
