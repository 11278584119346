import {FormattedMessage, useIntl} from "react-intl";
import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "../../../components/button";
import {useDashboardEmployeeProjects} from "../../Controllers/User/project-controller";
import {Skeleton} from "../../../components/skeleton";
import {nullOrUndefined} from "../../../Core/constants/variables";
import {Users} from "@phosphor-icons/react";
import {TaimModal} from "../../../Core/components/taim-modal";
import {cn} from "../../../lib/utils";
import {SwitchProject} from "../../Forms/switch-project";

export const UserProjects = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const userUUID = localStorage.getItem("userUUID") ?? "";
    const {
        projects,
        loadingProjects,
        setReloadProjects
    } = useDashboardEmployeeProjects(userUUID);

    const goToProject = (projectName: string) => {
        navigate("/project-management", {state: {
            projectName: projectName
        }});
    }

    return (
        <div className="lg:col-span-2 h-fit xl:col-span-5 flex flex-col gap-2">
            <div className="flex justify-between items-center">
                <span className={"font-semibold text-xl"}>
                    <FormattedMessage
                        id={"dashboard.project"}
                        defaultMessage={"Project"}
                    />
                </span>
                {projects.length ? (
                    <Link to={"/project-management"}>
                        <Button variant="link" className="text-blue-800 h-fit pt-1">
                            <FormattedMessage
                                id={"dashboard.viewAll"}
                                defaultMessage={"View All"}
                            />
                        </Button>
                    </Link>
                ) : null}
            </div>
            <div className="flex flex-row h-44 items-center gap-4 text-center text-gray-500 overflow-x-auto scroll-smooth no-scrollbar">
                {loadingProjects ? (
                    Array.from({length: 6}).map(() => (
                        <div className={"p-4 min-w-[16rem] h-44 border border-slate-200 bg-slate-50 rounded-lg flex flex-col justify-between "}>
                            <div className={"flex gap-3 items-center"}>
                                <Skeleton className="h-4 w-4 bg-slate-200"/>
                                <Skeleton className="h-4 w-1/4 bg-slate-200"/>
                            </div>
                            <div className={"flex justify-between font-bold"}>
                                <div className="flex items-center gap-1">
                                    <Skeleton className="h-4 w-8 bg-slate-200"/>
                                    <Skeleton className="h-4 w-4 bg-slate-200"/>
                                </div>
                                <Skeleton className="h-4 w-1/3 bg-slate-200"/>
                            </div>
                        </div>
                    ))
                ) : projects.length ? (
                    projects.map((project) => {
                        const status = (() => {
                            switch (project.status) {
                                case "Progress":
                                    return (
                                        <span className="relative flex h-3 w-3">
                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                                            <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                                        </span>
                                    );
                                case "Done":
                                    return (
                                        <span className="relative flex h-3 w-3">
                                            <span
                                                className="animate-ping absolute inline-flex h-full w-full rounded-full bg-lime-400 opacity-75"></span>
                                            <span
                                                className="relative inline-flex rounded-full h-3 w-3 bg-lime-400"></span>
                                        </span>
                                    );
                                case "Pending":
                                    return (
                                        <span className="relative flex h-3 w-3">
                                            <span
                                                className="animate-ping absolute inline-flex h-full w-full rounded-full bg-amber-400 opacity-75"></span>
                                            <span
                                                className="relative inline-flex rounded-full h-3 w-3 bg-amber-300"></span>
                                        </span>
                                    );
                                default:
                                    return (
                                        <span className="relative flex h-3 w-3">
                                        <span
                                            className="animate-ping absolute inline-flex h-full w-full rounded-full bg-gray-400 opacity-75"></span>
                                        <span
                                            className="relative inline-flex rounded-full h-3 w-3 bg-gray-400"></span>
                                    </span>
                                    );
                            }
                        })();

                        return (
                            <div
                                onClick={() => {
                                    goToProject(project.name)
                                }}
                                className={cn(
                                    "p-4 min-w-[16rem] h-44 border border-slate-200 hover:border-slate-300 bg-slate-50 hover:bg-slate-100 rounded-lg flex flex-col justify-between scale-[0.99] hover:scale-[1] cursor-pointer",
                                    project.userIsActive && "bg-white hover:bg-slate-50/10"
                                )}>
                                <div className={"flex gap-3 items-center"}>
                                    {status}
                                    <div>{project.name ?? nullOrUndefined}</div>
                                </div>
                                <div className={"flex justify-between font-bold"}>
                                    <div className="flex items-end gap-1">
                                        <div>{project.employeesCount}</div>
                                        <Users size={24}/>
                                    </div>
                                    <div onClick={(e) => e.stopPropagation()}>
                                        <TaimModal
                                            header={
                                                project.userIsActive
                                                    ? intl.formatMessage({id: "dashboard.changeService", defaultMessage: "Change Service"})
                                                    : intl.formatMessage({id: "dashboard.switchProject", defaultMessage: "Switch Project"})
                                            }
                                            button={(
                                                <div className="flex flex-col items-start">
                                                    {project.userIsActive ? (
                                                        <Button variant={"taimDefault3"}>
                                                            <FormattedMessage id={"dashboard.active"} defaultMessage={"Active"}/>
                                                        </Button>
                                                    ) : (
                                                        <Button variant={"taimDefault2"}>
                                                            <FormattedMessage id={"dashboard.switch"} defaultMessage={"Switch"}/>
                                                        </Button>
                                                    )}
                                                </div>
                                            )}
                                        >
                                            <SwitchProject
                                                userUUID={userUUID}
                                                project={project}
                                                setReloadProjects={setReloadProjects}
                                            />
                                        </TaimModal>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <div className="grow h-full flex flex-col gap-1 items-center justify-center border p-4 rounded-lg bg-slate-50 border-dashed">
                        <FormattedMessage id={"no_results"} defaultMessage={"No results"}/>
                    </div>
                )}
            </div>
        </div>
    )
}