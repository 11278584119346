import React, {useContext, useEffect, useState} from "react";
import {Timesheet, timesheetManagementUrl} from "./Controllers/timesheet-controller";
import {useAxiosInstance} from "../Core/utilities/AxiosInstance";
import {PermissionContext} from "../Core/utilities/PermissionProvider";
import {FormattedMessage} from "react-intl";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../components/select";
import {years} from "../Core/constants/date";
import TimesheetDetail from "./PageSections/timesheet-detail";
import {Card} from "../components/card";
import TimesheetMaster from "./PageSections/timesheet-master";
import moment from "moment/moment";
import {isCurrentMonth} from "../Core/functions/is-current-month";

const TimesheetPage = () => {
    const [results, setResults] = useState<Timesheet[]>([]);
    const [urls, setUrls] = useState<string[]>([]);
    const [currMonth, setMonth] = useState<number>(moment().month() + 1);
    const [currYear, setYear] = useState<number>(moment().year());
    const [day, setDay] = useState<number | null>(null)
    const [timesheetMonthsLoading, setTimesheetMonthsLoading] = useState(false)
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const reset = () => {
        setUrls([])
        setResults([])
    }

    const makeUrl = (year: number, month: number) => timesheetManagementUrl + `${year}/${month}/`

    const handleSingleMonthUpdate = async (year: number, month: number, signal?: AbortSignal) => {
        try {
            const response = await axiosInstance.get(timesheetManagementUrl + `${year}/${month}/`, {
                signal
            })

            setResults(prevState => {
                return [...prevState].map((oldData) => {
                    if (oldData.month === month) {
                        return response.data
                    }
                    return oldData
                })
            });
        } catch (err) {
            throw err
        }
    }


    const fetchAllData = (signal: AbortSignal) => {
        setResults([]);
        setTimesheetMonthsLoading(true);

        let resolvedCount = 0;

        const promises = urls.map((url, index) => axiosInstance.get(url, {
            signal
        }).then((response) => response));

        promises.forEach((promise, index) => {
            promise
                .then(data => {
                    setResults((prevResults) => {
                        let newState: Timesheet[] = [...prevResults, data.data]

                        newState.sort((a,b) => {
                            if (a.month > b.month) {
                                return -1
                            } else if (a.month < b.month) {
                                return 1
                            }
                            return 0
                        })

                        return newState
                    });
                })
                .catch(error => {
                    console.error(`Error fetching data from ${urls[index]}:`, error);
                })
                .finally(() => {
                    resolvedCount++;
                    if (resolvedCount === promises.length) {
                        setTimesheetMonthsLoading(false);
                    }
                });
        });
    };


    useEffect( () => {
        if (!urls.length) return;
        const abortController = new AbortController();

        if (permissionContext.isChanchingState) {
            abortController.abort();
            return;
        }

        fetchAllData(abortController.signal);

        return () => {
            abortController.abort();
        };
    }, [urls, permissionContext]);

    useEffect(() => {
        if (isCurrentMonth(currMonth, currYear)) {
            let today = new Date().getDate();
            setDay(today);
        } else {
            setDay(1);
        }
    }, [currMonth]);

    useEffect(() => {
        reset()

        let todaysMonth = moment().month();
        let todaysYear = moment().year();

        for (let i = (currYear === todaysYear) ? (todaysMonth + 1) : 12; i > 0 ; i--) {
            setUrls((prevState) => {
                return [
                    ...prevState,
                    makeUrl(currYear, i)
                ]
            })
        }

        if (currYear === todaysYear) {
            setMonth(todaysMonth + 1);
        } else {
            setMonth(12);
        }
    }, [currYear]);

    return (
        <div className="gap-10">
            <span className="text-[#1b2436] text-[2rem] font-semibold leading-9">
                <FormattedMessage id="timesheetManagement.header" defaultMessage="Time Sheet Management"/>
            </span>
            <div className="grid grid-cols-6 gap-4 min-h-[50rem] items-start">
                <div className="col-span-6 flex flex-row justify-between items-end">
                    <span className="text-xl font-bold">
                        <FormattedMessage
                            id={"timesheetManagement.detail.month"}
                            defaultMessage={"Month"}
                        />
                    </span>
                    <Select
                        onValueChange={(value) => {
                            setYear(Number(value))
                        }}
                        disabled={timesheetMonthsLoading}
                    >
                        <SelectTrigger className="w-[8rem]">
                            <SelectValue placeholder={currYear}></SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {years && years.map((year, index) => (
                                <SelectItem key={index} value={year.toString()}>{year}</SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <TimesheetDetail
                    currMonth={currMonth}
                    monthsDetails={results}
                    year={currYear}
                    loading={timesheetMonthsLoading}
                    setCurrMonth={setMonth}
                />
                <Card className="col-span-6 p-6">
                    <TimesheetMaster
                        month={currMonth}
                        year={currYear}
                        day={day}
                        setDay={setDay}
                        handleSingleMonthUpdate={handleSingleMonthUpdate}
                        monthsLoading={false}
                    />
                </Card>
            </div>
        </div>
    )
}

export default TimesheetPage