import {
    Dialog,
    DialogContent,
    DialogHeader, DialogTitle,
    DialogTrigger,
} from "../../components/dialog";
import React, {ReactNode, useEffect} from "react";
import {cn} from "../../lib/utils";

export type ModalProps = {
    header: string
    button: ReactNode
    children?: ReactNode
    open?: boolean
    onOpenChange?: (open: boolean) => void
    dialogContentClassName?: string
    dialogTriggerClassName?: string
}

export const TaimModal: React.FC<ModalProps> = (
    {
        header,
        button,
        children,
        open,
        onOpenChange,
        dialogContentClassName,
        dialogTriggerClassName
    }
) => {

    return(
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogTrigger className={cn(
                !button ? "w-full" : "w-fit",
                dialogTriggerClassName
            )}>
                {!button ? header : button}
            </DialogTrigger>
            <DialogContent
                className={cn(
                    "max-h-[90vh] min-w-fit",
                    dialogContentClassName
                )}
                onInteractOutside={(e) => e.preventDefault()}
            >
                <DialogHeader>
                    <DialogTitle className="text-2xl">{header}</DialogTitle>
                </DialogHeader>
                <div className={cn(
                    "flex flex-col gap-4 max-h-[70vh] w-full overflow-y-auto no-scrollbar p-2",
                )}>
                    {children}
                </div>
            </DialogContent>
        </Dialog>
    )
}