import {TPortionType, TVacationType} from "./interfaces";
import {badgeStatusVariants} from "../../../Core/functions/status-variants";


export const vacationTypes: {key: string, value: TVacationType, variant: badgeStatusVariants}[] = [
    {
        key: "Vacation",
        value: "vacation",
        variant: "green"
    },
    {
        key: "Sick Leave",
        value: "sickLeave",
        variant: "red"
    },
    {
        key: "Day Off",
        value: "compensatoryTimeOff",
        variant: "blue"
    }
]

export const portionTypes: {key: string, value: TPortionType, variant: badgeStatusVariants}[] = [
    {
        key: "Full Day",
        value: "wholeDay",
        variant: "secondary"
    },
    {
        key: "First Half",
        value: "firstHalf",
        variant: "secondary"
    },
    {
        key: "Second Half",
        value: "secondHalf",
        variant: "secondary"
    }
]