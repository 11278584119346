import TimesheetEmployeesDataTable from "../../DataTables/timesheet-employees-data-table";
import TimesheetDailyTracksDataTable from "../../DataTables/timesheet-daily-tracks-data-table";
import React, {Dispatch, SetStateAction, useState} from "react";
import {useIntl} from "react-intl";
import {
    Timesheet,
    TimesheetEmployeesPayload, useFetchTimesheetDailyTracksDataTable,
    useFetchTimesheetEmployeesDataTable
} from "../../Controllers/timesheet-controller";

type SummarizedProps = {
    viewType: string
    year: number
    month: number
    day: number | null
    setDay: React.Dispatch<SetStateAction<number | null>>
    handleSingleMonthUpdate: (year: number, month: number, signal?: AbortSignal) => Promise<void>
    employeesPayload: TimesheetEmployeesPayload
    setEmployeesPayload: React.Dispatch<React.SetStateAction<TimesheetEmployeesPayload>>
    dailyTracksPayload: TimesheetEmployeesPayload
    setDailyTracksPayload: React.Dispatch<React.SetStateAction<TimesheetEmployeesPayload>>
    monthsLoading: boolean
}

export const Summarized: React.FC<SummarizedProps> = (
{
    viewType,
    year,
    month,
    day,
    setDay,
    handleSingleMonthUpdate,
    employeesPayload,
    setEmployeesPayload,
    dailyTracksPayload,
    setDailyTracksPayload,
    monthsLoading
}
) => {
    const intl = useIntl();
    const MONTHLY_TRACK = intl.formatMessage({id: "timesheetManagement.master.monthlyTrack", defaultMessage: "Month View"});
    const {
        timesheetEmployeesRequest,
        timesheetEmployeesLoading
    } = useFetchTimesheetEmployeesDataTable(employeesPayload, month, year);
    const {
        timesheetDailyTracksRequest,
        timesheetDailyTracksLoading,
    } = useFetchTimesheetDailyTracksDataTable(dailyTracksPayload, month, year, day);

    return (
        <>
            {viewType === MONTHLY_TRACK ? (
                <TimesheetEmployeesDataTable
                    timesheetEmployeesRequest={timesheetEmployeesRequest}
                    payload={employeesPayload}
                    setPayload={setEmployeesPayload}
                    loading={timesheetEmployeesLoading}
                    year={year}
                    month={month}
                    isLoading={monthsLoading}
                    handleSingleMonthUpdate={handleSingleMonthUpdate}
                />
            ) : (
                <TimesheetDailyTracksDataTable
                    timesheetDailyTracksRequest={timesheetDailyTracksRequest}
                    payload={dailyTracksPayload}
                    setPayload={setDailyTracksPayload}
                    loading={timesheetDailyTracksLoading}
                    year={year}
                    month={month}
                    setDay={setDay}
                    day={day}
                    isLoading={monthsLoading}
                />
            )}
        </>
    )
}