import React, {HTMLAttributes, ReactNode} from "react";
import {Card, CardContent, CardHeader} from "../../components/card";
import {cn} from "../../lib/utils";

export type row = {
    name?: string | ReactNode
    value?: string | ReactNode
    jsxElement?: ReactNode
}

type DetailViewCardProps = {
    header?: string | ReactNode
    isScrollable?: boolean
    rows: row[]
    sizeFit?: boolean
    className?: string
    ref?: React.Ref<HTMLDivElement>
} & HTMLAttributes<HTMLDivElement>

const RowItem = (props: row) => (
    <CardContent className="flex flex-row justify-between items-start px-4 py-2">
        <div className="text-gray-400">{props.name}</div>
        <div className="w-1/2">{props.value}</div>
    </CardContent>
)

const DetailViewCard: React.FC<DetailViewCardProps> = (
    {
        header,
        isScrollable,
        rows,
        sizeFit,
        className,
        ref,
        ...restProps
    }
) => {
    return(
        <Card {...restProps} ref={ref} className={cn(
            `py-2 flex flex-col overflow-x-auto`,
            sizeFit ? "min-h-fit" : "max-h-[23rem]",
            isScrollable && "overflow-y-auto",
            className
        )}>
            {header &&
                <CardHeader className="px-4 py-2 font-semibold">
                    {header}
                </CardHeader>
            }
            {rows && rows.map((row, index) => {
                if (Object.keys(row).length === 0) return null
                if (row.jsxElement) {
                    return <div className="px-4 py-2" key={index}>{row.jsxElement}</div>
                }
                return <RowItem name={row.name} value={row.value} key={index}/>
            })}
        </Card>
    )
}

export default DetailViewCard;