import moment from "moment";
import {TShift, TShiftFormatted} from "../Controller/shifts_controller";

type TWeeklyShiftPlan = {
    [key: string]: TShiftFormatted[]
}

const initialWeeklyShiftPlan: TWeeklyShiftPlan = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
}

export type TShiftPlan = {
    year: number
    week: number
    weeklyShift?: {
        pk: number
        shifts: TWeeklyShiftPlan
    }
    open: boolean
    columnsLength: number
}

export type TShiftActions =
    { type: "SET_YEAR", payload: number } |
    { type: "SET_WEEK", payload: number } |
    { type: "SET_DAY", payload: number } |
    { type: "START_STOP", payload: boolean } |
    { type: "SET_WEEKLY_SHIFT", payload: number } |
    { type: "SET_SHIFTS", payload: TShift[] } |
    { type: "SET_SHIFT", payload: TShift } |
    {
        type: "UPDATE_SHIFT"
        payload: { pk: number, data: TShift }
    } |
    { type: "REMOVE_SHIFT", payload: number } |
    { type: "REMOVE_WEEKLY_SHIFTS" } |
    { type: "REMOVE_SHIFTS" }

export const formatShift = (shift: TShift): TShiftFormatted => {
    return {
        ...shift,
        startDatetime: {
            date: moment(shift.startDatetime),
            string: shift.startDatetime,
            hoursAndMinutesString: moment(shift.startDatetime).format('HH:mm')
        },
        endDatetime: {
            date: moment(shift.endDatetime),
            string: shift.endDatetime,
            hoursAndMinutesString: moment(shift.endDatetime).format('HH:mm')
        }
    }
}

export const shiftReducer = (state: TShiftPlan, action: TShiftActions) => {

    switch (action.type) {
        case "SET_YEAR": {
            let newState = {...state}
            newState.year = action.payload
            newState.week = moment().year() === action.payload ? moment().week() : 1
            return newState
        }
        case "SET_WEEK": {
            let newState = {...state}
            newState.week = action.payload
            return newState
        }
        case "START_STOP": {
            let newState = {...state}
            newState.open = action.payload
            return newState
        }
        case "SET_WEEKLY_SHIFT": {
            let newState = {...state}
            newState.weeklyShift = {
                ...newState.weeklyShift,
                pk: action.payload,
                shifts: initialWeeklyShiftPlan
            }
            return newState
        }
        case "REMOVE_WEEKLY_SHIFTS": {
            let newState = {...state}
            delete newState.weeklyShift
            return newState
        }
        case "SET_SHIFTS": {
            if (!state.weeklyShift?.pk) return state;

            let newState = {...state}
            if (newState.weeklyShift) {
                newState.weeklyShift.shifts = initialWeeklyShiftPlan

                let columnsLength = 5

                Object.keys(newState.weeklyShift.shifts).forEach((day) => {
                    let currentDayShifts = action.payload.filter(shift => {
                        return String(moment(shift.startDatetime).isoWeekday() - 1) === day
                    })

                    if (currentDayShifts.length > columnsLength) {
                        columnsLength = currentDayShifts.length
                    }

                    let formattedCurrentDayShifts: TShiftFormatted[] = currentDayShifts.map((shift) => {
                        return {
                            ...shift,
                            startDatetime: {
                                date: moment(shift.startDatetime),
                                string: shift.startDatetime,
                                hoursAndMinutesString: moment(shift.startDatetime).format('HH:mm')
                            },
                            endDatetime: {
                                date: moment(shift.endDatetime),
                                string: shift.endDatetime,
                                hoursAndMinutesString: moment(shift.endDatetime).format('HH:mm')
                            }
                        }
                    })
                    if (newState.weeklyShift) {
                        newState.weeklyShift.shifts[day] = formattedCurrentDayShifts
                    }
                })

                if (columnsLength > newState.columnsLength) {
                    newState.columnsLength = columnsLength
                }
            }
            return newState
        }
        case "SET_SHIFT": {
            if (!state.weeklyShift?.pk) return state;

            return state
        }
        case "UPDATE_SHIFT": {
            return state
        }
        case "REMOVE_SHIFTS": {
            let newState = {...state}
            if (newState.weeklyShift?.shifts) {
                newState.weeklyShift.shifts = {}
            }
            return newState
        }
        default: {
            return state
        }
    }
}