import {useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {Service} from "./services-controller";
import {DataTableRequest} from "Core/Table/interfaces";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";


type Employee = {
    pk: string | number
    userUUID: string
    fullName: string
    roleName: string
}

export interface Project {
    pk: string
    name: string
    startDate: string | null
    endDate: string | null
    employees: Employee[]
    services: Service[]
    employeesCount: number
    completed: string | null
    status: "Done" | "Progress" | "Pending"
}

export interface ProjectsPayload {
    start: number
    length: number
    search?: string
    ordering?: string
    "filter.startDate"?: string
    "filter.endDate"?: string
}
export interface ProjectsRequest extends DataTableRequest {
    data: Project[]
}


export const projectsUrl = "project-management/projects/";

const projectsInitialState = {
    recordsTotal: 0,
    recordsFiltered: 0,
    next: "",
    previous: "",
    data: []
}

export const useFetchProjects = (payload: ProjectsPayload) => {
    const [
        projectsRequest,
        setData
    ] = useState<ProjectsRequest>(projectsInitialState);
    const [loadingProjects, setLoading] = useState(false);
    const [refetchProjects, shouldRefetchProjects] = useState(false);
    const [projectsErrors, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchProjects = () => {
        setLoading(true);
        axiosInstance.get(projectsUrl,{
            params: payload,
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                shouldRefetchProjects(false);
            })
    }

    useEffect( () => {
        fetchProjects();
    }, [payload, refetchProjects, permissionContext.isAdmin])

    return {projectsRequest, loadingProjects, projectsErrors, shouldRefetchProjects}
}
