import {useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../../Core/utilities/AxiosInstance";
import {PermissionContext} from "../../../Core/utilities/PermissionProvider";

const dashboardDataUrl = "dashboard/employees/";

export type UserDashboardData = {
    actualWorkHoursTotal: string
    plannedWorkHoursTotal: string
    overtime: string
    actualWorkHoursMonthTotal: string
    plannedWorkHoursMonthTotal: string
    overtimeMonth: string
    checkInState: boolean
    breakTimeData: {
        nextBreakTime?: {
            startTime: string
            endTime: string
            duration: string
            startIn: string
        } | null
        currBreakTime?: {
            startTime: string
            endTime: string
            duration: string
            startSince: string
        } | null
        breakTimesOfToday?: {
            startTime: string
            endTime: string
            duration: string
        }[]
    }
}


export const useDashboardData = (reloadDashboard: boolean) => {
    const [dashboardData, setdata] = useState<UserDashboardData>();
    const [loadingDashboardData, setLoading] = useState<boolean>(false);
    const [reloadDashboardData, setReloadDashboardData] = useState<boolean>(false);
    const [dashboardDataErrors, setErrors] = useState<any>({});
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);
    const userUUID = localStorage.getItem("userUUID");

    const fetchData = () => {
        setLoading(true);
        axiosInstance.get(dashboardDataUrl + `${userUUID}/dashboard-data/`)
            .then((res) => setdata(res.data))
            .catch((err) => setErrors(err.response?.data))
            .finally(() => {
                setLoading(false);
                setReloadDashboardData(false);
            })
    }

    useEffect(() => {
        fetchData();
    }, [userUUID, reloadDashboardData, reloadDashboard, permissionContext.isAdmin]);

    return {dashboardData, loadingDashboardData, setReloadDashboardData, dashboardDataErrors};
}