import {Input} from "../../components/input";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {DataTableMultipleFilter} from "./data-table-multiple-filter";
import {DataTableDateFilter} from "./data-table-date-filter";
import {FormattedMessage, useIntl} from "react-intl";
import {Button} from "../../components/button";
import {useAxiosInstance} from "../utilities/AxiosInstance";
import {toast} from "../../components/use-toast";
import {Switch} from "../../components/switch";
import {DataTablePayload} from "./interfaces";
import {DataTableSingleFilter} from "./data-table-single-filter";
import {ColumnOld} from "./data-table-old";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../components/select";
import {MixerHorizontalIcon} from "@radix-ui/react-icons";
import {
    DropdownMenu, DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuLabel, DropdownMenuSeparator,
    DropdownMenuTrigger
} from "../../components/dropdown-menu";

export type Option = {
    label: string
    value: string
    translationName?: string
    icon?: React.ComponentType<{ className?: string }>
}

export type Filter = {
    type: "simple" | "date" | "dateFromTo" | "switch"
    title?: string
}

export type SimpleFilter = {
    type: "simple"
    filterBy: string
    options: Option[]
    multiple?: boolean
} & Filter

export type DateFilter = {
    type: "date"
    date: string,
    defaultValue?: Date
    disabled?: boolean | Date
} & Filter

export type DateFromToFilter = {
    type: "dateFromTo",
    from: string,
    to: string,
    disabled?: boolean | Date
} & Filter

export type SwitchFilter = {
    type: "switch"
    label: string
    value: boolean
    dispatch: Dispatch<SetStateAction<boolean>>
} & Filter

export interface ExportDataTable {
    apiURL: string
    fileName: string
    payload: DataTablePayload | null
}

type DataTableToolsProps = {
    payload: DataTablePayload
    setPayload: React.Dispatch<SetStateAction<DataTablePayload>>
    filters?: (SimpleFilter | SwitchFilter | DateFilter | DateFromToFilter)[]
    exportTable?: ExportDataTable
    children?: React.ReactNode
    searchable?: boolean
    view?: {
        columns: ColumnOld[]
        setterFunction: React.Dispatch<SetStateAction<ColumnOld[]>>
    }
}

const DataTableTools = ({payload, setPayload, filters, exportTable, children, searchable, view}: DataTableToolsProps) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();

    const handleExport = () => {
        if (!exportTable?.apiURL) return;
        axiosInstance.post(exportTable.apiURL, null,{
            params: exportTable.payload,
            responseType: "blob"
        })
            .then((res) => {
                const blob = new Blob([res.data])
                const blobUrl = URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = `${exportTable.fileName}.xlsx`;
                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                URL.revokeObjectURL(blobUrl);

                toast({
                    title: "Exported Successfully !"
                })
            })
            .catch((err) => {
                toast({
                    title: "Export Failed !!",
                    variant: "destructive"
                })
            })
    }

    return(
        <div className="flex items-center justify-between">
            <div className="flex flex-1 items-center space-x-2">
                {(searchable === undefined || searchable) && (
                    <Input
                        placeholder={intl.formatMessage({ id: 'table.search', defaultMessage: 'Search...' })}
                        onChange={(event) => {
                            if (setPayload) {
                                setPayload((prevState: any) => ({
                                    ...prevState,
                                    start: 0,
                                    search: event.target.value
                                }))
                            }
                        }}
                        className="h-8 max-w-[200px]"
                        value={payload?.search}
                    />
                )}
                {filters?.map(filter => {
                    switch (filter.type) {
                        case "simple": {
                            if (filter.multiple !== undefined && filter.multiple === false) {
                                return <DataTableSingleFilter {...filter} payload={payload} setPayload={setPayload}/>
                            }
                            return <DataTableMultipleFilter {...filter} payload={payload} setPayload={setPayload}/>
                        }
                        case "switch": {
                            return <div className="flex gap-1 items-center">
                                <Switch
                                    value={"any"}
                                    className="data-[state=checked]:bg-blue-900"
                                    onCheckedChange={filter.dispatch}
                                />
                                <span className="text-xs text-gray-400">({filter.label})</span>
                            </div>
                        }
                        case "date":
                        case "dateFromTo": {
                            return <DataTableDateFilter
                                filter={filter}
                                payload={payload}
                                setPayload={setPayload}
                            />
                        }
                        default:{
                            return null
                        }
                    }
                })}

                {(view?.columns) &&
                    <div className={"justify-items-end"}>
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button
                                  variant="outline"
                                  size="sm"
                                  className="ml-auto hidden h-8 lg:flex"
                                >
                                  <MixerHorizontalIcon className="mr-2 h-4 w-4" />
                                  View
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end" className="w-[150px]">
                                <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
                                <DropdownMenuSeparator />
                                    <DropdownMenuCheckboxItem
                                        className="capitalize"
                                        checked={false}
                                        onCheckedChange={(value) => {
                                            view?.setterFunction((prevState) => {
                                                return prevState.map((e) => {
                                                    return e
                                                })
                                            })
                                        }}
                                    >
                                    Absence Days
                                    </DropdownMenuCheckboxItem>
                                    <DropdownMenuCheckboxItem
                                        className="capitalize"
                                        checked={false}
                                        onCheckedChange={(value) => {
                                            view?.setterFunction((prevState) => {
                                                return prevState.map((e) => {
                                                    return e
                                                })
                                            })
                                        }}
                                    >
                                    Absence Hours
                                    </DropdownMenuCheckboxItem>

                                {/*this is for later extension*/}
                                {/*{view.columns.map((column, index) => {
                                    return (
                                      <DropdownMenuCheckboxItem
                                        key={index}
                                        className="capitalize"
                                        checked={column.visible === undefined || column.visible}
                                        onCheckedChange={(value) => {
                                            view?.setterFunction((prevState) => {
                                                return prevState.map((e) => {
                                                    if (e.backendKey === column.backendKey) {
                                                        return {
                                                            ...e,
                                                            visible: !e.visible
                                                        }
                                                    }
                                                    return e
                                                })
                                            })
                                        }}
                                      >
                                        {column.frontendKey}
                                      </DropdownMenuCheckboxItem>
                                    )
                                  })}*/}
                                </DropdownMenuContent>
                            </DropdownMenu>
                    </div>
                }
                {children}

                {exportTable &&
                    <div className={"grow grid justify-items-end"}>
                        <Button variant={"taimDefault2"} onClick={handleExport}>
                            <FormattedMessage
                                id={"data.table.export"}
                                defaultMessage={"Export"}
                            />
                        </Button>
                    </div>
                }
            </div>
        </div>
    )
}

export default DataTableTools;