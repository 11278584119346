import {useFetchEmploymentInfos} from "EmployeeManagement/Controllers/employee-controller";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {EmploymentInfoHFormatted, EmploymentInfoPayload} from "EmployeeManagement/Controllers/employee-controller";
import {Skeleton} from "components/skeleton";
import {FormattedMessage, useIntl} from "react-intl";
import DataTableOld, {ColumnOld} from "Core/Table/data-table-old";
import {Button} from "components/button";
import {FormAddEmploymentType} from "./employment-history-forms";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {employeesUrl} from "EmployeeManagement/Controllers/employee-controller";
import {toast} from "components/use-toast";
import moment from "moment";
import {TaimModal} from "Core/components/taim-modal";
import DataTablePagination from "Core/Table/data-table-pagination";
import {PermissionContext} from "../../../Core/utilities/PermissionProvider";

type TEmploymentHistory = {
    userUUID: string
    rePopulateRecord: (employeeID: string) => void
    reLoadWorkingTimeProgress: Dispatch<SetStateAction<boolean>>
    reFetchWorkingPlans: Dispatch<SetStateAction<boolean>>
}

export const EmploymentHistory: React.FC<TEmploymentHistory> = (
    {
        userUUID,
        reLoadWorkingTimeProgress,
        rePopulateRecord,
        reFetchWorkingPlans
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);
    const [payload, setPayload] = useState<EmploymentInfoPayload>({
        start: 0,
        length: 10,
        search: "",
    })
    const {
        employeeInfos,
        loadingEmployeeInfos,
        reFetchEmployeeInfos
    } = useFetchEmploymentInfos(payload, userUUID);
    const [formattedData, setFormattedData] = useState<EmploymentInfoHFormatted[]>([])

    const employeeInfosColumns: ColumnOld[] = [
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.employmentHistory.date",
                defaultMessage: "Since Date"
            }),
            backendKey: "date"
        },
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.employmentHistory.type",
                defaultMessage: "Type"
            }),
            backendKey: "type"
        },
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.employmentHistory.hourPerWeek",
                defaultMessage: "Hours Per Week"
            }),
            backendKey: "weeklyWorkHours"
        },
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.employmentHistory.hourPerMonth",
                defaultMessage: "Hours Per Month"
            }),
            backendKey: "monthlyWorkHours"
        },
        {
            frontendKey: "actions",
            backendKey: "actions"
        },
    ]

    useEffect(() => {
        const formatData: EmploymentInfoHFormatted[] = employeeInfos.data.map((employmentInfo) => {
            return {
                ...employmentInfo,
                date: moment(employmentInfo.date).format("ddd DD MMM YYYY"),
                weeklyWorkHours: String(employmentInfo.monthlyWorkHours / 4),
                actions: [
                    {type: "delete", handleAction: () => handleDelete(employmentInfo.pk)}
                ]
            }
        })

        setFormattedData(formatData);
    }, [employeeInfos.data]);

    const handleDelete = (id: string) => {
        axiosInstance.delete(employeesUrl + `${userUUID}/employment-infos/${id}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                reFetchEmployeeInfos(true);
                reLoadWorkingTimeProgress(true);
                reFetchWorkingPlans(true);
                rePopulateRecord(userUUID)
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    return <div className="flex flex-col gap-4">
        {(formattedData.length && !loadingEmployeeInfos) ? (
            <>
                <DataTableOld columns={employeeInfosColumns} data={formattedData}/>
                <DataTablePagination request={employeeInfos} payload={payload} setPayload={setPayload}/>
            </>
        ) : (loadingEmployeeInfos) ? (
            <div className="flex flex-col gap-4">
                <Skeleton className="w-1/2 h-[15px]"/>
                <Skeleton className="w-1/3 h-[15px]"/>
                <Skeleton className="w-1/2 h-[15px]"/>
                <Skeleton className="w-full h-[15px]"/>
                <Skeleton className="w-1/6 h-[15px]"/>
            </div>
        ) : (
            <FormattedMessage id="no_results" defaultMessage="No results"/>
        )}
        {permissionContext.isAdmin && (
            <div>
                <TaimModal
                    button={<Button variant="taimDefault2">
                        <FormattedMessage id="employees.detail.employmentHistory.createType" defaultMessage="Add Employment Status Type"/>
                    </Button>}
                    header={intl.formatMessage({id: "employmentType.createButton", defaultMessage: "Add Employment Type"})}
                    dialogContentClassName={"min-w-[600px]"}
                >
                    <FormAddEmploymentType
                        userUUID={userUUID}
                        reFetch={reFetchEmployeeInfos}
                        rePopulateRecord={rePopulateRecord}
                        reLoadWorkingTimeProgress={reLoadWorkingTimeProgress}
                        reFetchWorkingPlans={reFetchWorkingPlans}
                    />
                </TaimModal>
            </div>
        )}
    </div>
}