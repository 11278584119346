import {TimeValue} from "react-aria";
import {TWorkingPlanBreak} from "../WorkingPlan/interfaces";
import moment from "moment";

const INITIAL_DURATION_STRING = "00h 00m"

export const calculateDuration = (startTime: TimeValue | null | undefined, endTime: TimeValue | null | undefined, breaktimes?: TWorkingPlanBreak[]) : {
    duration: moment.Duration
    durationString: string
} => {
    let duration = moment.duration(0)
    let durationString: string

    let startTimeMoment = startTime ? moment().set({
        hours: startTime?.hour,
        minutes: startTime?.minute,
    }) : null;
    let endTimeMoment = endTime ? moment().set({
        hours: endTime?.hour,
        minutes: endTime?.minute,
    }) : null;
    if (startTimeMoment && endTimeMoment) {
        if (startTimeMoment.isAfter(endTime)) {
            endTimeMoment.add(1, "d")
        }

        let diff = moment.duration(endTimeMoment.diff(startTimeMoment))
        duration = diff

        if (breaktimes) {
            breaktimes.forEach(elem => {
                if (elem.startTime && elem.endTime) {
                    let breaktimeDuration = calculateDuration(elem.startTime, elem.endTime)
                    duration.subtract(breaktimeDuration.duration)
                }
            })
        }

        let h = String(diff.hours()).padStart(2, "0")
        let m = String(diff.minutes()).padStart(2, "0")
        durationString = `${h}h ${m}m`;
    } else {
        duration = moment.duration(0)
        durationString = INITIAL_DURATION_STRING
    }

    return {
        duration,
        durationString
    }
}

export const calculateSumOfDurations = (durations: moment.Duration[]): {
    sumDuration: moment.Duration
    sumDurationString: string
} => {
    let sumDuration: moment.Duration = moment.duration(0)
    let sumDurationString: string

    sumDuration = durations.reduce((accumulator, currentValue) => {
        return accumulator?.add(currentValue)
    }, sumDuration)

    let h = String(Math.floor(sumDuration.asHours())).padStart(2, "0");
    let m = String(sumDuration.minutes()).padStart(2, "0");
    sumDurationString = `${h}h ${m}m`;

    return {
        sumDuration,
        sumDurationString
    }
}