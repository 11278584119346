import React, {useContext, useEffect, useState} from "react";
import EmployeesDataTable from "EmployeeManagement/DataTables/employees-data-table";
import {
    Employee,
    EmployeesPayload, employeesUrl,
    useFetchEmployeesDataTable
} from "EmployeeManagement/Controllers/employee-controller";
import EmployeesDetailView from "EmployeeManagement/DetailViews/employees-detail-view";
import {Button} from "components/button";
import {FormEmployeeCreate} from "../Forms/form-employee-create";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {employeeManagementUrl} from "../index";
import {FormattedMessage, useIntl} from "react-intl";
import {MainTab, PageActions, PageState} from "Core/layout/page-shape";
import {toast} from "components/use-toast";
import {EmployeeFormatted} from "EmployeeManagement/Controllers/employee-controller";
import FormEmployeeEdit from "../Forms/form-employee-edit";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import {Action} from "../../Core/Table/data-table-actions";
import {useSearchParams} from "react-router-dom";

type EmployeesEntryProps = {
    name: string
    pagesState?: PageState[]
}

const initialPayload: EmployeesPayload = {
    start: 0,
    length:10,
    search: "",
    ordering: "",
}

const EmployeesEntry: React.FC<EmployeesEntryProps> = ({name, pagesState}) => {
    const intl = useIntl();
    const [payload, setPayload] = useState<EmployeesPayload>(initialPayload);
    const {
        employeesRequest,
        loading,
        reFetch
    } = useFetchEmployeesDataTable(payload);
    const [employeeData, setEmployee] = useState<any>();
    const [formattedData, setFormattedData] = useState<EmployeeFormatted[]>([]);
    const [triggerEmployeesCount, setTrigger] = useState(false);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [detailTab, setDetailTab] = useState<string>('information')


    const deleteEmployee = (userUUID: string) => {
        axiosInstance.delete(employeesUrl + `${userUUID}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                reFetch(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    const logout = (id: string) => {
        axiosInstance.post(employeeManagementUrl + `employees/${id}/logout/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.requestProcessed", defaultMessage: "Your request has been processed successfully."})
                });
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToProcess", defaultMessage: "Unable to process your request at the moment."})
                });
            })
    }

    const rePopulateRecord = (pk: string) => {
        axiosInstance.get(employeesUrl + `${pk}/`)
            .then((res) => {
                setFormattedData((prevState) => {
                    return prevState.map((rec: EmployeeFormatted) => {
                        if (rec.userUUID === pk) {
                            let updatedRec: EmployeeFormatted = {
                                ...rec,
                                ...res.data,
                                role: res.data.role
                            };

                            setEmployee(updatedRec);
                            return updatedRec;
                        }
                        return rec
                    })
                })
            })
            .catch((err) => console.log(err))
            .finally(() => setTrigger(true))
    }

    useEffect(() => {
        const formatData: EmployeeFormatted[] = employeesRequest.data?.map((employee: Employee) => {
            return {
                ...employee,
                rowID: `${employee.userUUID}_${employee.firstName}`,
                actions: [
                    {
                        type: "edit",
                        content: (<FormEmployeeEdit employeeUUID={employee.userUUID} rePopulateRecord={rePopulateRecord}/>),
                    },
                    {type: "delete", handleAction: () => deleteEmployee(employee.userUUID)},
                    {
                        type: "custom",
                        title: intl.formatMessage({id: "employeeManagement.employees.logout.title", defaultMessage: "Logout Action"}),
                        name: intl.formatMessage({id: "employeeManagement.employees.logout", defaultMessage: "Logout"}),
                        content: intl.formatMessage({id: "employeeManagement.employees.logout.content", defaultMessage: "User will be logged out."}),
                        handleAction: () => logout(employee.userUUID)
                    }
                ] as Action[]
            };
        })
        setFormattedData(formatData);

        let rowID = searchParams.get('rec')
        if (rowID) setEmployee(formatData.find(e => e.rowID === rowID))
    }, [employeesRequest.data]);


    useEffect(() => {
        let currPage = pagesState?.find(page => page.page.value === name);

        if (currPage?.isCurrent) {
            if (currPage.reFetch) reFetch(true);
            if (employeeData?.rowID) {
                setSearchParams(prev => {
                    prev.set('rec', employeeData?.rowID)
                    return prev
                })
            }
        }

    }, [pagesState, permissionContext.isAdmin]);

    useEffect(() => {
        if (triggerEmployeesCount) setTrigger(false)
    }, [triggerEmployeesCount]);

    return (
        <MainTab
            type="mainAndDetail"
            name={name}
            main={{
                title: intl.formatMessage({id: "table.list", defaultMessage: "List"}),
                content: <EmployeesDataTable
                    setDetailData={(e) => {
                        if (e?.rowID) {
                            setSearchParams(prev => {
                                prev.set('rec', e?.rowID)
                                return prev
                            })
                        }
                        setEmployee(e);
                    }}
                    detailData={employeeData}
                    setPayload={setPayload}
                    payload={payload}
                    loading={loading}
                    employeesRequest={employeesRequest}
                    formattedData={formattedData}
                    reFetch={reFetch}
                    triggerEmployeesCount={triggerEmployeesCount}
                />
            }}
            detail={{
                title: intl.formatMessage({id: "employees.detail.header", defaultMessage: "Employee Detail"}),
                content: <EmployeesDetailView
                    employee={employeeData}
                    loading={loading}
                    rePopulateRecord={rePopulateRecord}
                    detailTab={detailTab}
                    setDetailTab={setDetailTab}
                />
            }}
            actions={[
                {
                    type: 'modal',
                    button: (<Button variant='taimDefault'>
                        <FormattedMessage id="employees.createButton" defaultMessage="Create Employee"/>
                    </Button>),
                    header: intl.formatMessage({
                        id: "employees.createHeader",
                        defaultMessage: "Create Employee"
                    }),
                    children: (<FormEmployeeCreate reFetch={reFetch}/>),
                    dialogContentClassName: "min-w-[600px]"
                }
            ].filter((elem) => {
                if (permissionContext.isAdmin) return elem
            }) as PageActions[]}
        />
    )
}

export default EmployeesEntry;