import {DataTableRequest} from "../../../../Core/Table/interfaces";
import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../../../Core/utilities/AxiosInstance";
import {employeesUrl} from "../../../Controllers/employee-controller";
import {PermissionContext} from "../../../../Core/utilities/PermissionProvider";

export type TAnnualVacation = {
    pk: string
    year: number
    days: number
}

export type TAnnualVacationPayload = {
    start: number
    length: number
}

export interface TAnnualVacationRequest extends DataTableRequest {
    data: TAnnualVacation[]
}

export const useAnnualVacations = (payload: TAnnualVacationPayload, userUUID: string, reloadVacationPlanTab: boolean) => {
    const axiosInstance = useAxiosInstance();
    const [annualVacations, setAnnualVacations] = useState<TAnnualVacationRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    })
    const [shouldRefetch, reFetchAnnualVacations] = useState(false)
    const [loadingAnnualVacations, setLoading] = useState(false);
    const [errorAnnualVacations, setError] = useState(null);
    const permission = useContext(PermissionContext);

    const fetchAnnualVacations = (signal: AbortSignal) => {
        setLoading(true)
        axiosInstance.get(employeesUrl + `${userUUID}/annual-vacations/`,{
            params: payload,
            signal
        })
            .then((res) => {
                setAnnualVacations(res.data)
            })
            .catch((err) => {
                setError(err)
            })
            .finally(() => {
                setLoading(false)
                reFetchAnnualVacations(false)
            })
    }

    useEffect(() => {
        const abortController = new AbortController()
        const signal = abortController.signal

        fetchAnnualVacations(signal)

        return () => {
            abortController.abort()
        }
    }, [payload, userUUID, shouldRefetch, permission.isAdmin, reloadVacationPlanTab]);

    return {annualVacations, loadingAnnualVacations, errorAnnualVacations, reFetchAnnualVacations}
}