import {ReactNode, useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {status} from "../../Core/functions/status-variants";
import {Action} from "../../Core/Table/data-table-actions";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import {TPortionType, TVacationType} from "../Forms/absences/interfaces";

export const vacationsUrl: string = 'attendance-management/vacations/';

export type Employee = {
    userUUID: string
    fullName: string
    firstName: string
    lastName: string
    isActive?: boolean
}

export type vacationSubstitute = {
    userUUID: string
    fullName: string
    firstName: string
    lastName: string
}


export type vacation = {
    pk: string
    userFullName: string
    user: Employee
    dateFrom: string
    dateTo: string
    workingDays: string
    status: status
    accepted: boolean
    substitute: vacationSubstitute
    substituteFullName: string
    substituteRecords: []
    records: VacationRecord[]
    requestType?: string
    sickNoteSubmitted?: "yes" | "no"
};

export type vacationsRequest = {
    recordsTotal: number
    recordsFiltered: number
    next: string
    previous: string
    data: vacation[]
}

export type vacationsPayload = {
    start: number
    length: number
    search?: string
    ordering?: string
    "filter.status"?: string
    "filter.startDate"?: string
    "filter.endDate"?: string
}

export const useFetchVacationsDataTable = (payload: vacationsPayload) => {
    const [refetch, setRefetch] = useState(false)
    const [response, setResponse] = useState<vacationsRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchVacationsData = (signal: AbortSignal) => {
        setLoading(true);
        axiosInstance.get(vacationsUrl,{
            params: payload,
            signal
        })
            .then((res) => {
                setResponse(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                setRefetch(false);
            })
    }

    useEffect( () => {
        const abortController = new AbortController()
        const signal = abortController.signal

        if (
            payload.hasOwnProperty("filter.startDate") &&
            payload.hasOwnProperty("filter.endDate")
        ) fetchVacationsData(signal);

        return () => {
            abortController.abort()
        }
    }, [payload, refetch, permissionContext.isAdmin])

    return { response, loading, error, setRefetch, refetch };
}


export type VacationRecord = {
    pk: string
    date: string
    substitute: {
        userUUID: string
        fullName: string
    } | null
    portion?: TPortionType
    type?: TVacationType
    placeholder?: boolean
    startTime?: string
    endTime?: string
}

export type VacationRecordsRequest = {
    recordsTotal: number
    recordsFiltered: number
    next: string
    previous: string
    data: VacationRecord[]
}

export type VacationRecordsPayload = {
    start: number
    length: number
}

export const useVacationRecords = (payload: VacationRecordsPayload, refetchTrigger: boolean, vacationID?: string) => {
    const [vacationRecordsRequest, setData] = useState<VacationRecordsRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    });
    const [loadingVacationRecords, setLoading] = useState(false);
    const [shouldReFetch, reFetchVacationRecords] = useState(false);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchVacationRecordsData = () => {
        if (!vacationID) return;

        setLoading(true);
        axiosInstance.get(vacationsUrl + `${vacationID}/records/`,{
            params: payload
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                reFetchVacationRecords(false);
            })
    }

    useEffect( () => {
        fetchVacationRecordsData();
    }, [payload, vacationID, shouldReFetch, refetchTrigger, permissionContext.isAdmin])

    return { vacationRecordsRequest, loadingVacationRecords, error, reFetchVacationRecords };
}

export interface VacationFormatted extends vacation {
    rowID: string
    actions: Action[]
    statusBadge: ReactNode
}