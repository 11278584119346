import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {
    TWorkingPlan,
    EmploymentType,
    employeesUrl
} from "../../Controllers/employee-controller";
import {Select, SelectContent, SelectItem, SelectTrigger} from "../../../components/select";
import DetailViewCard from "../../../Core/components/detail-view-card";
import {nullOrUndefined} from "../../../Core/constants/variables";
import DataTableOld, {ColumnOld} from "../../../Core/Table/data-table-old";
import {Button} from "../../../components/button";
import {ManageWorkingPlans} from "./ManageWorkingPlans";
import {Skeleton} from "../../../components/skeleton";
import {FormattedMessage, useIntl} from "react-intl";
import moment from "moment";
import {TaimModal} from "../../../Core/components/taim-modal";
import {useAxiosInstance} from "../../../Core/utilities/AxiosInstance";
import {PermissionContext} from "../../../Core/utilities/PermissionProvider";

type WorkingPlanProps = {
    userUUID: string
    employmentType?: EmploymentType
    rePopulateRecord: (employeeID: string) => void
    reLoadWorkingTimeProgress: Dispatch<SetStateAction<boolean>>
    workingPlans: TWorkingPlan[]
    loadingWorkingPlans: boolean
    reFetchWorkingPlans: Dispatch<SetStateAction<boolean>>
}

export const WorkingPlan: React.FC<WorkingPlanProps> = ({
    userUUID,
    employmentType,
    rePopulateRecord,
    reLoadWorkingTimeProgress,
    workingPlans,
    loadingWorkingPlans,
    reFetchWorkingPlans
}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);
    const [formattedWorkingPlans, setFormattedWorkingPlans] = useState<TWorkingPlan[]>([])
    const [selectedWorkingPlan, setSelectedWorkingPlan] = useState<TWorkingPlan>();
    const [hasEmploymentType, setHasEmploymentType] = useState<boolean>();

    const workingPlanColumns: ColumnOld[] = [
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.workingPlan.day",
                defaultMessage: "Day"
            }),
            backendKey: "dayName"
        },
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.workingPlan.startTime",
                defaultMessage: "Start Time"
            }),
            backendKey: "startTime"
        },
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.workingPlan.endTime",
                defaultMessage: "End Time"
            }),
            backendKey: "endTime"
        },
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.workingPlan.duration",
                defaultMessage: "Duration"
            }),
            backendKey: "duration"
        },
    ]

    useEffect(() => {
        const formettedData: TWorkingPlan[] = workingPlans.map((workingPlan) => ({
            ...workingPlan,
            records: workingPlan.records.map((record) => ({
                ...record,
                dayName: moment(record.day, 'DDD').format('dddd')
            }))
        }))
        setFormattedWorkingPlans(formettedData)
    }, [workingPlans]);

    useEffect(() => {
        axiosInstance.get(employeesUrl + `${userUUID}/employment-infos/`)
            .then((res) => {
                setHasEmploymentType(res.data.length >= 1);
            })
            .catch((err) => {
                console.log(err);
            })

        if (formattedWorkingPlans?.length) {
            let currDate = moment()
            let defaultWorkingPlan: TWorkingPlan | undefined

            formattedWorkingPlans.forEach((workingPlan) => {
                let startDate = moment(workingPlan.dateFrom).startOf('day')
                let endDate = workingPlan.dateTo
                    ? moment(workingPlan.dateTo)
                    : moment()
                if (currDate.isBetween(startDate, endDate.endOf('day'))) {
                    defaultWorkingPlan = workingPlan
                }
            })

            defaultWorkingPlan = defaultWorkingPlan
                ? formattedWorkingPlans.find(workingPlan => workingPlan.pk === defaultWorkingPlan?.pk)
                : undefined
            setSelectedWorkingPlan(defaultWorkingPlan)
        }
    }, [userUUID, formattedWorkingPlans]);

    return <div className="flex flex-col gap-4">
        {(formattedWorkingPlans?.length && !loadingWorkingPlans) ? (
            <>
                <Select
                    onValueChange={(value) => {
                        setSelectedWorkingPlan(formattedWorkingPlans?.find((workingPlan) => workingPlan.pk === value))
                    }}
                    value={selectedWorkingPlan?.pk}
                >
                    <SelectTrigger>
                        {(formattedWorkingPlans?.length && selectedWorkingPlan) ? (
                            <span>
                                {moment(selectedWorkingPlan?.dateFrom).format("ddd DD MMM YYYY")}
                                {" - "}
                                {selectedWorkingPlan?.dateTo ?
                                    moment(selectedWorkingPlan?.dateTo).format("ddd DD MMM YYYY") :
                                    intl.formatMessage({id: "allTime", defaultMessage: "'All Time'"})
                                }
                            </span>
                        ) : nullOrUndefined}
                    </SelectTrigger>
                    <SelectContent>
                        {formattedWorkingPlans?.length ? formattedWorkingPlans.map((workingPlan) =>
                            <SelectItem value={workingPlan.pk}>
                                <span>
                                    {moment(workingPlan?.dateFrom).format("ddd DD MMM YYYY")}
                                    {" - "}
                                    {workingPlan?.dateTo ?
                                        moment(workingPlan?.dateTo).format("ddd DD MMM YYYY") :
                                        intl.formatMessage({id: "allTime", defaultMessage: "'All Time'"})
                                    }
                                </span>
                            </SelectItem>
                        ) : (
                            <FormattedMessage id="no_results" defaultMessage="No results"/>
                        )}
                    </SelectContent>
                </Select>
                {selectedWorkingPlan ? (
                    <div className="flex flex-col gap-4">
                        <DetailViewCard rows={[
                            {
                                name: intl.formatMessage({
                                    id: "employees.detail.workingPlan.employmentType",
                                    defaultMessage: "Employee Type"
                                }),
                                value: selectedWorkingPlan?.employmentInfo?.type ?? nullOrUndefined
                            },
                            {
                                name: intl.formatMessage({
                                    id: "employees.forms.addWorkingPlan.hoursPerMonth",
                                    defaultMessage: "Hours Per Month"
                                }),
                                value: selectedWorkingPlan?.employmentInfo?.monthlyWorkingHours ?? nullOrUndefined
                            },
                            {
                                name: intl.formatMessage({
                                    id: "employees.forms.addWorkingPlan.hoursPerWeek",
                                    defaultMessage: "Hours Per Week"
                                }),
                                value: selectedWorkingPlan?.employmentInfo?.monthlyWorkingHours ? Number(selectedWorkingPlan.employmentInfo.monthlyWorkingHours) / 4 : nullOrUndefined
                            }
                        ]}/>
                        <DataTableOld columns={workingPlanColumns} data={selectedWorkingPlan.records}/>
                    </div>
                ) : null}
            </>
        ) : loadingWorkingPlans ? (
            <div className="flex flex-col gap-4">
                <Skeleton className="w-1/2 h-[15px]"/>
                <Skeleton className="w-1/3 h-[15px]"/>
                <Skeleton className="w-1/2 h-[15px]"/>
                <Skeleton className="w-full h-[15px]"/>
                <Skeleton className="w-1/6 h-[15px]"/>
            </div>
        ) : <FormattedMessage id="no_results" defaultMessage="No results"/>}
        {permissionContext.isAdmin && (
            <div>
                <TaimModal
                    button={<Button variant="taimDefault2">
                        <FormattedMessage id="workingPlan.manageWorkingPlan.button" defaultMessage="Manage Working Plans"/>
                    </Button>}
                    header={intl.formatMessage({
                        id: "employees.forms.manageWorkingPlans",
                        defaultMessage: "Manage Working Plans"
                    })}
                    dialogContentClassName={"min-w-[540px]"}
                >
                    <ManageWorkingPlans
                        workingPlans={workingPlans}
                        selectedWorkingPlan={selectedWorkingPlan}
                        employmentType={employmentType}
                        userUUID={userUUID}
                        reFetch={reFetchWorkingPlans}
                        rePopulateRecord={rePopulateRecord}
                        hasEmploymentType={hasEmploymentType}
                        reLoadWorkingTimeProgress={reLoadWorkingTimeProgress}
                        reFetchWorkingPlans={reFetchWorkingPlans}
                    />
                </TaimModal>
            </div>
        )}
    </div>
}