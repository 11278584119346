import {Avatar, AvatarFallback} from "../../components/avatar";
import {firstLetterFromEachWord} from "../functions/first-letter-from-each-word";
import {ReactNode} from "react";

type RegularNameAvatarProps = {
    username: string
    information?: string | ReactNode
}

const RegularNameAvatar = (props: RegularNameAvatarProps) => {
    return(
        <div className={`flex flex-row gap-4 ${props.information ? 'items-start' : 'items-center'} text-black`}>
            <Avatar className="h-9 w-9 text-sm">
                <AvatarFallback>{firstLetterFromEachWord(props.username)}</AvatarFallback>
            </Avatar>
            <div className="flex flex-col gap-2">
                <p className="font-medium leading-none">{props.username}</p>
                {props?.information && <p className="font-medium leading-none">{props?.information}</p>}
            </div>
        </div>
    )
}

export default RegularNameAvatar;