import {useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {Action} from "../../Core/Table/data-table-actions";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";

export const publicHolidaysUrl: string = 'attendance-management/public-holidays/';

export type PublicHoliday = {
    pk: string
    stateCode: string
    stateName?: string
    stateCountry?: string
    name: string
    date: string
    employees?: {
        firstName: string
        lastName: string
        fullName: string
        role?: string
    }[]
}

export type PublicHolidaysPayload = {
    start: number
    length: number
    search?: string
    ordering?: string
}

export type PublicHolidaysRequest = {
    recordsTotal: number
    recordsFiltered: number
    next: string
    previous: string
    data: PublicHoliday[]
}

export const useFetchPublicHolidaysDataTable = (payload: PublicHolidaysPayload) => {
    const [shouldReFetch, reFetch] = useState(false)
    const [request, setData] = useState<PublicHolidaysRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchPublicHolidaysData = (signal: AbortSignal) => {
        setLoading(true);
        axiosInstance.get(publicHolidaysUrl,{
            params: payload,
            signal
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                reFetch(false);
            })
    }

    useEffect( () => {
        const abortController = new AbortController()
        const signal = abortController.signal

        fetchPublicHolidaysData(signal);

        return () => {
            abortController.abort()
        }
    }, [shouldReFetch, payload, permissionContext.isAdmin])

    return { request, loading, error, reFetch };
}



export interface PublicHolidayFormatted extends PublicHoliday {
    rowID: string
    actions: Action[]
}
