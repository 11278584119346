import {useContext, useEffect, useState} from "react";
import AdminDashboard from "./Sections/Admin";
import UserDashboard from "./Sections/User";
import {PermissionContext} from "../Core/utilities/PermissionProvider";
import moment from "moment";


const Dashboard = () => {
    const permissionContext = useContext(PermissionContext)
    const [lastLoaded, setLastLoaded] = useState(moment())
    const [reloadDashboard, setReloadDashboard] = useState<boolean>(false)

    useEffect(() => {
        const intervalId = setInterval(() => {
            setReloadDashboard(true)
        }, 1000 * 60 * 10)

        return () => clearInterval(intervalId)
    }, [permissionContext.isAdmin]);

    useEffect(() => {
        setLastLoaded(moment())

        if (reloadDashboard) {
            setReloadDashboard(false)
        }
    }, [reloadDashboard, permissionContext.isAdmin])

    if (permissionContext.isAdmin)  return (
        <AdminDashboard
            lastLoaded={lastLoaded}
            reloadDashboard={reloadDashboard}
            setReloadDashboard={setReloadDashboard}
        />
    )
    return <UserDashboard
        lastLoaded={lastLoaded}
        reloadDashboard={reloadDashboard}
        setReloadDashboard={setReloadDashboard}
    />
}

export default Dashboard;
