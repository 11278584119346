import {DTFormattedActivity} from "./transform-activities";
import React from "react";
import {HoverCard, HoverCardContent, HoverCardTrigger} from "../../../components/hover-card";
import {firstLetterFromEachWord} from "../../../Core/functions/first-letter-from-each-word";
import moment from "moment";
import {cn} from "../../../lib/utils";
import {useMouse} from "../../../Core/functions/useMouse";
import {FormattedMessage} from "react-intl";

type ActivitiesTrackProps = {
    activities: DTFormattedActivity[]
    isCurrentDate: boolean
}

const WorkingBlock : React.FC<DTFormattedActivity & {isStillGoing?: boolean}> = (
    {isStillGoing, ...props}
) => {
    if (props.type !== "workingTime") return <></>;
    let width = props.width;
    let shift = props.shift;

    return (
        <div className={cn(
            "absolute w-0 z-10 top-0 left-0 h-2 flex flex-col justify-center items-center"
        )}
            style={{
                width: `${width}px`,
                left: `${shift}px`,
            }}
        ><div className={cn(
            'h-2 w-full bg-gray-400 rounded-r rounded-l',
            isStillGoing && 'rounded-r-none'
        )}
        ></div></div>
    )
}
const BreakBlock : React.FC<DTFormattedActivity & {isStillGoing?: boolean}> = (
    {isStillGoing,...props}
) => {
    if (props.type !== "breakTime") return <></>;
    let width = props.width;
    let shift = props.shift;

    return (
        <div className={cn(
            'absolute z-20 top-0 left-0 h-2 bg-white flex flex-col justify-center items-center pl-0.5 pr-0.5',
            isStillGoing && 'pr-0',
            (width && width < 5) && 'pr-0 pl-0',
        )}
             style={{
                width: `${width}px`,
                left: `${shift}px`,
            }}
        >
            <div className={"h-0.5 bg-gray-400 w-full"}></div>
        </div>
    )
}
const ServiceBlock : React.FC<DTFormattedActivity & {isStillGoing?: boolean}> = (
    {isStillGoing, ...props}
) => {
    const {ref, x} = useMouse();

    if (props.type !== "service" || props.width === undefined || props.shift === undefined) return <></>;
    let width = props.width;
    let shift = String(props.shift);
    let name = String(props.name);
    let duration = String(props.duration);

    return (
        <div className="absolute top-4 left-0 h-5 w-0"
            style={{
                width: `${width}px`,
                left: `${shift}px`,
            }}
        >
            <HoverCard>
                <HoverCardTrigger >
                    <div
                        className={cn(
                            'capitalize w-full h-full bg-slate-200 rounded-l-full rounded-r-full px-2 text-xs cursor-pointer flex flex-row items-center justify-between overflow-clip',
                            isStillGoing && 'rounded-r-none',
                            width < 14 && 'px-0'
                        )}
                        ref={ref}
                    >
                        <div>
                            {((name.length <= 16 && width > 120) || (name.length <= 8 && width > 60)) ? name
                                : (props.width > 15) ? firstLetterFromEachWord(name) : null}
                        </div>
                        <div>
                            {(width > 120 && name.length < 16) ? duration : null}
                        </div>
                    </div>
                </HoverCardTrigger>
                <HoverCardContent
                    className={"w-fit h-fit text-xs p-2 capitalize flex flex-col"}
                    align="start"
                    alignOffset={x}
                    hideWhenDetached
                >
                    <div><FormattedMessage id={"calendar.service"} defaultMessage={"Service"}/> : {name}</div>
                    <div><FormattedMessage id={"calendar.duration"} defaultMessage={"Duration"}/>  : {duration}</div>
                </HoverCardContent>
            </HoverCard>
        </div>
    )
}

const DefaultBlock : React.FC<DTFormattedActivity> = ({...props}) => {
    if (props.type !== "empty") return <></>;

    let width = String(props.width);
    let shift = String(props.shift);

    return (
        <div className={"absolute top-0 left-0 h-2 bg-slate-200"}
            style={{
                width: `${width}px`,
                left: `${shift}px`,
            }}
        ></div>
    )
}

export const Usertrack: React.FC<ActivitiesTrackProps> = ({activities, isCurrentDate}) => {

    return (
        <div className="h-24 w-full flex flex-col justify-center relative">
            <div
                className={cn(
                    "absolute top-0 left-1 h-full bg-red-500 line-tracker w-[1px] z-30",
                    !isCurrentDate && "hidden"
                )}
                style={{
                    left: `${moment().utc().diff(moment().startOf('day'), 'minutes')}px`
                }}
            ></div>
            {Array.from({length: 24}).map((hour, index) =>
                <div className="absolute top-0 h-full bg-gray-100 line-tracker w-[1px]" style={{ left: `${60 * index}px` }}></div>
            )}
            <div className="flex flex-row h-2 relative">
                {activities.map((activity, index) => {
                    const isStillGoing = (
                        moment(activity.endDatetime).get("hour") === moment().get("hours")
                        && moment(activity.endDatetime).get("minute") === moment().get("minutes")
                    );

                    switch (activity.type) {
                        case "workingTime":
                            return <WorkingBlock key={index} {...activity} isStillGoing={isStillGoing}/>;
                        case "breakTime":
                            return <BreakBlock key={index} {...activity} isStillGoing={isStillGoing}/>;
                        case "service":
                            return <ServiceBlock key={index} {...activity} isStillGoing={isStillGoing}/>;
                        case "empty":
                            return <DefaultBlock key={index} {...activity} />;
                    }
                })}
            </div>
        </div>
    )
}