import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/select";
import {Button} from "../../components/button";
import React, {SetStateAction, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {DataTablePayload, DataTableRequest} from "./interfaces";

type DataTablePaginationProps = {
    request: DataTableRequest
    payload: DataTablePayload
    setPayload: React.Dispatch<SetStateAction<DataTablePayload>>
}

type Limits = {
    min: number
    max: number
}

function determineLimits (length: number, records: number) : Limits {
    let min: number;
    let max: number;

    max = Math.ceil(records / length);
    min = 1;

    return {
        min: min,
        max: max
    } as Limits
}

const DataTablePagination = ({request, payload, setPayload}: DataTablePaginationProps) => {
    const [limits, setLimits] = useState<Limits>({
        min: 1,
        max: 1
    });
    const [currentPage, setCurrentPage] = useState<number>(1)

    useEffect(() => {
        if (payload?.length && request.recordsTotal) {
            setLimits(determineLimits(payload.length, request.recordsTotal));
        }
    }, [payload?.length, request?.recordsTotal]);

    useEffect(() => {
        if (payload?.start || payload?.start === 0) {
            setCurrentPage(Math.ceil((payload.start + 1) / payload.length))
        }
    }, [payload]);

    // out of range
    useEffect(() => {
        const isOutOfRange = !request.data.length && request.previous

        if (isOutOfRange) {
            setPayload((prevState) => ({
                ...prevState,
                start: (prevState.start - prevState.length) >= 0 ? prevState.start - prevState.length : 0
            }))
        }
    }, [request]);

    return (
        <div className="flex justify-between">
            <div className="flex items-center space-x-6 lg:space-x-8">
                <div className="flex items-center space-x-2">
                    <p className="text-sm font-medium">
                        <FormattedMessage id="table.rowsPerPage" defaultMessage={"Rows per page"}/>
                    </p>
                    <Select onValueChange={(e) => {
                        if (setPayload) {
                            setPayload((prevState: any) => ({
                                ...prevState,
                                length: Number(e),
                                start: 0
                            }))
                        }
                    }}>
                        <SelectTrigger className="h-8 w-[70px]">
                            <SelectValue placeholder={payload?.length} />
                        </SelectTrigger>
                        <SelectContent side="top">
                            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                                <SelectItem key={pageSize} value={`${pageSize}`}>
                                    {pageSize}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
            </div>
            <div className="flex items-center justify-end space-x-2 py-4">
                <div className="flex w-[100px] items-center justify-center text-sm font-medium">
                    {(payload && request.data.length) ? (
                        <FormattedMessage
                            id="table.pageOf"
                            values={{
                                currPage: currentPage,
                                totalPages: limits.max
                            }}
                            defaultMessage={`Page ${currentPage} of ${limits.max}`}
                        />
                    ) : (
                        <FormattedMessage
                            id="table.pageOf"
                            values={{
                                currPage: 1,
                                totalPages: 1
                            }}
                            defaultMessage={`Page 1 of 1`}
                        />
                    )}
                </div>
                <Button
                    variant="outline"
                    size="sm"
                    onClick={() => {
                        if (setPayload && request.previous) {
                            setPayload((prevState: any) => ({
                                ...prevState,
                                start: (() => {
                                    const nextStartingPoint = Number(prevState.start) - Number(prevState.length);
                                    if (nextStartingPoint <= 0) {
                                        return 0;
                                    } else {
                                        return nextStartingPoint;
                                    }
                                })()
                            }))
                        }
                    }}
                >
                    <FormattedMessage id="table.previous" defaultMessage="Previous"/>
                </Button>
                <Button
                    variant="outline"
                    size="sm"
                    onClick={() => {
                        if (setPayload && request.next) {
                            setPayload((prevState: any) => ({
                                ...prevState,
                                start: (() => {
                                    const nextStartingPoint = Number(prevState.start) + Number(prevState.length);
                                    if (nextStartingPoint >= (limits.max * prevState.length)) {
                                        return prevState.start
                                    } else {
                                        return nextStartingPoint;
                                    }
                                })()
                            }))
                        }
                    }}
                >
                    <FormattedMessage id="table.next" defaultMessage="Next"/>
                </Button>
            </div>
        </div>

    )
}

export default DataTablePagination;