import React, {useContext, useEffect, useState} from "react";
import {
    TimeCorrectionsPayload, timeCorrectionsUrl,
    useFetchTimeCorrectionsDataTable
} from "../Controllers/time-corrections-controller";
import TimeCorrectionsDataTable from "../DataTables/time-corrections-data-table";
import {TimeCorrectionsDetailView} from "../DetailViews/time-corrections-detail-view";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {TimeCorrectionFormatted} from "AttendanceManagement/Controllers/time-corrections-controller";
import {FormattedMessage, useIntl} from "react-intl";
import {MainTab, PageEntrySchema} from "Core/layout/page-shape";
import {Button} from "components/button";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import {toast} from "../../components/use-toast";


const initialPayload = {
    start: 0,
    length:10,
    search: "",
    ordering: "-correctionCheckInDate,userFullName",
    "filter.status": ""
}

const TimeCorrectionsEntry: React.FC<PageEntrySchema> = ({name, pagesState}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const [payload, setPayload] = useState<TimeCorrectionsPayload>(initialPayload);
    const [detailData, setDetailData] = useState<TimeCorrectionFormatted | null>(null)
    const timeCorrections = useFetchTimeCorrectionsDataTable(payload, [() => setDetailData(null)]);
    const permissionContext = useContext(PermissionContext);

    const Accept = ({timeCorrection}: {timeCorrection: TimeCorrectionFormatted | null }) => (
        <Button
            variant="taimDefault"
            className="grow"
            onClick={() => handleAccept(timeCorrection)}
        >
            <FormattedMessage id={"attendance.timeCorrections.detail.accept"} defaultMessage={"Accept"}/>
        </Button>
    )
    const Reject = ({isDisabled, timeCorrection}: {isDisabled: boolean, timeCorrection: TimeCorrectionFormatted | null}) => (
        <Button
            variant="outline"
            className="grow"
            onClick={() => handleReject(timeCorrection)}
            disabled={isDisabled}
        >
            <FormattedMessage id={"attendance.timeCorrections.detail.reject"} defaultMessage={"Reject"}/>
        </Button>
    )

    const handleAccept = (timeCorrection: TimeCorrectionFormatted | null) => {
        axiosInstance.post(timeCorrectionsUrl + `${timeCorrection?.pk}/accept/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.requestProcessed", defaultMessage: "Your request has been processed successfully."})
                });
                timeCorrections.reFetchTimeCorrections(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToProcess", defaultMessage: "Unable to process your request at the moment."})
                });
            })
            .finally(() => {
                axiosInstance.get(timeCorrectionsUrl + `${timeCorrection?.pk}/`)
                    .then((res) => setDetailData(prevState => ({
                        ...prevState,
                        ...res.data
                    })))
            })
    }

    const handleReject = (timeCorrection: TimeCorrectionFormatted | null) => {
        axiosInstance.post(timeCorrectionsUrl + `${timeCorrection?.pk}/reject/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.requestProcessed", defaultMessage: "Your request has been processed successfully."})
                });
                timeCorrections.reFetchTimeCorrections(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToProcess", defaultMessage: "Unable to process your request at the moment."})
                });
            })
            .finally(() => {
                axiosInstance.get(timeCorrectionsUrl + `${timeCorrection?.pk}/`)
                    .then((res) => setDetailData(prevState => ({
                        ...prevState,
                        ...res.data
                    })))
            })
    }


    useEffect(() => {
        let currPage = pagesState?.find(page => page.page.value === name);
        if (currPage && currPage.reFetch) {
            // setPayload(initialPayload);
            timeCorrections.reFetchTimeCorrections(true);
        }
    }, [pagesState]);

    useEffect(() => {
        const getSelectedValue = () => {
            let v = timeCorrections.formattedData.find(elem => elem.pk === timeCorrections.selectedRecord)
            if (v) setDetailData(v)
        }

        getSelectedValue()
    }, [timeCorrections.selectedRecord]);

    return (
        <MainTab
            type="mainAndDetail"
            name={name}
            main={{
                title: intl.formatMessage({id: "table.list", defaultMessage: "List"}),
                content: <TimeCorrectionsDataTable
                    timeCorrectionRequest={timeCorrections.response}
                    formattedData={timeCorrections.formattedData}
                    payload={payload}
                    setPayload={setPayload}
                    loading={timeCorrections.loading}
                    selectedRecord={timeCorrections.selectedRecord}
                    setSelectedRecord={timeCorrections.setSelectedRecord}
                    detailData={detailData}
                    setDetailData={setDetailData}
                    reFetch={timeCorrections.reFetchTimeCorrections}
                />
            }}
            detail={{
                title: intl.formatMessage({id: "attendance.timeCorrections.detail", defaultMessage: "Time Correction Detail"}),
                content: <TimeCorrectionsDetailView
                    timeCorrection={detailData}
                    loading={timeCorrections.loading}
                    handleAccept={handleAccept}
                    handleReject={handleReject}
                />,
                actions: [
                    <>
                        {(detailData && detailData.status.toLowerCase()) === "pending" ? (
                            <>
                                <Reject isDisabled={false} timeCorrection={detailData}/>
                                <Accept timeCorrection={detailData}/>
                            </>
                        ) : (detailData && detailData.status.toLowerCase()) === "rejected" ? (
                            <>
                                <Reject isDisabled={true} timeCorrection={detailData}/>
                                <Accept timeCorrection={detailData}/>
                            </>
                        ) : null}
                   </>
                ].filter((elem) => {
                    if (permissionContext.isAdmin) return elem
                })
            }}
        />
    )
}

export default TimeCorrectionsEntry;