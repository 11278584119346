import {useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {DataTablePayload, DataTableRequest} from "../../Core/Table/interfaces";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";

export const servicesUrl = "project-management/services/";



type Employee = {
    pk: string | number
    userUUID: string
    fullName: string
    roleName: string
    totalDuration: string
}

export interface Service {
    pk: string
    name: string
    employeesCount?: number
    employeesSpentTime?: number
    employees?: Employee[]
}

export interface ServicePayload extends DataTablePayload {}
export interface ServiceRequest extends DataTableRequest {
    data: Service[]
}

const servicesInitialState = {
    recordsTotal: 0,
    recordsFiltered: 0,
    next: "",
    previous: "",
    data: []
}

export const useFetchServices = (payload: ServicePayload) => {
    const [
        servicesRequest,
        setData
    ] = useState<ServiceRequest>(servicesInitialState);
    const [loadingServices, setLoading] = useState(false);
    const [refetchServices, shouldRefetchServices] = useState(false);
    const [servicesErrors, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchServices = () => {
        setLoading(true);
        axiosInstance.get(servicesUrl,{
            params: payload
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                shouldRefetchServices(false);
            })
    }

    useEffect( () => {
        fetchServices();
    }, [payload, refetchServices, permissionContext.isAdmin])

    return {servicesRequest, loadingServices, servicesErrors, shouldRefetchServices}
}