import {Avatar, AvatarFallback, AvatarImage} from "components/avatar";
import React from "react";
import {Absence} from "Dashboard/Controllers/Admin/employees-absences-controller";
import {firstLetterFromEachWord} from "Core/functions/first-letter-from-each-word";
import {ArrowRight} from "@phosphor-icons/react";
import {useIntl} from "react-intl";
import {nullOrUndefined} from "../../Core/constants/variables";

type AbsenceCardProps = {
    absence: Absence
}

const absenceTypes = [
    {type: "Day Off", color: "text-red-500"},
    {type: "Vacation", color: "text-green-500"},
    {type: "Sick Leave", color: "text-yellow-500"},
]

const AbsenceCard: React.FC<AbsenceCardProps> = ({absence}) => {
    const intl = useIntl();

    return (
        <div className="flex items-center">
            <Avatar className="h-9 w-9">
                <AvatarImage src="/avatars/01.png" alt="Avatar" />
                <AvatarFallback>{firstLetterFromEachWord(absence?.fullName)}</AvatarFallback>
            </Avatar>
            <div className="ml-4 space-y-1 text-sm font-medium">
                <p className="leading-none">{absence?.fullName}</p>
                <div className="flex items-center gap-2">
                    <span
                        className={`text-gray-500`}
                    >{
                        absence?.type
                            ? intl.formatMessage({id: absence.type.split(/\s/g).join('.').toLowerCase(), defaultMessage: absence.type})
                            : nullOrUndefined
                    }</span>
                    <ArrowRight color={"grey"}/>
                    <span className="text-gray-400 leading-[14px]">{absence?.message ?? nullOrUndefined}</span>
                </div>
            </div>
        </div>
    )
}

export default AbsenceCard;