import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "components/form";
import {Input} from "components/input";
import React, {useRef, useState} from "react";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {Service, servicesUrl} from "ProjectManagement/Controller/services-controller";
import {toast} from "components/use-toast";
import {DialogClose, DialogFooter} from "components/dialog";
import {Button} from "components/button";
import {FormattedMessage, useIntl} from "react-intl";
import {ServerErrorsType, useServerErrors} from "Core/functions/use-server-errors";
import ErrorMessageList from "Core/components/ErrorMessageList";

const createServiceSchema = z.object({
    name: z.string().optional()
})

type CreateServiceFormProps = {
    reloadData: (value: boolean) => void
}

export const CreateServiceForm: React.FC<CreateServiceFormProps> = (
    {
        reloadData
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof createServiceSchema>>({
        resolver: zodResolver(createServiceSchema)
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();
    const onSubmit = (values: z.infer<typeof createServiceSchema>) => {
        const data = {
            ...values,
            costCenterID: null
        }

        axiosInstance.post(servicesUrl, data)
            .then((res) => {
                setApiErrors({});
                reloadData(true);
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response.data);
            })
    }

    return (
        <Form {...form}>
            <form method="post" onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage
                                    id={"project.service"}
                                    defaultMessage={"Service"}
                                />
                            </FormLabel>
                            <FormControl>
                                <Input placeholder={intl.formatMessage({id: "project.services.servicePlaceholder", defaultMessage: "Enter service name"})} {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <ErrorMessageList errors={errors.nonFieldErrors} />
                <ErrorMessageList errors={errors.detailErrors} />

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}





const editServiceSchema = z.object({
    name: z.string().optional()
})

type EditServiceFormProps = {
    service: Service
    rePopulateRecord: (service: Service) => void
}

export const EditServiceForm: React.FC<EditServiceFormProps> = (
    {
        service,
        rePopulateRecord
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof editServiceSchema>>({
        resolver: zodResolver(editServiceSchema),
        defaultValues: {
            name: service.name
        }
    })
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();
    const onSubmit = (values: z.infer<typeof editServiceSchema>) => {
        axiosInstance.put(servicesUrl + `${service.pk}/`,
            {...values, costCenterID: null}
        )
            .then((res) => {
                setApiErrors({});
                rePopulateRecord(res.data);
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.updatedSuccessfully", defaultMessage: "The update was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response.data);
            })
    }

    return (
        <Form {...form}>
            <form method="post" onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage
                                    id={"project.service"}
                                    defaultMessage={"Service"}
                                />
                            </FormLabel>
                            <FormControl>
                                <Input placeholder={intl.formatMessage({id: "project.services.servicePlaceholder", defaultMessage: "Enter service name"})} {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <ErrorMessageList errors={errors.nonFieldErrors} />
                <ErrorMessageList errors={errors.detailErrors} />

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}