import React, {useContext, useEffect} from "react";
import {MainTab, PageState} from "Core/layout/page-shape";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import {ShiftsTable} from "../Datatables/shifts_table";

type EmployeesEntryProps = {
    name: string
    pagesState?: PageState[]
}

const ManagerEntry: React.FC<EmployeesEntryProps> = ({name, pagesState}) => {
    const permissionContext = useContext(PermissionContext);
    useEffect(() => {
        let currPage = pagesState?.find(page => page.page.value === name);
        if (currPage && currPage.reFetch) {
            // reFetch(true);
        }

    }, [pagesState, permissionContext.isAdmin]);

    return (
        <MainTab
            type="mainOnly"
            name={name}
            main={{
                content: <ShiftsTable/>
            }}
        />
    )
}

export default ManagerEntry;