import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {HoverCard, HoverCardContent, HoverCardTrigger} from "../../components/hover-card";
import {Avatar, AvatarFallback} from "../../components/avatar";
import {cn} from "../../lib/utils";
import {firstLetterFromEachWord} from "../../Core/functions/first-letter-from-each-word";
import Modal from "../../Core/components/modal";
import {Card} from "../../components/card";
import RegularNameAvatar from "../../Core/components/regular-name-avatar";

type UsersListProps = {
    users: {
        username: string
        fullName: string
    }[]
    avatarThemes: {
        background?: string
        text?: string
    }
    children?: React.ReactNode
}

export const UsersList: React.FC<UsersListProps> = (
    {users, avatarThemes, children}
) => {
    const intl = useIntl();

    return (
        <div className="flex flex-row justify-between items-center">
            {users?.length ? (
                <div className="flex items-center -space-x-2.5 cursor-pointer">
                    {users.map((user, index) => {
                        if (index <= 3) {
                            return (
                                <HoverCard>
                                    <HoverCardTrigger>
                                        <Avatar
                                            key={index}
                                            style={{
                                                ...(avatarThemes.background && {color: avatarThemes.background}),
                                                ...(avatarThemes.text && {backgroundColor: avatarThemes.text}),
                                            }}
                                            className={cn("h-8 w-8 hover:z-10 focus:z-10 border-2 border-white")}
                                        >
                                            <AvatarFallback
                                                className="bg-slate-200 text-[10px] font-semibold"
                                                style={{
                                                    ...(avatarThemes.background && {color: avatarThemes.background}),
                                                    ...(avatarThemes.text && {backgroundColor: avatarThemes.text}),
                                                }}
                                            >
                                                {(index === 3 || index === users.length) ? `+${users.length - index}` : firstLetterFromEachWord(user.fullName)}
                                            </AvatarFallback>
                                        </Avatar>
                                    </HoverCardTrigger>
                                    <HoverCardContent className={"w-fit h-fit text-xs p-2 capitalize"}>
                                        {(index === 3 || index === users.length) ? intl.formatMessage({
                                            id: "dashboard.overview.more",
                                            defaultMessage: `+{number} more`,
                                            }, {number: users.length - index}
                                        ) : user.fullName}
                                    </HoverCardContent>
                                </HoverCard>
                            )
                        }
                    })}
                </div>
            ) : <div>...</div>}
            {children ? children : (
                <Modal
                    button={<div className="underline cursor-pointer hover:text-slate-700">
                        <FormattedMessage
                            id={"dashboard.viewAll"}
                            defaultMessage={"View All"}
                        />
                    </div>}
                    header={"Employees"}
                    content={(
                        <Card className="p-4 max-h-[28rem] overflow-auto">
                            {users?.length ? users?.map((user, index) => {
                                return (
                                    <div className="py-2">
                                        <RegularNameAvatar username={user.fullName} key={index}></RegularNameAvatar>
                                    </div>
                                )
                            }) : (
                                <div className="py-2">
                                    <FormattedMessage id={"no_results"} defaultMessage={"No results found."}/>
                                </div>
                            ) }
                        </Card>
                    )}
                />
            )}
        </div>
    )
}