import {useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {DataTablePayload, DataTableRequest} from "Core/Table/interfaces";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import {systemsUrl} from "../index";


export type System = {
    pk: string
    name: string
    subdomainName: string
    coupled: boolean
}

const systemsInitialState: DataTableRequest = {
    recordsTotal: 0,
    recordsFiltered: 0,
    next: "",
    previous: "",
    data: []
}

export const useFetchSystems = (payload: DataTablePayload) => {
    const [
        systemsData,
        setData
    ] = useState<DataTableRequest>(systemsInitialState);
    const [loadingSystems, setLoading] = useState(false);
    const [refetch, shouldRefetchSystems] = useState(false);
    const [employeesErrors, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchData = () => {
        setLoading(true);
        axiosInstance.get(systemsUrl,{
            params: payload
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                shouldRefetchSystems(false);
            })
    }

    useEffect( () => {
        fetchData();
    }, [payload, refetch, permissionContext.isAdmin])

    return {systemsData, loadingSystems, employeesErrors, shouldRefetchSystems}
}
