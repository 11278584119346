import React from 'react';
import {Routes, Route, BrowserRouter, Outlet} from "react-router-dom";
import AppLayout from "./Core/layout/app-layout";
import Dashboard from "./Dashboard";
import Attendance from "./AttendanceManagement";
import Timesheet from "./TimesheetManagement";
import EmployeeManagement from "./EmployeeManagement";
import {ProjectManagement} from "./ProjectManagement";
import {FallbackPage} from "./Core/AppSections/FallbackPage";
import {ComingSoonPage} from "./Core/AppSections/ComingSoonPage";
import AuthenticationPage from "./Authentication";
import {AuthMiddleware} from "./Core/utilities/Authenticator";
import {SystemsManagement} from "./SystemsManagement";
import ImportManagement from "./ImportManagement";
import ShiftManagement from "./ShiftManagement";
import NetworkNotice from "./Core/utilities/Notice";
import {TaimButton} from "./TestComponents/loading-btn";


function App() {

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route element={
                        <>
                            <NetworkNotice/>
                            <Outlet/>
                        </>
                    }>
                        <Route path="/account-management/login" element={<AuthenticationPage/>}/>
                        <Route element={<AuthMiddleware><AppLayout/></AuthMiddleware>}>
                            <Route path="/" element={<Dashboard/>}/>
                            <Route path="/employee-management" element={<EmployeeManagement/>}/>
                            <Route path="/project-management" element={<ProjectManagement/>}/>
                            <Route path="/attendance-management" element={<Attendance/>}/>
                            <Route path="/shift-management" element={<ShiftManagement/>}/>
                            <Route path="/systems-management" element={<SystemsManagement/>}/>
                            <Route path="/import-management" element={<ImportManagement/>}/>
                            <Route path="/timesheet-management" element={<Timesheet/>}/>
                            <Route path="/help-center" element={<ComingSoonPage/>}/>
                            <Route path="/contact-us" element={<ComingSoonPage/>}/>
                            <Route path="/testing-page" element={
                                <div className="flex flex-col gap-10">
                                    <TaimButton>test</TaimButton>
                                </div>
                            }/>
                        </Route>
                        <Route path="*" element={<FallbackPage/>} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
