import {Button} from "../../components/button";
import {Link} from "react-router-dom";
import {SmileyXEyes} from "@phosphor-icons/react";

export const FallbackPage = () => {
    return (
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="flex flex-col justify-center items-center gap-4 text-center">
                <p className="text-base font-semibold text-gray-600">404</p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-slate-900 sm:text-5xl">Page not found</h1>
                <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
                <SmileyXEyes size={100}/>
                <div className="flex items-center justify-center gap-x-6">
                    <Link to={"/"}>
                        <Button variant={"taimDefault"}>
                            Go back
                        </Button>
                    </Link>
                </div>
            </div>
        </main>
    )
}