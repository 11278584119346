/*
import {useEffect, useState} from "react";
import {UseFormReturn} from "react-hook-form";

export type FieldErrors = {
    [key: string]: string[]
}

export type ServerErrorsType = {
    detail?: string[]
    non_field_errors?: string[]
} | FieldErrors

export const useServerErrorsOld = (
    errors?: ServerErrorsType,
    form?: UseFormReturn<any, any, undefined>
) => {
    const [detailErrors, setDetailErrors] = useState<string[]>();
    const [nonFieldErrors, setNonFieldErrors] = useState<string[]>();
    const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

    useEffect(() => {
        if (!errors) return

        if (errors.hasOwnProperty("detail")) {
            setDetailErrors(errors.detail);
            setFieldErrors({});
            setNonFieldErrors(undefined);
        } else if (errors.hasOwnProperty("non_field_errors")) {
            setNonFieldErrors(errors.non_field_errors);
            setDetailErrors(undefined);
            setFieldErrors({});
        } else if (typeof errors === "object") {
            setFieldErrors(errors)
            setDetailErrors(undefined);
            setNonFieldErrors(undefined);
        } else {
            setFieldErrors({});
            setDetailErrors(undefined);
            setNonFieldErrors(undefined);
        }
    }, [errors]);

    useEffect(() => {
        if (fieldErrors) {
            Object.keys(fieldErrors).forEach((key) => {
                if (fieldErrors?.hasOwnProperty(key)) {
                    const isString = typeof fieldErrors[key][0] === "string";
                    const isObject = typeof fieldErrors[key] === "object";
                    if (isString) {
                        let message = fieldErrors[key][0] || '';
                        form?.setError(key, { message });
                    } else if (isObject && key !== "records" && key !== "workingPlan") {
                        let nestedObject = fieldErrors[key]
                        Object.keys(nestedObject).forEach((nestedKey) => {
                            // @ts-ignore
                            let message = fieldErrors[key][nestedKey][0];
                            form?.setError(`${key}.${nestedKey}`, {message});
                        })
                    }
                }
            });
        }
    }, [fieldErrors, form]);

    useEffect(() => {
        if (!errors) return

        if (Object.keys(errors).length === 0 && form) {
            form.clearErrors();
        }
    }, [errors]);

    return {fieldErrors, detailErrors, nonFieldErrors}
}*/


import {useEffect, useState} from "react";
import {UseFormReturn} from "react-hook-form";
import {object} from "zod";

export const FALLBACK_ERROR_MESSAGE = "Unkown Error";

export type FieldErrors = ({
    [key: string]: string[] | string | FieldErrors
} & {
    detail?: never;
    non_field_errors?: never;
})

export type ServerErrorsType = {
    detail?: string[]
    non_field_errors?: string[]
} | FieldErrors

export function isFieldErrorsExperimental(errors: any): errors is FieldErrors {
    if (typeof errors !== 'object' || errors === null || Array.isArray(errors)) {
        return false;
    }

    for (const key in errors) {
        if (key === 'detail' || key === 'non_field_errors') {
            return false;
        }

        const value = errors[key];

        if (Array.isArray(value)) {
            if (!value.every((item) => typeof item === 'string') && !value.every(isFieldErrorsExperimental)) {
                return false;
            }
        } else {
            return false;
        }
    }

    return true;
}

export const useServerErrors = (
    errors?: ServerErrorsType,
    form?: UseFormReturn<any, any, undefined>
) => {
    const [detailErrors, setDetailErrors] = useState<string[]>();
    const [nonFieldErrors, setNonFieldErrors] = useState<string[]>();
    const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

    useEffect(() => {
        if (!errors) return;

        let {detail, non_field_errors, ...fieldErrors} = errors

        if (detail) {
            setDetailErrors(detail);
            setFieldErrors({});
            setNonFieldErrors(undefined);
        } else if (non_field_errors) {
            setNonFieldErrors(non_field_errors);
            setDetailErrors(undefined);
            setFieldErrors({});
        } else if (fieldErrors) {
            setFieldErrors(fieldErrors)
            setDetailErrors(undefined);
            setNonFieldErrors(undefined);
        } else {
            setFieldErrors({});
            setDetailErrors(undefined);
            setNonFieldErrors(undefined);
        }
    }, [errors]);

    useEffect(() => {
        if (fieldErrors) {
            Object.keys(fieldErrors).forEach((key) => {
                if (fieldErrors?.hasOwnProperty(key)) {
                    // @ts-ignore
                    const isString = typeof fieldErrors[key][0] === "string";
                    const isObject = typeof fieldErrors[key] === "object";
                    if (isString) {
                        // @ts-ignore
                        let message = fieldErrors[key][0] || '';
                        // @ts-ignore
                        form?.setError(key, { message });
                    } else if (isObject && key !== "records" && key !== "workingPlan") {
                        let nestedObject = fieldErrors[key]
                        Object.keys(nestedObject).forEach((nestedKey) => {
                            // @ts-ignore
                            let message = fieldErrors[key][nestedKey][0];
                            // @ts-ignore
                            form?.setError(`${key}.${nestedKey}`, {message});
                        })
                    }
                }
            });
        }
    }, [fieldErrors, form]);

    useEffect(() => {
        if (!errors) return

        if (Object.keys(errors).length === 0 && form) {
            form.clearErrors();
        }
    }, [errors]);

    const findValueByKey = (obj: { [k: string]: any }, targetKey: string): string[] | string | object | undefined => {
        for (const key in obj) {
            if (key === targetKey) return obj[key];

            if (typeof obj[key] === "object" && obj[key] !== null) {
                const result = findValueByKey(obj[key], targetKey);
                if (result !== undefined) return result;
            }
        }
        return undefined;
    }

    const printErrorFieldMessage = (input: string): string => {
        let outputMessage = FALLBACK_ERROR_MESSAGE;
        let value = findValueByKey(fieldErrors, input)
        if (value) {
            if (value instanceof Array) {
                if (typeof value[0] === "string") outputMessage = value[0]
            } else if (typeof value === "string") {
                outputMessage = value
            }
        }
        return outputMessage;
    }

    return {fieldErrors, detailErrors, nonFieldErrors, printErrorFieldMessage}
}