type DateValues = {
    year: number
    month: number
    day: number
    hour: number
    minute: number
    millisecond?: number
}
export function formatValuesToDateString (value: DateValues) {
    const month = value.month.toString().length === 1 ? `0${value.month}` : value.month;
    const day = value.day.toString().length === 1 ? `0${value.day}` : value.day;
    const hour = value.hour.toString().length === 1 ? `0${value.hour}` : value.hour;
    const minute = value.minute.toString().length === 1 ? `0${value.minute}` : value.minute;

    return `${value.year}-${month}-${day}T${hour}:${minute}:${value.millisecond ?? "00"}.0000`;
}