import {Info} from "@phosphor-icons/react";
import {HoverCard, HoverCardContent, HoverCardTrigger} from "../../components/hover-card";
import React from "react";

type TaimInfoProps = {
    text: string
    size?: string | number
}

export const TaimInfo: React.FC<TaimInfoProps> = ({ text,size }) => {
    return (
        <HoverCard>
            <HoverCardTrigger className="cursor-pointer text-gray-600 hover:text-gray-800">
                <Info size={size}/>
            </HoverCardTrigger>
            <HoverCardContent
                align={"start"}
                className="min-w-[400px] border-none shadow-lg rounded-lg text-xs bg-slate-500 text-white text-left"
            >
                {text}
            </HoverCardContent>
        </HoverCard>
    );
};