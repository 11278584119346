import {Dispatch, SetStateAction} from "react";
import {WorkingPlanSchemaType} from "./interfaces";

export const useWorkingPlan = (setterFunc: Dispatch<SetStateAction<WorkingPlanSchemaType[]>>) => {

    const createWithAI = () => {
        setterFunc((prevState) => {
            return prevState.map((currDay) => {
                if (currDay.day <= 5) {
                    return {
                        ...currDay,
                        startTime: {
                            hour: 9,
                            minute: 0
                        },
                        endTime: {
                            hour: 17,
                            minute: 0
                        },
                        checked: true
                    } as WorkingPlanSchemaType
                } else {
                    return {
                        ...currDay,
                        startTime: null,
                        endTime: null,
                        checked: false
                    } as WorkingPlanSchemaType
                }
            })
        })
    }

    const handleSwitchChange = (index: number) => {
        setterFunc((prevState) => {
            return prevState.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        checked: !item.checked
                    };
                }
                return item;
            });
        });
    };

    return {createWithAI, handleSwitchChange}
};