import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {Skeleton} from "../../../../components/skeleton";
import {FormattedMessage, useIntl} from "react-intl";
import DataTableOld, {ColumnOld} from "../../../../Core/Table/data-table-old";
import {useAxiosInstance} from "../../../../Core/utilities/AxiosInstance";
import DataTablePagination from "Core/Table/data-table-pagination";
import {TVacationExceptionRule} from "./controller";
import {DataTablePayload, DataTableRequest} from "../../../../Core/Table/interfaces";
import {Action} from "../../../../Core/Table/data-table-actions";
import {employeesUrl} from "../../../Controllers/employee-controller";
import moment from "moment";
import {AddVacationExceptionRule, EditVacationExceptionRule} from "./forms";
import {TaimModal} from "../../../../Core/components/taim-modal";
import {Button} from "../../../../components/button";
import {toast} from "../../../../components/use-toast";
import {PermissionContext} from "../../../../Core/utilities/PermissionProvider";

type TVacationExceptionRulesFormatted = TVacationExceptionRule & {
    actions: Action[]
}

type VacationExceptionRulesProps = {
    userUUID: string
    reloadVacationPlanTab: boolean
    setReloadVacationPlanTab: Dispatch<SetStateAction<boolean>>
}

export const VacationExceptionRules: React.FC<VacationExceptionRulesProps> = (
    {
        userUUID,
        reloadVacationPlanTab,
        setReloadVacationPlanTab
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);
    const [vacationExceptionRules,setVacationExceptionRules] = useState<TVacationExceptionRulesFormatted[]>([])
    const [payload, setPayload] = useState<DataTablePayload>({
        length: 5,
        start: 0
    })
    const [request, setRaquest] = useState<DataTableRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    })
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)

    const columns: ColumnOld[] = [
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.vacationExceptionRules.year",
                defaultMessage: "Year"
            }),
            backendKey: "year"
        },
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.vacationExceptionRules.carryover",
                defaultMessage: "Carry Over"
            }),
            backendKey: "carryover"
        },
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.vacationExceptionRules.expiryDate",
                defaultMessage: "Expiry Date"
            }),
            backendKey: "expiryDate"
        },
        {backendKey: "actions", frontendKey: "actions"}
    ]

    const fetchVacationRules = () => {
        setLoading(true)

        axiosInstance.get(employeesUrl + `${userUUID}/vacation-exception-rules/`)
            .then(res => {
                let data: TVacationExceptionRule[]= res.data
                let recordsTotal = data.length
                let recordsFiltered = payload.length
                let next = data.length > payload.length ? "next" : ""
                let previous = ""

                let formatData: TVacationExceptionRulesFormatted[] = res.data.map((vacationExceptionRule: TVacationExceptionRule) => ({
                    ...vacationExceptionRule,
                    carryover: vacationExceptionRule.carryover ?
                        intl.formatMessage({id: "yes", defaultMessage: "Yes"}) :
                         intl.formatMessage({id: "no", defaultMessage: "No"}),
                    actions: [
                        {
                            type: "edit",
                            content: <EditVacationExceptionRule
                                userUUID={userUUID}
                                vacationRule={vacationExceptionRule}
                                reFetch={setReload}
                                setReloadVacationPlanTab={setReloadVacationPlanTab}
                            />
                        },
                        {
                            type: "delete",
                            handleAction: () => handleDelete(vacationExceptionRule.pk)
                        }
                    ],
                    ...(vacationExceptionRule.expiryDate && {
                        expiryDate: moment(vacationExceptionRule.expiryDate).format("DD MMM YYYY")
                    })
                }))

                setRaquest({
                    recordsTotal,
                    recordsFiltered,
                    next,
                    previous,
                    data: formatData.slice(payload.start,(payload.start + payload.length))
                })
                setVacationExceptionRules(formatData)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setLoading(true)
                setReload(false)
            })
    }

    useEffect(() => {
        fetchVacationRules()
    }, [userUUID, reload, reloadVacationPlanTab]);

    const handleDelete = (id: string) => {
        axiosInstance.delete(employeesUrl + `${userUUID}/vacation-exception-rules/${id}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                setReload(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    useEffect(() => {

        let data: TVacationExceptionRulesFormatted[]= vacationExceptionRules.slice(payload.start,(payload.start + payload.length))
        let next = payload.start + payload.length < vacationExceptionRules.length ? "next" : undefined
        let previous = payload.start > 0 && payload.start <= vacationExceptionRules.length ? "previous" : undefined

        setRaquest(prevState => ({
            ...prevState,
            recordsFiltered: payload.length,
            data: data,
            next,
            previous,
        }))
    }, [payload]);

    return <div className="flex flex-col gap-4">
        {vacationExceptionRules && (loading || !loading) ? (
            <>
                <DataTableOld columns={columns} data={request.data}/>
                <DataTablePagination request={request} payload={payload} setPayload={setPayload}/>
            </>
        ) : (!vacationExceptionRules.length && loading) ? (
            <div className="flex flex-col gap-4">
                <Skeleton className="w-1/2 h-[15px]"/>
                <Skeleton className="w-1/3 h-[15px]"/>
                <Skeleton className="w-1/2 h-[15px]"/>
                <Skeleton className="w-full h-[15px]"/>
                <Skeleton className="w-1/6 h-[15px]"/>
            </div>
        ) : (
            <FormattedMessage id="no_results" defaultMessage="No results"/>
        )}
        {permissionContext.isAdmin && (
            <div>
                <TaimModal
                    button={(
                        <Button variant="taimDefault2">
                            <FormattedMessage id="employees.detail.vacationExceptionRules.create" defaultMessage="Add Vacation Rule"/>
                        </Button>
                    )}
                    header={intl.formatMessage({id: "employees.detail.vacationExceptionRules.create", defaultMessage: "Add Vacation Rule"})}
                    dialogContentClassName={"min-w-[600px]"}
                >
                    <AddVacationExceptionRule
                        userUUID={userUUID}
                        reFetch={setReload}
                        setReloadVacationPlanTab={setReloadVacationPlanTab}
                    />
                </TaimModal>
            </div>
        )}
    </div>
}