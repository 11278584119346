import {FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage} from "../../../components/form";
import {FormattedMessage, useIntl} from "react-intl";
import SearchInput from "../../../Core/components/search-input";
import {DatePicker} from "../../../components/date-picker";
import {Input} from "../../../components/input";
import {cn} from "../../../lib/utils";
import moment from "moment";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../../components/select";
import {Trash} from "@phosphor-icons/react";
import React, {useEffect} from "react";
import {UseFormReturn} from "react-hook-form";
import {
    TDayRecord,
    TFirstStepData,
    TPortionType,
    TSecondStepData,
    TStep,
    TVacationsFormSchema,
    TVacationType
} from "./interfaces";
import {Employee, vacation, vacationSubstitute} from "../../Controllers/vactions-controller";
import "./index.css"
import {nullOrUndefined} from "../../../Core/constants/variables";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../../components/table";
import DetailViewCard from "../../../Core/components/detail-view-card";
import {portionTypes, vacationTypes} from "./constants";
import {Checkbox} from "../../../components/checkbox";
import {TimePicker} from "../../../components/date-time-picker/time-picker";
import {TimeValue} from "react-aria";
import {DatePickerWithRange} from "../../../components/date-picker-with-range";
import {AbscenceDispatch, changePortion, changeSubstitute, changeTime, changeType, toggleRecord} from "./index";
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from "../../../components/accordion";
import {Switch} from "../../../components/switch";
import {Badge} from "../../../components/badge";

type FirstStepProps = {
    form: UseFormReturn<TVacationsFormSchema>
    employees: Employee[]
    substitutes: vacationSubstitute[]
    duration: string
    dayDuration: {duration: moment.Duration, durationString: string}
    employeeName?: string
}

export const VacationsFirstStep: React.FC<FirstStepProps> = ({
    form,
    employees,
    substitutes,
    duration,
    dayDuration,
    employeeName
}) => {
    const intl = useIntl()

    return (
        <div className="flex flex-col gap-4 w-[500px] max-h-[54vh] no-scrollbar overflow-y-scroll">
            <FormField
                control={form.control}
                name="userUUID"
                render={({field}) => (
                    <FormItem>
                        <FormLabel>
                            <FormattedMessage
                                id={"attendance.vacations.employee"}
                                defaultMessage={"Employee"}
                            />
                        </FormLabel>
                        <SearchInput
                            title={intl.formatMessage({
                                id: "attendance.vacations.employeePlaceholder",
                                defaultMessage: "Select an employee"
                            })}
                            value={field.value}
                            values={employees?.map((employee) => {
                                return {
                                    key: employee.fullName,
                                    value: employee.userUUID
                                }
                            })}
                            onChange={field.onChange}
                        />
                        <FormMessage/>
                    </FormItem>
                )}
            />

            <FormField
                control={form.control}
                name="dateFromTo"
                render={({field}) => (
                    <FormItem className="flex flex-col">
                        <FormLabel>
                            <FormattedMessage
                                id={"attendance.vacations.dateFromTo"}
                                defaultMessage={"Start and End Date"}
                            />
                        </FormLabel>
                        <DatePickerWithRange
                            btnClassName={"w-full"}
                            date={field.value}
                            setDate={field.onChange}
                        />
                        <FormMessage/>
                    </FormItem>
                )}
            />

            <FormField
                control={form.control}
                name="ignoreWeekends"
                render={({field}) => (
                    <FormItem>
                        <div className={"flex space-x-2 items-center"}>
                            <FormControl>
                                <Checkbox checked={field.value} onCheckedChange={field.onChange}/>
                            </FormControl>
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.vacations.without_weekends"}
                                    defaultMessage={"Without weekends"}
                                />
                            </FormLabel>
                        </div>
                        <FormMessage/>
                    </FormItem>
                )}
            />

            <div className="flex flex-col gap-2">
                <FormLabel>
                    <FormattedMessage
                        id={"attendance.vacations.duration"}
                        defaultMessage={"Duration"}
                    />
                </FormLabel>
                <Input
                    value={intl.formatMessage(
                        {id: "attendance.vacations.days_count", defaultMessage: "days"},
                        {daysCount: duration}
                    )}
                    disabled={true}
                />
            </div>


            <div className="flex flex-row gap-4">
                <FormField
                    control={form.control}
                    name="start"
                    render={({field}) => (
                        <FormItem className="w-1/2 flex flex-col p-1">
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.vacations.start"}
                                    defaultMessage={"Starts at"}
                                />
                            </FormLabel>
                            <FormControl>
                                <TimePicker value={field.value} onChange={field.onChange}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="end"
                    render={({field}) => (
                        <FormItem className="w-1/2 flex flex-col p-1">
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.vacations.end"}
                                    defaultMessage={"Ends at"}
                                />
                            </FormLabel>
                            <FormControl>
                                <TimePicker value={field.value} onChange={field.onChange}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
            </div>
            <div className="flex flex-col gap-2">
                <FormLabel>
                    <FormattedMessage
                        id={"attendance.vacations.time_duration"}
                        defaultMessage={"Time Duration"}
                    />
                </FormLabel>
                <Input value={dayDuration.durationString} disabled={true}/>
            </div>

            <div className="flex flex-row gap-4">

                <FormField
                    control={form.control}
                    name="substituteUUID"
                    render={({field}) => (
                        <FormItem className="w-1/2">
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.vacations.substitute"}
                                    defaultMessage={"Substitute"}
                                />
                            </FormLabel>
                            <SearchInput
                                title={intl.formatMessage({
                                    id: "attendance.vacations.substitutePlaceholder",
                                    defaultMessage: "Select a substitute"
                                })}
                                value={field.value}
                                values={substitutes?.map((user) => {
                                    return {
                                        key: user.fullName,
                                        value: user.userUUID
                                    }
                                })}
                                onChange={field.onChange}
                                isDisabled={!employeeName?.length}
                                fallbackSentence={"Pick an employee first."}
                            />
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="requestType"
                    render={({field}) => (
                        <FormItem className="w-1/2">
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.vacations.type"}
                                    defaultMessage={"Type"}
                                />
                            </FormLabel>
                            <SearchInput
                                title={intl.formatMessage({
                                    id: "attendance.vacations.typePlaceholder",
                                    defaultMessage: "Select a type"
                                })}
                                value={field.value}
                                values={vacationTypes?.map(({key, value}) => {
                                    return {key: intl.formatMessage({id: `attendance.vacations.${value}`, defaultMessage: key}), value}
                                })}
                                onChange={field.onChange}
                            />
                            <FormMessage/>
                        </FormItem>
                    )}
                />
            </div>
        </div>
    )
}

type SecondStepProps = {
    form: UseFormReturn<TVacationsFormSchema, any, undefined>
    data?: {dateFrom: string, dateTo: string, records: TDayRecord[]}
    setData: AbscenceDispatch
    substitutes: vacationSubstitute[]
    employeeName?: string
}

export const VacationsSecondStep: React.FC<SecondStepProps> = (
    {
        form,
        data,
        setData,
        substitutes,
        employeeName,
    }
) => {
    const intl = useIntl()
    const substituteUUID = form.getValues('substituteUUID')
    const vacationType = form.getValues('requestType')

    return (
        <div className={"flex flex-col space-y-4"}>
            <DetailViewCard rows={[
                {
                    name: intl.formatMessage({id: "attendance.vacations.employee", defaultMessage: "Employee"}),
                    value: employeeName ?? nullOrUndefined
                },
                {
                    name: intl.formatMessage({id: "attendance.vacations.dateFrom", defaultMessage: "Date From"}),
                    value: form.getValues('dateFromTo.from') ? moment(form.getValues('dateFromTo.from')).local().format('ddd DD MMM YYYY') : nullOrUndefined
                },
                {
                    name: intl.formatMessage({id: "attendance.vacations.dateTo", defaultMessage: "Date To"}),
                    value: form.getValues('dateFromTo.to') ? moment(form.getValues('dateFromTo.to')).local().format('ddd DD MMM YYYY') : nullOrUndefined
                }
            ]}/>

            {data?.records ? (
                <div className="border rounded-md max-h-[32vh] overflow-scroll py-2 px-4">
                    <Accordion type="single" collapsible className="w-full space-y-2">
                        {data.records.map((record, index) => (
                            <AccordionItem value={index.toString()} className="strikeout">
                                <AccordionTrigger className={cn(
                                    record.placeholder && "vacation-row-child hover:no-underline"
                                )}>
                                    <div className="grow grid grid-cols-9 gap-4 items-center">
                                        <div
                                            className="col-span-1"
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <Checkbox
                                                id={index.toString()}
                                                checked={!record.placeholder}
                                                onClick={() => {
                                                    toggleRecord(index, setData)
                                                }}
                                            />
                                        </div>
                                        <span className="col-span-4 text-left">{moment(record.date).format('dddd DD MMM YYYY')}</span>
                                        {!record.placeholder && (
                                            <div className={"flex flex-row space-x-2 col-span-4"}>
                                                {(() => {
                                                let portionType = portionTypes.find(elem => elem.value === record.portion)

                                                return portionType ? <span><Badge className="hover:no-underline" variant={portionType.variant}>{intl.formatMessage({id: `attendance.vacations.${portionType.value}`, defaultMessage: portionType.key})}</Badge></span> : null
                                                })()}
                                                {(() => {
                                                    let vacationType = vacationTypes.find(elem => elem.value === record.type)

                                                    return vacationType ? <span><Badge className="hover:no-underline" variant={vacationType.variant}>{intl.formatMessage({id: `attendance.vacations.${vacationType.value}`, defaultMessage: vacationType.key})}</Badge></span> : null
                                                })()}
                                            </div>
                                        )}
                                    </div>
                                </AccordionTrigger>
                                <AccordionContent>
                                    <div className="flex flex-col gap-4">
                                        {!record.placeholder ? (
                                            <div className="flex flex-col gap-4 border rounded-lg p-4">
                                                <div className="flex flex-col gap-2">
                                                    <FormattedMessage
                                                        id={"attendance.vacations.substitute"}
                                                        defaultMessage={"Substitute"}
                                                    />
                                                    <Select defaultValue={record.substitute?.userUUID ? record.substitute?.userUUID : substituteUUID ? substituteUUID : undefined} onValueChange={(value) => {
                                                        changeSubstitute(index, value, substitutes, setData)
                                                    }}>
                                                        <SelectTrigger>
                                                            <SelectValue placeholder={
                                                                    intl.formatMessage({
                                                                        id: "attendance.vacations.substitutePlaceholder",
                                                                        defaultMessage: "Select a substitute"
                                                                    })
                                                                }
                                                            />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            {substitutes.map((substitute) => (
                                                                <SelectItem value={substitute.userUUID}>{substitute.fullName}</SelectItem>
                                                            ))}
                                                        </SelectContent>
                                                    </Select>
                                                </div>
                                                <div className="flex space-x-2">
                                                    <div className="flex flex-col gap-2 w-1/2">
                                                        <FormattedMessage
                                                            id={"attendance.vacations.type"}
                                                            defaultMessage={"Type"}
                                                        />
                                                        <FormField control={form.control} name={`records.${index}.type`} render={({ field, fieldState, formState, }) => (
                                                            <FormItem>
                                                                <Select defaultValue={record?.type ? record.type : vacationType ? vacationType : undefined} onValueChange={(value: TVacationType) => {
                                                                    changeType(index, value, setData)
                                                                }}>
                                                                    <SelectTrigger className={cn(fieldState.error?.message ? "ring-1 ring-offset-2 ring-red-500" : "")}>
                                                                        <SelectValue placeholder={
                                                                                intl.formatMessage({
                                                                                    id: "attendance.vacations.typePlaceholder",
                                                                                    defaultMessage: "Select a type"
                                                                                })
                                                                            }
                                                                        />
                                                                    </SelectTrigger>
                                                                    <SelectContent>
                                                                        {vacationTypes.map(({key, value}) => (
                                                                            <SelectItem value={value}>{intl.formatMessage({id: `attendance.vacations.${value}`, defaultMessage: key})}</SelectItem>
                                                                        ))}
                                                                    </SelectContent>
                                                                </Select>
                                                            </FormItem>
                                                        )}/>
                                                    </div>
                                                    <div className="flex flex-col gap-2 w-1/2">
                                                        <FormattedMessage
                                                            id={"attendance.vacations.portion"}
                                                            defaultMessage={"Portion"}
                                                        />
                                                        <Select defaultValue={record?.portion} onValueChange={(value: TPortionType) => {
                                                            changePortion(index, value, setData)
                                                        }}>
                                                            <SelectTrigger>
                                                                <SelectValue placeholder={
                                                                        intl.formatMessage({
                                                                            id: "attendance.vacations.portionPlaceholder",
                                                                            defaultMessage: "Select a portion"
                                                                        })
                                                                    }
                                                                />
                                                            </SelectTrigger>
                                                            <SelectContent>
                                                                <SelectItem value={"wholeDay"}>
                                                                    <FormattedMessage id={"attendance.vacations.wholeDay"} defaultMessage={"Whole Day"}/>
                                                                </SelectItem>
                                                                <SelectItem value={"firstHalf"}>
                                                                    <FormattedMessage id={"attendance.vacations.firstHalf"} defaultMessage={"First Half"}/>
                                                                </SelectItem>
                                                                <SelectItem value={"secondHalf"}>
                                                                    <FormattedMessage id={"attendance.vacations.secondHalf"} defaultMessage={"Second Half"}/>
                                                                </SelectItem>
                                                            </SelectContent>
                                                        </Select>
                                                    </div>
                                                </div>

                                                <div className="flex space-x-2">
                                                    <div className="flex flex-col gap-2 w-1/2">
                                                        <FormattedMessage
                                                            id={"attendance.vacations.start"}
                                                            defaultMessage={"Start"}
                                                        />
                                                        <FormField
                                                            control={form.control}
                                                            name={`records.${index}.startTime`}
                                                            render={({ field }) => (
                                                                <FormItem>
                                                                    <FormControl>
                                                                        <TimePicker
                                                                            label={field.name}
                                                                            value={(() => {
                                                                                let value = record.startTime ? moment(record.startTime, 'HH:mm:ss') : undefined
                                                                                return value ? {
                                                                                    hour: value.get('hour'),
                                                                                    minute: value.get('minute')
                                                                                } as TimeValue : undefined
                                                                            })()}
                                                                            onChange={(value) => {
                                                                                changeTime(index, 'startTime', value ? moment(value).format('HH:mm') : undefined, setData)
                                                                                field.onChange(value)
                                                                            }}
                                                                            
                                                                        />
                                                                    </FormControl>
                                                                    <FormMessage/>
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col gap-2 w-1/2">
                                                        <FormattedMessage
                                                            id={"attendance.vacations.end"}
                                                            defaultMessage={"End"}
                                                        />
                                                        <FormField
                                                            control={form.control}
                                                            name={`records.${index}.endTime`}
                                                            render={({ field }) => (
                                                                <FormItem>
                                                                    <FormControl>
                                                                        <TimePicker
                                                                            label={field.name}
                                                                            value={(() => {
                                                                                let value = record.endTime ? moment(record.endTime, 'HH:mm:ss') : undefined

                                                                                return value ? {
                                                                                    hour: value.get('hour'),
                                                                                    minute: value.get('minute')
                                                                                } as TimeValue : undefined
                                                                            })()}
                                                                            onChange={(value) => {
                                                                                changeTime(index, 'endTime', value ? moment(value).format('HH:mm') : undefined, setData)
                                                                                field.onChange(value)
                                                                            }}
                                                                            
                                                                        />
                                                                    </FormControl>
                                                                    <FormMessage/>
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="border text-gray-500 rounded-lg p-4">
                                                <FormattedMessage
                                                    id={"day_not_picked.sentence"}
                                                    defaultMessage={"This day is not selected."}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </AccordionContent>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
            ) : null}

            <div className="w-full text-right text-xs text-gray-500/50">
                <span>
                    {intl.formatMessage({id: "attendance.vacations.workingDays", defaultMessage: "Working Days"})}
                    {" "}
                    ( {data?.records.filter(elem => !elem.placeholder).length} / {data?.records.length} )
                </span>
            </div>

        </div>
    )
}

type PreviewProps = {
    form: UseFormReturn<TVacationsFormSchema, any, undefined>
    data?: {dateFrom: string, dateTo: string, records: TDayRecord[]}
    employeeName?: string
}

export const VacationsPreview: React.FC<PreviewProps> = (
    {
        form,
        data,
        employeeName
    }
) => {
    const intl = useIntl()

    return (
        <>
            <DetailViewCard rows={[
                {
                    name: intl.formatMessage({id: "attendance.vacations.employee", defaultMessage: "Employee"}),
                    value: employeeName ?? nullOrUndefined
                },
                {
                    name: intl.formatMessage({id: "attendance.vacations.dateFrom", defaultMessage: "Date From"}),
                    value: form.getValues('dateFromTo.from') ? moment(form.getValues('dateFromTo.from')).local().format('ddd DD MMM YYYY') : nullOrUndefined
                },
                {
                    name: intl.formatMessage({id: "attendance.vacations.dateTo", defaultMessage: "Date To"}),
                    value: form.getValues('dateFromTo.to') ? moment(form.getValues('dateFromTo.to')).local().format('ddd DD MMM YYYY') : nullOrUndefined
                }
            ]}/>

            {data ? (
                <div className="border rounded-md max-h-[32vh] overflow-y-scroll">
                    <Table className="border-none">
                        <TableHeader>
                            <TableRow>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.day"}
                                        defaultMessage={"Day"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.substitute"}
                                        defaultMessage={"Substitute"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.type"}
                                        defaultMessage={"Type"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.portion"}
                                        defaultMessage={"Portion"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.start"}
                                        defaultMessage={"Start"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.end"}
                                        defaultMessage={"End"}
                                    />
                                </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {data.records.length ? (
                                data.records?.map((record,index) => {
                                    return <TableRow>
                                        <TableCell className={"min-w-fit"}>{moment(record.date).format("ddd DD MMM YYYY")}</TableCell>
                                        <TableCell className={"min-w-fit"}>{record.substitute?.fullName ?? nullOrUndefined}</TableCell>
                                        <TableCell className={"min-w-fit"}>{(() => {
                                            let vacationType = vacationTypes.find(type => type.value === record?.type)

                                            if (!vacationType) return nullOrUndefined

                                            return intl.formatMessage({id: `attendance.vacations.${vacationType?.value}`, defaultMessage: vacationType?.key})
                                        })()}</TableCell>
                                        <TableCell className={"min-w-fit"}>{(() => {
                                            let portionType = portionTypes.find(type => type.value === record?.portion)

                                            if (!portionType) return nullOrUndefined

                                            return intl.formatMessage({id: `attendance.vacations.${portionType?.value}`, defaultMessage: portionType?.key})
                                        })()}</TableCell>
                                        <TableCell className={"min-w-fit"}>{record?.startTime ? moment(record.startTime, 'HH:mm:ss').format('HH:mm') : nullOrUndefined}</TableCell>
                                        <TableCell className={"min-w-fit"}>{record?.endTime ? moment(record.endTime, 'HH:mm:ss').format('HH:mm') : nullOrUndefined}</TableCell>
                                    </TableRow>
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <FormattedMessage id={"no_results"} defaultMessage={"No results found."}/>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                </div>
            ) : null}
        </>
    )
}
