import React from "react";
import AttendanceEntry from "./Pages/attendance";
import VacationsEntry from "./Pages/absences";
import TimeCorrectionsEntry from "./Pages/time-corrections";
import PublicHolidaysEntry from "./Pages/public-holidays";
import LocationsEntry from "./Pages/locations";
import {useIntl} from "react-intl";
import {PageShape, PageShapeProps} from "../Core/layout/page-shape";

export const attendanceManagementUrl: string = 'attendance-management/';

const ATTENDANCES_TAB = "attendance";
const TIME_CORRECTIONS_TAB = "time-corrections";
const ABSENCES_TAB = "absences";
const PUBLIC_HOLIDAYS_TAB = "public-holidays";
const LOCATIONS_TAB = "locations";

const Attendance = () => {
    const intl = useIntl();
    const attendanceName = intl.formatMessage({id: "attendance.attendances", defaultMessage: "Attendances"});
    const timeCorrectionsName = intl.formatMessage({id: "attendance.timeCorrections", defaultMessage: "Time Corrections"});
    const absencesName = intl.formatMessage({id: "attendance.vacations", defaultMessage: "Absences"});
    const publicHolidaysName = intl.formatMessage({id: "attendance.publicHolidays", defaultMessage: "Public Holidays"});
    const locationsName = intl.formatMessage({id: "attendance.locations", defaultMessage: "Locations"});
    const sections = [
        {label: attendanceName, value: ATTENDANCES_TAB},
        {label: timeCorrectionsName, value: TIME_CORRECTIONS_TAB},
        {label: absencesName, value: ABSENCES_TAB},
        {label: publicHolidaysName, value: PUBLIC_HOLIDAYS_TAB},
        {label: locationsName, value: LOCATIONS_TAB},
    ]

    const PageContent: PageShapeProps = {
        header: intl.formatMessage({id: "attendance", defaultMessage: "Attendance Management"}),
        sections: sections,
        pageEntries: [
            props => <AttendanceEntry name={ATTENDANCES_TAB} pagesState={props.pagesState}/>,
            props => <TimeCorrectionsEntry name={TIME_CORRECTIONS_TAB} pagesState={props.pagesState}/>,
            props => <VacationsEntry name={ABSENCES_TAB} pagesState={props.pagesState}/>,
            props => <PublicHolidaysEntry name={PUBLIC_HOLIDAYS_TAB} pagesState={props.pagesState}/>,
            props => <LocationsEntry name={LOCATIONS_TAB} pagesState={props.pagesState}/>,
        ],
        defaultSection: sections[0]
    }

    return <PageShape {...PageContent}/>
}

export default Attendance;