import NavBarComponent from "../AppSections/NavBarComponent";
import TopBarComponent from "../AppSections/TopBarComponent";
import {Outlet} from "react-router-dom";
import React, {useContext} from "react";
import {PermissionContext} from "../utilities/PermissionProvider";

const AppLayout: React.FC = () => {
    const permissionContext = useContext(PermissionContext)

    return (
        <div className="flex flex-row min-h-[100vh] h-fit">
            {permissionContext.isChanchingState ? (
                <div className={"fixed top-0 left-0 bottom-0 right-0 bg-black/50 z-[100] flex items-center justify-center"}></div>
            ) : null}
            <div className="flex fixed top-0 left-0 bottom-0 w-[5rem] z-10">
                <NavBarComponent/>
            </div>
            <div className="flex flex-col grow h-fit ml-[5rem]">
                <TopBarComponent/>
                <div className="p-6 min-h-[92vh] max-h-fit self-stretch">
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}

export default AppLayout;