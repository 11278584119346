import React, {Dispatch, ReactNode, SetStateAction, useEffect, useState} from "react";
import DataTableTools from "../../Core/Table/data-table-tools";
import DataTablePagination from "../../Core/Table/data-table-pagination";
import {
    Timesheet,
    TimesheetEmployee,
    TimesheetEmployeesPayload,
    TimesheetEmployeesRequest, timesheetManagementUrl
} from "../Controllers/timesheet-controller";
import {Button} from "../../components/button";
import {Badge} from "../../components/badge";
import statusVariants from "../../Core/functions/status-variants";
import moment from "moment/moment";
import {FormattedMessage, useIntl} from "react-intl";
import {cn} from "../../lib/utils";
import {toast} from "../../components/use-toast";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {Action} from "Core/Table/data-table-actions";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent, AlertDialogDescription, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "../../components/alert-dialog";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuLabel,
    DropdownMenuSeparator, DropdownMenuTrigger
} from "../../components/dropdown-menu";
import {MixerHorizontalIcon} from "@radix-ui/react-icons";
import DataTableExperimental, {ColumnExperimental} from "../../Core/components/Table_experimental/data-table";
import {nullOrUndefined} from "../../Core/constants/variables";


type TimesheetEmployeesDataTableProps = {
    timesheetEmployeesRequest: TimesheetEmployeesRequest
    payload: TimesheetEmployeesPayload
    setPayload: React.Dispatch<SetStateAction<TimesheetEmployeesPayload>>
    loading: boolean
    month: number
    year: number
    isLoading?: boolean
    handleSingleMonthUpdate: (year: number, month: number, signal?: AbortSignal) => Promise<void>
}

type TTimesheetEmployeeFromatted = TimesheetEmployee & {
    statusElement: ReactNode
    overtimeElement: ReactNode
    actions: Action[] | ReactNode
}

type completeFuncParams = {
    uuid: string
    month: string
    year: string
}

const useCompleteMonth = (
    rePopulateRecord: (userUUID: string) => void,
    handleSingleMonthUpdate: (year: number, month: number, signal?: AbortSignal) => Promise<void>
) => {
    const intl = useIntl()
    const axiosInstance = useAxiosInstance()
    const [loadingComplete, setLoading] = useState<boolean>(false)

    const complete = async ({...params}: completeFuncParams) => {
        setLoading(true)

        try {
            const response = await axiosInstance.post(`users/${params.uuid}/new-work-month-confirmation/`, {
                month: params.month,
                year: params.year
            })
            if (response) {
                rePopulateRecord(params.uuid)
                handleSingleMonthUpdate(Number(params.year), Number(params.month))
                    .then(() => {
                        toast({
                            title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                            description: intl.formatMessage({id: "toast.success.requestProcessed", defaultMessage: "Your request has been processed successfully."})
                        });
                    })
            }
        } catch (err) {
            toast({
                title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                description: intl.formatMessage({id: "toast.success.unableToProcess", defaultMessage: "Unable to process your request at the moment."}),
                variant: "destructive"
            })
        } finally {
            setLoading(false)
        }
    }
    return {complete, loadingComplete}
}

const TimesheetEmployeesDataTable = (
    {
        timesheetEmployeesRequest,
        payload,
        setPayload,
        loading,
        year,
        month,
        isLoading,
        handleSingleMonthUpdate
    }: TimesheetEmployeesDataTableProps) => {
    const intl = useIntl();
    const [formattedData, setFormattedData] = useState<TTimesheetEmployeeFromatted[]>([])
    const [hours,setHours] = useState(false)
    const [days,setDays] = useState(false)

    const rePopulateWhenCompleted = (userUUID: string) => {
        setFormattedData(prevState => {
            return prevState.map((employee) => {
                if (employee.userUUID !== userUUID) return employee
                return {
                    ...employee,
                    status: "Complete",
                    statusElement: <Badge variant={statusVariants("Complete")}>{intl.formatMessage({id: "complete", defaultMessage: "Complete"})}</Badge>,
                    actions: <Button variant={"taimDefault"} disabled>
                        <FormattedMessage id={"timesheetManagement.complete"} defaultMessage={"Complete"}/>
                    </Button>
                }
            })
        })
    }

    const {complete, loadingComplete} = useCompleteMonth(
        rePopulateWhenCompleted,
        handleSingleMonthUpdate
    );

    const HARDCODED_ALL_COLUMNS: ColumnExperimental<TTimesheetEmployeeFromatted>[] = [
        {
            backendKey: "fullName",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.employeeName", defaultMessage: "Employee Name"}),
            sortable: true,
            isSorted: "asc",
        },
        {
            backendKey: "attendanceTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.attendanceTotalHours", defaultMessage: "Attendance Hours"}),
            sortable: true
        },
        {
            backendKey: "vacationTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.vacationTotalHours", defaultMessage: "Vacation Hours"}),
            sortable: true
        },
        {
            backendKey: "vacationTotalDays",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.vacationTotalDays", defaultMessage: "Vacation Days"}),
            sortable: true
        },
        {
            backendKey: "sickNoteTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.sickNoteTotalHours", defaultMessage: "Sick Hours"}),
            sortable: true,
        },
        {
            backendKey: "sickNoteTotalDays",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.sickNoteTotalDays", defaultMessage: "Sick Days"}),
            sortable: true
        },
        {
            backendKey: "publicHolidayTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.publicHolidayTotalHours", defaultMessage: "Public Holiday Hours"}),
            sortable: true,
        },
        {
            backendKey: "publicHolidayTotalDays",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.publicHolidayTotalDays", defaultMessage: "Public Holidays"}),
            sortable: true
        },
        {
            backendKey: "compensatoryTimeOffTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.compensatoryTimeOffTotalHours", defaultMessage: "Compensatory Time Off Hours"}),
            sortable: true,
        },
        {
            backendKey: "compensatoryTimeOffTotalDays",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.compensatoryTimeOffTotalDays", defaultMessage: "Compensatory Time Off Days"}),
            sortable: true
        },
        {
            backendKey: "absenceTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.absenceTotalHours", defaultMessage: "Absense Hours"}),
            sortable: true,
        },
        {
            backendKey: "absenceTotalDays",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.absenceTotalDays", defaultMessage: "Absence Days"}),
            sortable: true
        },
        {
            backendKey: "spentTime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.actualTime", defaultMessage: "Actual Time"}),
            sortable: true,
            isSorted: "desc",
        },
        {
            backendKey: "prevOvertime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.prevOvertime", defaultMessage: "Carryover"}),
            sortable: true
        },
        {
            backendKey: "manualCarryoverCorrectionDuration",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.manualCarryoverCorrectionDuration", defaultMessage: "Manual Carryover"}),
            sortable: true
        },
        {
            backendKey: "plannedTime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.plannedTime", defaultMessage: "Target Time"}),
            sortable: true,
            isSorted: "desc",
        },
        {
            backendKey: "inflowDuration",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.inflowDuration", defaultMessage: "Inflow Duration"}),
            sortable: true
        },
        {
            backendKey: "outflowDuration",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.outflowDuration", defaultMessage: "Outflow Duration"}),
            sortable: true
        },
        {
            backendKey: "overtimeElement",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.overtime", defaultMessage: "Overtime"}),
            sortable: true
        },
        {
            backendKey: "statusElement",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.status", defaultMessage: "Status"}),
            sortable: true
        },
        {
            backendKey: "actions",
            frontendKey: "actions",
            isPublic: true
        },
    ]

    const HARDCODED_WITHOUT_HOURS_DAYS_COLUMNS: ColumnExperimental<TTimesheetEmployeeFromatted>[] = [
        {
            backendKey: "fullName",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.employeeName", defaultMessage: "Employee Name"}),
            sortable: true,
            isSorted: "asc",
        },
        {
            backendKey: "attendanceTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.attendanceTotalHours", defaultMessage: "Attendance Hours"}),
            sortable: true
        },
        {
            backendKey: "absenceTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.absenceTotalHours", defaultMessage: "Absense Hours"}),
            sortable: true,
        },
        {
            backendKey: "absenceTotalDays",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.absenceTotalDays", defaultMessage: "Absence Days"}),
            sortable: true
        },
        {
            backendKey: "spentTime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.actualTime", defaultMessage: "Actual Time"}),
            sortable: true,
            isSorted: "desc",
        },
        {
            backendKey: "prevOvertime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.prevOvertime", defaultMessage: "Carryover"}),
            sortable: true
        },
        {
            backendKey: "manualCarryoverCorrectionDuration",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.manualCarryoverCorrectionDuration", defaultMessage: "Manual Carryover"}),
            sortable: true
        },
        {
            backendKey: "plannedTime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.plannedTime", defaultMessage: "Target Time"}),
            sortable: true,
            isSorted: "desc",
        },
        {
            backendKey: "inflowDuration",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.inflowDuration", defaultMessage: "Inflow Duration"}),
            sortable: true
        },
        {
            backendKey: "outflowDuration",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.outflowDuration", defaultMessage: "Outflow Duration"}),
            sortable: true
        },
        {
            backendKey: "overtimeElement",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.overtime", defaultMessage: "Overtime"}),
            sortable: true
        },
        {
            backendKey: "statusElement",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.status", defaultMessage: "Status"}),
            sortable: true
        },
        {
            backendKey: "actions",
            frontendKey: "actions",
            isPublic: true
        },
    ]

    const HARDCODED_WITH_DAYS_COLUMNS: ColumnExperimental<TTimesheetEmployeeFromatted>[] = [
        {
            backendKey: "fullName",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.employeeName", defaultMessage: "Employee Name"}),
            sortable: true,
            isSorted: "asc",
        },
        {
            backendKey: "attendanceTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.attendanceTotalHours", defaultMessage: "Attendance Hours"}),
            sortable: true
        },
        {
            backendKey: "vacationTotalDays",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.vacationTotalDays", defaultMessage: "Vacation Days"}),
            sortable: true
        },
        {
            backendKey: "sickNoteTotalDays",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.sickNoteTotalDays", defaultMessage: "Sick Days"}),
            sortable: true
        },
        {
            backendKey: "publicHolidayTotalDays",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.publicHolidayTotalDays", defaultMessage: "Public Holidays"}),
            sortable: true
        },
        {
            backendKey: "compensatoryTimeOffTotalDays",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.compensatoryTimeOffTotalDays", defaultMessage: "Compensatory Time Off Days"}),
            sortable: true
        },
        {
            backendKey: "absenceTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.absenceTotalHours", defaultMessage: "Absense Hours"}),
            sortable: true,
        },
        {
            backendKey: "absenceTotalDays",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.absenceTotalDays", defaultMessage: "Absence Days"}),
            sortable: true
        },
        {
            backendKey: "spentTime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.actualTime", defaultMessage: "Actual Time"}),
            sortable: true,
            isSorted: "desc",
        },
        {
            backendKey: "prevOvertime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.prevOvertime", defaultMessage: "Carryover"}),
            sortable: true
        },
        {
            backendKey: "manualCarryoverCorrectionDuration",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.manualCarryoverCorrectionDuration", defaultMessage: "Manual Carryover"}),
            sortable: true
        },
        {
            backendKey: "plannedTime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.plannedTime", defaultMessage: "Target Time"}),
            sortable: true,
            isSorted: "desc",
        },
        {
            backendKey: "inflowDuration",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.inflowDuration", defaultMessage: "Inflow Duration"}),
            sortable: true
        },
        {
            backendKey: "outflowDuration",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.outflowDuration", defaultMessage: "Outflow Duration"}),
            sortable: true
        },
        {
            backendKey: "overtimeElement",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.overtime", defaultMessage: "Overtime"}),
            sortable: true
        },
        {
            backendKey: "statusElement",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.status", defaultMessage: "Status"}),
            sortable: true
        },
        {
            backendKey: "actions",
            frontendKey: "actions",
            isPublic: true
        },
    ]

    const HARDCODED_WITH_HOURS_COLUMNS: ColumnExperimental<TTimesheetEmployeeFromatted>[] = [
        {
            backendKey: "fullName",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.employeeName", defaultMessage: "Employee Name"}),
            sortable: true,
            isSorted: "asc",
        },
        {
            backendKey: "attendanceTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.attendanceTotalHours", defaultMessage: "Attendance Hours"}),
            sortable: true
        },
        {
            backendKey: "vacationTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.vacationTotalHours", defaultMessage: "Vacation Hours"}),
            sortable: true
        },
        {
            backendKey: "sickNoteTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.sickNoteTotalHours", defaultMessage: "Sick Hours"}),
            sortable: true,
        },
        {
            backendKey: "publicHolidayTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.publicHolidayTotalHours", defaultMessage: "Public Holiday Hours"}),
            sortable: true,
        },
        {
            backendKey: "compensatoryTimeOffTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.compensatoryTimeOffTotalHours", defaultMessage: "Compensatory Time Off Hours"}),
            sortable: true,
        },
        {
            backendKey: "absenceTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.absenceTotalHours", defaultMessage: "Absense Hours"}),
            sortable: true,
        },
        {
            backendKey: "absenceTotalDays",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.absenceTotalDays", defaultMessage: "Absence Days"}),
            sortable: true
        },
        {
            backendKey: "spentTime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.actualTime", defaultMessage: "Actual Time"}),
            sortable: true,
            isSorted: "desc",
        },
        {
            backendKey: "prevOvertime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.prevOvertime", defaultMessage: "Carryover"}),
            sortable: true
        },
        {
            backendKey: "manualCarryoverCorrectionDuration",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.manualCarryoverCorrectionDuration", defaultMessage: "Manual Carryover"}),
            sortable: true
        },
        {
            backendKey: "plannedTime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.plannedTime", defaultMessage: "Target Time"}),
            sortable: true,
            isSorted: "desc",
        },
        {
            backendKey: "inflowDuration",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.inflowDuration", defaultMessage: "Inflow Duration"}),
            sortable: true
        },
        {
            backendKey: "outflowDuration",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.outflowDuration", defaultMessage: "Outflow Duration"}),
            sortable: true
        },
        {
            backendKey: "overtimeElement",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.overtime", defaultMessage: "Overtime"}),
            sortable: true
        },
        {
            backendKey: "statusElement",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.status", defaultMessage: "Status"}),
            sortable: true
        },
        {
            backendKey: "actions",
            frontendKey: "actions",
            isPublic: true
        },
    ]

    const [columns, setColumns] = useState<ColumnExperimental<TTimesheetEmployeeFromatted>[]>(HARDCODED_ALL_COLUMNS)

    useEffect(() => {
        const formatData: TTimesheetEmployeeFromatted[] = timesheetEmployeesRequest?.data.map((employee: TimesheetEmployee) => {
            const isOvertime = employee.overtime.slice(0,1) !== "-";
            const isCurrentMonth = Number(month) === new Date().getMonth() + 1 && Number(year) === new Date().getFullYear();
            return {
                ...employee,
                statusElement: <Badge variant={statusVariants(employee?.status)}>{
                    employee?.status
                        ? intl.formatMessage({id: employee.status.toLowerCase(), defaultMessage: employee.status})
                        : nullOrUndefined
                }</Badge>,
                sickNoteTotalDays: String(employee.sickNoteTotalDays),
                vacationTotalDays: String(employee.vacationTotalDays),
                publicHolidayTotalDays: String(employee.publicHolidayTotalDays),
                absenceTotalDays: String(employee.absenceTotalDays),
                compensatoryTimeOffTotalDays: String(employee.compensatoryTimeOffTotalDays),
                overtimeElement: <span className={cn(
                    isOvertime ? "text-green-500" : "text-red-500"
                )}>{employee.overtime}</span>,
                actions: (
                    <AlertDialog>
                        <AlertDialogTrigger
                            className={cn(
                                "flex-1 flex flex-row justify-start w-full",
                                (employee?.status === "Complete" || loadingComplete || isCurrentMonth) && "cursor-not-allowed"
                            )}
                            onClick={(e) => {
                                if (employee?.status === "Complete" || loadingComplete || isCurrentMonth) e.preventDefault()
                            }}
                        disabled={employee?.status === "Complete" || loadingComplete || isCurrentMonth}
                        >
                            <Button
                                variant={"taimDefault"}
                                disabled={employee?.status === "Complete" || loadingComplete || isCurrentMonth}
                            >
                                <FormattedMessage id={"timesheetManagement.complete"} defaultMessage={"Complete"}/>
                            </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent >
                            <AlertDialogHeader>
                                <AlertDialogTitle>
                                    <FormattedMessage
                                        id={"timesheetManagement.complete.titleh"}
                                        defaultMessage={"{month} Timesheet"}
                                        values={{
                                            month: moment().set({month: month-1}).format('MMMM')
                                        }}
                                    />
                                </AlertDialogTitle>
                            </AlertDialogHeader>
                            <AlertDialogDescription>
                                {employee?.status === "Complete" ? (
                                    <FormattedMessage
                                        id={"timesheetManagement.complete.sentenceForCompleted"}
                                        defaultMessage={`{username}'s timesheet for {month} is complete. {br} Are you sure you want to complete the month?`}
                                        values={{
                                            username: employee.fullName,
                                            month: moment().set({month: month-1}).format('MMMM'),
                                            br: <br/>
                                        }}
                                    />
                                ) : (
                                    <FormattedMessage
                                        id={"timesheetManagement.complete.sentenceForIncomplete"}
                                        defaultMessage={"{username}'s timesheet for {month} is not complete. {br} Are you sure you want to complete the month?"}
                                        values={{
                                            username: employee.fullName,
                                            month: moment().set({month: month-1}).format('MMMM'),
                                            br: <br/>
                                        }}
                                    />
                                )}
                            </AlertDialogDescription>
                            <AlertDialogFooter>
                                <AlertDialogCancel>
                                    <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                                </AlertDialogCancel>
                                <AlertDialogAction
                                    variant={"taimDefault"}
                                    onClick={() => {
                                        if (!isCurrentMonth) {
                                            complete({
                                                uuid: employee.userUUID,
                                                year: String(year),
                                                month: String(month)
                                            })
                                        }
                                    }}
                                >
                                    <FormattedMessage id={"timesheetManagement.completeMonth"} defaultMessage={"Complete Month"}/>
                                </AlertDialogAction>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                )
            };
        })

        setFormattedData(formatData)
    }, [timesheetEmployeesRequest.data]);

    useEffect(() => {
        if (hours && days) {
            setColumns(HARDCODED_ALL_COLUMNS)
        }
        if (hours && !days) {
            setColumns(HARDCODED_WITH_HOURS_COLUMNS)
        }
        if (!hours && days) {
            setColumns(HARDCODED_WITH_DAYS_COLUMNS)
        }
        if (!hours && !days) {
            setColumns(HARDCODED_WITHOUT_HOURS_DAYS_COLUMNS)
        }
    }, [hours,days]);

    return(
        <div className="space-y-4 min-h-[40rem] flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools
                    payload={payload}
                    setPayload={setPayload}
                    exportTable={{
                        fileName: `${year}_${month}_${moment().valueOf()}_employees`,
                        apiURL: timesheetManagementUrl + `${year}/${month}/employees/export/`,
                        payload: payload
                    }}
                >
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button
                              variant="outline"
                              size="sm"
                              className="ml-auto hidden h-8 lg:flex"
                            >
                              <MixerHorizontalIcon className="mr-2 h-4 w-4" />
                              View
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="start" className="w-fit">
                            <DropdownMenuLabel>
                                <FormattedMessage id={"toggle_columns"} defaultMessage={"Toggle columns"}/>
                            </DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuCheckboxItem
                                className="capitalize"
                                checked={days}
                                onCheckedChange={(value) => setDays(value)}
                            >
                                <FormattedMessage id={"timesheet.absence_days"} defaultMessage={"Absence Days"}/>
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuCheckboxItem
                                className="capitalize"
                                checked={hours}
                                onCheckedChange={(value) => setHours(value)}
                            >
                                <FormattedMessage id={"timesheet.absence_hours"} defaultMessage={"Absence Hours"}/>
                            </DropdownMenuCheckboxItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </DataTableTools>
                <DataTableExperimental<TTimesheetEmployeeFromatted>
                    data={formattedData}
                    loading={isLoading || loading}
                    setPayload={setPayload}
                    columns={columns}
                    setColumns={setColumns}
                    payload={payload}
                    showLoadingState={true}
                />
            </div>
            <DataTablePagination request={timesheetEmployeesRequest} payload={payload} setPayload={setPayload}/>
        </div>
    )
}

export default TimesheetEmployeesDataTable;