import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "components/button"
import {
    Form, FormControl, FormField, FormItem, FormLabel,
    FormMessage,
} from "components/form"
import {DialogClose, DialogFooter} from "components/dialog";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {employeesUrl} from "EmployeeManagement/Controllers/employee-controller";
import {toast} from "components/use-toast";
import {Input} from "components/input";
import {FormattedMessage, useIntl} from "react-intl";
import {ServerErrorsType, useServerErrors} from "Core/functions/use-server-errors";
import ErrorMessageList from "Core/components/ErrorMessageList";
import {TVacationExceptionRule} from "./controller";
import {DatePicker} from "../../../../components/date-picker";
import {Switch} from "../../../../components/switch";
import moment from "moment";
import {TaimInfo} from "../../../../Core/components/taim-info";
import {InputDateTimePicker} from "../../../../Core/components/input-date-picker";

const addVacationExceptionRuleFormSchema = z.object({
    year: z.any(),
    carryover: z.any(),
    expiryDate: z.any(),
});

type AddVacationExceptionRuleProps = {
    userUUID: string
    reFetch?: any
    setReloadVacationPlanTab: Dispatch<SetStateAction<boolean>>
}

export const AddVacationExceptionRule: React.FC<AddVacationExceptionRuleProps>  = ({
    userUUID,
    reFetch,
    setReloadVacationPlanTab
}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof addVacationExceptionRuleFormSchema>>({
        resolver: zodResolver(addVacationExceptionRuleFormSchema),
        defaultValues: {
            carryover: false
        }
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();
    const [carryover, setCarryover] = useState(false)

    const onSubmit = (values: z.infer<typeof addVacationExceptionRuleFormSchema>) => {
        axiosInstance.post(employeesUrl + `${userUUID}/vacation-exception-rules/`, {
            ...values,
            ...(values.expiryDate && {expiryDate: moment(values.expiryDate).format("YYYY-MM-DD")})
        })
            .then((res) => {
                setApiErrors({});
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
                reFetch(true);
                setReloadVacationPlanTab(true);
            })
            .catch((err) => {
                setApiErrors(err.response?.data)
            })
    };

    useEffect(() => {
        const subscription = form.watch((value, { name, type }) => {
            if (name === "carryover") {
                setCarryover(value.carryover)
            }
        })
        return () => subscription.unsubscribe()
    }, [])

    return (
    <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <FormField
                control={form.control}
                name="year"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel className="flex flex-row items-center gap-2">
                            <FormattedMessage
                                id={"employees.detail.vacationExceptionRules.year"}
                                defaultMessage={"Year"}
                            />
                            <TaimInfo text={intl.formatMessage({
                                id: "employees.detail.vacationExceptionRules.yearInfo",
                                defaultMessage: "Select the year for which the exception rule should be observed."
                            })}/>
                        </FormLabel>
                        <FormControl>
                            <Input
                                type={"number"}
                                placeholder={intl.formatMessage({
                                    id: "employees.detail.vacationExceptionRules.year",
                                    defaultMessage: "Year"
                                })}
                                {...field}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <FormField
                control={form.control}
                name="carryover"
                render={({ field }) => (
                    <FormItem className="flex flex-col space-y-4">
                        <FormLabel>
                            <FormattedMessage
                                id={"employees.detail.vacationExceptionRules.carryover"}
                                defaultMessage={"Carry Over"}
                            />
                        </FormLabel>
                        <FormControl>
                            <Switch
                                className="data-[state=checked]:bg-green-500"
                                checked={field.value}
                                onCheckedChange={field.onChange}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <FormField
                control={form.control}
                name="expiryDate"
                render={({ field }) => (
                    <FormItem className="flex flex-col space-y-4">
                        <FormLabel>
                            <FormattedMessage
                                id={"employees.detail.vacationExceptionRules.expiryDate"}
                                defaultMessage={"Expiry Date"}
                            />
                        </FormLabel>
                        <FormControl>
                            <DatePicker
                                date={field.value}
                                setDate={field.onChange}
                                disabled={!carryover}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <ErrorMessageList errors={errors.detailErrors}/>
            <ErrorMessageList errors={errors.nonFieldErrors}/>

            <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                <DialogClose ref={closeModal as any} asChild>
                    <Button variant="outline">
                        <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                    </Button>
                </DialogClose>
                <Button
                    variant="taimDefault"
                    type="submit"
                ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
            </DialogFooter>
        </form>
    </Form>
    )
}



const editVacationExceptionRulesFormSchema = z.object({
    expiryDate: z.any(),
    carryover: z.any(),
    year: z.any()
});

type EditVacationExceptionRuleProps = {
    userUUID: string
    vacationRule: TVacationExceptionRule
    reFetch?: any
    setReloadVacationPlanTab: Dispatch<SetStateAction<boolean>>
}

export const EditVacationExceptionRule: React.FC<EditVacationExceptionRuleProps>  = ({
    userUUID,
    vacationRule,
    reFetch,
    setReloadVacationPlanTab
}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof editVacationExceptionRulesFormSchema>>({
        resolver: zodResolver(editVacationExceptionRulesFormSchema),
        defaultValues: {
            ...(vacationRule?.year && {year: vacationRule.year}),
            ...(vacationRule?.expiryDate && {expiryDate: new Date(vacationRule.expiryDate)}),
            ...(vacationRule.hasOwnProperty('carryover') && {carryover: vacationRule.carryover}),
        }
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();
    const [carryover, setCarryover] = useState(vacationRule.carryover)

    const onSubmit = (values: z.infer<typeof editVacationExceptionRulesFormSchema>) => {
        axiosInstance.put(employeesUrl + `${userUUID}/vacation-exception-rules/${vacationRule.pk}/`, {
            ...values,
            ...(values.expiryDate && {expiryDate: moment(values.expiryDate).format("YYYY-MM-DD")})
        })
            .then((res) => {
                setApiErrors({});
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
                reFetch(true);
                setReloadVacationPlanTab(true);
            })
            .catch((err) => {
                setApiErrors(err.response?.data)
            })
    };

    useEffect(() => {
        const subscription = form.watch((value, { name, type }) => {
            if (name === "carryover") {
                setCarryover(value.carryover)
            }
        })
        return () => subscription.unsubscribe()
    }, [])

    return (
    <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <FormField
                control={form.control}
                name="year"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel className="flex flex-row items-center gap-2">
                            <FormattedMessage
                                id={"employees.detail.vacationExceptionRules.year"}
                                defaultMessage={"Year"}
                            />
                            <TaimInfo text={intl.formatMessage({
                                id: "employees.detail.vacationExceptionRules.yearInfo",
                                defaultMessage: "Select the year for which the exception rule should be observed."
                            })}/>
                        </FormLabel>
                        <FormControl>
                            <Input
                                type={"number"}
                                placeholder={intl.formatMessage({
                                    id: "employees.detail.vacationExceptionRules.year",
                                    defaultMessage: "Year"
                                })}
                                {...field}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <FormField
                control={form.control}
                name="carryover"
                render={({ field }) => (
                    <FormItem className="flex flex-col space-y-4">
                        <FormLabel>
                            <FormattedMessage
                                id={"employees.detail.vacationExceptionRules.carryover"}
                                defaultMessage={"Carry Over"}
                            />
                        </FormLabel>
                        <FormControl>
                            <Switch
                                className="data-[state=checked]:bg-green-500"
                                checked={field.value}
                                onCheckedChange={field.onChange}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <FormField
                control={form.control}
                name="expiryDate"
                render={({ field }) => (
                    <FormItem className="flex flex-col space-y-4">
                        <FormLabel>
                            <FormattedMessage
                                id={"employees.detail.vacationExceptionRules.expiryDate"}
                                defaultMessage={"Expiry Date"}
                            />
                        </FormLabel>
                        <FormControl>
                            <DatePicker
                                date={field.value}
                                setDate={field.onChange}
                                disabled={!carryover}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <ErrorMessageList errors={errors.detailErrors}/>
            <ErrorMessageList errors={errors.nonFieldErrors}/>

            <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                <DialogClose ref={closeModal as any} asChild>
                    <Button variant="outline">
                        <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                    </Button>
                </DialogClose>
                <Button
                    variant="taimDefault"
                    type="submit"
                ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
            </DialogFooter>
        </form>
    </Form>
    )
}