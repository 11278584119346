import {FormattedMessage} from "react-intl";

const ComingSoon = () => {
    return(
        <div className="flex relative flex-col items-start gap-3 self-stretch p-6 rounded-xl border border-slate-200 bg-slate-800 overflow-hidden">
            <div className="flex items-center gap-2.5 self-stretch">
                <div className="text-slate-200 text-sm font-medium leading-5">
                    <FormattedMessage id="comingSoon.title" defaultMessage="Coming Soon Features"/>
                </div>
                <div className="w-[10.8125rem] h-px bg-slate-500" />
            </div>
            <div className="self-stretch text-white text-[1.375rem] font-semibold leading-7">
                <span className="text-[#FACC15]">
                    <FormattedMessage id="comingSoon.description1" defaultMessage="Quick view and action"/>
                </span><FormattedMessage id="comingSoon.description2" defaultMessage=" for employee activity"/>
            </div>
            <div className="self-stretch text-gray-300 leading-6 ml-6">
                <ul className="list-disc">
                    <li><FormattedMessage id="comingSoon.body1" defaultMessage="Accept or reject vacation request"/></li>
                    <li><FormattedMessage id="comingSoon.body2" defaultMessage="Employee activity"/></li>
                    <li><FormattedMessage id="comingSoon.body3" defaultMessage="Reject vacation request Employee"/></li>
                    <li><FormattedMessage id="comingSoon.body4" defaultMessage="Activities"/></li>
                </ul>
            </div>
            <div className="right-[-5.25rem] bottom-[-1rem] absolute w-[21rem] h-[8.125rem] bg-[url(./assets/images/taim_comingsoon.png)] bg-contain bg-no-repeat"/>
        </div>
    )
}

export default ComingSoon;