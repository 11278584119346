import {UserDashboardData} from "Dashboard/Controllers/User/dashboard-controller";
import {nullOrUndefined} from "Core/constants/variables";
import {Card, CardContent, CardHeader} from "components/card";
import {FormattedMessage, useIntl} from "react-intl";
import React, {Dispatch, SetStateAction, useEffect, useReducer} from "react";
import {useFetchEmployeesAbsences} from "Dashboard/Controllers/Admin/employees-absences-controller";
import AbsenceCard from "Dashboard/Components/absence-card";
import {Skeleton} from "components/skeleton";
import {getRandomInt} from "Core/functions/random";
import {Button} from "../../../../components/button";
import {Clock, Gear, Play, Stop, Timer} from "@phosphor-icons/react";
import {TaimModal} from "../../../../Core/components/taim-modal";
import {Label} from "../../../../components/label";
import {Switch} from "../../../../components/switch";
import {TaimInfo} from "../../../../Core/components/taim-info";
import {useAxiosInstance} from "../../../../Core/utilities/AxiosInstance";
import {toast} from "../../../../components/use-toast";

type UserOverviewProps = {
    dashboardData?: UserDashboardData
    loadingDashboardData: boolean
    reloadDashboard: boolean
    setReloadDashboard: Dispatch<SetStateAction<boolean>>
    setReloadDashboardData: Dispatch<SetStateAction<boolean>>
}

type UserBreakTimesCardProps = {
    dashboardData?: UserDashboardData
    loadingDashboardData: boolean
    setReloadDashboardData: Dispatch<SetStateAction<boolean>>
    reloadDashboard: boolean
    setReloadDashboard: Dispatch<SetStateAction<boolean>>
}

enum BreakTimesActionKind {
    START_AND_STOP = 'START_AND_STOP',
    SET_AUTOMATIC_OR_MANUAL = 'SET_AUTOMATIC_OR_MANUAL',
}

// An interface for our actions
interface BreakTimesAction {
    type: BreakTimesActionKind;
    payload: boolean;
}

type BreakTimes = {
    isManualBreakTimes: {
        state: boolean,
        error?: string
    }
    onBreak?: {
        state: boolean,
        error?: string
    }
}

const INITIAL_BREAKTIMES_STATE: BreakTimes = {
    isManualBreakTimes: {
        state: false
    },
    onBreak: {
        state: false
    }
}

const reducer = (state: BreakTimes, action: BreakTimesAction): BreakTimes => {

    switch (action.type) {
        case BreakTimesActionKind.START_AND_STOP:
            return {
                ...state,
                onBreak: {
                    state: action.payload
                }
            }
        case BreakTimesActionKind.SET_AUTOMATIC_OR_MANUAL:
            return {
                ...state,
                isManualBreakTimes: {
                    state: action.payload
                }
            }
        default:
            return state
    }
}


const BreakTimesCard: React.FC<UserBreakTimesCardProps> = (
{
    dashboardData,
    loadingDashboardData,
    reloadDashboard,
    setReloadDashboard
}
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const userUUID = localStorage.getItem('userUUID');
    const [breakTimes, dispatch] = useReducer(reducer, INITIAL_BREAKTIMES_STATE)
    const fetchManualBreakTimesState = () => {
        axiosInstance.get(`dashboard/employees/${userUUID}/switch-auto-break-time/`)
            .then((res) => {
                dispatch({type: BreakTimesActionKind.SET_AUTOMATIC_OR_MANUAL, payload: res.data?.manualBreakTimes ?? false})
            })
            .catch((err) => console.log(err))
    }

    const changeManualBreakTimesState = () => {
        axiosInstance.post(`dashboard/employees/${userUUID}/switch-auto-break-time/`)
            .then((res) => {
                dispatch({type: BreakTimesActionKind.SET_AUTOMATIC_OR_MANUAL, payload: !breakTimes.isManualBreakTimes.state})
                setReloadDashboard(true)
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.errorOccured", defaultMessage: err.response?.detail ?? "An error occurred. Please try again later."})
                });
            })
    }

    const fetchStartAndStopBreakTimes = () => {
        axiosInstance.get(`dashboard/employees/${userUUID}/start-stop-break-time/`)
            .then((res) => {
                dispatch({type: BreakTimesActionKind.START_AND_STOP, payload: res.data.started})
            })
            .catch((err) => console.log(err))
    }

    const startAndStopBreakTimes = () => {
        axiosInstance.post(`dashboard/employees/${userUUID}/start-stop-break-time/`)
            .then((res) => {
                dispatch({type: BreakTimesActionKind.START_AND_STOP, payload: !breakTimes.onBreak?.state})
                setReloadDashboard(true)
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.errorOccured", defaultMessage: err.response?.detail ?? "An error occurred. Please try again later."})
                });
            })
    }

    let currBreakTime = dashboardData?.breakTimeData?.currBreakTime
    let nextBreakTime = dashboardData?.breakTimeData?.nextBreakTime

    let breaktimesOfToday = dashboardData?.breakTimeData?.breakTimesOfToday?.map((breaktime: any) => (
        <div className="flex flex-row justify-between items-center text-gray-400 text-[14px]">
            <div className="flex flex-row space-x-1 items-center">
                <Clock size={16}/>
                <span>{breaktime.startTime + ' - ' + breaktime.endTime}</span>
            </div>
            <div className="flex flex-row space-x-1 items-center">
                <Timer size={16}/>
                <span>{breaktime.duration}</span>
            </div>
        </div>
    ))

    const title = (() => {
        if (currBreakTime) {
            return <div className="flex flex-row space-x-3 items-center">
                <span className="relative flex h-3 w-3">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-400"></span>
                </span>
                <span className="text-gray-500">
                    <FormattedMessage id={"dashboard.breakSince"} defaultMessage={"Break Since"}/>
                </span>
            </div>
        }

        if (nextBreakTime) {
            return <span className="text-gray-500">
                <FormattedMessage id={"dashboard.breakIn"} defaultMessage={"Break In"}/>
            </span>
        }

        return <span className="text-gray-500">
            <FormattedMessage id={"dashboard.breaktimes"} defaultMessage={"Break Times"}/>
        </span>
    })()

    useEffect(() => {
        fetchManualBreakTimesState()
        fetchStartAndStopBreakTimes()
    }, [reloadDashboard]);

    return (
        <div className="grow flex flex-col gap-2 border rounded-lg p-4">
            {(!dashboardData?.breakTimeData && loadingDashboardData) ? (
                <>
                    <div className="flex flex-row justify-between items-center">
                        <Skeleton className="h-6 w-1/4"/>
                        <Skeleton className="h-6 w-1/6"/>
                    </div>
                    <Skeleton className="h-8 w-1/3"/>
                    <div className="flex flex-row justify-between items-center text-gray-400 text-[14px]">
                        <Skeleton className="h-4 w-1/6"/>
                        <Skeleton className="h-4 w-12"/>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex flex-row justify-between items-center">
                        {title}
                        <TaimModal
                            header={intl.formatMessage({id: "edit", defaultMessage: "Edit"})}
                            button={(
                                <Gear className="text-blue-800 h-fit p-0"/>
                            )}
                        >
                            <Label className="flex flex-row items-center space-x-2 w-fit">
                                <span>
                                    <FormattedMessage id={"type"} defaultMessage={"Type"}/>
                                </span>
                                <TaimInfo text={intl.formatMessage({id: "type.info_sentence", defaultMessage: "Set break times to either automatic or manual"})}/>
                            </Label>
                            <div className="flex flex-row items-center gap-2 text-xs text-gray-500 capitalize">
                                <Switch
                                    className="data-[state=checked]:bg-green-500"
                                    checked={!breakTimes.isManualBreakTimes.state}
                                    onCheckedChange={changeManualBreakTimesState}
                                />
                                {breakTimes.isManualBreakTimes.state ? <FormattedMessage id={"manual"} defaultMessage={"Manual"}/> : <FormattedMessage id={"automatic"} defaultMessage={"Automatic"}/>}
                            </div>
                        </TaimModal>
                    </div>
                    {(currBreakTime || nextBreakTime) ? (
                        <span className="text-2xl font-bold mb-2">{currBreakTime?.startSince || nextBreakTime?.startIn}</span>
                    ) : null}

                    <>
                        {dashboardData?.breakTimeData?.breakTimesOfToday?.length ? (
                            breaktimesOfToday
                        ) : (!currBreakTime && !nextBreakTime && !breakTimes.isManualBreakTimes.state) ? (
                            <FormattedMessage id={"no_breaks"} defaultMessage={"No breaks."}/>
                        ) : null}
                    </>

                    {breakTimes.isManualBreakTimes.state ? (
                        <Button
                            variant={breakTimes.onBreak?.state ? "destructive" : "outline"}
                            onClick={() => startAndStopBreakTimes()}
                        >{breakTimes.onBreak?.state ? (
                            <div className="flex flex-row space-x-1 items-center">
                                <span>
                                    <FormattedMessage id={'stop'} defaultMessage={'Stop'}/>
                                </span><Stop size={14} weight={"fill"}/>
                            </div>
                        ) : (
                            <div className="flex flex-row space-x-1 items-center">
                                <span>
                                    <FormattedMessage id={'start'} defaultMessage={'Start'}/>
                                </span><Play size={14} />
                            </div>
                        )}</Button>
                    ) : null}
                </>
            )}
        </div>
    )
}


export const UserOverview: React.FC<UserOverviewProps> = (
    {
        dashboardData,
        loadingDashboardData,
        reloadDashboard,
        setReloadDashboard,
        setReloadDashboardData
    }
) => {
    const {
        absences,
        loadingAbsences
    } = useFetchEmployeesAbsences(reloadDashboard)

    return (
        <div className="col-span-2 flex flex-col gap-4">

            <BreakTimesCard
                dashboardData={dashboardData}
                loadingDashboardData={loadingDashboardData}
                setReloadDashboardData={setReloadDashboardData}
                setReloadDashboard={setReloadDashboard}
                reloadDashboard={reloadDashboard}
            />

            <div className="flex flex-row gap-4 h-fit">
                <div className={"grow flex flex-col gap-1 border rounded-lg p-4"}>
                    <span className={"text-gray-500"}>
                        <FormattedMessage id={"dashboard.today"} defaultMessage={"Today"}/>
                    </span>
                    <span className={"text-lg font-bold"}>
                        {loadingDashboardData && !dashboardData?.actualWorkHoursTotal ? <Skeleton className="h-4 w-12"/> : dashboardData ? dashboardData.actualWorkHoursTotal : nullOrUndefined}
                    </span>
                </div>
                <div className={"grow flex flex-col gap-1 border rounded-lg p-4"}>
                    <span className={"text-gray-500"}>
                        <FormattedMessage id={"dashboard.month"} defaultMessage={"Month"}/></span>
                    <span className={"text-lg font-bold"}>
                        {loadingDashboardData && !dashboardData?.actualWorkHoursMonthTotal ? <Skeleton className="h-4 w-12"/> : dashboardData ? dashboardData.actualWorkHoursMonthTotal : nullOrUndefined}
                    </span>
                </div>
            </div>
            <Card className="pb-4 grow">
                <CardHeader className={"font-semibold text-xl"}>
                    <FormattedMessage id="dashboard.absences.title" defaultMessage={"Absences"}/>
                </CardHeader>
                <CardContent className="pr-1">
                    <div className="space-y-8 max-h-[16rem] overflow-auto scrollbar">
                        {absences.length > 0 && (loadingAbsences || !loadingAbsences) ? (
                            absences?.map((absence, index) => <AbsenceCard key={index} absence={absence}/>)
                        ) : (absences.length === 0 && loadingAbsences) ? (
                            <div className="flex flex-row items-center gap-4">
                                <Skeleton className="w-[40px] h-[35px] rounded-full"/>
                                <div className="flex flex-col gap-2 w-full">
                                    <Skeleton className={`w-1/5 h-[10px]`}/>
                                    <Skeleton className={`w-1/${getRandomInt(2,4)} h-[10px]`}/>
                                </div>
                            </div>
                        ) : (
                            <FormattedMessage id={"no_results"} defaultMessage={"No results."}/>
                        )}
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}