import React, {useEffect, useState} from "react";
import {EmployeeTable} from "../DataTables/employee-table";
import {useIntl} from "react-intl";
import {Employee, ProjectEmployeesPayload, useFetchProjectEmployees} from "../Controller/employees-controller";
import {MainTab, PageState} from "../../Core/layout/page-shape";

const projectEmployeesPayloadInitialState: ProjectEmployeesPayload = {
    start: 0,
    length: 10,
    search: "",
    ordering: "",
}

type EmployeesProps = {
    name: string
    pagesState?: PageState[]
}

export const Employees: React.FC<EmployeesProps> = ({name, pagesState}) => {
    const intl = useIntl();
    const [
        employeesPayload,
        setEmployeesPayload
    ] = useState<ProjectEmployeesPayload>(projectEmployeesPayloadInitialState);
    const {
        employeesRequest,
        loadingEmployees,
        shouldRefetchEmployees
    } = useFetchProjectEmployees(employeesPayload);
    const [
        employeeDetail,
        setEmployeeDetail
    ] = useState<Employee | null>(null);


    useEffect(() => {
        let currPage = pagesState?.find(page => page.page.value === name);
        if (currPage && currPage.reFetch) {
            // setEmployeesPayload(projectEmployeesPayloadInitialState);
            shouldRefetchEmployees(true);
        }
    }, [pagesState]);
    
    return <MainTab
        type={"mainOnly"}
        name={name}
        main={{
            title: intl.formatMessage({id: "table.list", defaultMessage: "List"}),
            content: (<EmployeeTable
                request={employeesRequest}
                payload={employeesPayload}
                setPayload={setEmployeesPayload}
                reloadData={shouldRefetchEmployees}
                loading={loadingEmployees}
                detailData={employeeDetail}
                setDetailData={setEmployeeDetail}
            />)
        }}
    />
}