import {useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {Action} from "Core/Table/data-table-actions";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import {DataTablePayload, DataTableRequest} from "Core/Table/interfaces";
import {TWorkingPlanBreak} from "../../Core/WorkingPlan/interfaces";
import {parseStringTimeToObject} from "../../Core/WorkingPlan/functions";

export const employeesUrl: string = 'employee-management/employees/';

export type WorkingTimeProgress = {
    plannedHours: string
    actualHours: string
    percentage: number
}

export type WorkingTimePlanData = {
    startingDate: string
    sundayTimeFrom: string
    sundayTimeTo: string
    mondayTimeFrom: string
    mondayTimeTo: string
    tuesdayTimeFrom: string
    tuesdayTimeTo: string
    wednesdayTimeFrom: string
    wednesdayTimeTo: string
    thursdayTimeFrom: string
    thursdayTimeTo: string
    fridayTimeFrom: string
    fridayTimeTo: string
    saturdayTimeFrom: string
    saturdayTimeTo: string
}

export type BreakTimeSettings = {
    time_from: string,
    time_to: string
}

export type Employee = {
    userUUID: string
    fullName: string
    firstName: string
    lastName: string
    username?: string
    email: string
    nfcUUID: string
    role: string
    employmentType: string
    entranceDate: string
    phoneNumber: string
    stateCode: string
    isTestUser: boolean
    loggedIn: boolean
    breakTimeSettings: BreakTimeSettings[]
    totalVacationDays?: string
    vacationDays?: string
    qrCode: string
    isActive: boolean
    password?: string
    confirmPassword?: string
    accountRole?: string
    totalSickNoteDays?: string
    authCode?: string
    personalNumber?: string
}

export type EmployeeCreationProps = {
    firstName: string
    lastName: string
    email: string
    nfcUUID: string
    roleID: string
    employmentTypeID: string
    vacationDays: string
    entranceDate: string
    phoneNumber: string
    stateCode: string
    password: string
    confirmPassword: string
    isTestUser: boolean
    breakTimeConfigData: BreakTimeSettings
    workingTimePlanData?: WorkingTimePlanData
}

export type EmployeesRequest = {
    recordsTotal: number
    recordsFiltered: number
    next: string
    previous: string
    data: Employee[]
}

export type EmployeesPayload = {
    start: number
    length: number
    search?: string
    ordering?: string
    "filter.roleID"?: string
    "filter.employmentType"?: string
}

export type Role = {
    pk: string
    name: string,
    totalEmployees: string
}

export type EmploymentType = {
    pk: string
    type: string
    monthlyWorkHours: number
    weeklyWorkHours: number
    monthlyWorkHoursString: string
    weeklyWorkHoursString: string
    totalEmployees: number
}

export type federalState = {
    stateCode: string
    stateName: string
}

export type WorkingPlanRecord = {
    pk?: string,
    day: string,
    dayName?: string,
    startTime?: string | null,
    endTime?: string | null,
    duration?: string
    breakTimeRecords?: TWorkingPlanBreak[]
}

export type EmploymentInfo = {
    type?: string
    monthlyWorkHours?: string
    monthlyWorkingHours?: string
    vacationDaysPerYear?: string
    remainingVacationDays?: string
}

export type TWorkingPlan = {
    pk: string,
    dateFrom: string,
    dateTo: string,
    employmentInfo: EmploymentInfo,
    records: WorkingPlanRecord[]
}

export const useFetchEmployeesDataTable = (payload: EmployeesPayload) => {
    const [shouldReFetch, reFetch] = useState(false)
    const [employeesRequest, setData] = useState<EmployeesRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permission = useContext(PermissionContext);

    const fetchEmployeesData = () => {
        setLoading(true);
        axiosInstance.get(employeesUrl,{
            params: payload
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                reFetch(false);
            })
    }

    useEffect( () => {
        fetchEmployeesData();
    }, [payload, shouldReFetch, permission.isAdmin]);


    return { employeesRequest, loading, error, reFetch };
}

export const useEmployeeWorkingPlans = (userUUID?: string) => {
    const [shouldReFetch, setRefetchWorkingPlans] = useState(false)
    const [data, setData] = useState<TWorkingPlan[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();

    const fetchWorkingPlans = (signal: AbortSignal) => {


        setLoading(true);
        axiosInstance.get(employeesUrl + `${userUUID}/working-plans/`, {
            signal
        })
            .then((res) => {
                let updatedResponse = res.data.map((workingPlan: TWorkingPlan) => {
                    return {
                        ...workingPlan,
                        records: workingPlan.records.map((record) => {
                            return {
                                ...record,
                                breakTimeRecords: record.breakTimeRecords?.map((breaktime) => {
                                    let breaktimeStartString = String(breaktime.startTime)
                                    let breaktimeEndString = String(breaktime.endTime)

                                    return {
                                        ...breaktime,
                                        startTime: parseStringTimeToObject(breaktimeStartString),
                                        endTime: parseStringTimeToObject(breaktimeEndString),
                                        startTimeString: breaktime.startTime,
                                        endTimeString: breaktime.endTime
                                    }
                                })
                            }
                        })
                    }
                })
                setData(updatedResponse);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                setRefetchWorkingPlans(false);
            })
    }

    useEffect( () => {
        const abortController = new AbortController()
        const signal = abortController.signal

        if (userUUID) fetchWorkingPlans(signal);

        return () => {
            abortController.abort()
        }
    }, [userUUID, shouldReFetch])

    return { data, loading, error, setRefetchWorkingPlans };
}


export type EmployeeFormatted = {
    rowID: string
    actions: Action[]
} & Employee




export interface EmployeeInfoRequest extends DataTableRequest {}

export interface EmploymentInfoPayload extends DataTablePayload {}

export type EmploymentInfoH = {
    pk: string,
    userUUID: string,
    date: string,
    dateFrom: string,
    dateTo: string,
    type: string,
    monthlyWorkHours: number,
    vacationDaysPerYear: number,
    remainingVacationDays: number
}


export interface EmploymentInfoHFormatted extends EmploymentInfoH{
    weeklyWorkHours: string
    actions: Action[]
}


export const useFetchEmploymentInfos = (payload: EmploymentInfoPayload, userUUID: string) => {
    const [shouldReFetch, reFetchEmployeeInfos] = useState(false)
    const [employeeInfos, setData] = useState<EmployeeInfoRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    });
    const [loadingEmployeeInfos, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();

    const fetchEmployeeInfosData = (signal: AbortSignal) => {
        setLoading(true);
        axiosInstance.get(employeesUrl + `${userUUID}/employment-infos/`,{
            params: payload,
            signal
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                reFetchEmployeeInfos(false);
            })
    }

    useEffect( () => {
        const abortController = new AbortController()
        const signal = abortController.signal

        fetchEmployeeInfosData(signal);

        return () => {
            abortController.abort()
        }
    }, [payload, shouldReFetch, userUUID])

    return { employeeInfos, loadingEmployeeInfos, error, reFetchEmployeeInfos };
}


export const useEmployeeWorkingTimeProgress = (uuid?: string) => {
    const [workingTimeProgress, setWorkingTimeProgress] = useState<WorkingTimeProgress>({
        percentage: 0,
        plannedHours: "00h 00m",
        actualHours: "00h 00m"
    })
    const [loadingWorkingTimeProgress, setLoading] = useState<boolean>(false)
    const [shouldReload, reLoadWorkingTimeProgress] = useState<boolean>(false)
    const axiosInstance = useAxiosInstance()

    const fetchWorkingTimeProgress = (signal: AbortSignal) => {
        setLoading(true)
        axiosInstance.get(employeesUrl + `${uuid}/working-time-progresses/`, {signal})
            .then((res) => setWorkingTimeProgress(res.data))
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false)
                reLoadWorkingTimeProgress(false)
            })
    }

    useEffect(() => {
        const abortController = new AbortController()
        const signal = abortController.signal

        if (!uuid) return;

        fetchWorkingTimeProgress(signal)

        return () => {
            abortController.abort()
        }
    }, [uuid, shouldReload]);

    return {workingTimeProgress, loadingWorkingTimeProgress, reLoadWorkingTimeProgress}
}