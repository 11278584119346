import {useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
export const employmentTypeUrl: string = 'employee-management/employment-types/';

export type employmentType = {
    pk: string
    type: string
    monthlyWorkHours: number
    weeklyWorkHours: number
    monthlyWorkHoursString: string
    weeklyWorkHoursString: string
    totalEmployees: number
    employees?: {
        firstName: string
        lastName: string
        fullName: string
    }[]
}

export type employmentTypesRequest = {
    recordsTotal: number
    recordsFiltered: number
    next: string
    previous: string
    data: employmentType[]
}

export type employmentTypesPayload = {
    start: number
    length: number
    search?: string
    ordering?: string
}

export const useFetchEmploymentTypesDataTable = (payload: employmentTypesPayload) => {
    const axiosInstance = useAxiosInstance();
    const [shouldReFetch, reFetch] = useState(false)
    const [dataRequest, setData] = useState<employmentTypesRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const permissionContext = useContext(PermissionContext);

    const fetchEmployeesData = () => {
        setLoading(true);
        axiosInstance.get(employmentTypeUrl,{
            params: payload
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                reFetch(false);
            })
    }

    useEffect( () => {
        fetchEmployeesData();
    }, [payload, shouldReFetch, permissionContext.isAdmin])

    return { dataRequest, loading, error, reFetch };
}