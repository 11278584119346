import {Popover, PopoverContent, PopoverTrigger} from "../../components/popover";
import {Button} from "../../components/button";
import * as React from "react";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../../components/command";
import {ChevronDown} from "lucide-react";
import {FormattedMessage} from "react-intl";
import {cn} from "../../lib/utils";
import {HoverCard, HoverCardContent, HoverCardTrigger} from "../../components/hover-card";

export type SearchInputValue = {
    key: string
    value: string
}

interface SearchInputI {
    title: string
    values: SearchInputValue[]
    value?: string | null
    onChange: Dispatch<SetStateAction<string | undefined | null>>
    fallbackSentence?: string
    isDisabled?: boolean
}

interface SearchInputGroupI {
    title: string
    values: SearchInputValue[]
    onChange: Dispatch<SetStateAction<string | undefined | null>>
    setOpen: Dispatch<SetStateAction<boolean>>
    selectedValue?: SearchInputValue | null
    open: boolean
}

const SearchInput: React.FC<SearchInputI> = (
    {
        title,
        values,
        value,
        onChange,
        fallbackSentence,
        isDisabled
    }
) => {
    const [open, setOpen] = useState<boolean>(false);
    const formattedValues = values?.map((v) => {
        return {
            key: typeof v === "object" ? v.key : v,
            value: typeof v === "object" ? v.value : v,
        }
    }) ?? [];
    const [selectedValue, setSelectedValue] = useState<SearchInputValue | null>();

    useEffect(() => {
        if (!values) return

        let keyFromValuesArray = values.find(elem => elem.value === value)?.key;

        if (keyFromValuesArray && value) {
            let selectedValue = {
                key: keyFromValuesArray,
                value: value
            };
            setSelectedValue(selectedValue);
        } else {
            setSelectedValue(null);
        }
    }, [value, values]);

    return(
        <Popover modal={true} open={open} onOpenChange={setOpen}>
            {isDisabled ? (
                <div className={"cursor-not-allowed"}>
                    <HoverCard>
                        <HoverCardTrigger>
                            <Button disabled variant="outline" size="sm" className="h-10 flex flex-row justify-between w-full">
                                {title}<ChevronDown className="h-4 w-4 opacity-50" />
                            </Button>
                        </HoverCardTrigger>
                        <HoverCardContent className="text-gray-500 text-xs">
                            {fallbackSentence}
                        </HoverCardContent>
                    </HoverCard>
                </div>
            ) : (
                <PopoverTrigger asChild className="w-full">
                    <Button variant="outline" size="sm" className="h-8 flex flex-row justify-between px-3 py-5">
                        {selectedValue?.key ? (<span>{selectedValue.key}</span>) : (<>{title}</>)}
                        <ChevronDown className="h-4 w-4 opacity-50" />
                    </Button>
                </PopoverTrigger>
            )}
            <SearchInputGroup
                title={title}
                values={formattedValues}
                onChange={onChange}
                setOpen={setOpen}
                selectedValue={selectedValue}
                open={open}
            />
        </Popover>
    )
}

const SearchInputGroup: React.FC<SearchInputGroupI> = (
    {
        title,
        values,
        setOpen,
        selectedValue,
        onChange,
        open
    }
) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (open && event.key === "Enter") {
            setOpen(false);
        }
    };

    return (
        <PopoverContent className={"min-w-fit p-0"} align="start" onKeyDown={handleKeyDown}>
            <Command>
                <CommandInput placeholder={title}/>
                <CommandList>
                    <CommandEmpty>
                        <FormattedMessage id={"no_results"} defaultMessage={"No results found."}/>
                    </CommandEmpty>
                    <CommandGroup>
                        <CommandItem
                            value={undefined}
                            onSelect={() => {
                                onChange(null)
                                setOpen(false)
                            }}
                        >----</CommandItem>
                        {values.map((iterationValue, key) => {
                            return (
                                <CommandItem
                                    onSelect={(value) => {
                                        let isSameValue = selectedValue?.key.toLowerCase() === value.toLowerCase();
                                        if (selectedValue) {
                                            onChange(isSameValue ? null : iterationValue.value)
                                        } else {
                                            onChange(iterationValue.value)
                                        }
                                        setOpen(false);
                                    }}
                                    key={key}
                                    className={cn(selectedValue?.key === iterationValue.key ? "focus:bg-accent" : "bg-none" )}
                                >
                                    {iterationValue?.key}
                                </CommandItem>
                            )
                        })}
                    </CommandGroup>
                </CommandList>
            </Command>
        </PopoverContent>
    )
}

export default SearchInput;
