import React, {useEffect, useState} from "react";
import DetailViewCard from "../../Core/components/detail-view-card";
import RegularNameAvatar from "../../Core/components/regular-name-avatar";
import {PublicHoliday, publicHolidaysUrl} from "../Controllers/public-holidays-controller";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {nullOrUndefined} from "../../Core/constants/variables";
import {FormattedMessage, useIntl} from "react-intl";

type PublicHolidayDetailViewProps = {
    publicHoliday: PublicHoliday | null
    loading?: boolean
}

const PublicHolidayDetailView: React.FC<PublicHolidayDetailViewProps> = ({publicHoliday, loading}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const [publicHolidayDetails, setPublicHolidayDetails] = useState<PublicHoliday>()
    const publicHolidayEmployees = publicHolidayDetails?.employees?.map((employee) => {
        return (
            <div className="p-2">
                <RegularNameAvatar username={employee.fullName}/>
            </div>
        )
    });


    const fetchPublicHolidayData = () => {
        if (publicHoliday && publicHoliday?.pk) {
            axiosInstance.get(publicHolidaysUrl + `${publicHoliday?.pk}/`)
                .then((res) => setPublicHolidayDetails(res.data))
                .catch((err) => console.log(err));
        }
    }

    useEffect(() => {
        fetchPublicHolidayData();
    },[publicHoliday])

    return (
        <>
            {publicHoliday ? (
                <>
                    <DetailViewCard
                        header={intl.formatMessage({id: "attendance.publicHolidays.detail.information", defaultMessage: "Information"})}
                        rows={[
                            {
                                name: intl.formatMessage({id: "attendance.publicHolidays.detail.holidayName", defaultMessage: "Holiday Name"}),
                                value: publicHoliday.name ?? nullOrUndefined
                            },
                            {
                                name: intl.formatMessage({id: "attendance.publicHolidays.detail.state", defaultMessage: "State"}),
                                value: publicHoliday.stateName ?? nullOrUndefined
                            },
                            {
                                name: intl.formatMessage({id: "attendance.publicHolidays.detail.date", defaultMessage: "Date"}),
                                value: publicHoliday.date ?? nullOrUndefined
                            },
                        ]}
                    />
                    <DetailViewCard
                        sizeFit={true}
                        header={intl.formatMessage({id: "attendance.publicHolidays.detail.employeesInHoliday", defaultMessage: "Employees In Holiday"})}
                        rows={[
                            {jsxElement: (
                                <div className="flex flex-col max-h-[23rem] overflow-y-scroll">
                                    {(publicHolidayEmployees && publicHolidayEmployees.length) ? (
                                        <div>{publicHolidayEmployees}</div>
                                    ) : (
                                        <FormattedMessage id={"no_results"} defaultMessage={"No results found."}/>
                                    )}
                                </div>
                            )}
                        ]}
                    />
                </>
            ) : (
                <FormattedMessage
                    id={"pick_a_record"}
                    defaultMessage={"Select a record to view details."}
                />
            )}
        </>
    )
}

export default PublicHolidayDetailView;