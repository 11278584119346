import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow,} from "../../../components/table";
import {CaretUpDown} from "@phosphor-icons/react";
import React, {ReactNode, SetStateAction, useContext, useEffect, useState} from "react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from "../../../components/dropdown-menu";
import {Button} from "../../../components/button";
import {ArrowDownIcon, ArrowUpIcon} from "@radix-ui/react-icons";
import {nullOrUndefined} from "../../constants/variables";
import {Skeleton} from "../../../components/skeleton";
import {getRandomInt} from "../../functions/random";
import {FormattedMessage} from "react-intl";
import {Action, DataTableActions} from "./data-table-actions";
import {cn} from "../../../lib/utils";
import {PermissionContext} from "../../utilities/PermissionProvider";
import * as z from "zod"

export type BaseAttributes = {
    rowID?: string;
    className?: string
    actions?: ReactNode | Action[];
};

export type ColumnExperimental<T> = {
    backendKey: keyof T | 'actions'
    frontendKey: string
    sortingValue?: string
    sortable?: boolean
    isSorted?: 'none' | 'asc' | 'desc'
    index?: number
    isPublic?: boolean
    visible?: boolean
}

export type TablePropsExperimental<T extends BaseAttributes> = {
    columns: ColumnExperimental<T>[]
    setColumns: React.Dispatch<SetStateAction<ColumnExperimental<T>[]>>
    data: T[]
    loading?: boolean
    showLoadingState?: boolean
    detailData?: any
    setDetailData?: any
    setPayload?: any
    dailyTrack?: ReactNode
    payload?: any
};


const DataTableExperimental = <T extends BaseAttributes>({
    data,
    loading,
    showLoadingState,
    setDetailData,
    detailData,
    setPayload,
    columns,
    setColumns,
    dailyTrack,
    payload
} : TablePropsExperimental<T>) => {
    const permissionContext = useContext(PermissionContext)

    useEffect(() => {
        if (setDetailData) {
            let isInArray = data.find((elem) =>
                (elem?.hasOwnProperty("rowID") && detailData?.hasOwnProperty("rowID")) &&
                (elem["rowID"] === detailData["rowID"])
            )
            if (!isInArray) {
                setDetailData(null);
            }
        }
    }, [data]);

    useEffect(() => {
        setColumns(() => {
            return columns.filter(elem => {
                if (
                    elem?.isPublic
                ) {
                    return elem
                } else if (permissionContext.isAdmin || (!permissionContext.isAdmin && elem.frontendKey !== "actions")) {
                    return elem
                }
            })
        })
    }, [permissionContext]);

    if (dailyTrack) {
        return (
            <div>
                <Table>
                    <TableBody>
                        {(data.length > 0 && (loading || !loading)) ? (
                            <>{dailyTrack}</>
                        ) : (!loading && data.length === 0) ? (
                            <TableRow>
                                <TableCell className="h-24 text-center">
                                    <FormattedMessage id="no_results" defaultMessage="No results"/>
                                </TableCell>
                            </TableRow>
                        ) : (
                            <div className="m-4 mt-14">
                                {Array.from({length: payload.length ?? 1}).map((_) => (
                                    <div className={"grid grid-cols-11 gap-6 h-[80px] items-center"}>
                                        <div className={"col-span-2"}>
                                            <Skeleton className={`w-1/${getRandomInt(2, 4)} h-[15px]`}/>
                                        </div>
                                        <div className={"col-span-8"}>
                                            <Skeleton className={`w-full h-[15px]`}/>
                                        </div>
                                        <div className={"col-span-1"}>
                                            <Skeleton className={`w-full h-[15px]`}/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                        }
                    </TableBody>
                </Table>
            </div>
        )
    }


    const TableRows = () => {
        return (
            <>
                {data.map((row, rowIndex) => {
                    const isSelected = (() => {
                        if (row?.hasOwnProperty("rowID")) {
                            return detailData?.rowID === data[rowIndex]?.rowID;
                        }
                        return undefined;
                    })();
                    return (
                        <TableRow key={rowIndex}
                            className={cn(row?.className)}
                            onClick={(e) => {
                                if (row?.hasOwnProperty("rowID") && setDetailData) {
                                    setDetailData(data[rowIndex])
                                }
                            }}
                            data-state={isSelected && "selected"}
                        >
                            {columns.map((col, colIndex) => {
                                if (col.frontendKey === "actions" && row.actions) {
                                    return (
                                        <TableCell key={colIndex} className="px-6 py-4 whitespace-nowrap">
                                            <DataTableActions actions={row.actions}/>
                                        </TableCell>
                                    )
                                }
                                return (
                                    <TableCell key={colIndex} className="px-6 py-4 whitespace-nowrap">
                                        {(row?.[col.backendKey] && String(row[col.backendKey]).length) ?
                                        <>{row?.[col.backendKey]}</> :
                                        nullOrUndefined}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })}
            </>
        )
    };


    const NoResults = () => (
        <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
                <FormattedMessage id="no_results" defaultMessage="No results"/>
            </TableCell>
        </TableRow>
    );


    const SkeletonLoading = () => (
        <>
            {Array.from({length: payload?.length ?? 10}).map((_) => (
                <TableRow className="h-14">
                    {columns.map((col, index) => (
                        <TableCell>
                            <Skeleton className={`w-1/${getRandomInt(2, 6)} h-[10px] rounded-full`}/>
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </>
    );


    return (
        <div className="border rounded-md">
            {columns ? (
                <Table>
                <TableHeader>
                    <TableRow>
                        {columns.map((col, index) => (
                            <TableHead
                                key={index}
                                className="px-6 py-3 text-left font-medium text-gray-500 text-xs tracking-wider"
                            >
                                {(col.frontendKey.toLowerCase() !== "actions" && col.sortable) ? (
                                    <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Button
                                            variant="ghost"
                                            size="sm"
                                            className="-ml-3 h-8 data-[state=open]:bg-accent"
                                        >
                                            <span>{col.frontendKey}</span>
                                            {col.isSorted === "desc" ? (
                                                <ArrowDownIcon width={14} height={14} className="ml-2" />
                                            ) : col.isSorted === "asc" ? (
                                                <ArrowUpIcon width={14} height={14} className="ml-2" />
                                            ) : (
                                                <CaretUpDown size={14} className="ml-2" />
                                            )}
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent align="start">
                                        <DropdownMenuItem
                                            onClick={() => setPayload((prevState: any) => {
                                                setColumns((prevState) => {
                                                    return prevState.map((currCol) => {
                                                        if (currCol.backendKey === col.backendKey) {
                                                            return {
                                                                ...currCol,
                                                                isSorted: "asc"
                                                            }
                                                        }
                                                        return currCol;
                                                    })
                                                })
                                                return {
                                                    ...prevState,
                                                    start: 0, // start ordering from the beginning
                                                    ordering: handleAsc(prevState.ordering, (col.sortingValue ?? col.backendKey).toString())
                                                }
                                            })}
                                        >
                                            <ArrowUpIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                                            Asc
                                        </DropdownMenuItem>
                                        <DropdownMenuItem
                                            onClick={() => setPayload((prevState: any) => {
                                                setColumns((prevState) => {
                                                    return prevState.map((currCol) => {
                                                        if (currCol.backendKey === col.backendKey) {
                                                            return {
                                                                ...currCol,
                                                                isSorted: "desc"
                                                            }
                                                        }
                                                        return currCol;
                                                    })
                                                })
                                                return {
                                                    ...prevState,
                                                    start: 0, // start ordering from the beginning
                                                    ordering: handleDesc(prevState.ordering, (col.sortingValue ?? col.backendKey).toString())
                                                }
                                            })}
                                        >
                                            <ArrowDownIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                                            Desc
                                        </DropdownMenuItem>
                                        <DropdownMenuSeparator />
                                        <DropdownMenuItem
                                            onClick={() => setPayload((prevState: any) => {
                                                setColumns((prevState) => {
                                                    return prevState.map((currCol) => {
                                                        if (currCol.backendKey === col.backendKey) {
                                                            return {
                                                                ...currCol,
                                                                isSorted: "none"
                                                            }
                                                        }
                                                        return currCol;
                                                    })
                                                })
                                                return {
                                                    ...prevState,
                                                    start: 0, // start ordering from the beginning
                                                    ordering: removeOrdering(prevState.ordering, (col.sortingValue ?? col.backendKey).toString())
                                                }
                                            })}
                                        >
                                            <CaretUpDown className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                                            None
                                        </DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                                ) : (col.frontendKey.toLowerCase() !== "actions") ? (
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        className="-ml-3 h-8 data-[state=open]:bg-accent capitalize"
                                    >
                                        <span>{col.frontendKey}</span>
                                    </Button>
                                ) : (col.frontendKey.toLowerCase() === "actions") ? (
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        className="-ml-3 h-8 data-[state=open]:bg-accent capitalize"
                                    >
                                        <FormattedMessage id={"table.actions"} defaultMessage={"Actions"}/>
                                    </Button>
                                ) : null}
                            </TableHead>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {(
                        ((!showLoadingState && data.length && (loading || !loading))) ||
                        (showLoadingState && data.length && !loading)) &&
                        !permissionContext.isChanchingState ? (
                        <TableRows/>
                    ) : ((!showLoadingState && !loading && data.length === 0) ||
                        (showLoadingState && !loading && data.length === 0)) &&
                        !permissionContext.isChanchingState ? (
                        <NoResults/>
                    ) : <SkeletonLoading/>}
                </TableBody>
            </Table>
            ) : (
                <>Nothing To Show !</>
            )}
        </div>
    );
};

const handleAsc = (prevState: string, value: string) => {
    let ordering: string;
    ordering = prevState;
    if (value === "actions") return ordering;

    const re = new RegExp(value);
    if (re.test(ordering)) {
        const index = ordering.search(value);
        if (ordering[index - 1] === "-") {
            // remove - when trying to order asc after desc
            ordering = ordering.slice(0, index - 1) + ordering.slice(index);
        }
    } else {
        ordering += (ordering) ? ',' + value : value
    }
    return ordering.startsWith(',') ? ordering.slice(1) : ordering;
}

const handleDesc = (prevState: string, value: string) => {
    let ordering: string;
    ordering = prevState;
    if (value === "actions") return ordering;

    const re = new RegExp(value);
    if (re.test(ordering)) {
        const index = ordering.search(value);
        if (ordering[index - 1] !== "-") {
            // remove - when trying to order asc after desc
            ordering = ordering.slice(0, index) + "-" + ordering.slice(index);
        }
    } else {
        ordering += (ordering) ? `,-${value}` : `-${value}`
    }
    return ordering.startsWith(',') ? ordering.slice(1) : ordering;
}

const removeOrdering = (prevState: string, value: string) => {
    let ordering: string;
    ordering = prevState;
    if (value === "actions") return ordering;

    const re = new RegExp(value);
    if (re.test(ordering)) {
        const regex = new RegExp(`,?-?${value}`);
        ordering = ordering.replace(regex, '');
    }

    return ordering.startsWith(',') ? ordering.slice(1) : ordering;
}


export default DataTableExperimental;

