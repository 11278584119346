import {useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../../Core/utilities/AxiosInstance";
import {PermissionContext} from "../../../Core/utilities/PermissionProvider";

const dashboardProjectsUrl = "dashboard/employees/";

type Employee = {
    pk: string
    userUUID: string
    fullName: string
    roleName: string
}

type Service = {
    pk: string
    name: string
    employees: any[]
    employeesCount: number
    employeesSpentTime: string
}

export type Project = {
    pk: string,
    name: string,
    startDate: string,
    endDate: string,
    employees: Employee[]
    "services": Service[]
    employeesCount: number,
    completed: boolean,
    status: string,
    userIsActive: boolean
}


export const useDashboardEmployeeProjects = (userUUID: string) => {
    const [projects, setProjects] = useState<Project[]>([]);
    const [loadingProjects, setLoading] = useState<boolean>(false);
    const [reloadProjects, setReloadProjects] = useState<boolean>(false);
    const [projectErrors, setErrors] = useState<any>({});
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchProjects = () => {
        setLoading(true);
        axiosInstance.get(dashboardProjectsUrl + `${userUUID}/projects/`)
            .then((res) => setProjects(res.data))
            .catch((err) => setErrors(err.response?.data))
            .finally(() => {
                setLoading(false);
                setReloadProjects(false);
            })
    }

    useEffect(() => {
        fetchProjects();
    }, [userUUID, reloadProjects, permissionContext.isAdmin]);

    return {projects, loadingProjects, setReloadProjects, projectErrors};
}