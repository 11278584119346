import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "components/button"
import {
    Form, FormControl, FormField, FormItem, FormLabel,
    FormMessage,
} from "components/form"
import {DialogClose, DialogFooter} from "components/dialog";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {employeesUrl} from "EmployeeManagement/Controllers/employee-controller";
import {toast} from "components/use-toast";
import {Input} from "components/input";
import {FormattedMessage, useIntl} from "react-intl";
import {ServerErrorsType, useServerErrors} from "Core/functions/use-server-errors";
import ErrorMessageList from "Core/components/ErrorMessageList";
import {Switch} from "components/switch";
import moment from "moment";
import {TaimInfo} from "Core/components/taim-info";
import {InputDateTimePicker} from "Core/components/input-date-picker";
import {systemsUrl} from "../index";
import {System} from "../Controller/systems-controller";

const addSystemFormSchema = z.object({
    name: z.any(),
    subdomainName: z.any()
});

const SYSTEM_DEFAULT_VALUES = {
    name: "",
    subdomainName: ""
}

type AddSystemProps = {
    reFetch?: any
}

export const AddSystem: React.FC<AddSystemProps>  = ({reFetch}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof addSystemFormSchema>>({
        resolver: zodResolver(addSystemFormSchema),
        defaultValues: SYSTEM_DEFAULT_VALUES
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();

    const onSubmit = (values: z.infer<typeof addSystemFormSchema>) => {
        axiosInstance.post(systemsUrl, {
            ...values
        })
            .then((res) => {
                setApiErrors({});
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
                reFetch(true);
            })
            .catch((err) => {
                setApiErrors(err.response?.data)
            })
    };


    return (
    <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>
                            <FormattedMessage
                                id={"sd.systems.name"}
                                defaultMessage={"Name"}
                            />
                        </FormLabel>
                        <FormControl>
                            <Input
                                placeholder={intl.formatMessage({
                                    id: "sd.systems.namePlaceholder",
                                    defaultMessage: "Enter a name"
                                })}
                                {...field}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <FormField
                control={form.control}
                name="subdomainName"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>
                            <FormattedMessage
                                id={"sd.systems.subdomainName"}
                                defaultMessage={"Sub-Domain"}
                            />
                        </FormLabel>
                        <FormControl>
                            <Input
                                placeholder={intl.formatMessage({
                                    id: "sd.systems.subdomainNamePlaceholder",
                                    defaultMessage: "Enter a Sub-Domain"
                                })}
                                {...field}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <ErrorMessageList errors={errors.detailErrors}/>
            <ErrorMessageList errors={errors.nonFieldErrors}/>

            <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                <DialogClose ref={closeModal as any} asChild>
                    <Button variant="outline">
                        <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                    </Button>
                </DialogClose>
                <Button
                    variant="taimDefault"
                    type="submit"
                ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
            </DialogFooter>
        </form>
    </Form>
    )
}



const editSystemsFormSchema = z.object({
    name: z.any(),
    subdomainName: z.any(),
});

type EditSystemProps = {
    system: System
    reFetch?: any
}

export const EditSystem: React.FC<EditSystemProps>  = ({
    system,
    reFetch,
}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof editSystemsFormSchema>>({
        resolver: zodResolver(editSystemsFormSchema),
        defaultValues: system
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();

    const onSubmit = (values: z.infer<typeof editSystemsFormSchema>) => {
        axiosInstance.put(systemsUrl + `${system.pk}/`, values)
            .then((res) => {
                setApiErrors({});
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
                reFetch(true);
            })
            .catch((err) => {
                setApiErrors(err.response?.data)
            })
    };

    return (
    <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>
                            <FormattedMessage
                                id={"sd.systems.name"}
                                defaultMessage={"Name"}
                            />
                        </FormLabel>
                        <FormControl>
                            <Input
                                placeholder={intl.formatMessage({
                                    id: "sd.systems.namePlaceholder",
                                    defaultMessage: "Enter a name"
                                })}
                                {...field}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <FormField
                control={form.control}
                name="subdomainName"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>
                            <FormattedMessage
                                id={"sd.systems.subdomainName"}
                                defaultMessage={"Sub-Domain"}
                            />
                        </FormLabel>
                        <FormControl>
                            <Input
                                placeholder={intl.formatMessage({
                                    id: "sd.systems.subdomainNamePlaceholder",
                                    defaultMessage: "Enter a Sub-Domain"
                                })}
                                {...field}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <ErrorMessageList errors={errors.detailErrors}/>
            <ErrorMessageList errors={errors.nonFieldErrors}/>

            <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                <DialogClose ref={closeModal as any} asChild>
                    <Button variant="outline">
                        <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                    </Button>
                </DialogClose>
                <Button
                    variant="taimDefault"
                    type="submit"
                ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
            </DialogFooter>
        </form>
    </Form>
    )
}