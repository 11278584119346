import {ReactNode, useEffect, useState} from "react";
import {array} from "zod";

export type TStep = {
    name: string
    title: string
    isOpen: boolean
    content?: ReactNode
    formExtention?: {
        hasError: boolean
    }
}


export const useSteps = (inputSteps: string[], entryStep: string, isFormContext = false) => {
    const [steps, setSteps] = useState<TStep[]>([])
    const [currentStep, setCurrentStep] = useState<string>(entryStep)

    useEffect(() => {
        if (inputSteps.length && steps.length === 0) {
            let currentIndex = inputSteps.findIndex(elem => elem === entryStep)
            setSteps(inputSteps.map((elem, index) => ({
                name: elem,
                title: elem.split('_').map((word) => word.slice(0,1).toUpperCase() + word.slice(1,)).join(' '),
                isOpen: index <= currentIndex,
                ...(isFormContext && {formExtention: {hasError: false}})
            })))
        }
    }, [inputSteps.length]);

    useEffect(() => {
        /*if (steps.length > 0) {
            let currentIndex = getCurrentStepIndex()
            setSteps(prevState => {
                return prevState.map((elem, index) => ({
                    ...elem,
                    isOpen: index <= currentIndex
                }))
            })
        }*/
    }, [currentStep]);

    const closeSteps = (fromStep?: string) => {
        if (steps.length > 0) {
            let currentIndex = fromStep ? steps.findIndex(elem => elem.name === fromStep) : getCurrentStepIndex()
            setSteps(prevState => {
                return prevState.map((elem, index) => ({
                    ...elem,
                    isOpen: index <= currentIndex
                }))
            })
        }
    }

    const goToStep = (newStep: string) => {
        let stepIndex = steps.findIndex(elem => elem.name === newStep)
        if (stepIndex || stepIndex.toString() === "0") {
            setSteps(prevState => prevState.map((step, index) => {
                if (stepIndex === index) {
                    setCurrentStep(step.name)
                    return {
                        ...step,
                        isOpen: true
                    }
                }
                return step
            }))
        }
    }

    const goToNextStep = () => {
        let nextStepIndex = steps.findIndex(elem => elem.name === currentStep) + 1
        if (nextStepIndex) {
            setSteps(prevState => prevState.map((step, index) => {
                if (nextStepIndex === index) {
                    setCurrentStep(step.name)
                    return {
                        ...step,
                        isOpen: true
                    }
                }
                return step
            }))
        }
    }

    const goToPreviousStep = (closeNextSteps: boolean = false) => {
        let previousStep = steps.findIndex(elem => elem.name === currentStep) - 1
        if (previousStep >= 0) {
            steps.forEach((step, index) => {
                if (previousStep === index) {
                    setCurrentStep(step.name)
                    if (closeNextSteps) closeSteps(step.name)
                }
            })
        }
    }

    const getCurrentStepIndex = (): number => {
        let currentStepElement = steps.find(e => e.name === currentStep)
        return currentStepElement ? steps.indexOf(currentStepElement) : 0
    }

    const refineTo = (length = 3): TStep[] => {
        if (steps.length < 3) return steps

        let firstOfArray = steps[0]
        let lastOfArray = steps[steps.length - 1]

        switch (currentStep) {
            case (firstOfArray.name) :
                return steps.slice(0,3)
            case (lastOfArray.name) :
                return steps.slice(-3)
            default :
                let currentStepIndex = getCurrentStepIndex()
                return steps.slice(currentStepIndex-1, currentStepIndex+2)
        }
    }

    const hydrateStep = (step: string, content: ReactNode) => {
        setSteps(prevState => {
            return prevState.map(elem => {
                if (elem.name === step) {
                    return {
                        ...elem,
                        content
                    }
                }
                return elem
            })
        })
    }

    const attachError = (step: string, hasError: boolean) => {
        if (isFormContext) {
            setSteps(prevState => {
                return prevState.map(elem => {
                    if (elem.name === step) {
                        return {
                            ...elem,
                            formExtention: {hasError: hasError}
                        }
                    }
                    return elem
                })
            })
        }
    }

    return {steps, setSteps, goToStep, goToNextStep, goToPreviousStep, currentStep, setCurrentStep, refineTo, hydrateStep, attachError, closeSteps}
}