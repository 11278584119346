import React, {useContext, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Button} from "components/button";
import {MainTab, PageActions, PageState} from "Core/layout/page-shape";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import {DataTablePayload} from "../../Core/Table/interfaces";
import {SystemsTable} from "../DataTables/systems-table";
import {useFetchSystems} from "../Controller/systems-controller";
import {AddSystem} from "../Forms/system-forms";


const syestemsPayloadInitialState: DataTablePayload = {
    length: 10,
    start: 0,
    ordering: "",
    "filter.startDate": "",
    "filter.endDate": ""
};

type SyestemsProps = {
    name: string
    pagesState?: PageState[]
}


export const Systems: React.FC<SyestemsProps> = ({name, pagesState}
) => {
    const intl = useIntl();
    const permissionContext = useContext(PermissionContext);
    const [payload,setPayload] = useState<DataTablePayload>({
        start: 0,
        length: 10,
    })
    const {
        systemsData,
        shouldRefetchSystems,
        loadingSystems
    } = useFetchSystems(payload)

    return <MainTab
        type={"mainOnly"}
        name={name}
        main={{
            title: intl.formatMessage({id: "table.list", defaultMessage: "List"}),
            content: <SystemsTable
                payload={payload}
                setPayload={setPayload}
                loading={loadingSystems}
                request={systemsData}
                reloadData={shouldRefetchSystems}
            />
        }}
        actions={[
            {
                type: "modal",
                button: (
                    <Button variant={"taimDefault"}>
                        <FormattedMessage id={"sd.systems.createButton"} defaultMessage={"Create System"}/>
                    </Button>
                ),
                header: intl.formatMessage({id: "sd.systems.createButton", defaultMessage: "Create System"}),
                children: <AddSystem reFetch={shouldRefetchSystems}/>
            }
        ].filter((elem) => {
            if (permissionContext.isAdmin) return elem
        }) as PageActions[]}
    />
}