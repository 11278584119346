import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/alert-dialog"
import {ReactEventHandler} from "react";
import {FormattedMessage, useIntl} from "react-intl";

type action = {
    name: string
    handleEvent?: ReactEventHandler
}

type ActionAlertDialog = {
    name: string
    title: string
    content: string
    action?: action
}


const ActionAlertDialog = (props: ActionAlertDialog) => {
    const intl = useIntl();
    return(<>

        <AlertDialog>
            <AlertDialogTrigger className="flex-1 flex flex-row justify-start w-full">{props.name}</AlertDialogTrigger>
            <AlertDialogContent >
                <AlertDialogHeader>
                    <AlertDialogTitle>{props.title}</AlertDialogTitle>
                    <AlertDialogDescription>
                        {props.content}
                    </AlertDialogDescription>
                </AlertDialogHeader>
            <AlertDialogFooter>
                <AlertDialogCancel>
                    <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                </AlertDialogCancel>
                <AlertDialogAction
                    variant="destructive"
                    onClick={props.action?.handleEvent}
                >{props.action?.name ? props.action?.name : intl.formatMessage({id: "button.continue", defaultMessage: "Continue"})}</AlertDialogAction>
            </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>


    </>)
}

export default ActionAlertDialog;