import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {Skeleton} from "../../../../components/skeleton";
import {FormattedMessage, useIntl} from "react-intl";
import DataTableOld, {ColumnOld} from "../../../../Core/Table/data-table-old";
import {Button} from "../../../../components/button";
import {useAxiosInstance} from "../../../../Core/utilities/AxiosInstance";
import {employeesUrl} from "../../../Controllers/employee-controller";
import {toast} from "components/use-toast";
import {TaimModal} from "Core/components/taim-modal";
import DataTablePagination from "Core/Table/data-table-pagination";
import {TManualVacationCarryOver, useManualVacationCarryOvers} from "./controller";
import {DataTablePayload} from "../../../../Core/Table/interfaces";
import {Action} from "../../../../Core/Table/data-table-actions";
import {AddManualVacationCarryOver, EditManualVacationCarryOver} from "./forms";
import {PermissionContext} from "../../../../Core/utilities/PermissionProvider";

type TManualVacationCarryOverFormatted = TManualVacationCarryOver & {
    actions: Action[]
}

type ManualVacationCarryOversProps = {
    userUUID: string
    reloadVacationPlanTab: boolean
    setReloadVacationPlanTab: Dispatch<SetStateAction<boolean>>
}

export const ManualVacationCarryOvers: React.FC<ManualVacationCarryOversProps> = (
    {
        userUUID,
        reloadVacationPlanTab,
        setReloadVacationPlanTab
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);
    const [payload, setPayload] = useState<DataTablePayload>({
        start: 0,
        length: 5,
    })
    const {
        manualVacationCarryOvers,
        loadingManualVacationCarryOvers,
        reFetchManualVacationCarryOvers
    } = useManualVacationCarryOvers(payload, userUUID, reloadVacationPlanTab);
    const [formattedData, setFormattedData] = useState<TManualVacationCarryOverFormatted[]>([])

    const columns: ColumnOld[] = [
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.manualVacationCarryOvers.year",
                defaultMessage: "year"
            }),
            backendKey: "year"
        },
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.manualVacationCarryOvers.days",
                defaultMessage: "Days"
            }),
            backendKey: "days"
        },
        {
            frontendKey: "actions",
            backendKey: "actions"
        },
    ]

    useEffect(() => {
        const formatData: TManualVacationCarryOverFormatted[] = manualVacationCarryOvers.data.map((manualVacationCarryOver) => {
            return {
                ...manualVacationCarryOver,
                actions: [
                    {
                        type: "edit",
                        content: <EditManualVacationCarryOver
                            userUUID={userUUID}
                            manualVacationCarryOver={manualVacationCarryOver}
                            reFetch={reFetchManualVacationCarryOvers}
                            setReloadVacationPlanTab={setReloadVacationPlanTab}
                        />
                    },
                    {type: "delete", handleAction: () => handleDelete(manualVacationCarryOver.pk)}
                ]
            }
        })

        setFormattedData(formatData);
    }, [manualVacationCarryOvers.data]);

    const handleDelete = (id: string) => {
        axiosInstance.delete(employeesUrl + `${userUUID}/manual-vacation-carry-overs/${id}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                reFetchManualVacationCarryOvers(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    return <div className="flex flex-col gap-4">
        {formattedData.length && (loadingManualVacationCarryOvers || !loadingManualVacationCarryOvers) ? (
            <>
                <DataTableOld columns={columns} data={formattedData}/>
                <DataTablePagination request={manualVacationCarryOvers} payload={payload} setPayload={setPayload}/>
            </>
        ) : (!formattedData.length && loadingManualVacationCarryOvers) ? (
            <div className="flex flex-col gap-4">
                <Skeleton className="w-1/2 h-[15px]"/>
                <Skeleton className="w-1/3 h-[15px]"/>
                <Skeleton className="w-1/2 h-[15px]"/>
                <Skeleton className="w-full h-[15px]"/>
                <Skeleton className="w-1/6 h-[15px]"/>
            </div>
        ) : (
            <FormattedMessage id="no_results" defaultMessage="No results"/>
        )}
        {permissionContext.isAdmin && (
            <div>
                <TaimModal
                    button={(
                        <Button variant="taimDefault2">
                            <FormattedMessage id="employees.detail.manualVacationCarryOvers.create" defaultMessage="Add Vacation Carry Over"/>
                        </Button>
                    )}
                    header={intl.formatMessage({id: "employees.detail.manualVacationCarryOvers.create", defaultMessage: "Add Vacation Carry Over"})}
                    dialogContentClassName={"min-w-[600px]"}
                >
                    <AddManualVacationCarryOver
                        userUUID={userUUID}
                        reFetch={reFetchManualVacationCarryOvers}
                        setReloadVacationPlanTab={setReloadVacationPlanTab}
                    />
                </TaimModal>
            </div>
        )}
    </div>
}