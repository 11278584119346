import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "../../components/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/form"
import { Input } from "../../components/input"
import {DialogClose, DialogFooter} from "../../components/dialog";
import React, {useRef, useState} from "react";
import {DatePicker} from "../../components/date-picker";
import {federalState} from "../../EmployeeManagement/Controllers/employee-controller";
import formatDate from "../../Core/functions/format-date";
import {PublicHoliday, publicHolidaysUrl} from "../Controllers/public-holidays-controller";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {toast} from "../../components/use-toast";
import {FormattedMessage, useIntl} from "react-intl";
import {ServerErrorsType, useServerErrors} from "../../Core/functions/use-server-errors";
import ErrorMessageList from "../../Core/components/ErrorMessageList";
import SearchInput from "../../Core/components/search-input";

const formSchema = z.object({
    stateCode: z.string().optional(),
    name: z.string().optional(),
    date: z.date().optional()
})

type CreatePublicHolidayFormProps = {
    federalStates?: federalState[]
    reFetch: any
}

export const CreatePublicHolidayForm: React.FC<CreatePublicHolidayFormProps> = ({federalStates, reFetch}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();
    const federalStatesKeyValuePairs = federalStates?.map((federalState, index) => {
        return {
            key: federalState.stateName,
            value: federalState.stateCode
        }
    }) || []

    const createPublicHoliday = (publicHoliday: PublicHoliday) => {
        axiosInstance.post(publicHolidaysUrl, publicHoliday)
            .then((res) => {
                setApiErrors({});
                reFetch(true);
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response.data)
            })
    }

    async function onSubmit(values: z.infer<typeof formSchema>) {
        const data = {
            ...values,
            ...(values.date ? {date: formatDate(values.date)}: null)
        };
        if (values.stateCode !== "no-value") createPublicHoliday(data as any);
        else {
            form.setError('stateCode', {message: "Select a state"})
        }
    }

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <FormField
                    control={form.control}
                    name="stateCode"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.publicHolidays.state"}
                                    defaultMessage={"State"}
                                />
                            </FormLabel>

                            <SearchInput
                                title={intl.formatMessage({
                                    id: "attendance.publicHolidays.statePlaceholder",
                                    defaultMessage: "Select a Federal State"
                                })}
                                values={federalStatesKeyValuePairs}
                                value={field.value}
                                onChange={field.onChange}
                            />
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.publicHolidays.name"}
                                    defaultMessage={"Public Holiday Name"}
                                />
                            </FormLabel>
                            <FormControl>
                                <Input placeholder={intl.formatMessage({
                                    id: "attendance.publicHolidays.name",
                                    defaultMessage: "Enter a name"
                                })} {...field} />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="date"
                    render={({ field }) => (
                        <FormItem className="flex flex-col">
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.publicHolidays.date"}
                                    defaultMessage={"Date"}
                                />
                            </FormLabel>
                            <FormControl>
                                <DatePicker date={field.value} setDate={field.onChange}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <ErrorMessageList errors={errors.detailErrors}/>
                <ErrorMessageList errors={errors.nonFieldErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}
