import React, {Dispatch, SetStateAction, useEffect} from "react";
import {TWorkingPlan, EmploymentType, employeesUrl} from "../../Controllers/employee-controller";
import {Card} from "../../../components/card";
import {nullOrUndefined} from "../../../Core/constants/variables";
import {Button} from "../../../components/button";
import {DialogClose, DialogFooter} from "../../../components/dialog";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger
} from "../../../components/dropdown-menu";
import {MoreHorizontal} from "lucide-react";
import {useAxiosInstance} from "../../../Core/utilities/AxiosInstance";
import {toast} from "../../../components/use-toast";
import {FormAddWorkingPlan, FormEditWorkingPlan} from "./working-plan-forms";
import {FormattedMessage, useIntl} from "react-intl";
import {TaimModal} from "../../../Core/components/taim-modal";
import moment from "moment";
import {ArrowRight} from "@phosphor-icons/react";
import {FormAddEmploymentType} from "../EmploymentHistory/employment-history-forms";
import DetailViewCard from "../../../Core/components/detail-view-card";
import ActionsAlertDialog from "../../../Core/components/action-alert-dialog";
import {Badge} from "../../../components/badge";

type ManageWorkingPlansProps = {
    workingPlans: TWorkingPlan[]
    selectedWorkingPlan?: TWorkingPlan
    employmentType?: EmploymentType
    userUUID: string
    rePopulateRecord: (employeeID: string) => void
    hasEmploymentType?: boolean
    reFetch: Dispatch<SetStateAction<boolean>>
    reLoadWorkingTimeProgress: Dispatch<SetStateAction<boolean>>
    reFetchWorkingPlans: Dispatch<SetStateAction<boolean>>
}

type WorkingPlanRecordComponentProps = {
    title: string
    workingPlan: TWorkingPlan
    userUUID: string
    workingPlanID: string
    reFetch: any
    rePopulateRecord: (employeeID: string) => void
    reLoadWorkingTimeProgress: Dispatch<SetStateAction<boolean>>
    isCurrentWorkingPlan: boolean
}

const WorkingPlanRecordComponent: React.FC<WorkingPlanRecordComponentProps> = (
    {
        title,
        workingPlan,
        userUUID,
        workingPlanID,
        reFetch,
        rePopulateRecord,
        reLoadWorkingTimeProgress,
        isCurrentWorkingPlan
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();

    const deleteWorkingPlan = () => {
        axiosInstance.delete(employeesUrl + `${userUUID}/working-plans/${workingPlanID}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                reFetch(true);
                reLoadWorkingTimeProgress(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    return (
        <Card className="p-4 flex flex-col gap-4">
            <div className="flex flex-row justify-between items-center">
                <div className="flex gap-2">
                    <div className="text-[16px] font-semibold">{title}</div>
                    {isCurrentWorkingPlan && <Badge variant="green">
                        <FormattedMessage id={"current"} defaultMessage={"Current"}/>
                    </Badge>}
                </div>
                <div>
                    <DropdownMenu modal={false}>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only">Open menu</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <TaimModal
                                header={intl.formatMessage({id: "action.name.edit", defaultMessage: "Edit"})}
                                button={
                                    <DropdownMenuItem className={"cursor-pointer"} onSelect={(e) => e.preventDefault()}>
                                        <FormattedMessage id={"action.name.edit"} defaultMessage={"Edit"} />
                                    </DropdownMenuItem>
                                }
                                dialogTriggerClassName={"w-full"}
                                dialogContentClassName={"min-w-[850px]"}
                            >
                                <FormEditWorkingPlan
                                    userWorkingPlan={workingPlan}
                                    userUUID={userUUID}
                                    reFetch={reFetch}
                                    rePopulateRecord={rePopulateRecord}
                                    reLoadWorkingTimeProgress={reLoadWorkingTimeProgress}
                                />
                            </TaimModal>
                            <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                                <ActionsAlertDialog
                                    name={intl.formatMessage({id: "action.name.delete", defaultMessage: "Delete"})}
                                    title={intl.formatMessage({id: "toast.areYouSure", defaultMessage: "Are you sure ?"})}
                                    content={intl.formatMessage({id: "toast.areYouSure.description", defaultMessage: "This action can not be undone."})}
                                    action={{
                                        name: intl.formatMessage({id: "button.continue",defaultMessage: "Continue"}),
                                        handleEvent: deleteWorkingPlan
                                    }}
                                />
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>
            <div className="flex flex-row justify-between items-start">
                <div className="text-gray-400">
                    <FormattedMessage id={"employees.forms.manageWorkingPlans.workingPlan.type"} defaultMessage={"Type"}/>
                </div>
                <div className="w-1/2">{workingPlan?.employmentInfo?.type ?? nullOrUndefined}</div>
            </div>
            <div className="flex flex-row justify-between items-start">
                <div className="text-gray-400">
                    <FormattedMessage id={"employees.forms.manageWorkingPlans.workingPlan.startDate"} defaultMessage={"Start Date"}/>
                </div>
                <div className="w-1/2">{moment(workingPlan?.dateFrom).format("ddd DD MMM YYYY") ?? nullOrUndefined}</div>
            </div>
            <div className="flex flex-row justify-between items-start">
                <div className="text-gray-400">
                    <FormattedMessage id={"employees.forms.manageWorkingPlans.workingPlan.endDate"} defaultMessage={"End Date"}/>
                </div>
                <div className="w-1/2">{workingPlan?.dateTo ?
                    moment(workingPlan?.dateTo).format("ddd DD MMM YYYY") :
                    intl.formatMessage({id: "allTime", defaultMessage: "'All Time'"})
                }</div>
            </div>
        </Card>
    )
}

export const ManageWorkingPlans: React.FC<ManageWorkingPlansProps> = ({
    workingPlans,
    userUUID,
    reFetch,
    rePopulateRecord,
    hasEmploymentType,
    reLoadWorkingTimeProgress,
    reFetchWorkingPlans,
    selectedWorkingPlan
}) => {
    const intl = useIntl();

    if (hasEmploymentType !== undefined && !hasEmploymentType) return (
        <div className="flex flex-col gap-4">
            <FormattedMessage
                id={"employees.forms.manageWorkingPlans.null"}
                defaultMessage={"User does not have an employment type, click below to add it."}
            />
            <TaimModal
                header={intl.formatMessage({id: "employment", defaultMessage: "Add Employment Type"})}
                button={(
                    <div className={"group flex justify-end items-center space-x-1 text-xs cursor-pointer text-blue-900 hover:underline"}>
                        <span>Add Employment Type</span>
                        <ArrowRight className={"group-hover:translate-x-0.5"}/>
                    </div>
                )}
                dialogContentClassName={"min-w-[600px]"}
                dialogTriggerClassName={"w-full"}
            >
                <FormAddEmploymentType
                    userUUID={userUUID}
                    reFetch={reFetch}
                    reLoadWorkingTimeProgress={reLoadWorkingTimeProgress}
                    rePopulateRecord={rePopulateRecord}
                    reFetchWorkingPlans={reFetchWorkingPlans}
                />
            </TaimModal>
        </div>
    )

    return (
        <div className="flex flex-col gap-4">
            <div className="flex justify-end">
                <TaimModal
                    header={intl.formatMessage({id: "employees.forms.manageWorkingPlans.add", defaultMessage: "Add Working Plan"})}
                    button={<Button variant="taimDefault2">
                        <FormattedMessage id={"employees.forms.manageWorkingPlans.add"} defaultMessage={"Add Working Plan"}/>
                    </Button>}
                    dialogContentClassName={"min-w-[850px]"}
                >
                    <FormAddWorkingPlan
                        userUUID={userUUID}
                        reFetch={reFetch}
                        rePopulateRecord={rePopulateRecord}
                        reLoadWorkingTimeProgress={reLoadWorkingTimeProgress}
                    />
                </TaimModal>
            </div>
            {workingPlans.length ? workingPlans.map((workingPlan, index) => (
                <WorkingPlanRecordComponent
                    title={`${intl.formatMessage({
                        id: "employees.forms.manageWorkingPlans.workingPlan",
                        defaultMessage: "Working Plan"
                    })} ${workingPlans.length - index}`}
                    workingPlan={workingPlan}
                    userUUID={userUUID}
                    workingPlanID={workingPlan.pk}
                    reFetch={reFetch}
                    rePopulateRecord={rePopulateRecord}
                    reLoadWorkingTimeProgress={reLoadWorkingTimeProgress}
                    isCurrentWorkingPlan={workingPlan.pk === selectedWorkingPlan?.pk}
                />
            )) : (
                <DetailViewCard rows={[
                    {jsxElement: <FormattedMessage id={"no_results"} defaultMessage={"No results found."}/>}
                ]}/>
            )}
            <DialogFooter className="flex flex-row justify-between">
                <DialogClose asChild>
                    <Button variant="outline">
                        <FormattedMessage id={"button.leave"} defaultMessage={"Leave"}/>
                    </Button>
                </DialogClose>
            </DialogFooter>
        </div>
    )
}